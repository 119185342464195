import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Flex } from '@components';
import {Form, Input, Checkbox, Row, Col, Button, Modal,
        Upload, Icon, message,Spin,InputNumber,Radio } from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import {createUuid, getLoginUser} from '@utils/NHCore';
import NHFetch from "@utils/NHFetch";
import View from "@pages/SelfHelp/applyDorm/view";
const Dragger = Upload.Dragger;
import createHistory from 'history/createHashHistory';
import CommonTip from "@pages/SelfHelp/common";
import NHSelect from "@components/common/NHSelect";
import NextButton from '@pages/SelfHelp/common/nextIndex'
import {mkbz} from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";
import NHFormItem from "@components/common/NHFormItem";
const history = createHistory();
const { TextArea } = Input;
const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 }
};

@Form.create()
export default class JhysChannel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mkbz:'GD032',
            hjmc:'',
            uploading:false,
            loading:false,
            previewVisible: false,
            fileList: [],
            data:{},//绿色通道申请信息
            nextUrl:'',
            search:'',
            operable:true,//是否显示环节
            nonOperationalData:{},//不可操作的数据
            sqlyFlag:false , //申请理由flag
            jymFlag:false ,  //6位数回执校验码flag
            judgeImgList: ['.png', '.PNG', '.jpg', '.JPG', '.bpm', '.BPM', '.jpeg', '.JPEG'],   //可以导入的图片格式
            judgeFileList: ['.pdf', '.PDF', '.doc', '.DOC', '.zip', '.ZIP', '.txt', '.TXT', '.xls', '.XLS', '.rar', '.RAR'],  //可以导入的非图片格式
            sfzsValue:"",//是否住宿
            edit:"",
            oldFileList:{},
            saveloading:false
        };
    }

    componentDidMount(){
        this.queryQztjAndYlhj((operable) => {
                if (operable) {
                    this.loadInfo();
                    this.updateZzbdBlzt();
                }
            });
       
    }

    //迎新现场已办理时迎新网站自动办理
    updateZzbdBlzt = () =>{
            let zzbdhjid = this.getQueryString('pkid');
            NHFetch('/zzbd/updateZzbdBlzt', 'GET',{zzbdhjid:zzbdhjid,hjdm:'GD032'}).then(res => {
                if(res.data){
                    this.reloadMenu();
                }
            })
    }

    //查询前置条件和依赖环节
    queryQztjAndYlhj=(callback)=>{
        let xsid = getLoginUser()?getLoginUser().userId:'';
        if(!xsid){
            this.setState({
                nonOperationalData:{nologin:true},
                operable:false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', {xsid:xsid,zzbdhjid:zzbdhjid})
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj'];
                    this.setState({nonOperationalData:res.data});
                    if(kfsj && kfsj['YXSJ']!='1'){
                         this.setState({operable:false});
                    }

                    if(ylhj && ylhj.length>0){
                        this.setState({operable:false});
                    }

                    if(qzhj && qzhj.length>0){
                        this.setState({operable:false});
                    }
                    if (nohj) {
                        this.setState({ operable: false });
                    }
                    if (callback) {
                        callback(this.state.operable);
                    }
                    
                }
            });
    }

    getQueryString =(name)=> {
        return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[, ''])[1].replace(/\+/g, '%20')) || null
    }

    setMenuList=(menuList)=>{
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem,index)=>{
            if(elem.HJDM==bz){
                let hjmc = elem.HJMC;
                this.setState({hjmc:hjmc})
                nextCount = index+1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if(menu){
            let hjdm = menu.HJDM;
            if(hjdm.indexOf('GD')==0){
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl:gdUrl,
                    search:'pkid='+menu.PKID,
                })
            }else if(hjdm.indexOf('ZDY')==0){
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl:zdyUrl,
                    search:'pkid='+menu.PKID+'&mkbz='+hjdm+'&lx='+menu.LX,
                })
            }
        }else{
            this.setState({
                nextUrl:'',
                search:'',
            })
        }
    }

    //读取学生的是否住宿信息
    loadInfo=(flag)=>{
        console.log(flag);
        let xsid = getLoginUser().userId;
        NHFetch('/zzbd/xzsfzz/getXzsfzzByXsid', 'GET',{xsid:xsid}).then(resData => {
            if (resData && resData.data) {
                if(flag == "edit"){
                    this.setState({
                        edit:true
                    })
                }
                if(resData.data.SFZS){
                    this.setState({
                        sfzsValue:resData.data.SFZS
                    })
                }
                this.setState({ data: resData.data });
                let fileList = [];
                let oldFileList = [];
                if(resData.data && resData.data['WSSQFJ']){
                    //格式化对于附件取值
                    let file = {};
                    oldFileList = JSON.parse(resData.data['WSSQFJ']);
                    if(oldFileList.length>0){
                        oldFileList.map((item,index)=>{
                            console.log(item,index);
                            file = {
                                uid: item.fjid,
                                name: item.fjmc,
                                response:{
                                    data:{
                                        ssbh:item.fjid,
                                        fjmc:item.fjmc,
                                    }
                                },
                                status: 'done'
                            }
                            fileList.push(file);
                        })
                    }
                    console.log("file",fileList);
                }
                this.setState({fileList:fileList,oldFileList:oldFileList});
                this.props.form.setFieldsValue({wssqfj:{fileList:this.state.fileList}});
            }else{
                this.setState({ data:{},edit:true});
                this.setState({fileList:[]});
                this.props.form.resetFields();
            }
        });
    }

    // 转化base64
    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    // 点击文件链接或预览图标时的回调
    handlePreview = file => {
        const isImage = /^image\/.*$/i.test(file.type);
        if (isImage) {
            if (file) {
                this.getBase64(file, imageUrl => {
                    this.setState({
                        previewImage: imageUrl,
                        previewVisible: true
                    });
                });
            } else {
                this.setState({
                    previewImage: file.url || file.thumbUrl,
                    previewVisible: true
                });
            }
        }else if(file && file['response'] && file['response']['data']){//其他情况
            let isImage = true;
            let fjmc = file['response']['data']['fjmc'];
            let ssbh = file['response']['data']['ssbh'];
            if (
                !this.setJudge(fjmc,this.state.judgeImgList)
            ) {
                isImage = false;
            }

            if (isImage) {
                this.showPreview(`anonymity/docrepo/download/file?attachmentId=${ssbh}`);
            } else {
                window.open(`anonymity/docrepo/download/file?attachmentId=${ssbh}`);
            }
        }
    };

    showPreview=(url)=>{
        this.setState({
            previewImage:url,
            previewVisible: true
        });
    }



    // 关闭图片弹窗
    handleCancel = () => this.setState({ previewVisible: false });

    // 提交
    handleSubmit = e => {
        const xsid = getLoginUser() ? getLoginUser().userId : '';
            if(!xsid){
                message.error("登录已过期，请重新登录！");
                return;
        }
        this.setState({loading:true,saveloading:true});
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if(err){
                this.setState({loading:false,saveloading:false});
                return false;
            }
            console.log("提交数据----------------",values);
            let pkid = values['pkid'];
            let params  = {};
        if(values['sfzs'] == 0){
            // 附件
            const wssqfj = values['wssqfj'];
            let isEmpty = false;
            if(wssqfj){
                if(wssqfj['fileList'] && wssqfj['fileList'].length>0){
                    isEmpty = false;
                }else{
                    isEmpty = true;
                }
            }else{
                isEmpty = true;
            }
            if(isEmpty){
                this.props.form.setFields({
                    wssqfj:{
                        value:undefined,
                        errors:[new Error('请上传附件')],
                    }
                });
                this.setState({loading:false,saveloading:false});
                return false;
            }

            let size = wssqfj['fileList'].length;
            let file = wssqfj['fileList'].filter((file) => {
                if (file.status==='error') {
                    return false;
                }
                return true;
            });
            if(file.length!=size){
                this.props.form.setFields({
                    wssqfj:{
                        value:wssqfj,
                        errors:[new Error('附件上传出错，请重新上传.')],
                    }
                });
                this.setState({loading:false,saveloading:false});
                return false;
            }
            let fjid = '';
            let fjmc = '';
            let fileList = wssqfj['fileList'];
            let fjlist = [];
            for(let i=0;i<fileList.length;i++){
                fjid = fileList[i].response.data.ssbh;
                fjmc = fileList[i].name;
                fjlist.push({
                    fjid:fjid,
                    fjmc:fjmc
                });
            }
            console.log(this.state.oldFileList,fjlist);
            // `${fjid};${fjmc}`
            let fjIdAndMc = JSON.stringify(fjlist);
            let wsdz = values['wsdz'];
            let jhr = values['jhr'];
            let jhrgx = values['jhrgx'];
            let jhrdh = values['jhrdh'];

            let sfzs = values['sfzs'];
            let shzt = '0';
            
            params = {
                wssqfj:fjIdAndMc,
                wsdz:wsdz,
                jhrgx:jhrgx,
                jhr:jhr,
                jhrdh:jhrdh,
                xsid:xsid,
                sfzs:sfzs,
                shzt:shzt
            }
        }else{
            let sfzs = values['sfzs'];
            let shzt = '0';
            params = {
                xsid:xsid,
                sfzs:sfzs,
                shzt:shzt
            }
        }

            console.log(params);
                NHFetch('/zzbd/xzsfzz/updateXzsfzz',"POST",params)
                    .then(res => {
                        if (res) {
                            if(res.data=='1'){
                                message.success("是否选择住宿申请保存成功。");
                                this.loadInfo();
                                this.reloadMenu();
                                this.setState({ loading: false,edit:false ,saveloading:false})
                            }else{
                                this.setState({ loading: false,saveloading:false })
                            }
                        }else{
                            this.setState({ loading: false,saveloading:false })
                        }
                    }).catch((res) => {
                    this.setState({ loading: false ,saveloading:false})
                    return res;
                });
        });
    };

    handleChange = ({ fileList } ) => {
            let count = 0;
            fileList.map((elem)=>{
                if(elem && elem['status'] && elem['status']==='uploading'){
                    this.setState({uploading:true});
                    return;
                }else if(elem && elem['status']){
                    count++;
                }
            })
            if(count>0){
                this.setState({uploading:false});
            }
    }

    //判断文件类型是否正常  name：文件名称  map：类型范围数组  true正常
    setJudge = (name, map) => {
        let flag = false;
        map.map(item => {
            if (name.indexOf(item) !== -1) {
                flag = true;
            }
        })
        return flag;
    }

    beforeUpload = (file) => {
            if (
                !(this.setJudge(file.name, this.state.judgeImgList) ||
                this.setJudge(file.name, this.state.judgeFileList))
            ) {
                message.error('不能上传该格式文件');
                return false;
            } else {
                return true;
            }
    }

    remove=(ssbh)=>{
        let url = `api/docrepo/attachment/sourceId/delete?souerceId=${ssbh}`;
        NHFetch(url,"POST").then(res => {
            if (res.data && res.data > 0) {
                message.success('已删除材料.');
            }
        });
    }

    //重新加载菜单
    reloadMenu=()=>{
        this.refs.siderMenu.reloadMenuList()
    }

    // 是否住宿回调
    sfzsOnChange = (e) => {
        this.setState({
            sfzsValue: e.target.value,
        });
    }

    render() {
        const {form} = this.props;
        const { getFieldDecorator } = this.props.form;
        const { previewImage, previewVisible,jymFlag,sqlyFlag,sfzsValue,edit  } = this.state;
        const props = {
            name: 'file',
            action: 'zuul/docrepo/upload',
            data: { uuid: createUuid(), type: '1' },

            onRemove: file => {
                this.setState(({ fileList }) => {
                    const index = fileList.indexOf(file);
                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    this.props.form.setFieldsValue({wssqfj:{fileList:newFileList}});
                    return {
                        fileList: newFileList
                    };
                },()=>{
                    if(file.response && file.response.data && file.response.data.ssbh){
                        this.remove(file.response.data.ssbh);
                    }
                });
            },
            beforeUpload: file => {
                if(!this.beforeUpload(file)){
                    return false;
                }
                this.setState(({ fileList }) => ({
                    fileList: [...fileList, file]
                }));
                return true;
            },
            onChange:file=>{this.handleChange(file)},
            onPreview: this.handlePreview,
            fileList:this.state.fileList,
           /*  defaultFileList:[
                {
                    uid: '1',
                    name: 'xxx.png',
                    status: 'done',
                    response: 'Server Error 500', // custom error message to show
                    url: 'http://www.baidu.com/xxx.png',
                  },
            ] */
        };
        // console.log("---------prop------",props,this.state.fileList);
        const data = this.state.data;
        return (
            <div style={{position:'relative'}}>{this.state.operable?
                <div>
                <Flex className="w1180 mt-20" align="start">
                    <SiderMenu
                                ref={"siderMenu"}
                                setMenuList={this.setMenuList}
                                title="自助报到"
                                mkbz = {this.state.mkbz}
                          /*     active={4}*/
                                menuType='自助报到'/>
                    <Flex.Item className="page_con_r" styleName="">
                        <Breadcrumb
                            noLine
                            list={[
                                { name: '首页', url: '/index' },
                                { name: '自助报到', url: '/register' },
                                { name: this.state.hjmc }
                            ]}
                        />
                        <div className="box">
                            <Hint/>
                            {edit?<Form  onSubmit={this.handleSubmit}>
                                <TitleTop title="申请信息" extra={false} />
                                <div className="p-sw">
                                    <div style={{display:'none'}}>
                                    <NHFormItem id={'pkid'} initialValue={data['PKID']?data['PKID']:""} form={form}>
                                        <Input/>
                                    </NHFormItem>
                                    </div>
                                    <div style = {{marginLeft:"30px"}}>
                                        <NHFormItem id={'sfzs'} required={true} initialValue={data['SFZS']?data['SFZS']:""} form={form} formItemLayout={{labelCol:{span:4},wrapperCol:{span:16}}} label="是否选择住宿">
                                            <Radio.Group onChange={this.sfzsOnChange} value={sfzsValue} style={{display:"flex",margin:"10px"}}>
                                                <Radio value={'1'}>是</Radio>
                                                <Radio value={'0'}>否</Radio>
                                            </Radio.Group>
                                        </NHFormItem>
                                    </div>
                                    {sfzsValue=='0' && <div className="form_item" style={{marginLeft: '7%'}}>
                                        <NHFormItem id={'wsdz'} required={true}  initialValue={data['WSDZ']?data['WSDZ']:""} form={form}  formItemLayout = {formItemLayout} label="潜江详细地址">
                                            <Input style={{width:'100%'}} placeholder="请填写详细地址" />
                                        </NHFormItem>
                                        <NHFormItem id={'jhr'} required={true}  initialValue={data['JHR']?data['JHR']:""} form={form}  formItemLayout = {formItemLayout} label="监护人">
                                            <Input style={{width:'100%'}} placeholder="请填写监护人" />
                                        </NHFormItem>
                                        <NHFormItem id={'jhrgx'} required={true}  initialValue={data['JHRGX']?data['JHRGX']:""} form={form}  formItemLayout = {formItemLayout} label="与监护人关系">
                                            <NHSelect sign={"DMK_XSXX_JTCYGX"} onChange={(v) => { }}/>
                                        </NHFormItem>
                                        <NHFormItem id={'jhrdh'} required={true}  initialValue={data['JHRDH']?data['JHRDH']:""} form={form}  formItemLayout = {formItemLayout} label="监护人电话">
                                            <Input placeholder="请填写监护人电话" />
                                        </NHFormItem>
                                    </div>}
                                </div>
                                {sfzsValue=='0' && <TitleTop title="申请材料" extra={false} />}
                                {sfzsValue=='0' && <div className="p-sw">
                                    <div className="form_item" style={{marginLeft: '7%'}}>
                                        <FormItem
                                            labelCol= { {span: 8} }
                                            wrapperCol={ {span: 16} }
                                            label="附件走读申请书"
                                        >
                                            {getFieldDecorator('wssqfj', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message:
                                                            '请上传附件'
                                                    }
                                                ],
                                                initialValue: data['WSSQFJ'] ? data['WSSQFJ'] : '',
                                            })(
                                                <Dragger {...props}>
                                                    <p className="ant-upload-drag-icon">
                                                        <Icon type="inbox" />
                                                    </p>
                                                    <Spin spinning={this.state.uploading}/>
                                                    <p className="ant-upload-text font-14">
                                                        点击此处或拖拽文件到此
                                                    </p>
                                                </Dragger>
                                            )}
                                        </FormItem>
                                    </div>
                                </div>}
                                <div className="text-center mt-50">
                                    <Button type="primary" htmlType="submit" loading={this.state.saveloading}>
                                        保存
                                    </Button>
                                    <NextButton nextUrl={this.state.nextUrl}  search={this.state.search}/>
                                </div>
                            </Form>:<View  reloadMenu={this.reloadMenu} nextUrl={this.state.nextUrl} loadInfo={()=>this.loadInfo("edit")}  search={this.state.search} showPreview={this.showPreview} setJudge={this.setJudge}  data={this.state.data} judgeImgList={this.state.judgeImgList}/>}
                        </div>
                    </Flex.Item>
                </Flex>
                <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <img
                        alt="example"
                        style={{ width: '100%' }}
                        src={previewImage}
                    />
                </Modal>
                    <Spin style={{ position:'absolute',top:'300px',left:'60%'}} spinning={this.state.loading}/> </div>:<CommonTip hjmc = {"绿色通道申请"}  key={"GD032"} mkbz='GD032' nonOperationalData={this.state.nonOperationalData}/>}
            </div>

        );
    }
}
