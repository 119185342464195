import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Flex } from '@components';
import {Form, Input, Checkbox, Row, Col, Button, Modal,
        Upload, Icon, message,Spin,InputNumber,Radio,DatePicker,Tooltip} from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import {createUuid, getLoginUser} from '@utils/NHCore';
import NHFetch from "@utils/NHFetch";
import View from "@pages/SelfHelp/ChannelWydx/view";
const Dragger = Upload.Dragger;
import createHistory from 'history/createHashHistory';
import CommonTip from "@pages/SelfHelp/common";
import NHSelect from "@components/common/NHSelect";
import NextButton from '@pages/SelfHelp/common/nextIndex'
import {mkbz} from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";
import NHFormItem from "@components/common/NHFormItem";
import {NHConfirm} from "@components/common/NHModal";
import moment from 'moment';
const history = createHistory();
//确认对话框
const confirm = Modal.confirm;
const { TextArea } = Input;
const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;
const RadioGroup = Radio.Group;
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 }
};
const formItemLayoutJx = {
    labelCol: { span: 7 },
    wrapperCol: { span: 15 }
};

@Form.create()
export default class ChannelWydx extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mkbz:'GD005_12',
            hjmc:'',
            uploading:false,
            loading:false,
            CheckboxGroup:[],//绿色通道办理原因
            data:{},//绿色通道申请信息
            nextUrl:'',
            search:'',
            operable:true,//是否显示环节
            nonOperationalData:{},//不可操作的数据
            sqlyFlag:false , //申请理由flag
            // jymFlag:false ,  //6位数回执校验码flag
            sqValue:"",//是否申请绿色通道
            edit:true,
            jfhjzt:false,//缴费环节状态
            BlztList:[],//办理状态
            saveLoading:false,
            blyy: '', // 选中的办理原因
            sfhj: false, // 判断选择的办理原因是否是缓交
        };
    }

    componentDidMount(){
        this.loadLstdInfo();
        this.queryQztjAndYlhj((operable) => {
                if (operable) {
                    this.loadLstdBlyy();
                    this.updateZzbdBlzt();
                }
            });
        this.getSfsqLstd();
    }

    // 获取绿色通道是否办理选项
    getSfsqLstd=()=>{
        NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList','get','sign=dmk_YXXT_LSTD_BLZT').then(res=>{
            if(res){
                if(res.data){
                    this.setState({
                        BlztList:res.data
                    })
                }
            }
        })
    }

    //迎新现场已办理时迎新网站自动办理
    updateZzbdBlzt = () =>{
            let zzbdhjid = this.getQueryString('pkid');
            NHFetch('/zzbd/updateZzbdBlzt', 'GET',{zzbdhjid:zzbdhjid,hjdm:'GD005_12'}).then(res => {
                if(res.data){
                    this.reloadMenu();
                }
            })
    }

    //查询前置条件和依赖环节
    queryQztjAndYlhj=(callback)=>{
        let xsid = getLoginUser()?getLoginUser().userId:'';
        if(!xsid){
            this.setState({
                nonOperationalData:{nologin:true},
                operable:false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', {xsid:xsid,zzbdhjid:zzbdhjid})
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj'];
                    this.setState({nonOperationalData:res.data});
                    if(kfsj && kfsj['YXSJ']!='1'){
                         this.setState({operable:false});
                    }

                    if(ylhj && ylhj.length>0){
                        this.setState({operable:false});
                    }

                    if(qzhj && qzhj.length>0){
                        this.setState({operable:false});
                    }
                    if (nohj) {
                        this.setState({ operable: false });
                    }
                    if (callback) {
                        callback(this.state.operable);
                    }
                    
                }
            });
    }

    getQueryString =(name)=> {
        return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[, ''])[1].replace(/\+/g, '%20')) || null
    }

    setMenuList=(menuList)=>{
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem,index)=>{
            if(elem.HJDM==bz){
                let hjmc = elem.HJMC;
                this.setState({hjmc:hjmc})
                nextCount = index+1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if(menu){
            let hjdm = menu.HJDM;
            if(hjdm.indexOf('GD')==0){
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl:gdUrl,
                    search:'pkid='+menu.PKID,
                })
            }else if(hjdm.indexOf('ZDY')==0){
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl:zdyUrl,
                    search:'pkid='+menu.PKID+'&mkbz='+hjdm+'&lx='+menu.LX,
                })
            }
        }else{
            this.setState({
                nextUrl:'',
                search:'',
            })
        }
    }

    loadLstdBlyy=()=>{
        NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList','get','sign=dmk_YXXT_LSTD_BLYY').then(res=>{
            if(res){
                let col = [];
                res.data.map(item=>{
                    col.push(
                        <Col span={12}>
                             <Radio
                                value={item.VALUE}

                             >
                                {item.LABEL}
                             </Radio>
                        </Col>
                    );
                });
               
                let html = <Row>{col}</Row>
                this.setState({checkGroup:html});
            }
        });
    }

    //读取学生的绿色通道信息
    loadLstdInfo=(flag)=>{
        let xsid = getLoginUser()?getLoginUser().userId:'';
        NHFetch('/lstdFb/getLstdInfoByXsid', 'GET',{ xsid: xsid }).then(resData => {
            if (resData && resData.data) {
                if(flag=="edit"){
                    this.setState({
                        edit:true
                    })
                    return;
                }
                if(resData.data.sfbllstd=='0'){
                    this.setState({
                        edit:false
                    })
                }
                let params = {
                xsid:xsid,
                hjdm:'GD005_12'
                }
              
                if (!flag) {
                    NHFetch('/zzbdCommon/getBlztByHjdm','GET',params).then(res =>{
                        if(res){
                            if(res.data == '1'){
                                this.setState({
                                    edit: false
                                })
                            }
                        }
                    })
                }
                
                this.setState({
                    data: resData.data,
                    sqValue:resData.data.sfbllstd,
                    sfhj: resData.data.blyydm[0] == '101'
                })
            }else{
                this.setState({ data:{},edit:true});
                this.props.form.resetFields();
            }
        });
    }


    //提交
    handleOk = e =>{
        confirm({
            title: '提示',
            // content: '是否确定提交，提交后不可修改！',
            content: '是否确定提交?',
            okText: '确定',
            cancelText: '取消',
            okType: 'danger',
            iconType: 'info-circle',
            onOk: () => {
                this.handleSubmit(e);
            },
            onCancel() {

            },
        });
    }

    // 确认提交
    handleSubmit = e => {
        let xsid = getLoginUser().userId?getLoginUser().userId:"";
        if(!xsid){
            message.error("登录已过期，请重新登录！");
            return;
        }
        let modalFlag = "";
        this.setState({loading:true,saveLoading:true}); 
        let zzbdhjid = this.getQueryString('pkid');
        this.props.form.validateFieldsAndScroll((err, values) => {
            if(err){
                this.setState({loading:false,saveLoading:false});
                return false;
            }

            let sqsmFlag = false;
            let msg = '';
            let blyy = [values['blyy']];
            let sqsm = values['sqsm'];
            let dkyh = values['dkyh'];
            let hzjym = values['hzjym'];
            let qtblyy = values['qtblyy'];
            let dkhtbh = values['dkhtbh'];
            let pkid = values['pkid'];
            let sfbllstd = values['sfbllstd']
            let params = {};
            if(values['sfbllstd']== 1){
                if (this.state.sfhj) {
                    params.qtblyy = qtblyy;
                } else {
                    params.dkyh = dkyh;
                    params.hzjym = hzjym;
                    params.dkhtbh = dkhtbh;
                }
                params = Object.assign(params, {
                    sfbllstd:sfbllstd,
                    blyy:blyy,
                    sqsm:sqsm,
                    xsid:xsid,
                    zzbdhjid:zzbdhjid,
                    pkid:pkid,
                })
            }else{
                params = {
                    sfbllstd:sfbllstd,
                    xsid:xsid,
                    zzbdhjid:zzbdhjid,
                    pkid:pkid,
                }
            }


            if(!pkid){
                NHFetch('/xmd/wydx/lstd/insert',"POST",params)
                    .then(res => {
                        if (res && res.data && res.data>0) {
                            message.success("绿色通道申请保存成功。");
                            this.loadLstdInfo();
                            this.reloadMenu(); 
                        } else {
                            message.success("绿色通道申请保存失败，请重试。");
                        }
                        this.setState({ loading: false,edit:false,saveLoading:false })
                    }).catch((res) => {
                    this.setState({ loading: false,saveLoading:false })
                    return res;
                });
            } 
            else{
                NHFetch('/xmd/wydx/lstd/update',"POST",params)
                    .then(res => {
                        if (res && res.data && res.data>0) {
                            message.success("绿色通道申请保存成功。");
                            this.loadLstdInfo();
                            this.reloadMenu(); 
                        }else{
                            if(!res.message.startsWith("businessLogicWarm[")){
                                message.warning("绿色通道申请保存失败，请重试。");
                            }
                        }
                        this.setState({ loading: false,edit:false,saveLoading:false })
                    }).catch((res) => {
                         this.setState({ loading: false,edit:false,saveLoading:false })
                    return res;
                });
            }
            
        });
    };

    getQueryString =(name)=> {
        return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[, ''])[1].replace(/\+/g, '%20')) || null
    }

    remove=(ssbh)=>{
        let url = `api/docrepo/attachment/sourceId/delete?souerceId=${ssbh}`;
        NHFetch(url,"POST").then(res => {
            if (res.data && res.data > 0) {
                message.success('已删除材料.');
            }
        });
    }

    //重新加载菜单
    reloadMenu=()=>{
        this.refs.siderMenu.reloadMenuList()
    }

    sfsqOnChange = e => { 
    this.setState({
        sqValue: e.target.value,
    });
    };

    render() {
        const {form} = this.props;
        const { getFieldDecorator } = this.props.form;
        const { sqlyFlag,edit,data,sqValue ,BlztList} = this.state;

        const { sfhj } = this.state;
       

        return (
            <div style={{position:'relative'}}>{this.state.operable?
                <div>
                <Flex className="w1180 mt-20" align="start">
                    <SiderMenu
                                ref={"siderMenu"}
                                setMenuList={this.setMenuList}
                                title="自助报到"
                                mkbz = {this.state.mkbz}
                          /*     active={4}*/
                                menuType='自助报到'/>
                    <Flex.Item className="page_con_r" styleName="">
                        <Breadcrumb
                            noLine
                            list={[
                                { name: '首页', url: '/index' },
                                { name: '自助报到', url: '/register' },
                                { name: this.state.hjmc }
                            ]}
                        />
                        <div className="box">
                            <Hint/>
                            {edit?<Form>
                                <TitleTop title="申请信息" extra={false} />
                                <div className="p-sw">

                                    <div style={{display:'none'}}>
                                    <NHFormItem id={'pkid'} initialValue={data['pkid']} form={form}>
                                        <Input/>
                                    </NHFormItem>
                                    </div>

                                    <div className="form_item" style={{marginLeft: '7%'}}>
                                    <NHFormItem required={true} id={'sfbllstd'} initialValue={data['sfbllstd']} form={form}  formItemLayout = {formItemLayout} label="绿色通道申请">
                                        <Radio.Group onChange={this.sfsqOnChange} value={this.state.sqValue} style={{display:"flex",margin:"10px"}}>
                                            {
                                                BlztList && BlztList.map((item,index)=>{
                                                   return <Radio value={item.VALUE}>{item.LABEL}</Radio>
                                                })
                                            }
                                            {/* <Radio value={'0'}>否</Radio> */}
                                        </Radio.Group>
                                    </NHFormItem>
                                    </div>
                                    {sqValue=='1' && <div style = {{marginLeft:"30px"}}>
                                        {<NHFormItem id={'blyy'} required={true} initialValue={data['blyydm']? data['blyydm'][0] : ''} form={form} formItemLayout={{labelCol:{span:4},wrapperCol:{span:16}}} label="办理原因">  
                                            <RadioGroup style={{ width: '100%'}} onChange={(e) => {this.setState({sfhj: e.target.value == '101'})}}>
                                                {this.state.checkGroup}
                                            </RadioGroup>
                                        </NHFormItem>}
                                    </div>}

                                    {sqValue=='1' && <div className="form_item" style={{marginLeft: '7%'}}>
                                        {!sfhj && <NHFormItem id={'dkyh'} required={true}  initialValue={data['dkyh']} form={form}  formItemLayout = {formItemLayout} label="贷款银行">
                                            <NHSelect sign={"dmk_yxwz_dkyh"} onChange={(v) => { }}/>
                                        </NHFormItem>}

                                        {!sfhj && <NHFormItem required={false} id={'hzjym'} initialValue={data['hzjym']} form={form}  formItemLayout = {formItemLayout} label="回执校验码" pattern={{pattern: '^[A-Za-z0-9]{6}$', message: "请输入6位数回执校验码"}}>

                                            <Input maxLength ={6} placeholder="6位数回执校验码" />
                                        </NHFormItem>}

                                        {!sfhj && <NHFormItem required={false} id={'dkhtbh'} initialValue={data['dkhtbh']} form={form}  formItemLayout = {formItemLayout} label="贷款合同编号">
                                            <Input placeholder="贷款合同编号" />
                                        </NHFormItem>}

                                        {sfhj && <NHFormItem id={'qtblyy'} required={true}  initialValue={data['qtblyy']} form={form}  formItemLayout = {formItemLayout} label="家庭困难类别">
                                            <NHSelect sign={"dmk_WYDX_ZZBD_LSTD_JTKNLB"} onChange={(v) => { }}/>
                                        </NHFormItem>}

                                        {<NHFormItem id={'sqsm'} required={false} initialValue={data['sqsm']} form={form}  formItemLayout = {formItemLayout} label="申请理由">    
                                            <TextArea maxLength ={50} placeholder="请填写申请理由" />
                                        </NHFormItem>}
                                    </div>}
                                </div>
                            
                                <div className="text-center mt-50">
                                    <Button type="primary" htmlType="submit" onClick={this.handleOk} loading={this.state.saveLoading}>
                                        提交
                                    </Button>
                                    <NextButton nextUrl={this.state.nextUrl}  search={this.state.search}/>
                                </div>
                            </Form>
                            :<View  reloadMenu={this.reloadMenu} nextUrl={this.state.nextUrl} loadLstdInfo={()=>this.loadLstdInfo("edit")}  search={this.state.search}  data={this.state.data} judgeImgList={this.state.judgeImgList} jfhjzt={this.state.jfhjzt} BlztList={BlztList} sfhj={sfhj}/>}
                        </div>
                    </Flex.Item>
                </Flex>
                    <Spin style={{ position:'absolute',top:'300px',left:'60%'}} spinning={this.state.loading}/> </div>:<CommonTip hjmc = {"绿色通道申请"}  key={"GD005_12"} mkbz='GD005_12' nonOperationalData={this.state.nonOperationalData}/>}
            </div>

        );
    }
}
