import React from 'react'
import { Flex } from '@components'
import { Button, message, Modal, Icon, Spin } from 'antd'
import SiderMenu from '@pages/Assembly/siderMenu'
import Breadcrumb from '@pages/Assembly/Breadcrumb'
import TitleTop from '@pages/Assembly/titleTop'
import NHFetch from '@utils/NHFetch'
import getSize from '@utils/getSize'
import { getLoginUser, getLoginUserInfo } from '@utils/NHCore'
import CommonTip from '../common'
import NextButton from '@pages/SelfHelp/common/nextIndex'
import { mkbz } from '@pages/Assembly/siderMenu/menuObj'
import Hint from '@pages/SelfHelp/common/hint'
import '../../../../../assets/css/dormV2/index.css'
import Filter from './Filter'
import RoomDetail from './RoomDetail'
import Roommate from './Roommate'
import { createUuid } from '@utils/NHCore'

export default class DormV2 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mkbz: 'GD006V2_1',
      hjmc: '',
      scrollHeight: 200, //默认滚动高度
      nextUrl: '', //左侧菜单加载完后传进来
      search: '',
      operable: true, //是否显示环节
      nonOperationalData: {}, //不可操作的数据
      showFlag: '',
      filterRef: React.createRef(),
      roomDetailRef: React.createRef(),
      fjxxList: [], //房间信息列表
      sfqyzdbl: false, //是否启用自动办理
      roomDetailVisible: false,
      currentFjid: '',
      modalTitle: '',
      lc_fh: '',
      zzbdhjid: '', //自助报到环节id
      xsid: '',
      selectedDetail: {}, //已办理后获取的宿舍信息
      roommateDetail: [], //舍友信息
      isViewRoommate: false, //是否在查看舍友
      loading: false,
      autoBlLoading: false,
      roomListLoading: false,
      submitSelectBedLoading: false,
      cancelBlLoading: false,
      roomDetailLoading: false,
      roomDetailKey: createUuid()
    }
  }

  componentWillMount() {
    let that = this
    this.setState({ scrollHeight: getSize().windowH - 206 })
    window.onresize = function () {
      that.setState({
        scrollHeight: getSize().windowH - 206
      })
    }
  }

  componentDidMount() {
    this.checkQzyl()
  }

  // 查询前置依赖
  checkQzyl = () => {
    this.setState({ loading: true })
    let xsid = getLoginUser() ? getLoginUser().userId : ''
    let zzbdhjid = this.getUrlParams('pkid')
    this.setState({ zzbdhjid, xsid })
    if (!xsid) {
      this.setState({
        nonOperationalData: { nologin: true },
        operable: false,
        loading: false
      })
      return
    }

    NHFetch('/zzbd/queryQztjAndYlhj', 'GET', {
      xsid,
      zzbdhjid
    }).then(res => {
      if (res && res.data) {
        let kfsj = res.data['kfsj']
        let ylhj = res.data['ylhj']
        let qzhj = res.data['qzhj']
        let nohj = res.data['nohj']
        this.setState({ nonOperationalData: res.data })

        const operable = !((kfsj && kfsj['YXSJ'] != '1') || (ylhj && ylhj.length > 0) || (qzhj && qzhj.length > 0) || nohj)

        this.setState({ operable })
        if (operable) {
          this.checkZzbdisLinked()
        } else {
          this.setState({ loading: false })
        }
      }
    })
  }

  //检查环节是否已办理
  checkZzbdisLinked = () => {
    this.setState({ showFlag: '' })
    const { zzbdhjid, xsid } = this.state
    NHFetch('/zzbdCommon/checkIsZzbdHjLinked', 'GET', {
      xsid,
      zzbdhjid
    }).then(res => {
      if (res) {
        if (res.data) {
          this.setState({ showFlag: '已办理' })
          this.getSelectedDetail()
        } else {
          this.getConditionAndRoomDetail() // 未办理则获取筛选条件及宿舍列表信息
        }
      } else {
        // 未正常响应则默认未办理并获取筛选条件及宿舍列表信息
        this.getConditionAndRoomDetail()
      }
    })
  }

  // 获取筛选选条件
  getCondition = () => {
    return NHFetch('/ssfp/getDefaultChoice', 'post').then(res => {
      if (res && res.code === 200) {
        // 非必须住宿且取消了最后一次自选床位
        if (res.data.code === '-0001') {
          this.setState({ showFlag: '未办理且不可再选' })
          return '未办理且不可再选'
        }

        // 暂未分配床源
        if (!(res.data.ldxxList && res.data.ldxxList.length)) {
          this.setState({ showFlag: '暂未分配床源' })
          return '暂未分配床源'
        }

        return this.state.filterRef.current.updateFilterData(res.data, res.data.sfxssfbz)
      }
    })
  }

  // 获取宿舍信息
  getRooomDetail = params => {
    this.setState({ roomListLoading: true })
    return NHFetch('/ssfp/getYxwzSsfpFjxxList', 'post', params).then(res => {
      if (res && res.code === 200) {
        this.setState({ fjxxList: res.data.fjxxList, sfqyzdbl: res.data.sfqyzdbl, loading: false, roomListLoading: false })
        if (!Object.keys(res.data.fjxxList).length) {
          this.setState({ showFlag: '当前条件无数据' })
        } else {
          this.setState({ showFlag: '未办理且可选' })
        }
      }
    })
  }

  getConditionAndRoomDetail = async () => {
    const result = await this.getCondition()
    if (result === '未办理且不可再选' || result === '暂未分配床源') {
      // 此时不需获取宿舍信息
      this.setState({ loading: false })
      return
    }
    const searchParams = this.state.filterRef.current.getFilterParams(result)
    await this.getRooomDetail(searchParams)
  }

  // 整理房间数据
  dealwithfjxxList = () => {
    const { fjxxList } = this.state
    const buildingData = []

    for (let key in fjxxList) {
      const floor = {
        floorName: key,
        rooms: fjxxList[key]
      }
      buildingData.push(floor)
    }
    return buildingData
  }

  setMenuList = menuList => {
    let nextCount = 0
    let bz = this.state.mkbz
    menuList.map((elem, index) => {
      if (elem.HJDM == bz) {
        let hjmc = elem.HJMC
        this.setState({ hjmc: hjmc })
        nextCount = index + 1
        return
      }
    })
    let menu = menuList[nextCount]
    if (menu) {
      let hjdm = menu.HJDM
      if (hjdm.indexOf('GD') == 0) {
        let gdUrl = mkbz[hjdm]
        this.setState({
          nextUrl: gdUrl,
          search: 'pkid=' + menu.PKID
        })
      } else if (hjdm.indexOf('ZDY') == 0) {
        let zdyUrl = '/customLink'
        this.setState({
          nextUrl: zdyUrl,
          search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX
        })
      }
    } else {
      this.setState({
        nextUrl: '',
        search: ''
      })
    }
  }

  //获取url中传递的参数
  getUrlParams = param => {
    return decodeURIComponent((new RegExp('[?|&]' + param + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
  }

  getQueryString = name => {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
  }

  //重新加载菜单
  reloadMenu = () => {
    this.refs.siderMenu.reloadMenuList()
  }

  // 显示选择床位对话框
  toSelectBed = room => {
    return () => {
      const modalTitle = room.XQMC + '-' + room.LDMC + '-' + room.LCMC + '-' + room.FJ + '号房'
      const lc_fh = room.LCMC + '-' + room.FJ + '号房'
      this.setState({ roomDetailVisible: true, currentFjid: room.FJID, modalTitle, lc_fh, roomDetailKey: createUuid() })
    }
  }

  // 确定选择床位
  submitSelectBed = async () => {
    const { roomDetailRef } = this.state
    this.setState({ submitSelectBedLoading: true })
    let result;
    try {
        result = await roomDetailRef.current.confirmSelect()
    } catch(e) {
        this.setState({ submitSelectBedLoading: false })
    }
    if (result === 'success') {
      this.setState({ roomDetailVisible: false, submitSelectBedLoading: false })
      this.reloadMenu()
      this.checkQzyl()
    }
  }

  // 已办理时获取宿舍信息
  getSelectedDetail = () => {
    NHFetch('/ssfp/getStuSsrzByXsid', 'GET').then(res => {
      if (res && res.code === 200) {
        // Toast.hide();
        this.setState({ selectedDetail: res.data, loading: false })
      }
    })
  }

  // 取消办理
  cancelSelect = () => {
    const that = this
    Modal.confirm({
      title: '确定取消办理吗?',
      onOk() {
        that.setState({ cancelBlLoading: true })
        const { zzbdhjid, xsid } = that.state
        NHFetch('/ssfp/cancelBlSsfp', 'post', { xsid, zzbdhjid }).then(res => {
          if (res && res.code === 200) {
            that.setState({ cancelBlLoading: false })
            message.success('取消办理成功')
            // 刷新数据
            that.checkQzyl()
            that.reloadMenu()
          }
        })
      }
    })
  }

  // 查看舍友
  toCheckRoommate = () => {
    const { selectedDetail } = this.state
    this.setState({ isViewRoommate: true, roomDetailLoading: true })
    NHFetch('/ssfp/getRoommateInfor', 'get', {
      fjid: selectedDetail.FJID
    }).then(res => {
      if (res && res.code === 200) {
        this.setState({ roomDetailLoading: false })
        const result = res.data.sort((a, b) => a.CWH - b.CWH)
        this.setState({ roommateDetail: result })
      } else {
        message.error('获取舍友信息失败')
      }
    })
  }

  backFromRoommate = () => {
    this.setState({ isViewRoommate: false })
  }

  // 自动办理
  zdblSubmit = () => {
    this.setState({ autoBlLoading: true })
    const { zzbdhjid, xsid } = this.state
    NHFetch('/ssfp/yxwzAutoDistributeSscwxx', 'post', {
      xsid,
      zzbdhjid
    }).then(res => {
      this.setState({ autoBlLoading: false })
      if (res && res.code === 200) {
        message.success('自动办理成功', 1)
        this.reloadMenu()
        this.checkQzyl()
      } else {
        message.success('自动办理失败', 1)
      }
    })
  }

  render() {
    const { autoBlLoading, filterRef, roomDetailRef, showFlag, sfqyzdbl, roomDetailVisible, currentFjid, modalTitle, lc_fh, zzbdhjid, selectedDetail, roommateDetail, isViewRoommate, loading, roomListLoading, submitSelectBedLoading, cancelBlLoading, roomDetailLoading, roomDetailKey, xsid } = this.state
    return (
      <div className='dormV2'>
        {this.state.operable ? (
          <Flex className="w1180 mt-20" align="start">
            <SiderMenu ref={'siderMenu'} setMenuList={this.setMenuList} title="自助报到" mkbz={this.state.mkbz} menuType="自助报到" />
            <Flex.Item className="page_con_r" styleName="">
              <Breadcrumb noLine list={[{ name: '首页', url: '/index' }, { name: '自助报到', url: '/register' }, { name: this.state.hjmc }]} />
              <Spin spinning={loading}>
                <div className="box" style={{ position: 'relative', overflow: 'hidden' }}>
                  {showFlag && showFlag !== '当前条件无数据' && showFlag !== '暂未分配床源' && <Hint />}
                  <TitleTop className="mt-20" title={showFlag === '已办理' ? '床位信息' : this.state.hjmc} extra={false}>
                    {sfqyzdbl === '1' && (showFlag === '未办理且可选' || showFlag === '当前条件无数据') && (
                      <Button className="ml-10" type="primary" ghost loading={autoBlLoading} onClick={this.zdblSubmit}>
                        自动办理
                      </Button>
                    )}
                  </TitleTop>

                  {(showFlag === '' || showFlag === '未办理且可选' || showFlag === '当前条件无数据') && (
                    <div className="mt-24">
                      <Filter ref={filterRef} onSearch={this.getRooomDetail} className="filter"></Filter>
                    </div>
                  )}

                  {showFlag === '已办理' && (
                    <div className="selected-result">
                      {selectedDetail.SYCS === 0 && selectedDetail.SFBXZS === '1' && <div class="selected-result-tip">自选次数已用完：不能取消办理</div>}
                      {selectedDetail.SYCS === 0 && selectedDetail.SFBXZS !== '1' && <div class="selected-result-tip cancel">自选次数已用完：取消办理后将无法再选择。</div>}

                      <div class="title">您选择的床位</div>
                      <div class="selected-result-detail">
                        <table>
                          <tr>
                            <td>校区</td>
                            <td>{selectedDetail.XQMC}</td>
                          </tr>
                          <tr>
                            <td>楼栋</td>
                            <td>{selectedDetail.LDMC}</td>
                          </tr>
                          <tr>
                            <td>楼层</td>
                            <td>{selectedDetail.LCMC}</td>
                          </tr>
                          <tr>
                            <td>房号</td>
                            <td>{selectedDetail.FJ}</td>
                          </tr>
                          <tr>
                            <td>床号</td>
                            <td>{selectedDetail.CWH}</td>
                          </tr>
                          {selectedDetail.SFXSSFBZ === '1' && (
                            <tr>
                              <td>费用</td>
                              <td>{selectedDetail.SFBZ || '暂未维护'}</td>
                            </tr>
                          )}
                          <tr>
                            <td>剩余自选次数</td>
                            <td>{selectedDetail.SYCS}</td>
                          </tr>
                        </table>
                      </div>
                      <div class="btns">
                        <Button type="primary" onClick={this.cancelSelect} loading={cancelBlLoading} className={selectedDetail.SYCS === 0 && selectedDetail.SFBXZS === '1' ? 'disable' : ''} disabled={selectedDetail.SYCS === 0 && selectedDetail.SFBXZS === '1'}>
                          {!cancelBlLoading && <Icon type="undo" rotate={90} />}
                          取消办理
                        </Button>
                        <Button onClick={this.toCheckRoommate}>查看舍友</Button>
                      </div>
                      <Roommate roommateDetail={roommateDetail} isViewRoommate={isViewRoommate} back={this.backFromRoommate} loading={roomDetailLoading} selfId={xsid}/>
                    </div>
                  )}

                  {showFlag === '未办理且不可再选' && (
                    <div className="no-data">
                      您的自选次数已经用完
                      <br />
                      无法再进行自选宿舍
                    </div>
                  )}

                  {showFlag === '未办理且可选' && (
                    <div className="mt-20 pdlr-22">
                      <Spin spinning={roomListLoading}>
                        {this.dealwithfjxxList().map(floor => {
                          return (
                            <div className="card" key={floor.floorName}>
                              <header>
                                <span>{floor.floorName}</span>
                              </header>
                              <div className="content">
                                {floor.rooms.map(room => {
                                  return (
                                    <div class="room can-select" className={room.SYCW ? 'room can-select' : 'room can-not-select'} key={room.FJ} onClick={this.toSelectBed(room)}>
                                      <div class="roomname">{room.FJ}</div>
                                      <div class="row">
                                        <div class="count">{room.CWS}人间</div>
                                        <div class="rest">余：{room.SYCW}</div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          )
                        })}
                      </Spin>

                      <Modal
                        className="room-detail-modal"
                        visible={roomDetailVisible}
                        width={614}
                        title={modalTitle}
                        onCancel={() => {
                          this.setState({ roomDetailVisible: false })
                        }}
                        onOk={this.submitSelectBed}
                        okButtonProps={{ loading: submitSelectBedLoading }}
                      >
                        <RoomDetail fjid={currentFjid} key={roomDetailKey} lc_fh={lc_fh} ref={roomDetailRef} zzbdhjid={zzbdhjid}></RoomDetail>
                      </Modal>
                    </div>
                  )}

                  {showFlag === '当前条件无数据' && <div className="no-data">暂无数据</div>}

                  {showFlag === '暂未分配床源' && <div className="no-data">暂未分配床源</div>}

                  <div className="text-center mt-50">
                    <NextButton nextUrl={this.state.nextUrl} search={this.state.search} />
                  </div>
                </div>
              </Spin>
            </Flex.Item>
          </Flex>
        ) : (
          <CommonTip hjmc={'自选宿舍V2'} key={'GD006V2_1'} mkbz="GD006V2_1" nonOperationalData={this.state.nonOperationalData} />
        )}
      </div>
    )
  }
}
