import React from 'react';
import {Flex} from '@components';
import {Form, Input, Button, Upload, Icon} from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import NHFetch from "@utils/NHFetch";
import {message} from "antd/lib/index";
import NHSelect from "@components/common/NHSelect";
import NHMultiSelect from "@components/common/NHMultiSelect";
import {getLoginUser} from '@utils/NHCore';
import NextButton from '@pages/SelfHelp/common/nextIndex'
import createHistory from 'history/createHashHistory';
import CommonTip from "../common";
import {mkbz} from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";
import NHCascader from "@components/common/NHCascader";

const history = createHistory();


const basicUrl = "api/zhxg-yxxt/yxwz";
const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16}
};

const uploadUrl = "api/zhxg-yxwz/newStudent/cacheStudentPhoto"; //上传logo路径
function beforeUpload(file) {

    const isJPG = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJPG) {
        message.error("You can only upload JPG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
    }
    return isJPG && isLt2M;
}

@Form.create()
export default class antiepidemic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mkbz: 'GD034',
            hjmc: '',
            editable: true,
            update: false,
            initPOvalue: {
                xsid: '',
                cfddqdmArray: [],
                cfdDetail: '',
                cfdxxdz: '',
                sfwcymjz: '',
                ymjzqkdm: '',
                hsjcjgdm: '',
                jkmysdm: '',
                xcmysdm: '',
                lxfsdm: '',
                jqsfyysgrzz: '',
                jqysgrrygxdm: '',
                jqysgrrygxdmArray: [],
            },
            value: null,
            lxjtgjmcList: {},
            lxjtgjmc: '',
            ddz: {},
            options: [],
            dqddz: '',
            nextUrl: '',//左侧菜单加载完后传进来
            search: '',
            operable: true,//是否显示环节
            nonOperationalData: {},//不可操作的数据
            isHide: true,
            ymjzFlag: true,//疫苗接种标志
            ysgrFlag: false,//疑似感染
            day: '48小时',//   xx天，默认48小时
            LcidData:{},//lchjid
        };
    }


    componentWillMount() {
    }

    //渲染前获取信息
    componentDidMount() {
        this.queryQztjAndYlhj();
        this.getFydjInfo();
        this.getday();
        this.getLcIdInfo();
    }


    getLcIdInfo = () => {
        let xsid = getLoginUser()?getLoginUser().userId:'';
        NHFetch("/zdybl/getXsxxAndLcidByXsid", 'GET', {xsid:xsid})
            .then(res => {
                if(res.code == "200"){
                    console.log("获取专业信息接口----------",res.data);
                    this.setState({
                        LcidData:res.data
                    })
                }else{
                    Toast.error("获取专业信息接口失败");
                }
            })
    }

    // 确认办理
    submitBtnClick = () =>{
        const { LcidData } = this.state;
        let xsid = getLoginUser()?getLoginUser().userId:'';
        let zzbdhjid = this.getQueryString('pkid');
        let formData = {xsid: xsid, yhid: xsid, zzbdhjid: zzbdhjid, lchjid: LcidData.LCID}
            NHFetch('/zdybl/saveCustomLinkData',"POST",formData)
            .then(res => {
                if(res){
                    if (res.data == '1') {
                       // console.log('环节办理成功！');
                        //办理完成回调
                        this.reloadMenu();
                        this.getFydjInfo();
                    }else{
                            console.log('环节办理失败，请重试！');
                    }
                }
            })
    }

    //查询前置条件和依赖环节
    queryQztjAndYlhj = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({
                nonOperationalData: {nologin: true},
                operable: false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', {xsid: xsid, zzbdhjid: zzbdhjid})
          .then(res => {
              if (res && res.data) {
                  let kfsj = res.data['kfsj'];
                  let ylhj = res.data['ylhj'];
                  let qzhj = res.data['qzhj'];
                  let nohj = res.data['nohj'];
                  this.setState({nonOperationalData: res.data});
                  if (kfsj && kfsj['YXSJ'] != '1') {
                      this.setState({operable: false});
                  }

                  if (ylhj && ylhj.length > 0) {
                      this.setState({operable: false});
                  }

                  if (qzhj && qzhj.length > 0) {
                      this.setState({operable: false});
                  }
                  if (nohj) {
                    this.setState({ operable: false });
                }
              }
          });
    }

    getQueryString = (name) => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }

    setMenuList = (menuList) => {
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC;
                this.setState({hjmc: hjmc})
                nextCount = index + 1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if (menu) {
            let hjdm = menu.HJDM;
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID,
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: '',
            })
        }
    }


    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            console.log(this.state.editable)
            if (!this.state.editable) {
                this.setState({editable: true});
                return
            }
            if (err) {
                return;
            }

            const xsid = getLoginUser().userId;
            const zzbdhjid = this.getUrlParams("pkid");
            values['xsid'] = xsid
            values['zzbdhjid'] = zzbdhjid
            let params = values
            //请求保存或修改数据 
            NHFetch('/zzbd/fydj/insertOrUpdate', 'POST', params) 
              .then(res => {
                  if (res) {
                      if (res.data == '1') {
                        this.commonSaveLinkTransactionStatus();
                          message.success('信息保存成功！');
                          this.reloadMenu();
                          this.getFydjInfo();
                      } else {
                          message.error('信息保存失败，请重试！');
                      }
                  }
              })
        });
    };

    //自动办理环节
    commonSaveLinkTransactionStatus = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : "";
        let zzbdhjid = this.getQueryString("pkid");
        let params = { xsid: xsid, zzbdhjid: zzbdhjid };
        NHFetch("/zzbdCommon/commonSaveLinkTransactionStatus", "POST", params).then(
            res => {
                if (res) {
                    if (res.data === 1) {
                        this.setState({editable:false});
                        this.reloadMenu();
                    }
                } else {
                    message.error("办理环节失败，请稍后重试！");
                }
            }
        );
    };

    //获取url中传递的参数
    getUrlParams = (param) => {
        return decodeURIComponent((new RegExp('[?|&]' + param + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    };


    //重新加载菜单
    reloadMenu = () => {
        this.refs.siderMenu.reloadMenuList()
    }


    getFydjInfo = () => {
        const xsid = getLoginUser().userId;
        NHFetch("/zzbd/fydj/getFydjInfoByXsid", "GET", {xsid: xsid})
          .then(res => {
              if (res) {
                  if (res.data) {
                      this.setState({initPOvalue: res.data, //editable: false,
                        imageJkmjtUrl: `data:image/jpeg;base64,${res.data.jkmjt}`,
                        imageXcmjtUrl: `data:image/jpeg;base64,${res.data.xcmjt}`});
                      this.setFlag(res.data.jqsfyysgrzz, 'ysgr')
                      this.setFlag(res.data.sfwcymjz, 'ymjz')
                  }
              }
          });
    }

    getday = () => {
        NHFetch('/zzbdCommon/getCssz', "GET", {csbz: 'YXXT_FYDJ_HSJCSC'})
          .then(res => {
              if (res) {
                  if (res && res.code === 200) {
                      this.setState({day: res.data});
                  }
              }
          });
    }

    setFlag = (value, str) => {
        if (str === 'ysgr') {
            if (value === '1') {
                this.setState({ysgrFlag: true});
            } else {
                this.setState({ysgrFlag: false});
            }
        } else if (str === 'ymjz') {
            if (value === '1') {
                this.setState({ymjzFlag: true});
            } else {
                this.setState({ymjzFlag: false});
            }
        }
    }

    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }
    // 上传图片
    handleUploadChange = (info,type) => {
        let userId = getLoginUser() ? getLoginUser().userId : undefined;
        if (info && info['file'] && info['file'].status === 'done') {
            let photoUUID = info['file'].response.data;
            if (photoUUID) {
                let url = '/zzbd/fydj/saveFydjTp?xsid=' + userId + '&photoUUID=' + photoUUID + '&type=' + type;
                NHFetch(url, 'POST')
                    .then(res => {
                        if (res && res.data) {
                            message.success("上传成功!")
                        }
                    });
            }
        }
        if("jkm" === type){
            this.getBase64(info.file.originFileObj, imageJkmjtUrl =>
                this.setState({ imageJkmjtUrl })
            );
        }
        if("xcm" === type){
            this.getBase64(info.file.originFileObj, imageXcmjtUrl =>
                this.setState({ imageXcmjtUrl })
            );
        }
    }

    render() {
        const uploadButton = (
            <div>
              <Icon type={'plus'} />
              <div style={{marginTop: 8, color: '#666'}}>Upload</div>
            </div>
          );
        const {getFieldDecorator} = this.props.form;
        const {editable, initPOvalue, lxjtgjmc, options, dqddz, isHide, ymjzFlag, ysgrFlag, day, xsid, imageJkmjtUrl, imageXcmjtUrl} = this.state;
        let str = '近' + day + '的核酸检测结果：'
        return (
          <div>{this.state.operable ?
            <Flex className="w1180 mt-20" align="start">
                <SiderMenu
                  ref={"siderMenu"}
                  setMenuList={this.setMenuList}
                  title="自助报到"
                  /* active={3}*/
                  mkbz={this.state.mkbz}
                  menuType='自助报到'
                />
                <Flex.Item className="page_con_r" styleName="">
                    <Breadcrumb
                      noLine
                      list={[
                          {name: '首页', url: '/index'},
                          {name: '自助报到', url: '/register'},
                          {name: this.state.hjmc}
                      ]}
                    />
                    <div className="box">
                        <Hint/>
                        <TitleTop title={this.state.hjmc} extra={false}/>
                        <Form onSubmit={this.handleSubmit}>
                            <div className="p-sw">
                                <Flex align="start" wrap="wrap">

                                    <div className="form_item">
                                        <FormItem
                                          {...{
                                              labelCol: {span: 6},
                                              wrapperCol: {span: 18}
                                          }}
                                          labelAlign={'left'}
                                          label="来校出发地"
                                        >
                                            {getFieldDecorator('cfddqdmArray', {
                                                initialValue: initPOvalue.cfddqdmArray ? initPOvalue.cfddqdmArray : [],
                                                placeholder: '请选择',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message:
                                                          '请选择出发地区'
                                                    }
                                                ]
                                            })(
                                              editable ?
                                                <NHCascader style={{width: 450}} sign={"zhxg_ssq"}/>
                                                :
                                                <span>{initPOvalue.cfdDetail ? initPOvalue.cfdDetail : ''}</span>
                                            )}
                                        </FormItem>

                                        <FormItem
                                          {...{
                                              labelCol: {span: 7},
                                              wrapperCol: {span: 17}
                                          }}
                                          labelAlign={'left'}
                                          label="具体街道信息"
                                        >
                                            {getFieldDecorator('cfdxxdz', {
                                                initialValue: initPOvalue.cfdxxdz,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请填写具体街道信息'

                                                    }
                                                ]
                                            })
                                            (
                                              editable ? <Input placeholder="请填写具体街道信息" maxLength={50}/> :
                                                <span>{initPOvalue.cfdxxdz}</span>
                                            )}
                                        </FormItem>
                                        <FormItem
                                          {...{
                                              labelCol: {span: 5},
                                              wrapperCol: {span: 19}
                                          }}
                                          labelAlign={'left'}
                                          label="来校方式"
                                        >
                                            {getFieldDecorator('lxfsdm', {
                                                initialValue: initPOvalue.lxfsdm,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message:
                                                          '请填写来校方式'
                                                    }
                                                ]
                                            })(
                                              editable ?
                                                <NHSelect sign={"DMK_YXXT_FYDJ_LXFS"}/>
                                                : <NHSelect sign={"DMK_YXXT_FYDJ_LXFS"} disabled={true}/>
                                            )}
                                        </FormItem>
                                        <FormItem
                                          {...formItemLayout}
                                          label="是否完成疫苗接种"
                                          labelAlign={'left'}
                                        >
                                            {getFieldDecorator('sfwcymjz', {
                                                initialValue: initPOvalue.sfwcymjz ? initPOvalue.sfwcymjz : '',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message:
                                                          '请选择疫苗接种情况'
                                                    }
                                                ]
                                            })(
                                              editable ?
                                                <NHSelect dataSource={[{
                                                    value: '1',
                                                    label: '是'
                                                }, {
                                                    value: '0',
                                                    label: '否'
                                                }]}
                                                          onChange={(value) => {
                                                              this.setFlag(value, 'ymjz')
                                                          }}/>

                                                : <span>{initPOvalue.sfwcymjz === '1' ? '是' : '否'}</span>
                                            )}
                                        </FormItem>
                                        {
                                            !ymjzFlag &&
                                            <FormItem
                                              {...formItemLayout}
                                              labelAlign={'left'}
                                              label="请选择疫苗接种情况"
                                            >
                                                {getFieldDecorator('ymjzqkdm', {
                                                    initialValue: initPOvalue.ymjzqkdm ? initPOvalue.ymjzqkdm : '',
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message:
                                                              '请选择疫苗接种情况'
                                                        }
                                                    ]
                                                })(
                                                  editable ?
                                                    <NHSelect sign={"DMK_YXXT_BDQK_XGYMJZQK"}/>
                                                    :
                                                    <NHSelect sign={"DMK_YXXT_BDQK_XGYMJZQK"} disabled={true}/>
                                                )}
                                            </FormItem>
                                        }
                                        <FormItem
                                          {...{
                                              labelCol: {span: 10},
                                              wrapperCol: {span: 14}
                                          }}
                                          labelAlign={'left'}
                                          label={str}
                                        >
                                            {getFieldDecorator('hsjcjgdm', {
                                                initialValue: initPOvalue.hsjcjgdm ? initPOvalue.hsjcjgdm : '',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message:
                                                          '请选择核酸检测结果'
                                                    }
                                                ]
                                            })(
                                              editable ?
                                                <NHSelect sign={"DMK_YXXT_FYDJ_SJJCJG"}/>
                                                : <NHSelect sign={"DMK_YXXT_FYDJ_SJJCJG"} disabled={true}/>
                                            )}
                                        </FormItem>
                                        <FormItem
                                          {...{
                                              labelCol: {span: 6},
                                              wrapperCol: {span: 18}
                                          }}
                                          labelAlign={'left'}
                                          label="健康码颜色"
                                        >
                                            {getFieldDecorator('jkmysdm', {
                                                initialValue: initPOvalue.jkmysdm ? initPOvalue.jkmysdm : '',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message:
                                                          '请填写健康码'
                                                    }
                                                ]
                                            })(
                                              editable ?
                                                <NHSelect sign={"DMK_YXXT_FYDJ_JKMYS"}/>
                                                : <NHSelect sign={"DMK_YXXT_FYDJ_JKMYS"} disabled={true}/>
                                            )}
                                        </FormItem>
                                        <FormItem
                                          {...{
                                              labelCol: {span: 6},
                                              wrapperCol: {span: 18}
                                          }}
                                          labelAlign={'left'}
                                          label="上传健康码截图"
                                        >
                                            {/* {getFieldDecorator('jkmjt', {
                                                // initialValue: initPOvalue.cfddqdmArray ? initPOvalue.cfddqdmArray : [],
                                            })( */}
                                                <Upload
                                                    name="photo"
                                                    accept={'image/*'}
                                                    showUploadList={false}
                                                    listType="picture-card"
                                                    style={{width: 128, height: 128}}
                                                    action={uploadUrl + "?xsid=" + xsid}
                                                    beforeUpload={beforeUpload}
                                                    onChange={(info)=>this.handleUploadChange(info,"jkm")}
                                                >
                                                    {imageJkmjtUrl ? <img src={imageJkmjtUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                                </Upload>
                                            {/* )} */}
                                        </FormItem>
                                        <FormItem
                                          {...{
                                              labelCol: {span: 8},
                                              wrapperCol: {span: 16}
                                          }}
                                          labelAlign={'left'}
                                          label="大数据行程码颜色"
                                        >
                                            {getFieldDecorator('xcmysdm', {
                                                initialValue: initPOvalue.xcmysdm ? initPOvalue.xcmysdm : '',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message:
                                                          '请填写行程码'
                                                    }
                                                ]
                                            })(
                                              editable ?
                                                <NHSelect sign={"DMK_YXXT_FYDJ_XCMYS"}/>
                                                : <NHSelect sign={"DMK_YXXT_FYDJ_XCMYS"} disabled={true}/>
                                            )}
                                        </FormItem>
                                        <FormItem
                                          {...{
                                              labelCol: {span: 6},
                                              wrapperCol: {span: 18}
                                          }}
                                          labelAlign={'left'}
                                          label="上传行程码截图"
                                        >
                                            {/* {getFieldDecorator('xcmjt', {
                                                // initialValue: initPOvalue.cfddqdmArray ? initPOvalue.cfddqdmArray : [],
                                            })( */}
                                                <Upload
                                                    name="photo"
                                                    accept={'image/*'}
                                                    listType="picture-card"
                                                    style={{width: 128, height: 128}}
                                                    showUploadList={false}
                                                    action={uploadUrl + "?xsid=" + xsid}
                                                    beforeUpload={beforeUpload}
                                                    onChange={(info)=>this.handleUploadChange(info,"xcm")}
                                                >
                                                    {imageXcmjtUrl ? <img src={imageXcmjtUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                                </Upload>
                                            {/* )} */}
                                        </FormItem>
                                        <FormItem
                                          {...{
                                              labelCol: {span: 16},
                                              wrapperCol: {span: 8}
                                          }}
                                          labelAlign={'left'}
                                          label="近期是否有疑似感染症状（本人及同住人）"
                                        >
                                            {getFieldDecorator('jqsfyysgrzz', {
                                                initialValue: initPOvalue.jqsfyysgrzz ? initPOvalue.jqsfyysgrzz : '',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message:
                                                          '请填写近期是否有疑似感染症状（本人及同住人）'
                                                    }
                                                ]
                                            })(
                                              editable ?
                                                <NHSelect dataSource={[{
                                                    value: '1',
                                                    label: '是'
                                                }, {
                                                    value: '0',
                                                    label: '否'
                                                }]}
                                                          onChange={(value) => {
                                                              this.setFlag(value, 'ysgr')
                                                          }}/>
                                                : <span>{initPOvalue.jqsfyysgrzz === '1' ? '是' : '否'}</span>
                                            )}
                                        </FormItem>
                                        {
                                            ysgrFlag &&
                                            <FormItem
                                              {...{
                                                  labelCol: {span: 10},
                                                  wrapperCol: {span: 14}
                                              }}
                                              labelAlign={'left'}
                                              label="近期疑似感染的人员关系"
                                            >
                                                {getFieldDecorator('jqysgrrygxdmArray', {
                                                    initialValue: initPOvalue.jqysgrrygxdmArray ? initPOvalue.jqysgrrygxdmArray : [],
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '请填写近期疑似感染的人员关系'
                                                        }
                                                    ]
                                                })(
                                                  editable ?
                                                    <NHMultiSelect sign={"DMK_YXXT_FYDJ_YSGRRYGX"}/>
                                                    : <NHMultiSelect sign={"DMK_YXXT_FYDJ_YSGRRYGX"}
                                                                     disabled={true}/>
                                                )}
                                            </FormItem>
                                        }
                                    </div>
                                </Flex>
                                <div className="text-center mt-50">
                                    <Button
                                      type="primary"
                                      htmlType="submit"
                                    >
                                        {editable ? '保存' : '修改'}
                                    </Button>
                                    <NextButton nextUrl={this.state.nextUrl} search={this.state.search}/>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Flex.Item>
            </Flex>
            :
            <CommonTip hjmc={"防疫登记"} key={'GD034'} mkbz='GD034' nonOperationalData={this.state.nonOperationalData}/>
          }
          </div>
        );
    }
}
