import React, {Component} from 'react'
import {Button, Checkbox, Form, Icon, Input, InputNumber, message, Modal, Spin, Upload} from 'antd'
import TitleTop from '@pages/Assembly/titleTop'
import {createUuid, getLoginUser} from '@utils/NHCore'
import NHFetch from '@utils/NHFetch'
import View from './view'
import NextButton from '@pages/SelfHelp/common/nextIndex'
import NHFormItem from '@components/common/NHFormItem'

const Dragger = Upload.Dragger
//确认对话框
const confirm = Modal.confirm
const {TextArea} = Input
const FormItem = Form.Item
const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 16}
}
const formItemLayoutJx = {
    labelCol: {span: 7},
    wrapperCol: {span: 15}
}

@Form.create()
export default class MainType extends Component {
    constructor(props) {
        super(props)
        this.state = {
            uploading: false,
            previewVisible: false,
            checkGroup: [], //绿色通道办理原因
            HjsqdxGroup: [], //缓交申请对象
            judgeImgList: ['.png', '.PNG', '.jpg', '.JPG', '.bpm', '.BPM', '.jpeg', '.JPEG'], //可以导入的图片格式
            judgeFileList: ['.pdf', '.PDF', '.doc', '.DOC', '.docx', '.DOCX', '.zip', '.ZIP', '.txt', '.TXT', '.xls', '.XLS', '.xlsx', '.XLSX', '.rar', '.RAR'], //可以导入的非图片格式
            x_hjjzrq: '',
            jfhjzt: false, //缴费环节状态
            BlztList: [], //办理状态
            saveLoading: false,
            fileList: []
        }
    }

    componentDidMount() {
        if (this.props.operable) {
            this.updateZzbdBlzt()
        }
        this.getSfsqLstd()
        this.initFiles(this.props)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.data !== nextProps.data) {
            this.initFiles(nextProps)
        }
    }

    // 获取绿色通道是否办理选项
    getSfsqLstd = () => {
        NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList', 'get', 'sign=dmk_YXXT_LSTD_BLZT').then(res => {
            if (res) {
                if (res.data) {
                    this.setState({
                        BlztList: res.data
                    })
                }
            }
        })
    }

    //迎新现场已办理时迎新网站自动办理
    updateZzbdBlzt = () => {
        let zzbdhjid = this.getQueryString('pkid')
        NHFetch('/zzbd/updateZzbdBlzt', 'GET', {zzbdhjid: zzbdhjid, hjdm: 'GD005_2'}).then(res => {
            if (res.data) {
                this.props.reloadMenu()
            }
        })
    }

    getQueryString = name => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }


    // 转化base64
    getBase64 = (img, callback) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => callback(reader.result))
        reader.readAsDataURL(img)
    }

    // 点击文件链接或预览图标时的回调
    handlePreview = file => {
        const isImage = /^image\/.*$/i.test(file.type)
        if (isImage) {
            if (file) {
                this.getBase64(file, imageUrl => {
                    this.setState({
                        previewImage: imageUrl,
                        previewVisible: true
                    })
                })
            } else {
                this.setState({
                    previewImage: file.url || file.thumbUrl,
                    previewVisible: true
                })
            }
        } else if (file && file['response'] && file['response']['data']) {
            //其他情况
            let isImage = true
            let fjmc = file['response']['data']['fjmc']
            let ssbh = file['response']['data']['ssbh']
            if (!this.setJudge(fjmc, this.state.judgeImgList)) {
                isImage = false
            }

            if (isImage) {
                this.showPreview(`anonymity/docrepo/download/file?attachmentId=${ssbh}`)
            } else {
                window.open(`anonymity/docrepo/download/file?attachmentId=${ssbh}`)
            }
        }
    }

    showPreview = url => {
        this.setState({
            previewImage: url,
            previewVisible: true
        })
    }

    // 关闭图片弹窗
    handleCancel = () => this.setState({previewVisible: false})


    //提交
    handleOk = e => {
        const {sqValue, dkfsValue, setChannelState, data} = this.props
        if (!sqValue) {
            message.warning('请选择是否办理')
        } else if (sqValue === '1' && !dkfsValue) {
            message.warning('请选择绿色通道类型')
        } else {
            confirm({
                title: '提示',
                content: '是否确定提交，提交后不可修改！',
                okText: '确定',
                cancelText: '取消',
                okType: 'danger',
                iconType: 'info-circle',
                onOk: () => {
                    this.handleSubmit(e)
                }
            })
        }
    }

    // 确认提交
    handleSubmit = e => {
        const {sqValue, dkfsValue, setChannelState, data} = this.props

        let xsid = getLoginUser().userId ? getLoginUser().userId : ''
        if (!xsid) {
            message.error('登录已过期，请重新登录！')
            return
        }
        this.setState({saveLoading: true}, () => setChannelState({loading: this.state.saveLoading}))
        let zzbdhjid = this.getQueryString('pkid')
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                this.setState({saveLoading: false}, () => setChannelState({loading: this.state.saveLoading}))
                return false
            }

            let fjlist = []
            if (sqValue === '1') {
                const fj = values['fj']
                let lenList = []
                if (fj) {
                    if (fj['fileList'] && fj['fileList'].length > 0) {
                        fj['fileList'].map((item, index) => {
                            if (index < 5) {
                                lenList.push(item)
                            }
                        })
                    }
                }
                let isEmpty = false
                fj['fileList'] = lenList
                if (fj) {
                    isEmpty = !(fj['fileList'] && fj['fileList'].length > 0);
                } else {
                    isEmpty = true
                }
                if (isEmpty) {
                    this.props.form.setFields({
                        fj: {
                            value: undefined,
                            errors: [new Error('请上传材料证明')]
                        }
                    })
                    this.setState({saveLoading: false}, () => setChannelState({loading: this.state.saveLoading}))
                    return false
                }
                if (fj !== undefined) {
                    let size = fj['fileList'].length
                    let file = fj['fileList'].filter(file => {
                        if (file.status === 'error') {
                            return false
                        }
                        return true
                    })

                    if (file.length !== size) {
                        this.props.form.setFields({
                            fj: {
                                value: fj,
                                errors: [new Error('材料上传出错，请重新上传.')]
                            }
                        })
                        this.setState({saveLoading: false}, () => setChannelState({loading: this.state.saveLoading}))
                        return false
                    }

                    let fileList = fj['fileList']

                    for (let i = 0; i < fileList.length; i++) {
                        let fjid = fileList[i].response.data.ssbh
                        let fjmc = fileList[i].name
                        fjlist.push({
                            fjid: fjid,
                            fjmc: fjmc
                        })
                    }
                }
            }

            let sqsmFlag = false
            let msg = ''
            let blyy = values['blyy']
            let sqsm = values['sqsm']
            let dkyh = values['dkyh']
            let hzjym = values['hzjym']
            let dkhtbh = values['dkhtbh']
            let dklx = dkfsValue
            let pkid = data.pkid
            let sfbllstd = sqValue
            if (sqsmFlag) {
                message.error(msg)
                return
            }

            let params = {}
            let blje = values['blje']
            if (sqValue === '1') {
                if (fjlist.length > 0) {
                    params = {
                        sfbllstd: sfbllstd,
                        fj: fjlist,
                        blje: blje,
                        blyy: blyy,
                        sqsm: sqsm,
                        hzjym: hzjym,
                        dkyh: dkyh,
                        xsid: xsid,
                        zzbdhjid: zzbdhjid,
                        pkid: pkid,
                        dkhtbh: dkhtbh,
                        dklx: dklx
                    }
                } else {
                    params = {
                        sfbllstd: sfbllstd,
                        blje: blje,
                        blyy: blyy,
                        sqsm: sqsm,
                        hzjym: hzjym,
                        dkyh: dkyh,
                        xsid: xsid,
                        zzbdhjid: zzbdhjid,
                        pkid: pkid,
                        dkhtbh: dkhtbh,
                        dklx: dklx
                    }
                }
            } else {
                params = {
                    sfbllstd: sfbllstd,
                    xsid: xsid,
                    zzbdhjid: zzbdhjid,
                    pkid: pkid
                }
            }

            if (!pkid) {
                NHFetch('/lstdFb/insert', 'POST', params)
                    .then(res => {
                        if (res) {
                            message.success('绿色通道申请保存成功。')
                            this.props.loadLstdInfo()
                            this.props.reloadMenu()
                        }
                        this.setState({saveLoading: false}, () => setChannelState({
                            edit: false,
                            loading: this.state.saveLoading
                        }))
                    })
                    .catch(res => {
                        this.setState({saveLoading: false}, () => setChannelState({loading: this.state.saveLoading}))
                        return res
                    })
            } else {
                NHFetch('/lstdFb/update', 'POST', params)
                    .then(res => {
                        if (res && res.data && res.data > 0) {
                            message.success('绿色通道申请保存成功。')
                            this.props.loadLstdInfo()
                            this.props.reloadMenu()
                        } else {
                            if (!res.message.startsWith('businessLogicWarm[')) {
                                message.warning('绿色通道申请保存失败，请重试。')
                            }
                            // message.warning("绿色通道申请保存失败，请重试。");
                        }
                        this.setState({saveLoading: false}, () => setChannelState({
                            edit: false,
                            loading: this.state.saveLoading
                        }))
                    })
                    .catch(res => {
                        this.setState({saveLoading: false}, () => setChannelState({
                            edit: false,
                            loading: this.state.saveLoading
                        }))
                        return res
                    })
            }
        })
    }

    // 初始化附件
    initFiles = props => {

        const {data: lstdData} = props
        let fileList = []
        if (lstdData && lstdData['fjmap']) {
            lstdData['fjmap'].map((elem, index) => {
                //格式化对于附件取值
                let file = {
                    uid: elem.fjid,
                    name: elem.fjmc,
                    response: {
                        data: {
                            ssbh: elem.fjid,
                            fjmc: elem.fjmc
                        }
                    },
                    status: 'done'
                }
                fileList.push(file)
            })
        }
        this.setState({fileList}, () => {
            this.props.form.setFieldsValue({fj: {fileList}})
        })
    }

    handleChange = ({fileList}) => {
        let count = 0
        fileList.map(elem => {
            if (elem && elem['status'] && elem['status'] === 'uploading') {
                this.setState({uploading: true})
                return
            } else if (elem && elem['status']) {
                count++
            }
        })
        if (count > 0) {
            this.setState({uploading: false})
        }
    }

    //判断文件类型是否正常  name：文件名称  map：类型范围数组  true正常
    setJudge = (name, map) => {
        let flag = false
        map.map(item => {
            if (new RegExp(`${item}$`).test(name)) {
                flag = true
            }
        })
        return flag
    }

    beforeUpload = file => {
        const {fileList} = this.state
        if (fileList.length >= 5) {
            message.error('最多只能上传5个文件')
            return false
        }
        if (!(this.setJudge(file.name, this.state.judgeImgList) || this.setJudge(file.name, this.state.judgeFileList))) {
            message.error('不能上传该格式文件')
            return false
        } else {
            return true
        }
    }

    remove = ssbh => {
        let url = `api/docrepo/attachment/sourceId/delete?souerceId=${ssbh}`
        NHFetch(url, 'POST').then(res => {
            if (res.data && res.data > 0) {
                message.success('已删除材料.')
            }
        })
    }


    render() {
        const {form} = this.props
        const {getFieldDecorator} = this.props.form
        const {nextUrl, search, sqValue, data, BlztList, fjsmxx, fjsmzt, edit, dkfsValue} = this.props
        const {previewImage, previewVisible, snwList, saveLoading} = this.state
        const props = {
            name: 'file',
            action: 'zuul/docrepo/upload',
            maxCount: 5,
            data: {uuid: createUuid(), type: '1'},

            onRemove: file => {
                this.setState(
                    ({fileList}) => {
                        const index = fileList.indexOf(file)
                        const newFileList = fileList.slice()
                        newFileList.splice(index, 1)
                        this.props.form.setFieldsValue({fj: {fileList: newFileList}})
                        return {
                            fileList: newFileList
                        }
                    },
                    () => {
                        if (file.response && file.response.data && file.response.data.ssbh) {
                            this.remove(file.response.data.ssbh)
                        }
                    }
                )
            },
            beforeUpload: file => {
                if (!this.beforeUpload(file)) {
                    return false
                }
                if (this.state.fileList.length < 5) {
                    this.setState(({fileList}) => ({
                        fileList: [...fileList, file]
                    }))
                }
                return true
            },
            onChange: file => {
                this.handleChange(file)
            },
            onPreview: this.handlePreview,
            fileList: this.state.fileList
        }

        return (
            <React.Fragment>
                {edit ? (
                    <Form>
                        <div>
                            {sqValue === '1' && (
                                <div className="form_item" style={{marginLeft: '7%'}}>
                                    <NHFormItem id={'blje'} required={true} initialValue={data['blje']} form={form} formItemLayout={formItemLayout} label="缓交金额">
                                        <InputNumber formatter={value => `${value} 元`} style={{ width: '100%' }} min={0} max={9999999} precision="0" placeholder="缓交金额" />
                                    </NHFormItem>
                                    <NHFormItem id={'sqsm'} required={true} initialValue={data['sqsm']} form={form}
                                                formItemLayout={formItemLayout} label="申请理由">
                                        <TextArea maxLength={50} placeholder="请填写申请理由"/>
                                    </NHFormItem>
                                </div>
                            )}
                        </div>


                        {sqValue === '1' && dkfsValue !== '' && dkfsValue !== undefined &&
                            <TitleTop title="申请材料" extra={false}/>}
                        {sqValue === '1' && dkfsValue !== '' && dkfsValue !== undefined && (
                            <div className="p-sw">
                                <div className="form_item" style={{marginLeft: '7%'}}>
                                    <FormItem labelCol={{span: 8}} wrapperCol={{span: 16}}
                                              label={<span>请上传材料证明&nbsp;</span>}>
                                        {getFieldDecorator('fj', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请上传材料证明'
                                                }
                                            ]
                                        })(
                                            <Dragger {...props}>
                                                <p className="ant-upload-drag-icon">
                                                    <Icon type="inbox"/>
                                                </p>
                                                <Spin spinning={this.state.uploading}/>
                                                <p className="ant-upload-text font-14">点击此处或拖拽文件到此</p>
                                            </Dragger>
                                        )}
                                    </FormItem>
                                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                        <img alt="example" style={{width: '100%'}} src={previewImage}/>
                                    </Modal>
                                    {fjsmzt ? (
                                        <div style={{
                                            color: '#F00',
                                            wordBreak: 'break-all',
                                            whiteSpace: 'normal',
                                            lineHeight: '15px',
                                            width: '95%'
                                        }} justify="left" align="middle">
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{fjsmxx}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        )}
                        <div className="text-center mt-50">
                            <Button type="primary" htmlType="submit" onClick={this.handleOk} loading={saveLoading}>
                                保存
                            </Button>
                            <NextButton nextUrl={nextUrl} search={search}/>
                        </div>
                    </Form>
                ) : (
                    <View reloadMenu={this.props.reloadMenu} nextUrl={nextUrl}
                          loadLstdInfo={() => this.props.loadLstdInfo('edit')} search={search}
                          showPreview={this.showPreview} setJudge={this.setJudge} data={data || {}}
                          judgeImgList={this.state.judgeImgList} jfhjzt={this.state.jfhjzt} BlztList={BlztList}/>
                )}
            </React.Fragment>
        )
    }
}
