import React from 'react'
import { Row, Col, Button, message } from 'antd'
import { getLoginUser } from '@utils/NHCore'
import TitleTop from '@pages/Assembly/titleTop'
import NHFetch from '@utils/NHFetch'
import NextButton from '@pages/SelfHelp/common/nextIndex'

const shztList = {
  '-1': '审核不通过',
  0: '未审核',
  1: '审核通过',
  2: '已办理',
  9: '驳回'
}
export default class View extends React.Component {
  edit = () => {
    const { loadLstdInfo, reloadMenu } = this.props
    let xsid = getLoginUser() ? getLoginUser().userId : ''
    NHFetch('/lstdFb/getLstdInfoByXsid', 'GET', { xsid: xsid }).then(resData => {
      if (resData && resData.data) {
        if (resData.data.shzt != '' && resData.data.shzt != undefined && resData.data.shzt == null) {
          if (resData.data.shzt !== '0' && resData.data.shzt !== '9') {
            message.warning('该申请已经审核，不能修改')
            return
          }
          loadLstdInfo('edit')
          reloadMenu()
        } else {
          loadLstdInfo('edit')
          reloadMenu()
        }
      } else {
        message.warning('修改异常')
      }
    })
  }

  // 点击文件链接或预览图标时的回调
  handlePreview = (fjmc, fjid) => {
    const { setJudge, showPreview, judgeImgList } = this.props
    let isImage = true
    if (!setJudge(fjmc, judgeImgList)) {
      isImage = false
    }

    if (isImage) {
      window.open(`anonymity/docrepo/download/file?attachmentId=${fjid}`)
      showPreview(`anonymity/docrepo/download/file?attachmentId=${fjid}`)
    } else {
      window.open(`anonymity/docrepo/download/file?attachmentId=${fjid}`)
    }
  }

  renderApplyInfoItem = (label, value) => {
    return (
      <Row gutter={24} style={{ margin: '10px 0px' }}>
        <Col span={4} style={{ textAlign: 'right', paddingRight: 0 }}>
          <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>{label}&nbsp;: </span>
        </Col>
        <Col span={20}>{value}</Col>
      </Row>
    )
  }

  render() {
    const { data, BlztList, nextUrl, search } = this.props

    let lstdBlzt = ''
    if (data['sfbllstd']) {
      if (BlztList) {
        BlztList.map((item, index) => {
          if (data['sfbllstd'] == item.VALUE) {
            lstdBlzt = item.LABEL
          }
        })
      }
    }

    return (
      <div>
        <TitleTop title="申请信息" extra={false} />
        <div className="p-sw">
          <div className="form_item" style={{ width: '100%' }}>
            <Row gutter={24} style={{ margin: '10px 0px' }}>
              <Col span={4} style={{ textAlign: 'right', paddingRight: 0 }}>
                <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>绿色通道申请&nbsp;: </span>
              </Col>
              <Col span={20}>
                <span>{lstdBlzt}</span>
              </Col>
            </Row>
            {data['sfbllstd'] == '1' && (
              <React.Fragment>
                {this.renderApplyInfoItem('申请类型', <span>{data['dklxmc'] ? data['dklxmc'] : ''}</span>)}
                {this.renderApplyInfoItem(
                  '办理原因',
                  data['blyy'].map((elem, index) => {
                    return (
                      <span
                        style={{
                          borderColor: '#1890ff',
                          borderRadius: '5px',
                          padding: '2px 16px',
                          border: '1px solid rgb(24, 144, 255)',
                          margin: '5px',
                          display: 'inline-block'
                        }}
                      >
                        {elem}
                      </span>
                    )
                  })
                )}
               
                {this.renderApplyInfoItem('申请理由', <span>{data['sqsm'] ? data['sqsm'] : ''}</span>)}
              </React.Fragment>
            )}
          </div>
        </div>

        {data['sfbllstd'] == '1' && (
          <React.Fragment>
            <TitleTop title="申请材料" extra={false} />

            <div className="p-sw">
              <div className="form_item" style={{ width: '100%' }}>
                <Row gutter={24}>
                  <Col span={4} style={{ textAlign: 'right', paddingRight: 0 }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>材料证明&nbsp;: </span>
                  </Col>
                  <Col span={20}>
                    {data['fjmap'] &&
                      data['fjmap'].map((elem, index) => {
                        return (
                          <div style={{ marginBottom: '20px' }}>
                            <a
                              onClick={() => {
                                this.handlePreview(elem['fjmc'], elem['fjid'])
                              }}
                            >
                              {elem['fjmc']}
                            </a>
                          </div>
                        )
                      })}
                  </Col>
                </Row>
              </div>
            </div>

            <TitleTop title="申请审核" extra={false} />
            <div className="p-sw">
              <div className="form_item" style={{ width: '100%', marginBottom: '24px' }}>
                <Row gutter={24} style={{ margin: '10px 0px' }}>
                  <Col span={4} style={{ textAlign: 'right', paddingRight: 0 }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>审核意见&nbsp;: </span>
                  </Col>
                  <Col span={20}>
                    <span>{data['shyj']}</span>
                  </Col>
                </Row>
              </div>
              <br />

              <div className="form_item" style={{ width: '100%', marginBottom: '24px' }}>
                <Row gutter={24} style={{ margin: '10px 0px' }}>
                  <Col span={4} style={{ textAlign: 'right', paddingRight: 0 }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>审核状态&nbsp;: </span>
                  </Col>
                  <Col span={20}>
                    <span style={{ color: '#0066FF' }}>{shztList[data['shzt']]}</span>
                  </Col>
                </Row>
              </div>
            </div>
          </React.Fragment>
        )}

        <div className="text-center mt-50">
          {data['sfbllstd'] == '1' && data['shzt'] == '9' && (
            <Button
              type="primary"
              onClick={() => {
                this.edit()
              }}
            >
              修改
            </Button>
          )}
          <NextButton nextUrl={nextUrl} search={search} />
        </div>
      </div>
    )
  }
}
