import React from 'react';
// import Flex from '../../Flex';
import { Flex } from '@components';
import { Button, Progress, Table,Checkbox } from 'antd';
// import TitleTop from '../../Assembly/titleTop';
import TitleTop from '@pages/Assembly/titleTop';
import style from './index.css';
import {getLoginUser,createUuid} from "@utils/NHCore";
import BarCode from 'react-barcode';
import NHFetch from "@utils/NHFetch";


export default class PrintTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            xsxx:this.props.initFormDate,//学生信息
            nr: [
                {
                    key: 1,
                    row_id: 1,
                    nr: '交验录取通知书'
                },
                {
                    key: 2,
                    row_id: 2,
                    nr: '领取一卡通'
                },
                {
                    key: 3,
                    row_id: 3,
                    nr: '自愿购买保险'
                },
                {
                    key: 4,
                    row_id: 4,
                    nr: '自带床上用品检验'
                },
                {
                    key: 5,
                    row_id: 5,
                    nr: '领取宿舍钥匙'
                },
                {
                    key: 6,
                    row_id: 6,
                    nr: '参加体检'
                },
            ],
            bzItems: [
                { key: 1, bz: '新生拿到报到单后，请到指定地点完成报到流程。' },
                { key: 2, bz: '未缴清费用的同学，需在报到期间缴清费用，如有特殊情况，请到班主任处登记。' },
                { key: 3, bz: '报到单请务必保存好，报到结束后，交回班主任处。' }
            ],
            // sfxmList:[],//缴费列表
        };
    }

    /* componentWillReceiveProps(nextProps) {
        this.loadSfxmList(nextProps);
    } */

   /*  loadSfxmList = (nextProps) =>  {
        const {xsxx} = nextProps.initFormDate;
        const {xsid, sfzjh, xh} = xsxx;
        NHFetch('api/zhxg-yxwz/openapi/yxxt/getJfxxList', 'GET', { xsid: xsid, sfzjh: sfzjh, xh: xh }).then(res => {
          if (res && res.data) {
            this.setState({
                sfxmList: res.data,
            });
          }
        });

    } */

    componentDidMount() {
        let userId = getLoginUser() ? getLoginUser().userId : undefined;
        if (userId) {
            this.getStudentPhotoBase64(userId, (data => {
                if (!data) return;
                this.setState({ 
                    xsid: userId,
                    imageUrl: `data:image/jpeg;base64,${data}`
                })
            })) 
        } 
    }

     // 获取学生头像
     getStudentPhotoBase64 =  (userId, callback) => {
        NHFetch('api/zhxg-yxwz/openapi/student/getStudentPhotoBase64', 'get', { xsid: userId, type: 10 })
        .then(res => {
            if (res && res.code == 200) {
                callback(res.data);
            }
        }); 
    }


    render() {
        const { bzItems,imageUrl} = this.state;
        let { xsxx, schoolName, year, nr, account, bddbz,headimg,sfxmList } = this.props.initFormDate;
        let xqmc = xsxx.xqmc;
        let ldmc = xsxx.ldmc;
        let fjmc = xsxx.fjmc;
        let cwh = xsxx.cwh;
        let nrList = [];//内容list
        let bzList = [];//备注list
        if(nr && nr.length>0) {
            nr.map((elem,index)=>{
                nrList.push(
                    <div>
                        <div className={style.ejxybd_label1}>
                            <span>
                            {index+1}
                            </span>
                        </div>
                        <div className={style.ejxybd_label2}>
                            <span>
                            {/* {elem.HJMC} */}
                            {elem.nr}
                            </span>
                        </div>
                    </div>
                    )
                }
            )
        }
        // 获取备注list
        if(bzItems && bzItems.length>0){
            bzItems.map((elem)=>{
                bzList.push(
                    <div className={style.bz_item} key={elem.key}>
                        {elem.key}.{elem.bz}
                    </div>
                )
            })
        }
        const payColumns = [
            {
                title: '序号',
                dataIndex: 'row_id'
            },
            {
                title: '收费项目',
                dataIndex: 'sfxmmc'
            },
            {
                title: '应收金额',
                dataIndex: 'ysje'
            },
            {
                title: '已交金额',
                dataIndex: 'ssje',
                render: (text, record) => {
                    return <span className="text-primary">{text}</span>;
                }
            }
        ];
        const Columns = [
            {
                title: '序号',
                dataIndex: 'row_id'
            },
            {
                title: '主要内容',
                dataIndex: 'HJMC'
            }
        ];
        return (
                            <div className="box" style={{width:'90%'}}>
                                <div>
                                    <div style={{textAlign: 'center', position:'relative'}}>
                                        <div  className={style.title} style={{fontSize:'20px'}}>
                                            {schoolName}{year}新生入学报到单
                                        </div>
                                        <img style={{ position:'absolute',width:'120px',right:'0px',top:'0px' }} src={require('./image/gznyxylogo.png')} />
                                    </div>

                                    <TitleTop title="基本信息" extra={false} style={{margin:"10px 0px"}}/>
                                    <Flex className={style.p_sw_big} >
                                        <img className={style.jbxx_icon_bg} src={imageUrl}
                                        onError={e => { e.target.src = stuDefault }}/>
                                        <Flex.Item className={style.ml_20} style={{marginLeft:'5%'}}>
                                            {[
                                                {
                                                    label: '姓名',
                                                    name: xsxx.xm
                                                },
                                                {
                                                    label: '性别',
                                                    name: xsxx.xb
                                                },
                                                {
                                                    label: '学号',
                                                    name: xsxx.xh
                                                },
                                                {
                                                    label: '民族',
                                                    name: xsxx.mz
                                                },
                                                {
                                                    label: '政治面貌',
                                                    name: xsxx.zzmm
                                                },
                                                {
                                                    label: '籍贯',
                                                    name: xsxx.jg
                                                },
                                                {
                                                    label: '联系电话',
                                                    name: xsxx.sjhm
                                                },
                                                {
                                                    label: '身份证号',
                                                    name: xsxx.sfzjh
                                                },
                                                {
                                                    label: '考生类型',
                                                    name: xsxx.xslb
                                                },
                                                {
                                                    label: '学院',
                                                    name: xsxx.bmmc
                                                },
                                                {
                                                    label: '专业',
                                                    name: xsxx.zymc
                                                },
                                                {
                                                    label: '班级',
                                                    name: xsxx.bjmc
                                                }
                                            ].map((elem, index) => {
                                                return (
                                                    <div className={style.jbxx_item} key={index}>
                                                        <span className={style.item_label}>
                                                        {elem.label}：</span>
                                                        <div className={style.item_name_t_clamp_1}>
                                                        {elem.name}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </Flex.Item>
                                    </Flex>
                                    <div className={style.singelWarp}>
                                        <div className={style.singelWarp_label} style={{ width: '15%' }}>班主任：(签名)</div>
                                        <div className={style.singelWarp_Words}></div>
                                    </div>
                                    <div className={style.ItemWarp}>
                                        <div>
                                            <div className={style.itemWarp_label}>
                                                1、 登记缴纳录取通知书：
                                                <Checkbox checked={false}></Checkbox>
                                            </div>
                                            {/* <div className={style.itemWarp_Words}></div> */}
                                        </div>
                                        <div style={{marginTop:20}}>
                                            <div className={style.itemWarp_label}>2、 缴纳材料：</div>
                                            <div className={style.itemWarp_Words} style={{height: '60px' }}></div>
                                        </div>
                                        <div style={{margin:"20px 0px"}}>
                                            <div className={style.itemWarp_label}>3、 缴费金额：</div>
                                            <div className={style.itemWarp_Words}>
                                                <div>
                                                    <div className={style.jfxx_label}>
                                                        <span >
                                                        序号
                                                        </span>
                                                    </div>
                                                    <div className={style.jfxx_label}>
                                                        <span >
                                                        收费项目
                                                        </span>
                                                    </div>
                                                    <div className={style.jfxx_label}>
                                                        <span >
                                                        应收金额
                                                        </span>
                                                    </div>
                                                    <div className={style.jfxx_label}>
                                                        <span >
                                                        已交金额
                                                        </span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className={style.jfxx_label}>
                                                        <span>
                                                        {sfxmList && sfxmList.length >= 1 ? '1':""}
                                                        </span>
                                                    </div>
                                                    <div className={style.jfxx_label}>
                                                        <span >
                                                        {sfxmList && sfxmList.length >= 1 ? sfxmList[0].SFXMMC:""}
                                                        </span>
                                                    </div>
                                                    <div className={style.jfxx_label}>
                                                        <span >
                                                        {sfxmList && sfxmList.length >= 1 ? sfxmList[0].YSJE:""}
                                                        </span>
                                                    </div>
                                                    <div className={style.jfxx_label}>
                                                        <span >
                                                        {sfxmList && sfxmList.length >= 1 ? sfxmList[0].SSJE:""}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className={style.jfxx_label}>
                                                        <span>
                                                        {sfxmList && sfxmList.length >= 2 ? '2':""}
                                                        </span>
                                                    </div>
                                                    <div className={style.jfxx_label}>
                                                        <span >
                                                        {sfxmList && sfxmList.length >= 2 ? sfxmList[1].SFXMMC:""}
                                                        </span>
                                                    </div>
                                                    <div className={style.jfxx_label}>
                                                        <span >
                                                        {sfxmList && sfxmList.length >= 2 ? sfxmList[1].YSJE:""}
                                                        </span>
                                                    </div>
                                                    <div className={style.jfxx_label}>
                                                        <span >
                                                        {sfxmList && sfxmList.length >= 2 ? sfxmList[1].SSJE:""}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className={style.jfxx_label}>
                                                        <span>
                                                        {sfxmList && sfxmList.length >= 3 ? '3':""}
                                                        </span>
                                                    </div>
                                                    <div className={style.jfxx_label}>
                                                        <span >
                                                        {sfxmList && sfxmList.length >= 3 ? sfxmList[2].SFXMMC:""}
                                                        </span>
                                                    </div>
                                                    <div className={style.jfxx_label}>
                                                        <span >
                                                        {sfxmList && sfxmList.length >= 3 ? sfxmList[2].YSJE:""}
                                                        </span>
                                                    </div>
                                                    <div className={style.jfxx_label}>
                                                        <span >
                                                        {sfxmList && sfxmList.length >= 3 ? sfxmList[2].SSJE:""}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className={style.jfxx_label}>
                                                        <span>
                                                        {sfxmList && sfxmList.length >= 4 ? '4':""}
                                                        </span>
                                                    </div>
                                                    <div className={style.jfxx_label}>
                                                        <span >
                                                        {sfxmList && sfxmList.length >= 4 ? sfxmList[3].SFXMMC:""}
                                                        </span>
                                                    </div>
                                                    <div className={style.jfxx_label}>
                                                        <span >
                                                        {sfxmList && sfxmList.length >= 4 ? sfxmList[3].YSJE:""}
                                                        </span>
                                                    </div>
                                                    <div className={style.jfxx_label}>
                                                        <span >
                                                        {sfxmList && sfxmList.length >= 4 ? sfxmList[3].SSJE:""}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className={style.jfxx_label}>
                                                        <span>
                                                        {sfxmList && sfxmList.length >= 5 ? '5':""}
                                                        </span>
                                                    </div>
                                                    <div className={style.jfxx_label}>
                                                        <span >
                                                        {sfxmList && sfxmList.length >= 5 ? sfxmList[4].SFXMMC:""}
                                                        </span>
                                                    </div>
                                                    <div className={style.jfxx_label}>
                                                        <span >
                                                        {sfxmList && sfxmList.length >= 5 ? sfxmList[4].YSJE:""}
                                                        </span>
                                                    </div>
                                                    <div className={style.jfxx_label}>
                                                        <span >
                                                        {sfxmList && sfxmList.length >= 5 ? sfxmList[4].SSJE:""}
                                                        </span>
                                                    </div>
                                                </div>

                                            
                                                {/* {sfxmList} */}
                                            </div>
                                        </div>
                                        <div style={{display:"flex"}}>
                                            <div style={{width:"13%",padding:"32px 0px"}}>4、 宿舍信息：</div>
                                            <div className={style.p_sw_big} style={{padding: '10px', fontSize: '14px',width:"87%"}}>
                                                <div className={style.ssxx_list} style={{border: '1px solid #eaeeed'}}>
                                                    <div className={style.ssxx_item}>
                                                        <div className={style.ssxx_label} >
                                                            <span>校区：</span>
                                                        </div>
                                                        <div className={style.ssxx_name} >
                                                            {/* {xsxx.xqmc} */}
                                                            清镇校区
                                                        </div>
                                                    </div>
                                                    <div className={style.ssxx_item}>
                                                        <div  className={style.ssxx_label}>
                                                            <span>楼栋：</span>
                                                        </div>
                                                        <div  className={style.ssxx_name} >
                                                            {xsxx.ldmc}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={style.ssxx_list} style={{border: '1px solid #eaeeed'}}>
                                                    <div className={style.ssxx_item}>
                                                        <div className={style.ssxx_label}>
                                                            <span>房间：</span>
                                                        </div>
                                                        <div className={style.ssxx_name} >
                                                            {xsxx.fjmc}
                                                        </div>
                                                    </div>
                                                    <div  className={style.ssxx_item} >
                                                        <div  className={style.ssxx_label} >
                                                            <span>床位：</span>
                                                        </div>
                                                        <div className={style.ssxx_name} >
                                                            {xsxx.cwh}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.singelWarp}>
                                        <div className={style.singelWarp_label}>军训服装：</div>
                                        <div className={style.singelWarp_Words}>
                                            <div className={style.jxfz_item}>
                                                <div className={style.jxfz_label} >
                                                    <span>身高：</span>
                                                </div>
                                                <div className={style.jxfz_name} >
                                                    {xsxx.sg}cm
                                                </div>
                                            </div>
                                            <div className={style.jxfz_item}>
                                                <div  className={style.jxfz_label}>
                                                    <span>体重：</span>
                                                </div>
                                                <div  className={style.jxfz_name} >
                                                    {xsxx.tz}kg
                                                </div>
                                            </div>
                                            <div className={style.jxfz_item}>
                                                <div className={style.jxfz_label} >
                                                    <span>服装尺码：</span>
                                                </div>
                                                <div className={style.jxfz_name} >
                                                    {xsxx.fzcm}
                                                </div>
                                            </div>
                                            <div className={style.jxfz_item}>
                                                <div  className={style.jxfz_label}>
                                                    <span>鞋码：</span>
                                                </div>
                                                <div  className={style.jxfz_name} >
                                                    {xsxx.shoesSize}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <TitleTop title="缴费信息" extra={false} />
                                    <div className={style.p_sw_big} >
                                        <div>
                                            <div className={style.jfxx_label}>
                                                <span >
                                                序号
                                                </span>
                                            </div>
                                            <div className={style.jfxx_label}>
                                                <span >
                                                收费项目
                                                </span>
                                            </div>
                                            <div className={style.jfxx_label}>
                                                <span >
                                                应收金额
                                                </span>
                                            </div>
                                            <div className={style.jfxx_label}>
                                                <span >
                                                已交金额
                                                </span>
                                            </div>
                                        </div>
                                        {sfxmList}
                                    </div>
                                    <TitleTop title="二级学院报到" extra={false} />
                                    <div  className={style.p_sw_big}>
                                    <div>
                                        <div className={style.ejxybd_label1}>
                                            <span>
                                            序号
                                            </span>
                                        </div>
                                        <div className={style.ejxybd_label2}>
                                            <span>
                                            主要内容
                                            </span>
                                        </div>
                                    </div>
                                        {nrList}
                                    </div> */}
                                    <div className={style.p_sw_big}>
                                        <div  style={{position: 'relative',margin:"20px 0px"}}>
                                            <div className={style.text_title} style={{ color: '#000',fontSize:'14px'}}>
                                                备注：
                                            </div>
                                            {/* <div className={style.bz_item}>
                                                1.新生拿到报到单后，请到指定地点完成报到流程。
                                            </div>
                                            <div className={style.bz_item}>
                                                2.未缴清费用的同学，需在报到期间缴清费用，如有特殊情况，请到班主任处登记。
                                            </div>
                                            <div  className={style.bz_item}>
                                                3.报到单请务必保存好，报到结束后，由学院统一收回。
                                            </div> */}
                                            {bzList}
                                        </div>
                                        <div style={{display:"flex"}}>
                                            <div style={{width:'34%',padding:"3% 0px"}}>
                                                <BarCode
                                                className={style.bz_img}
                                                value={account}
                                                width={2}
                                                height={60}
                                                fontSize={14}
                                                >
                                                </BarCode>
                                            </div>
                                            <div className={style.bottom_png} >
                                                <div className={style.bottom_pngItem}>
                                                    <img src={require('./image/app1.png')}/>
                                                    <div>贵州农业职业学院移动校园APP下载</div>
                                                </div>
                                                <div className={style.bottom_pngItem}>
                                                    <img src={require('./image/app2.png')}/>
                                                    <div>一卡通完美校园APP</div>
                                                </div>
                                                <div className={style.bottom_pngItem}>
                                                    <img src={require('./image/app3.png')}/>
                                                    <div>缴费公众号</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
        );
    }
}
