export const mkbz = {
    GD001: '/personalInformation',
    GD001_10: '/personalInformationv10',
    GD001_10_1: '/personalInformationv10_1',
    GD001_7: '/personalInformationFg',
    GD002: '/buy',
    GD002_1: '/buyFa',
    GD003: '/clothingSize',
    GD003_1: '/clothingSizeJzykd',
    GD003_2: '/clothingSizeXatlzyjsxy',
    GD003_3: '/clothingSizeJxgczyjsxy',
    GD003_4: '/clothingSizeSdcsjszyxy',
    GD004: '/report',
    GD004_7:'/reportBjwlxy',
    GD004_8:'/reportFjsw',
    GD004_9:'/reportWydx',
    GD004_10:'/reportHndysf',
    GD001_123:'/reportCzxy',
    GD005: '/channel',
    GD005_1: '/szjdChannel',
    GD005_2: '/channelFb',
    GD005_12: '/channelWydx',
    GD005_13: '/channelHbnzxy',
    GD005_14: '/channelJxkjsfdx',
    GD005_15: '/channelJxcmzyxy',
    GD006: '/chooseDormitory',
    GD006_1: '/scjtChooseDor',
    GD006_2: '/checkDormitory',
    GD006_3: '/cdzyjsxyChooseDor',
    GD006_5: '/allotDormitory',
    GD006_8: '/viewDorm',
    GD006V2: '/chooseDormitoryV2',
    GD006V2_1: '/zjkjxyChooseDormitoryV2',
    GD006V2_3: '/chooseDormitoryV3',
    GD007: '/major',
    GD007_1: '/majorFa',
    GD007_2:'/majorFb',
    GD008: '/onlinePayment',
    GD008_8: '/jfxxHzczy',
    GD008_12: '/payGD008_12',

    GD009: '/print',
    GD010: '/sszychannel',
    GD010_1: '/xfcsypPay',
    GD010_2: '/xyhycz',
    GD010_3: '/rlsfxxhy',
    GD010_4: '/ykthyrlcj',
    GD010_5: '/financialPayment',
    GD010_6: '/identityVerification',

    GD011: '/sszywsjxf',
    GD012: '/sszyssfp',
    GD013: '/ssxzywsjssf',
    GD014: '/schoolUniform',
    GD015_2: 'jsSfhy',
    GD020_1: '/viewDormitory',
    GD021: '/education',
    GD023: '/examination',
    GD0081: 'nonTaxPay',
    // GD022:'/autobiography',
    GD001_1: '/info',
    GD008_1: '/onlinePaymentFxxy',
    GD008_3: '/wxChatPay',
    GD008_4: '/payOnline',
    GD008_9: '/orderPayOnline',
    GD001_2: '/pInfo',
    GD004_1:'/reportGdnhgs',
    GD004_3:'/reportCzxy',
    GD001_5:'/infoGdnhgs',
    GD001_6:'/infoHncs',
    GD004_2:'/reportHncs',
    GD005_4:'/channelZtxy',
    GD005_5:'/channelTlxy',
    GD005_6:'/channelCzxy',
    GD027_1: '/choosePhoneNum',
    GD027_2: '/choosePhone',
    GD001_4: '/newInfo',
    GD001_3: '/szjdInfo',
    GD031:'/mentalHealthSurvey',
    GD032:'/applyDorm',
    GD033:'/viewStudentId',
    GD033_1:'/getStudentId',
    GD005_8:'/ahGsLsTd',
    GD034:'/antiepidemic',
    GD034_1: '/antiEpidemicDlhl',
    GD035_1: '/confirmInfoDlhl',
    GD004_4:'/reportwhrj',
    GD006_4:'/whrjChooseDor',
    GD024_1:'/medicalInsurance',
    GD005_10: '/channelBjwl',
    GD015: '/photoUpload',
    GD015_3: '/photoUploadGxstzy',
    GD015_5: '/photoUploadQuick',
    GD015_7: '/stuModifyPic',
    GD008_2:'jfPayFees',
    GD040:'/insurance',
    GD022_1: '/enrollmentEducation',
    GD008_3_1: '/broadBand'
}