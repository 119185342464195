import React from "react";
import PropTypes from "prop-types";
import NHFetch from "../../../../utils/NHFetch";
import {Radio} from 'antd';

const RadioGroup = Radio.Group;

const pathUrl = "/proData/selectDataList";

/**
 * @Description: 单选按钮组控件
 * @author weishihuai
 * @date 2019/3/6 15:06
 * 更新日志：
 * 2019-05-23：增加RadioButton样式支持 by zengxiangkai
 * 使用说明:
 * 1. 使用sign标识从后台查询数据源时，以'DMK_'加上对应代码库标识,如: sign={'DMK_WJCF_WJLX'}
 * 2. 使用自定义数据源时,需注意格式. 如: [{"VALUE":"1","LABEL":"日常违纪"},{"VALUE":"2","LABEL":"考试违纪"},{"VALUE":"3","LABEL":"公寓违纪"}]
 * 3. 使用radioDisabledArr属性可动态配置需要禁用的按钮VALUE值,如: radioDisabledArr={['2','3']}
 * 4. 使用filterType集合filterData可以过滤指定按钮, 如: filterType={'save'} filterData={['2', '3', '4']}
 * 5. 使用示例:
 * <NHRadioGroup
 *      onChange={this.onWjlxChangeListener}
 *      radioStyle={radioStyle}
 *      sign={'DMK_WJCF_WJLX'}
 *      radioGroupDisabled={false}
 *      radioDisabledArr={['1', '2']}
 *      filterType={'save'}
 *      filterData={['2', '3', '4']}
 * />
 *
 */
class NHRadioGroup extends React.Component {

    constructor(props) {
        super(props);
        const {value = ''} = this.props;
        this.state = {
            data: [],
            value: value,
            radioOptions: []
        }
    }

    componentDidMount() {
        this._isMounted = true;
        const {sign} = this.props;
        this.getDataSource(sign);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ('value' in nextProps) {
            const value = nextProps.value;
            this.setState({
                value: value
            });
        }
        if ('dataSource' in nextProps) {
            const dataSource = nextProps.dataSource;
            this.setState({
                data: dataSource
            });
        }
    }

    //根据数据源标识从后台查询DataSource
    getDataSource = (sign) => {
        if (!sign) {
            this.getCustomDataSource();
        } else {
            NHFetch(pathUrl, 'GET', {sign: sign, t: new Date().getTime()})
                .then(res => {
                    if (res && this._isMounted) {
                        let data = res.data;
                        if (data) {
                            this.setState({data: [...data]}, () => {
                                this.buildRadio();
                            });
                        } else {
                            this.getCustomDataSource();
                        }
                    }
                });
        }
    };

    //使用自定义的DataSource
    getCustomDataSource = () => {
        const {dataSource = []} = this.props;
        if (dataSource && dataSource.length > 0) {
            this.setState({data: [...dataSource]}, () => {
                this.buildRadio();
            });
        }
    };

    //选项变化时的回调函数
    onRadioSelectChange = (e) => {
        this.setState({value: e.target.value});
        const {onChange} = this.props;
        if (onChange && typeof onChange === 'function') {
            onChange(e.target.value);
        }
    }

    //检查单选按钮是否需要禁用
    checkRadioIsDisable = (radioDisabledArr, value) => {
        if (radioDisabledArr && radioDisabledArr.length <= 0) {
            return false;
        }
        let val = radioDisabledArr.find((key) => {
            return value === key || value === key
        });
        return val !== undefined;
    };

    /**
     * * 组装Radio Options
     * @memberof NHRadioGroup
     */
    buildRadio = () => {

        const {data = []} = this.state;
        const {radioDisabledArr = [], filterType = '', filterData = []} = this.props;
        let radioOptions = [];

        //同时指定过滤类型和需要过滤的数据VALUE
        if (filterType && filterData) {
            switch (filterType) {
                case 'remove': { //过滤掉指定key的单选按钮
                    let filteredData = data.filter((item) => {
                        let itemVal = item.VALUE || item.value;
                        let isExist = filterData.find((val) => {
                            return val === itemVal;
                        });
                        return isExist === undefined;
                    });
                    if (filteredData && filteredData.length > 0) {
                        radioOptions = this.buildRadioOptions(filteredData, radioDisabledArr);
                    }
                    break;
                }
                case 'save': { //保留指定key值的单选按钮
                    let filteredData = data.filter((item) => {
                        let itemVal = item.VALUE || item.value;
                        let isExist = filterData.find((val) => {
                            return val === itemVal;
                        });
                        return isExist !== undefined;
                    });
                    if (filteredData && filteredData.length > 0) {
                        radioOptions = this.buildRadioOptions(filteredData, radioDisabledArr);
                    }
                    break;
                }
                default: {
                    radioOptions = this.buildRadioOptions(data, radioDisabledArr);
                    break;
                }
            }
        } else {
            //不进行数据过滤
            radioOptions = this.buildRadioOptions(data, radioDisabledArr);
        }

        this.setState({radioOptions});
    }

    /**
     * * 组装Radio
     * @memberof NHRadioGroup
     */
    buildRadioOptions = (filteredData, radioDisabledArr) => {

        const {radioButton, radioStyle} = this.props;

        if (radioButton) {
            return filteredData.map(option =>
                <Radio.Button
                    style={radioStyle}
                    key={option.VALUE || option.value}
                    disabled={this.checkRadioIsDisable(radioDisabledArr, option.VALUE || option.value)}
                    value={option.VALUE || option.value}>{option.LABEL || option.label}
                </Radio.Button>
            );
        } else {
            return filteredData.map(option =>
                <Radio style={radioStyle}
                       key={option.VALUE || option.value}
                       disabled={this.checkRadioIsDisable(radioDisabledArr, option.VALUE || option.value)}
                       value={option.VALUE || option.value}>{option.LABEL || option.label}
                </Radio>
            );
        }
    };

    render() {
        const {value, radioOptions} = this.state;
        const {buttonStyle, radioGroupDisabled, defaultValue} = this.props;

        return (
            <RadioGroup
                onChange={this.onRadioSelectChange}
                disabled={radioGroupDisabled}
                buttonStyle={buttonStyle}
                value={value}
                defaultValue={defaultValue}
            >
                {radioOptions}
            </RadioGroup>
        )
    }
}

NHRadioGroup.defaultProps = {
    radioGroupDisabled: false, //按钮组启用/禁用状态
    radioDisabledArr: [], //需要禁用的按钮集合
    radioStyle: {}, //按钮排列样式,可配置垂直排列,默认水平排列
    radioButton: false, //是否按钮样式
    buttonStyle: 'solid' //按钮样式              
};

NHRadioGroup.propTypes = {
    sign: PropTypes.string,                             //数据源标识
    dataSource: PropTypes.arrayOf(                      //自定义数据源
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.node
        })
    ),
    radioButton: PropTypes.bool, //是否是按钮样式
    buttonStyle: PropTypes.oneOf(['outline', 'solid']), //按钮样式（outline | solid）
    radioGroupDisabled: PropTypes.bool,                 //按钮组启用/禁用状态
    defaultValue: PropTypes.string,                     //默认值
    value: PropTypes.string,                            //初始值
    onChange: PropTypes.func,                           //选项变化时的回调函数
    radioDisabledArr: PropTypes.array,                  //需要禁用的按钮Value集合
    filterType: PropTypes.oneOf(['save', 'remove']),    //过滤数据类型,save - 保留  remove - 移除
    filterData: PropTypes.array                         //过滤数据, save - 保留指定的filterData remove - 移除指定的filterData
};

export default NHRadioGroup;