import React from 'react';
require('./ueditor/ueditor.config.js');
require('./ueditor/ueditor.all.min.js');
require('./ueditor/lang/zh-cn/zh-cn.js');
require('./ueditor/themes/default/css/ueditor.css');

import PropTypes from "prop-types";

class NHUeditor extends React.Component {
    static defaultProps = {
        id: 'content',
        Content: '',//父类传进来的值
        getContent: undefined,//通过该方法把 this.getContent传给父类
    }
    constructor(props) {
        super(props);
        this.state = {
            width: this.props.width ? this.props.width : '800px',
            height: this.props.height ? this.props.height : '400px',
            imgUploadUrl: '',//图片上传路径
            imgShowUrl: '',//图片展示路径
            fileUploadUrl: '',//附件上传路径
            fileDownloadUrl: ''//附件下载路径
        };
    }
    componentDidMount() {
        const { getContent } = this.props;
        this.initEditor();
        if (getContent) {
            getContent(this.getContent);
        }
    }
    componentWillUnmount() {
        // 组件卸载后，清除放入库的id 
        UE.delEditor(this.props.id);
    }
    initEditor() {
        const config = {
            autoFloatEnabled: this.props.autoFloatEnabled === true ? true : false,  //是否保持toolbar的位置不动,默认true
            autoHeightEnabled: this.props.autoHeightEnabled === false ? false : true, //是否自动长高,默认true
            serverUrl: 'jsp/controller.jsp',//粘贴时候图片的上传路径,这个暂时还没有实现
        };
        const { id, Editor } = this.props;
        const ueEditor = UE.getEditor(this.props.id, config);
        const self = this;

        ueEditor.ready((ueditor) => {
            if (!ueditor) {
                UE.delEditor(id);
                self.initEditor();
            }
            if (Editor) {
                Editor(ueEditor);
            }
        })
    }

    getContent = () => {
        return UE.getEditor(this.props.id).getContent();
    }
    render() {
        return (
            <div>
                <div id={this.props.id} name="content" type="text/plain" style={{ width: this.state.width, height: this.state.height }}></div>
               {/* <Link UE={UE}></Link>
                <Img UE={UE}></Img>*/}
                {/* <File UE={UE}></File> */}
            </div>
        )
    }

}
//属性检查
NHUeditor.propTypes = {
    getContent: PropTypes.func,//父级获取富文本的获取内容方法
}

export default NHUeditor;