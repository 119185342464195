import React from 'react'
import {Flex} from '@components'
import {Form, Radio, Spin} from 'antd'
import SiderMenu from '@pages/Assembly/siderMenu'
import Breadcrumb from '@pages/Assembly/Breadcrumb'
import {mkbz} from '@pages/Assembly/siderMenu/menuObj'
import {getLoginUser} from '@utils/NHCore'
import NHFetch from '@utils/NHFetch'
import TitleTop from '@pages/Assembly/titleTop'
import NHFormItem from '@components/common/NHFormItem'
import CommonTip from '@pages/SelfHelp/common'
import Hint from '@pages/SelfHelp/common/hint'
import MainType from './mainType'

const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 16}
}

@Form.create()
export default class Channel extends React.Component {
    state = {
        mkbz: 'GD005_14',
        hjmc: '',
        operable: true, //是否显示环节
        nonOperationalData: {}, //不可操作的数据
        loading: false,
        nextUrl: '',
        search: '',
        sqValue: '', //是否申请绿色通道
        data: {}, //绿色通道申请信息
        BlztList: [], //办理状态
        dkfsList: [],
        FjsmList: [], // 附件提示信息的内容集合
        fjsmxx: '', // 附件的提示信息
        fjsmzt: false, // 是否显示附件的提示信息
        dkfsValue: '', //申请类型
        edit: true
    }

    componentDidMount() {
        this.queryQztjAndYlhj()
        this.loadLstdInfo()
        this.getSfsqLstd()
        this.loadLstddkfs()
        this.getFjsmInfo()
    }

    //查询前置条件和依赖环节
    queryQztjAndYlhj = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : ''
        if (!xsid) {
            this.setState({
                nonOperationalData: {nologin: true},
                operable: false
            })
            return
        }
        let zzbdhjid = this.getQueryString('pkid')
        NHFetch('/zzbd/queryQztjAndYlhj', 'GET', {xsid: xsid, zzbdhjid: zzbdhjid}).then(res => {
            if (res && res.data) {
                let kfsj = res.data['kfsj']
                let ylhj = res.data['ylhj']
                let qzhj = res.data['qzhj']
                let nohj = res.data['nohj']
                this.setState({nonOperationalData: res.data})
                if (kfsj && kfsj['YXSJ'] != '1') {
                    this.setState({operable: false})
                }

                if (ylhj && ylhj.length > 0) {
                    this.setState({operable: false})
                }

                if (qzhj && qzhj.length > 0) {
                    this.setState({operable: false})
                }
                if (nohj) {
                    this.setState({operable: false})
                }
            }
        })
    }

    //读取学生的绿色通道信息
    loadLstdInfo = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : ''
        NHFetch('/jxkjsfdx/lstdFb/getLstdInfoByXsid', 'GET', {xsid: xsid}).then(resData => {
            if (resData && resData.data) {
                this.setState({
                    edit: resData.data.shzt === '9' && resData.data.sfbllstd === '1',
                    data: resData.data,
                    dkfsValue: resData.data.dklx,
                    sqValue: resData.data.sfbllstd
                })

                // NHFetch('/zzbdCommon/getBlztByHjdm', 'GET', {xsid: xsid, hjdm: 'GD005_14'}).then(res => {
                //     if (res) {
                //         this.setState({lstdblzt: res.data == '1'},
                //             () => {
                //                 const {lstdblzt} = this.state
                //                 if (!lstdblzt && resData.data.sfbllstd == '0') {
                //                     this.setState({edit: true})
                //                 }
                //             })
                //     }
                // })
            }

            this.props.form.resetFields()
        })
    }

    // 获取绿色通道是否办理选项
    getSfsqLstd = () => {
        NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList', 'get', 'sign=dmk_YXXT_LSTD_BLZT').then(res => {
            if (res) {
                if (res.data) {
                    this.setState({
                        BlztList: res.data
                    })
                }
            }
        })
    }

    loadLstddkfs = () => {
        NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList', 'get', 'sign=dmk_YXXT_DKLX').then(res => {
            if (res && res.code == 200) {
                let dkfsList = res.data
                this.setState({
                    dkfsList,
                })
            }
        })
    }

    // 选择绿色通道类型
    dkfsOnChange = e => {
        // 校验是否展示附件说明
        if (this.state.FjsmList) {
            let obj = this.state.FjsmList.find(o => o.lstdlxdm == e.target.value)
            if (obj) {
                this.setState({
                    fjsmzt: true,
                    fjsmxx: obj.fjsm
                })
            } else {
                this.setState({
                    fjsmzt: false,
                    fjsmxx: ''
                })
            }
        }

        this.setState({
            dkfsValue: e.target.value
        })
    }

    // 获取各种贷款类型的附件说明
    getFjsmInfo = () => {
        NHFetch('/zzbd/fjsm/getFjsmInfo', 'get').then(res => {
            if (res) {
                if (res.data && res.code === 200) {
                    this.setState({
                        FjsmList: res.data
                    })
                }
            }
        })
    }

    getQueryString = name => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }

    setMenuList = menuList => {
        let nextCount = 0
        let bz = this.state.mkbz
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC
                this.setState({hjmc: hjmc})
                nextCount = index + 1
                return
            }
        })
        let menu = menuList[nextCount]
        if (menu) {
            let hjdm = menu.HJDM
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm]
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = '/customLink'
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: ''
            })
        }
    }

    sfsqOnChange = e => {
        this.setState({
            sqValue: e.target.value
        })
        if (e.target.value === '0') {
            this.setState({dkfsValue: ''})
        }
    }

    //重新加载菜单
    reloadMenu = () => {
        this.refs.siderMenu.reloadMenuList()
    }

    setChannelState = state => {
        this.setState(state)
    }

    // 不同困难类型显示不同的表单
    getLstdType = dkfsValue => {
        const {
            operable,
            sqValue,
            nextUrl,
            search,
            data,
            BlztList,
            fjsmxx,
            fjsmzt,
            edit
        } = this.state
        const commonAttrs = {
            reloadMenu: this.reloadMenu,
            loadLstdInfo: this.loadLstdInfo,
            setChannelState: this.setChannelState,
            operable,
            sqValue,
            dkfsValue,
            nextUrl,
            search,
            data,
            BlztList,
            fjsmxx,
            fjsmzt,
            edit
        }

        const lstdComponents = {
            default: <MainType {...commonAttrs} />
        }

        return lstdComponents[dkfsValue] || lstdComponents.default
    }

    render() {
        const {form} = this.props
        const {data, BlztList, sqValue, dkfsList, dkfsValue, edit} = this.state

        return (
            <div style={{position: 'relative'}}>
                {this.state.operable ?

                        <div>
                            <Flex className="w1180 mt-20" align="start">
                                <SiderMenu
                                    ref={'siderMenu'}
                                    setMenuList={this.setMenuList}
                                    title="自助报到"
                                    mkbz={this.state.mkbz}
                                    menuType="自助报到"
                                />
                                <Flex.Item className="page_con_r" styleName="">
                                    <Breadcrumb noLine list={[{name: '首页', url: '/index'}, {
                                        name: '自助报到',
                                        url: '/register'
                                    }, {name: this.state.hjmc}]}/>
                                    <div className="box">
                                        <Hint/>

                                        <Form>
                                            <TitleTop title="申请信息" extra={false}/>

                                            <div className="p-sw">
                                                <div className="form_item" style={{marginLeft: '7%'}}>
                                                    <NHFormItem required={true}
                                                                id={'sfbllstd'}
                                                                initialValue={data['sfbllstd']}
                                                                form={form}
                                                                formItemLayout={formItemLayout}
                                                                label="绿色通道申请">
                                                        <Radio.Group onChange={this.sfsqOnChange}
                                                                     value={this.state.sqValue}
                                                                     disabled={!edit}
                                                                     style={{display: 'flex', margin: '10px'}}>
                                                            {
                                                                BlztList &&
                                                                BlztList.map(item => <Radio
                                                                    value={item.VALUE}>{item.LABEL}</Radio>)
                                                            }
                                                        </Radio.Group>
                                                    </NHFormItem>
                                                </div>

                                                {sqValue === '1' && (
                                                    <div className="form_item" style={{marginLeft: '2%'}}>
                                                        <NHFormItem label="困难类型"
                                                                    required={true}
                                                                    id={'dklx'}
                                                                    initialValue={data['dklx']}
                                                                    form={form}
                                                                    labelCol={{span: 1}}
                                                                    wrapperCol={{span: 4}}>
                                                            <Radio.Group onChange={this.dkfsOnChange}
                                                                         value={dkfsValue}
                                                                         disabled={!edit}
                                                                         style={{
                                                                             display: 'flex',
                                                                             flexWrap: 'wrap',
                                                                             margin: '10px'
                                                                         }}>
                                                                {dkfsList.map(item => <Radio
                                                                    value={item.VALUE}>{item.LABEL}</Radio>)}
                                                            </Radio.Group>
                                                        </NHFormItem>
                                                    </div>
                                                )}
                                            </div>
                                        </Form>
                                        {this.getLstdType(dkfsValue)}

                                    </div>
                                </Flex.Item>
                            </Flex>
                            <Spin style={{position: 'absolute', top: '300px', left: '60%'}}
                                  spinning={this.state.loading}/>
                            {' '}
                        </div>


                    :

                    <CommonTip hjmc={'绿色通道申请'} key={'GD005_14'} mkbz="GD005_14"
                               nonOperationalData={this.state.nonOperationalData}/>
                }
            </div>
        )
    }
}
