import React, { Component } from 'react'
import Index from '@pages/SelfHelp/print'
import PropTypes from 'prop-types'

/**
 * @author qianzhiwei
 * @date 2020/09/25
 */
export class LydxIndex extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             
        }
    }

    render() {
        return (
            <Index/>
        )
    }

    static propTypes = {

    }
}

export default LydxIndex
