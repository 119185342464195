import React from 'react';
import {Flex} from '@components';
import {Button, Descriptions, message, Modal, Table} from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import NextButton from '@pages/SelfHelp/common/nextIndex'
import '../pay/index.css';
import NHFetch from "@utils/NHFetch";
import {getLoginUser} from "@utils/NHCore";
import createHistory from 'history/createHashHistory';
import CommonTip from "../common";
import {mkbz} from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";
import Qrcode from './qrCode';
import NHUpload from "@components/common/NHUpload";

const history = createHistory();


export default class WeChatPay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mkbz: 'GD008_3_1',
            hjmc: '',
            data: [],
            loading: false,
            pageData: {//分页设置的是三个参数
                total: 0,
                pageSize: 10,//当前页默认多少条数据
                page: 1
            },
            nextUrl: '',//左侧菜单加载完后传进来
            search: '',
            operable: true,//是否显示环节
            nonOperationalData: {},//不可操作的数据
            zxjfInfo: {},                    //在线缴费相关信息
            visible: false,
            selectedPackage: {},          //选择的缴费项目
            token: '',               //防重令牌
            qrcode: '',              //缴费二维码
            hasSelected: false,
            showConfirmFlag: false,
            notProcessed: true
        };
    }

    componentDidMount() {
        this.queryOrderInfo();
        this.loadZxjfInfo();
        this.queryToken();
        this.queryQztjAndYlhj((operable) => {
            if (operable) {
            }
        });
    }

    getTableData = () => {
        NHFetch('/zjkjxy/wxChatPay/queryBroadBandData', 'GET').then(res => {
            if (res && res.code === 200) {
                this.setState({
                    data: res.data
                })
            } else {
                message.error("获取获取宽带数据失败，请稍后再试")
            }
        })
    }

    //获取防重令牌
    queryToken = () => {
        NHFetch('/wxChatPay/queryToken', 'GET').then(res => {
            if (res && res.code === 200) {
                this.setState({
                    token: res.data
                })
            } else {
                message.error("获取令牌失败，请稍后再试")
            }
        })
    }

    //检查该环节是否已经办理
    checkIsZzbdHjLinked = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        let zzbdhjid = this.getQueryString('pkid');

        NHFetch("/zzbdCommon/checkIsZzbdHjLinked", 'GET', {xsid: xsid, zzbdhjid: zzbdhjid})
            .then(res => {
                if (res && res.code == 200) {
                    if (!res.data) {
                        this.getTableData();
                    } else {
                        // 已办理，禁用办理按钮
                        this.setState({
                            notProcessed: false
                        })
                    }
                } else {
                    message.error("检查环节办理接口调用失败，请稍后再试")
                }
            });
    };

    //同步学生缴费情况信息
    queryOrderInfo = () => {
        const {mkbz} = this.state;
        NHFetch('/zjkjxy/wxChatPay/queryOrderInformation', 'GET', {hjdm: mkbz, accountName: 'zjkj_kdtczh'}).then(res => {
            if (res && res.code == 200) {
                if (res.data == 1) {
                    this.reloadMenu();
                }
                this.checkIsZzbdHjLinked();
            }
        })
    }

    //关闭微信支付订单
    closeWxPayOrder = () => {
        const {mkbz} = this.state;
        NHFetch('/zjkjxy/wxChatPay/closeWxPayOrderRequireAccountName', 'GET', {hjdm: mkbz, accountName: 'zjkj_kdtczh'}).then(res => {
            if (res && res.code === 200) {
                if (res.data === 1) {
                    this.createWxPayOrder();
                } else {
                    message.error("微信订单关闭失败，请稍后再试")
                }
            } else {
                message.error("微信订单关闭失败，请稍后再试");
            }
        })
    }

    //添加订单到业务系统
    createWxPayOrder = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        const {data, selectedPackage, token} = this.state;
        let jfje = Math.round(selectedPackage.JE * Math.pow(10, 2)) / Math.pow(10, 2); // 缴费金额

        // 合成订单信息
        let params = {
            xsid: xsid,
            paymentAmount: jfje,
            sfxmList: [
                {
                    sfxmid: selectedPackage.PKID,
                    qfje: jfje
                }
            ],
            token: token
        };

        NHFetch('/wxChatPay/addOrderInfo', 'POST', params).then(res => {
            if (res && res.code === 200) {
                if (res.data == 1) {
                    //微信支付Native下单
                    this.toNativeOrder();
                }
            } else {
                message.error("创建业务订单操作失败，请稍后再试")
            }
        })
    }

    //Native下单
    toNativeOrder = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        const {mkbz} = this.state;
        let params = {
            xsid: xsid,
            hjdm: mkbz,
            accountName: 'zjkj_kdtczh'
        }
        NHFetch('/wxChatPay/toNativeOrder', 'POST', params).then(res => {
            if (res && res.code === 200) {
                if (res.data) {
                    this.setState({
                        qrcode: res.data,
                        visible: true
                    })
                }
            } else {
                message.error("创建微信订单操作失败，请稍后再试")
            }
        })
    }

    //获取在线缴费相关信息
    loadZxjfInfo = () => {
        const {mkbz} = this.state;
        NHFetch('/jfxtUnauth/yxwzjfxx/getCurrDdxx', 'GET', {hjdm: mkbz})
            .then(res => {
                if (res && res.data) {
                    this.setState({
                        zxjfInfo: res.data,
                    });
                }

            });
    }

    //查询前置条件和依赖环节
    queryQztjAndYlhj = (callback) => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({
                nonOperationalData: {nologin: true},
                operable: false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', {xsid: xsid, zzbdhjid: zzbdhjid})
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj'];
                    this.setState({nonOperationalData: res.data});
                    if (kfsj && kfsj['YXSJ'] != '1') {
                        this.setState({operable: false});
                    } else if (ylhj && ylhj.length > 0) {
                        this.setState({operable: false});
                    } else if (qzhj && qzhj.length > 0) {
                        this.setState({operable: false});
                    } else if (nohj) {
                        this.setState({operable: false});
                    } else {
                        // this.getSfxxList();
                    }
                    if (callback) {
                        callback(this.state.operable);
                    }
                }
            });
    }

    getQueryString = (name) => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }

    setMenuList = (menuList) => {
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC;
                this.setState({hjmc: hjmc})
                nextCount = index + 1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if (menu) {
            let hjdm = menu.HJDM;
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID,
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: '',
            })
        }
    }

    onSave = () => {
        this.setState({loading: true});
        setTimeout(() => {
            this.setState({
                loading: false
            });
        }, 1000);
    };

    //重新加载菜单
    reloadMenu = () => {
        this.refs.siderMenu.reloadMenuList()
    }

    //获取选择的项目
    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedPackage: selectedRows[0],
            hasSelected: true
        });
    };

    showConfirm = () => {
        this.setState({showConfirmFlag: true});
    };

    handleNotConfirm = () => {
        this.setState({showConfirmFlag: false});
    };

    handleConfirm = () => {
        this.closeWxPayOrder();
    };

    //model关闭事件
    handleCancel = e => {
        this.setState({
            visible: false,
        }, () => {
            // this.getSfxxList();
            this.reloadMenu();
            this.queryToken();
        });
    };


    render() {
        const {data, zxjfInfo, qrcode, hasSelected, showConfirmFlag, selectedPackage, notProcessed, mkbz} = this.state;

        const columns = [
            {
                title: '序号',
                dataIndex: 'XH',
                width: '80px'
            },
            {
                title: '套餐名称',
                dataIndex: 'MC',
                width: '150px'
            },
            {
                title: '套餐金额（元）',
                dataIndex: 'JE',
                width: '120px'
            },
            {
                title: '介绍',
                dataIndex: 'XQ',
                width: '400px'
            },
            {
                title: '套餐详情',
                dataIndex: 'HBFJID',
                render: value => {
                    return <div>
                        <NHUpload
                            listType={"picture-card"}
                            isEdit={false}
                            value={value}
                            image
                        />
                    </div>
                }
            }
        ];

        return (
            <div styleName="page_pay">
                {
                    this.state.operable ?
                        <Flex className="w1180 mt-20" align="start">
                            <SiderMenu
                                ref={"siderMenu"}
                                setMenuList={this.setMenuList}
                                title="自助报到"
                                mkbz={this.state.mkbz}
                                menuType='自助报到'
                            />
                            <Flex.Item className="page_con_r">
                                <Breadcrumb
                                    noLine
                                    list={[
                                        {name: '首页', url: '/index'},
                                        {name: '自助报到', url: '/register'},
                                        {name: this.state.hjmc}
                                    ]}
                                />
                                <div className="box">
                                    <Hint/>
                                    <TitleTop title={this.state.hjmc} extra={false}/>
                                    <div className="p-sw mt-20">
                                        <Table
                                            bordered
                                            size="middle"
                                            rowKey={record => record.PKID}
                                            rowSelection={{
                                                type: "radio",
                                                onChange: this.onSelectChange
                                            }}
                                            columns={columns}
                                            dataSource={data}
                                        />
                                        <div className="text-center mt-50">

                                            <div className="text-center mt-50">
                                                {/* 办理按钮 */}
                                                {(zxjfInfo.ZFD !== '0' && notProcessed)
                                                    ?
                                                    <Button onClick={() => this.showConfirm()}
                                                            type="primary"
                                                            disabled={!hasSelected || !notProcessed}
                                                            htmlType="submit">
                                                        办理
                                                    </Button>
                                                    :
                                                    <div style={{color: 'red'}}>已办理</div>
                                                 }

                                                {/*<NextButton nextUrl={this.state.nextUrl} search={this.state.search}/>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Flex.Item>
                        </Flex>
                        :
                        <CommonTip hjmc={"宽带申请"} key={'GD008_3_1'} mkbz='GD008_3_1'
                                   nonOperationalData={this.state.nonOperationalData}/>
                }


                <Modal
                    title="确认办理此套餐吗？"
                    visible={showConfirmFlag}
                    onCancel={this.handleNotConfirm}
                    onOk={this.handleConfirm}>
                    <Descriptions title={selectedPackage.MC}>
                        <Descriptions.Item label="套餐金额（元）">{selectedPackage.JE}</Descriptions.Item>
                    </Descriptions>
                </Modal>

                <Modal
                    title="二维码信息"
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button onClick={this.handleCancel}>
                            关闭
                        </Button>,
                    ]}>
                    <Qrcode qrcode={qrcode} hjdm={mkbz} cancel={this.handleCancel}/>
                </Modal>
            </div>
        );
    }
}
