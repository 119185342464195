import React from "react";
import { InputType, RegularExpression, WidthType } from "./input-const";
import { Form, Input, message, Radio, Switch, Upload, Icon, Button, DatePicker, TimePicker, InputNumber } from 'antd';
import PropTypes from "prop-types";
import style from './style.css';
import createUuid from '@utils/createUuid'
import NHMultiSelect from '../NHMultiSelect/index'
import NHUpload from '../NHUpload/index'
import NHImage from '../NHImage/index'
import NHOldUpload from '../OldNHUpload/index'
import NHSelect from '../NHSelect/index'
import NHCascader from '../NHCascader/index'
import moment from 'moment'
import locale from 'antd/lib/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';

const { TextArea } = Input;
const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const { RangePicker, MonthPicker } = DatePicker;

/**
 * 输入控件渲染器
 * 根据属性参数、可以渲染成普通输入控件或封装成FormItem类型输入控件配合表单校验
 * Author: zengxiangkai@ly-sky.com
 * Created on: 2018-02-05 14:35:56
 * Version: 1.0
 * Modify log:
 * 2018-03-29: 1、增加readOnly属性，控制是否可编辑；
 * 2018-05-28: 1、修改:readOnly属性更为为是否是阅读模式；2、新增disabled属性，控制是否可编辑；
 * 2018-08-07: 1、增加文件上传控件类型，支持图片、文件两种类型的上传；
 * 2019-03-21: 1、修改：NHInputRender可做为受控组件使用，也可以直接渲染成表单项
 * 2019-04-09  1、增加日期控件
 * 2019-04-11  1、增加日期范围控件、增加文件上传控件
 * 2019-04-22  1、增加多选下拉框、级联下拉框、时间选择框，修改单选框配置，增加可以设置服务前缀baseUrl
 * 2019-10-30: 1、修复switch控件设置初始值无效问题；2、修复渲染成表单控件时，label为空，必填项没有显示*号标记问题
 * 2019-10-31: 1、表单项增加colon属性支持、增加hideLabel属性支持
 */
export class NHInputRender extends React.Component {

    constructor(props) {
        super(props);

        const value = props.value || '';

        this.state = {
            value: value,
            feature: '',
            featureParams: {},
            loading: false,
            uploadFile: {},
            uploadFileList: []
        }
    }

    componentWillMount() {
        const feature = this.props.feature;
        if (feature) {
            try {
                //解析参数，如果不是正确的json格式，会抛出异常提示
                let featureParams = JSON.parse(feature);
                this.setState({ feature: feature, featureParams: featureParams });
            } catch (e) {
                message.error('解析控件配置参数出错！');

            }
        }
    }

    componentDidMount() {
        let featureParams = this.state.featureParams;
        // if (InputType.UPLOAD == featureParams.type) {
        //   let { id, value } = this.props;
        //   let { uploadFile } = this.state;
        //   uploadFile[id] = value;
        //   this.setState({
        //     uploadFile: uploadFile
        //   });

        //   if (this.props.form) {
        //     let ids = 'FILE_DOC_UUID' + id;
        //     let fieldsObj = {};
        //     let valueObj = {};
        //     valueObj.value = value || '';
        //     fieldsObj[id] = valueObj;
        //     this.props.form.setFieldsValue(fieldsObj);

        //     let fieldsObj2 = {};
        //     fieldsObj2[ids] = value || '';
        //     this.props.form.setFieldsValue(fieldsObj2);

        //     //附件类型
        //     if (featureParams.fileType === 'file') {
        //       if (value) {
        //         let fileJsonObject = JSON.parse(value);
        //         let valueObj = {};
        //         valueObj.value = value || '';
        //         fieldsObj[id] = valueObj;
        //         this.props.form.setFieldsValue(fieldsObj);

        //         let objArr = [];
        //         fileJsonObject.map((file, index) => {
        //           let obj = {};
        //           obj.key = index;
        //           obj.uid = createUuid();
        //           obj.name = file.name;
        //           obj.status = 'done';
        //           obj.response = '';
        //           obj.url = '/anonymity/docrepo/download/file?attachmentId=' + file.value;
        //           obj.docUUID = file.value;
        //           objArr.push(obj);
        //         });

        //         this.setState({
        //           uploadFileList: objArr
        //         })
        //       }
        //     }
        //   }
        // }

    }

    componentWillReceiveProps(nextProps) {

        const feature = nextProps.feature;
        try {
            //解析参数，如果不是正确的json格式，会抛出异常提示
            let featureParams = JSON.parse(feature);
            this.setState({ feature: feature, featureParams: featureParams });
        } catch (e) {
            message.error('解析控件配置参数出错！');

        }
        // Should be a controlled component.
        if ('value' in nextProps) {
            const value = nextProps.value;
            this.setState({ value: value });
        }
    }

    //提供组件的onChange方法
    handleChange = (e) => {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    //渲染成表单项
    renderFormItem = ({ form, hasFeedback = false, patternTag = '', pDefaultValue = '', pRequired = false, component }) => {
        let { getFieldDecorator } = form;
        let { formLayout, formItemLayout, id, value, label = '', hideLabel, colon, defaultValue, required, readOnly, format } = this.props;
        let featureParams = this.state.featureParams;
        let validationValueType = featureParams.validationValueType ? featureParams.validationValueType : 'string';
        let requiredRule = {};
        let fileType = this.state.featureParams.fileType;
        //构建必填校验规则
        if (required) {
            requiredRule.required = true;
            requiredRule.message = label + '不可为空';
            // 如果label为空，且为必填项，则显示*号，但不显示冒号
            if (label == '' || hideLabel) {
                label = '';
                colon = false;
            }

        } else {
            if (pRequired) {
                required = pRequired;
                requiredRule.required = true;
                requiredRule.message = label + '不可为空';
                // 如果label为空，且为必填项，则显示*号，但不显示冒号
                if (label == '' || hideLabel) {
                    label = '';
                    colon = false;
                }
            } else {
                if (hideLabel) {
                    label = '';
                    colon = false;
                }
            }
        }

        //处理特殊组件在封装成表单项时的“值”属性名称
        let inputType = featureParams.type;
        let valuePropName = 'value';
        let initialValue;
        initialValue = !value ? (!defaultValue ? pDefaultValue : defaultValue) : value;
        if (InputType.SWITCH == inputType) {
            valuePropName = 'checked';
            if (value) {
                initialValue = value == 'true' ? true : false;
            } else if (typeof defaultValue == 'boolean') {

                initialValue = defaultValue;

            } else if (typeof defaultValue == 'string') {

                initialValue = defaultValue == 'true' ? true : false;

            } else if (typeof pDefaultValue == 'boolean') {

                initialValue = pDefaultValue;

            } else if (typeof pDefaultValue == 'string') {

                initialValue = pDefaultValue == 'true' ? true : false;

            } else {
                initialValue = false;
            }
        }
        if (InputType.DATEPICKER == inputType || InputType.TIMEPICKER == inputType || InputType.DATETIMEPICKER == inputType || InputType.MONTHPICKER == inputType || InputType.RANGEPICKER == inputType) {
            if (initialValue && !moment.isMoment(initialValue)) {
                initialValue = moment(initialValue, format);
            } else {

            }

        }
        // console.log(inputType,initialValue,this.props.value)
        //处理布局，只有水平布局时才应用表单项布局
        formItemLayout = formLayout == 'horizontal' ? formItemLayout : null;
        if (patternTag != '') {
            return (
                fileType === 'file' ?
                    <FormItem {...formItemLayout}
                        label={label}
                        colon={colon}
                        hasFeedback={hasFeedback}
                    >
                        {getFieldDecorator(id, {
                            valuePropName: valuePropName,
                            rules: [
                                { ...requiredRule },
                                { ...RegularExpression[patternTag].rule }
                            ],
                            initialValue: this.props.value
                        })(
                            component
                        )}
                    </FormItem> :
                    (readOnly ?
                        <FormItem {...formItemLayout}
                            label={label}
                            colon={colon}
                        >
                            <span>{initialValue}</span>
                        </FormItem>
                        :
                        <FormItem {...formItemLayout}
                            label={label}
                            colon={colon}
                            hasFeedback={hasFeedback}
                        >
                            {getFieldDecorator(id, {
                                valuePropName: valuePropName,
                                rules: [
                                    { ...requiredRule },
                                    { ...RegularExpression[patternTag].rule }
                                ],
                                initialValue: initialValue
                            })(
                                component
                            )}
                        </FormItem>)
            );
        } else if (required) {
            return (
                fileType === 'file' ?
                    <FormItem {...formItemLayout}
                        label={label}
                        colon={colon}
                        hasFeedback={hasFeedback}
                    >
                        {getFieldDecorator(id, {
                            valuePropName: valuePropName,
                            rules: [
                                { ...requiredRule }
                            ],
                            initialValue: this.props.value
                        })(
                            component
                        )}
                    </FormItem> :
                    (readOnly ?
                        <FormItem {...formItemLayout}
                            label={label}
                            colon={colon}
                        >
                            <span>{initialValue}</span>
                        </FormItem>
                        :
                        <FormItem {...formItemLayout}
                            label={label}
                            colon={colon}
                            hasFeedback={hasFeedback}
                        >
                            {getFieldDecorator(id, {
                                valuePropName: valuePropName,
                                rules: [
                                    { ...requiredRule }
                                ],
                                initialValue: initialValue
                            })(
                                component
                            )}
                        </FormItem>)
            );
        } else {
            return (
                fileType === 'file' ?
                    <FormItem {...formItemLayout}
                        label={label}
                        colon={colon}
                        hasFeedback={hasFeedback}
                    >
                        {getFieldDecorator(id, {
                            valuePropName: valuePropName,
                            initialValue: this.props.value
                        })(
                            component
                        )}
                    </FormItem> :
                    (readOnly ?
                        <FormItem {...formItemLayout}
                            label={label}
                            colon={colon}
                        >
                            <span>{initialValue}</span>
                        </FormItem>
                        :
                        <FormItem {...formItemLayout}
                            label={label}
                            colon={colon}
                            hasFeedback={hasFeedback}
                        >
                            {getFieldDecorator(id, {
                                valuePropName: valuePropName,
                                initialValue: initialValue
                            })(
                                component
                            )}
                        </FormItem>)
            );
        }

    }

    //渲染单行输入控件类型
    renderInput = () => {
        let { form, id, value, disabled, onChange } = this.props;
        let { widthType, widthStr, patternTag, placeholder, required, defaultValue } = this.state.featureParams;

        let inputComponent = null;
        let otherParams = {}; //动态参数

        //如果是表单项类型，则将id,value,defaultValue交由FormItem处理
        if (!form && id !== undefined && id != '') {
            otherParams.id = id;
        }
        if (!form && value) {
            otherParams.value = this.state.value;
        }
        if (!form && defaultValue !== undefined && defaultValue != '') {
            otherParams.defaultValue = defaultValue;
        }

        if (widthType != WidthType.DEFAULT) {
            inputComponent = (
                <Input
                    {...otherParams}
                    disabled={disabled}
                    placeholder={placeholder}
                    style={{ width: widthStr }}
                    onChange={
                        (e) => {

                            if (!('value' in this.props)) {
                                this.setState({ value: e.target.value });
                            }

                            if (typeof onChange == 'function') {
                                onChange(e.target.value, e.target.value);
                            }
                        }
                    }
                />
            );
        } else {
            inputComponent = (
                <Input
                    {...otherParams}
                    disabled={disabled}
                    placeholder={placeholder}
                    onChange={
                        (e) => {

                            if (!('value' in this.props)) {
                                this.setState({ value: e.target.value });
                            }

                            if (typeof onChange == 'function') {
                                onChange(e.target.value, e.target.value);
                            }
                        }
                    }
                />
            );
        }

        //是否是表单项
        if (form) {
            return this.renderFormItem({
                form: form, hasFeedback: true, patternTag: patternTag, pDefaultValue: defaultValue,
                pRequired: required, component: inputComponent
            });
        }

        return inputComponent;
    }

    //渲染多行输入控件类型
    renderTextArea = () => {
        let { form, id, value, disabled, onChange, isEdit = true } = this.props;
        let { widthType, widthStr, patternTag, placeholder, required, defaultValue, minRows, maxRows, maxLength } = this.state.featureParams;
        let textareaComponent = null;
        let otherParams = {}; //其他参数

        if (!form && id !== undefined && id != '') {
            otherParams.id = id;
        }
        if (!form && value) {
            otherParams.value = this.state.value;
        }
        if (!form && defaultValue !== undefined && defaultValue != '') {
            otherParams.defaultValue = defaultValue;
        }

        if (widthType != WidthType.DEFAULT) {
            textareaComponent = (
                <TextArea
                    {...otherParams}
                    readOnly={!isEdit}
                    disabled={disabled}
                    autosize={{ minRows: minRows ? minRows : 4, maxRows: maxRows ? maxRows : 6 }}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    style={{ width: widthStr }}
                    onChange={
                        (e) => {

                            if (!('value' in this.props)) {
                                this.setState({ value: e.target.value });
                            }

                            if (typeof onChange == 'function') {
                                onChange(e.target.value, e.target.value);
                            }
                        }
                    }
                />
            );
        } else {
            textareaComponent = (
                <TextArea
                    {...otherParams}
                    readOnly={!isEdit}
                    disabled={disabled}
                    autosize={{ minRows: minRows ? minRows : 4, maxRows: maxRows ? maxRows : 6 }}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    onChange={
                        (e) => {

                            if (!('value' in this.props)) {
                                this.setState({ value: e.target.value });
                            }

                            if (typeof onChange == 'function') {
                                onChange(e.target.value, e.target.value);
                            }
                        }
                    }
                />
            );
        }

        //是否是表单项
        if (form) {
            return this.renderFormItem({
                form: form, hasFeedback: true, patternTag: patternTag, pDefaultValue: defaultValue,
                pRequired: required, component: textareaComponent
            });
        }

        return textareaComponent;
    }

    //渲染单选框控件类型
    renderRadio = () => {
        let { form, id, value, disabled, onChange } = this.props;
        let { options, required, defaultValue } = this.state.featureParams;

        let otherParams = {}; //其他参数

        if (!form && id !== undefined && id != '') {
            otherParams.name = id;
        }
        if (!form && value) {
            otherParams.value = this.state.value;
        }
        if (!form && defaultValue !== undefined && defaultValue != '') {
            otherParams.defaultValue = defaultValue;
        }

        const radioComponent = (
            <RadioGroup
                {...otherParams}
                disabled={disabled}
                options={options}
                onChange={
                    (e) => {
                        let selectValue = e.target.value;
                        if (!('value' in this.props)) {
                            this.setState({ value: selectValue });
                        }

                        let selectItem = options.filter(item => {

                            return item.value === selectValue;
                        })[0];

                        if (typeof onChange == 'function') {
                            onChange(selectValue, selectItem.label, e);
                        }
                    }
                }
            />
        );

        //是否是表单项
        if (form) {
            return this.renderFormItem({
                form: form,
                pDefaultValue: defaultValue,
                pRequired: required,
                component: radioComponent
            });
        }

        return radioComponent;
    }

    //渲染下拉选择器输入控件类型
    renderSelect = () => {
        let { form, id, value, disabled, onChange, parentFieldId, parentValue } = this.props;
        let { widthType, widthStr, dataTag, required, defaultValue, baseUrl } = this.state.featureParams;
        let selectComponent = null;
        let otherParams = {}; //其他参数

        if (!form && id !== undefined && id != '') {
            otherParams.id = id;
        }
        if (!form && value) {
            otherParams.value = this.state.value;
        }
        if (!form && defaultValue !== undefined && defaultValue != '') {
            otherParams.defaultValue = defaultValue;
        }

        if (baseUrl !== null && baseUrl !== undefined && baseUrl !== '') {
            otherParams.baseUrl = baseUrl;
        }

        if (form && 'parentValue' in this.props) {
            const { getFieldValue } = form;
            otherParams.parentValue = parentValue;
        }
        if (widthType != WidthType.DEFAULT) {
            selectComponent = (
                <NHSelect
                    {...otherParams}
                    disabled={disabled}
                    sign={dataTag}
                    style={{ width: widthStr }}
                    onChange={
                        (v, t, p) => {
                            if (!('value' in this.props)) {
                                this.setState({ value: v });
                            }
                            if (typeof onChange == 'function') {
                                onChange(v, t, p);
                            }
                        }
                    }
                />
            );
        } else {
            selectComponent = (
                <NHSelect
                    {...otherParams}
                    disabled={disabled}
                    sign={dataTag}
                    onChange={
                        (v, t, p) => {
                            if (!('value' in this.props)) {
                                this.setState({ value: v });
                            }
                            if (typeof onChange == 'function') {
                                onChange(v, t, p);
                            }
                        }
                    }
                />
            );
        }

        //是否是表单项
        if (form) {
            return this.renderFormItem({
                form: form,
                pDefaultValue: defaultValue,
                pRequired: required,
                component: selectComponent
            });
        }

        return selectComponent;
    }

    //渲染开关选择器输入控件类型
    renderSwitch = () => {
        let { form, id, value, disabled, onChange } = this.props;
        let { checkedText, uncheckedText, defaultChecked } = this.state.featureParams;

        let switchComponent = null;
        let otherParams = {}; //其他参数

        if (!form && id !== undefined && id != '') {
            otherParams.id = id;
        }
        if (!form && value) {
            otherParams.checked = this.state.value == '1' ? true : false;
        }
        if (!form && defaultChecked !== undefined && defaultChecked != '') {
            otherParams.defaultChecked = defaultChecked;
        }

        switchComponent = (
            <Switch
                {...otherParams}
                disabled={disabled}
                checkedChildren={checkedText}
                unCheckedChildren={uncheckedText}
                onChange={
                    (v) => {
                        if (!('value' in this.props)) {
                            this.setState({ value: v ? '1' : '0' });
                        }
                        if (typeof onChange == 'function') {
                            onChange(v ? '1' : '0', v ? (checkedText ? checkedText : 'true') : (uncheckedText ? uncheckedText : 'false'));
                        }
                    }
                }
            />
        );

        //是否是表单项
        if (form) {
            return this.renderFormItem({ form: form, pDefaultValue: defaultChecked, component: switchComponent });
        }

        return switchComponent;
    };

    //文件上传之前执行的方法(可以限制上传文件的格式，类型，大小等)
    // beforeUpload = (file, size, fileType) => {
    //     let uploadFileList = this.state.uploadFileList;
    //     this.setState({
    //         uploadFileList: [...uploadFileList, file],
    //     });
    //     let fileSuffixName = file.name.substring(file.name.lastIndexOf('.'));
    //     let isAvailableSuffix;
    //     //判断是否支持该文件类型
    //     if (fileType === 'image') {     //图片类型
    //         let availFileSuffix = UploadFileNameSuffix.imageFileType;
    //         isAvailableSuffix = availFileSuffix.includes(fileSuffixName);
    //         if (!isAvailableSuffix) {
    //             let msg = '抱歉，只支持上传【' + availFileSuffix.join(' || ') + '】格式的文件！';
    //             message.error(msg);
    //         }
    //         return isAvailableSuffix;
    //     } else if (fileType === 'file') {     //文件类型
    //         let availFileSuffix = UploadFileNameSuffix.otherFileType;
    //         isAvailableSuffix = availFileSuffix.includes(fileSuffixName);
    //         if (!isAvailableSuffix) {
    //             let msg = '抱歉，只支持上传【' + availFileSuffix.join(' || ') + '】格式的文件！';
    //             message.error(msg);
    //         }
    //         return isAvailableSuffix;
    //     }

    //     let availSize = size;
    //     let fileSize = file.size / 1024 / 1024;
    //     const isOverSize = fileSize > availSize;

    //     if (isOverSize) {
    //         let msg = '抱歉，上传文件大小最大不能超过' + availSize + 'M！';
    //         message.error(msg);
    //         return !isOverSize;
    //     }
    //     return true;
    // };

    //文件上传中、完成、失败都会执行的方法
    handleUploadChange = (info) => {
        //上传中
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
        }

        //上传成功
        if (info.file.status === 'done') {
            message.success(`文件上传成功！`);

            let { fileType } = this.state.featureParams;
            // 上传的附件类型(非图片类型)
            if (fileType === 'file') {
                let responseFileUUID = info.file.response.data.bh;
                let responseFileMC = info.file.response.data.fjmc;
                let ids = 'FILE_DOC_UUID' + this.props.id;
                let val = this.props.form.getFieldValue(ids);
                let arr = val ? JSON.parse(val) : [];
                let obj = {};
                obj.name = responseFileMC;
                obj.value = responseFileUUID;
                arr.push(obj);

                let fieldsObj2 = {};
                fieldsObj2[ids] = JSON.stringify(arr);
                this.props.form.setFieldsValue(fieldsObj2);
            } else if (fileType === 'image') {  //图片类型
                //上传成功返回的文档库对应的UUID,使用这个可以从文档库中下载此文件
                let responseFileUUID = info.file.response.data.bh;
                let fieldsObj = {};
                let id = this.props.id;
                fieldsObj[id] = responseFileUUID;
                this.props.form.setFieldsValue(fieldsObj);

                let ids = 'FILE_DOC_UUID' + id;
                let fieldsObj2 = {};
                fieldsObj2[ids] = responseFileUUID;
                this.props.form.setFieldsValue(fieldsObj2);

                let { uploadFile } = this.state;
                uploadFile[id] = responseFileUUID;
                this.setState({
                    uploadFile: uploadFile,
                    loading: false,
                });
            }

        } else if (info.file.status === 'error') {  //上传失败
            message.error(`${info.file.name} 文件由于未知原因失败，请重新上传！`);
            this.setState({
                loading: false
            });
            return;
        }
    };

    //渲染文件上传控件
    // renderImageUpload = () => {
    //     let { form, id } = this.props;
    //     let { size, required, fileType } = this.state.featureParams;

    //     const uploadButton = (
    //         <div>
    //             <Icon type={this.state.loading ? 'loading' : 'plus'} />
    //             <div className="ant-upload-text">点击上传</div>
    //         </div>
    //     );

    //     const uploadImageProps = {
    //         action: 'anonymity/docrepo/upload',
    //         data: { uuid: createUuid(), type: '1' },
    //         listType: "picture-card",
    //         showUploadList: false
    //     };

    //     const { uploadFile } = this.state;
    //     let uploadFileUUID = uploadFile[id];

    //     const uploadComponent = (
    //         <Upload
    //             {...uploadImageProps}
    //             beforeUpload={(file) => this.beforeUpload(file, size, fileType)}
    //             onChange={this.handleUploadChange}
    //             className={style["avatar-uploader"]}
    //         >
    //             {uploadFileUUID ? <img src={"anonymity/docrepo/download?attachmentId=" + uploadFileUUID} /> : uploadButton}
    //         </Upload>
    //     );

    //     //是否是表单项
    //     if (form) {
    //         this.props.form.getFieldDecorator('FILE_DOC_UUID' + id);
    //         return this.renderFormItem({
    //             form: form,
    //             pRequired: required,
    //             component: uploadComponent
    //         });
    //     }
    //     return uploadComponent;
    // };

    //移除文件执行的方法
    onRemoveFile = (file) => {
        let id = this.props.id;
        let { uploadFileList } = this.state;
        let uploadFilearr = [];
        uploadFileList.map(item => {
            if (item.status !== 'removed') {
                uploadFilearr.push(item);
            }
        });
        this.setState({
            uploadFileList: uploadFilearr
        });
        let fieldValue = this.props.form.getFieldValue(id).value;
        if (fieldValue) {
            let fieldValueArr = JSON.parse(fieldValue);
            fieldValueArr = fieldValueArr.filter(item => {
                return file.name !== item.name;
            });

            let fieldsObj = {};
            let valueObj = {};
            valueObj.value = JSON.stringify(fieldValueArr) || '';
            fieldsObj[id] = valueObj;
            this.props.form.setFieldsValue(fieldsObj);

            let ids = 'FILE_DOC_UUID' + id;
            let val = this.props.form.getFieldValue(ids);
            let arr = JSON.parse(val);
            let tarArr = [];
            arr.map(item => {
                if (item.name !== file.name) {
                    tarArr.push(item);
                }
            });

            let fieldsObj2 = {};
            fieldsObj2[ids] = tarArr.length > 0 ? JSON.stringify(tarArr) : '';
            this.props.form.setFieldsValue(fieldsObj2);
        } else {
            let fileListVal = this.props.form.getFieldValue(id).fileList;
            let tarArr = [];
            fileListVal.map(item => {
                if (item.status !== 'removed') {
                    tarArr.push(item);
                }
            });

            let ids = 'FILE_DOC_UUID' + id;
            let objArr = [];
            if (tarArr.length > 0) {
                tarArr.map(item => {
                    let obj = {};
                    obj.name = item.name;
                    obj.value = item.response.data.bh;
                    objArr.push(obj);
                })
            }
            let fieldsObj2 = {};
            fieldsObj2[ids] = objArr.length > 0 ? JSON.stringify(objArr) : '';
            this.props.form.setFieldsValue(fieldsObj2);
        }
    };

    //渲染文件上传控件
    // renderFileUpload = () => {
    //     let { form, id } = this.props;
    //     let { size, required, fileType } = this.state.featureParams;

    //     const uploadOtherFileTypeProps = {
    //         action: 'anonymity/docrepo/upload',
    //         data: { uuid: createUuid(), type: '1' },
    //         fileList: this.state.uploadFileList,
    //         onRemove: this.onRemoveFile,
    //     };

    //     const uploadOtherFileComponent = (
    //         <Upload
    //             {...uploadOtherFileTypeProps}
    //             beforeUpload={(file) => this.beforeUpload(file, size, fileType)}
    //             onChange={this.handleUploadChange}
    //         >
    //             <Button>
    //                 <Icon type="upload" /> 点击上传
    //             </Button>
    //         </Upload>
    //     );

    //     //是否是表单项
    //     if (form) {
    //         this.props.form.getFieldDecorator('FILE_DOC_UUID' + id);
    //         return this.renderFormItem({
    //             form: form,
    //             pRequired: required,
    //             component: uploadOtherFileComponent
    //         });
    //     }
    //     return uploadOtherFileComponent;
    // };
    // 渲染日期控件
    renderDatePicker = () => {
        moment.locale('zh-cn');
        let { form, id, value, disabled, onChange } = this.props;
        let { widthType, widthStr, required, defaultValue, format } = this.state.featureParams;

        let datePickerComponent = null;
        let otherParams = {}; //其他参数

        if (!form && id !== undefined && id != '') {
            otherParams.id = id;
        }
        if (!form && value) {
            otherParams.value = this.state.value === '' ? null : this.state.value;
        }
        if (!form && defaultValue !== undefined && defaultValue != '') {
            otherParams.defaultValue = moment(defaultValue, format);
        }
        otherParams.format = format;
        // console.log("==========DatePicker-otherParams==========");
        // console.log({ ...otherParams });
        // console.log("==========DatePicker-locale==========");
        // console.log(locale);
        if (widthType != WidthType.DEFAULT) {
            datePickerComponent = (
                <DatePicker
                    {...otherParams}
                    disabled={disabled}
                    style={{ width: widthStr }}
                    locale={locale}
                    onChange={(date, dateString) => {
                        if (!('value' in this.props)) {
                            this.setState({ value: date });
                        }
                        if (typeof onChange == 'function') {
                            onChange(date, dateString);
                        }
                    }
                    }
                />
            );
        } else {
            datePickerComponent = (
                <DatePicker
                    {...otherParams}
                    disabled={disabled}
                    locale={locale}
                    onChange={
                        (date, dateString) => {
                            if (!('value' in this.props)) {
                                this.setState({ value: date });
                            }
                            if (typeof onChange == 'function') {
                                onChange(date, dateString);
                            }
                        }
                    }
                />
            );
        }
        //是否是表单项
        if (form) {
            return this.renderFormItem({
                form: form,
                pDefaultValue: defaultValue === '' ? null : moment(defaultValue, format),
                pRequired: required,
                component: datePickerComponent
            });
        }

        return datePickerComponent;
    }
    // 渲染日期时间控件
    renderDateTimePicker = () => {
        // moment.locale('zh-cn');
        let { form, id, value, disabled, onChange } = this.props;
        let { widthType, widthStr, required, defaultValue, format } = this.state.featureParams;

        let dateTimePickerComponent = null;
        let otherParams = {}; //其他参数

        if (!form && id !== undefined && id != '') {
            otherParams.id = id;
        }
        if (!form && value) {
            otherParams.value = this.state.value === '' ? null : this.state.value;
        }
        if (!form && defaultValue !== undefined && defaultValue != '') {
            otherParams.defaultValue = moment(defaultValue, format);
        }
        otherParams.format = format;
        if (widthType != WidthType.DEFAULT) {
            dateTimePickerComponent = (
                <DatePicker
                    {...otherParams}
                    disabled={disabled}
                    style={{ width: widthStr }}
                    locale={locale}
                    showTime
                    onChange={
                        (date, dateString) => {
                            if (!('value' in this.props)) {
                                this.setState({ value: date });
                            }
                            if (typeof onChange == 'function') {
                                onChange(date, dateString);
                            }
                        }
                    }
                />
            );
        } else {
            dateTimePickerComponent = (
                <DatePicker
                    {...otherParams}
                    disabled={disabled}
                    locale={locale}
                    placeholder={'请选择日期和时间'}
                    showTime
                    onChange={
                        (date, dateString) => {
                            if (!('value' in this.props)) {
                                this.setState({ value: date });
                            }
                            if (typeof onChange == 'function') {
                                onChange(date, dateString);
                            }
                        }
                    }
                />
            );
        }
        //是否是表单项
        if (form) {
            return this.renderFormItem({
                form: form,
                pDefaultValue: defaultValue === '' ? null : moment(defaultValue, format),
                pRequired: required,
                component: dateTimePickerComponent
            });
        }

        return dateTimePickerComponent;
    }
    // 渲染日期范围控件
    renderRangePicker = () => {
        moment.locale('zh-cn');
        let { form, id, value, disabled, onChange } = this.props;
        let { widthType, widthStr, required, defaultValueStart, defaultValueEnd, format, showTime } = this.state.featureParams;

        let rangePickerComponent = null;
        let otherParams = {}; //其他参数

        if (!form && id !== undefined && id != '') {
            otherParams.id = id;
        }
        if (!form && value) {
            otherParams.value = this.state.value === '' ? null : this.state.value;
        }
        if (!form && defaultValueStart !== undefined && defaultValueStart != '' && defaultValueEnd !== undefined && defaultValueEnd != '') {
            otherParams.defaultValue = [moment(defaultValueStart, format), moment(defaultValueEnd, format)];
        }
        otherParams.format = format;
        console.log("==========RangePicker-otherParams==========");
        console.log({ ...otherParams });
        console.log("==========RangePicker-locale==========");
        console.log(locale);
        if (widthType != WidthType.DEFAULT) {

            rangePickerComponent = (
                <RangePicker
                    //  {...otherParams}
                    value='[2020-03-21,2020-04-21]'
                    format={'YYYY-MM-DD'}

                />
            );
        } else {
            rangePickerComponent = (
                <RangePicker
                    // {...otherParams}
                    value='[2020-03-21,2020-04-21]'
                    format={'YYYY-MM-DD'}

                />
            );
        }
        //是否是表单项
        if (form) {
            return this.renderFormItem({
                form: form,
                pDefaultValue: [defaultValueStart === '' ? null : moment(defaultValueStart, format), defaultValueEnd === '' ? null : moment(defaultValueEnd, format)],
                pRequired: required,
                component: rangePickerComponent
            });
        }

        return rangePickerComponent;
    }

    renderUpload = () => {
        let { form, id, onChange, disabled, value, isEdit } = this.props;
        let { size, fileType, required, mode, numberLimit, serviceMark, moduleName, describe, fileMainName, templateList = [], accept } = this.state.featureParams;
        let params = {};
        if (!form && id !== undefined && id !== '') {
            params.id = id;
        }

        let uploadComponent = null;
        if (fileType === 'image') {
            params.listType = 'picture-card';
            params.accept = 'image/*';
            params.image = true;
        }
        if (accept && accept.length > 0) {
            params.accept = accept;
        }
        uploadComponent = (
            <NHUpload {...params} uploadType={mode} redisData={30} disabled={disabled}
                fileCountLimit={numberLimit}
                isEdit={isEdit}
                serviceMark={serviceMark}
                moduleName={moduleName}
                value={value}
                describe={describe}
                fileMainName={fileMainName}
                templateList={templateList}
                multiple={numberLimit ? true : false}
                onChange={(value) => {
                    if (typeof onChange === 'function') {
                        onChange(value);
                    }
                }}
            />
        );
        //是否是表单项
        if (form) {
            return this.renderFormItem({
                form: form,
                pDefaultValue: '',
                pRequired: required,
                component: uploadComponent
            });
        }

        return uploadComponent;
    }

    renderTimePicker = () => {
        moment.locale('zh-cn');
        let { form, id, value, disabled, onChange } = this.props;
        let { widthType, widthStr, required, defaultValue, format } = this.state.featureParams;

        let timePickerComponent = null;
        let otherParams = {}; //其他参数

        if (!form && id !== undefined && id != '') {
            otherParams.id = id;
        }
        if (!form && value) {
            otherParams.value = this.state.value === '' ? null : this.state.value;
        }
        if (!form && defaultValue !== undefined && defaultValue != '') {
            otherParams.defaultValue = moment(defaultValue, format);
        }
        otherParams.format = format;
        if (widthType != WidthType.DEFAULT) {
            timePickerComponent = (
                <TimePicker
                    {...otherParams}
                    disabled={disabled}
                    style={{ width: widthStr }}
                    onChange={
                        (time, timeString) => {
                            if (!('value' in this.props)) {
                                this.setState({ value: time });
                            }
                            if (typeof onChange == 'function') {
                                onChange(time, timeString);
                            }
                        }
                    }
                />
            );
        } else {
            timePickerComponent = (
                <TimePicker
                    {...otherParams}
                    disabled={disabled}
                    locale={locale}
                    onChange={
                        (time, timeString) => {
                            if (!('value' in this.props)) {
                                this.setState({ value: time });
                            }
                            if (typeof onChange == 'function') {
                                onChange(time, timeString);
                            }
                        }
                    }
                />
            );
        }
        //是否是表单项
        if (form) {
            return this.renderFormItem({
                form: form,
                pDefaultValue: (defaultValue === '' || defaultValue == undefined) ? null : moment(defaultValue, format),
                pRequired: required,
                component: timePickerComponent
            });
        }

        return timePickerComponent;
    }

    //渲染月份选择器
    renderMonthPicker = () => {
        moment.locale('zh-cn');
        let { form, id, value, disabled, onChange } = this.props;
        let { widthType, widthStr, required, defaultValue, format } = this.state.featureParams;

        let monthPickerComponent = null;
        let otherParams = {}; //其他参数

        if (!form && id !== undefined && id != '') {
            otherParams.id = id;
        }
        if (!form && value) {
            otherParams.value = this.state.value === '' ? null : this.state.value;
        }
        if (!form && defaultValue !== undefined && defaultValue != '') {
            otherParams.defaultValue = moment(defaultValue, format);
        }

        otherParams.format = format;
        if (widthType != WidthType.DEFAULT) {
            monthPickerComponent = (
                <MonthPicker
                    {...otherParams}
                    disabled={disabled}
                    style={{ width: widthStr }}
                    locale={locale}
                    onChange={(date, dateString) => {
                        if (!('value' in this.props)) {
                            this.setState({ value: date });
                        }
                        if (typeof onChange == 'function') {
                            onChange(date, dateString);
                        }
                    }
                    }
                />
            );
        } else {
            monthPickerComponent = (
                <MonthPicker
                    {...otherParams}
                    disabled={disabled}
                    locale={locale}
                    onChange={
                        (date, dateString) => {
                            if (!('value' in this.props)) {
                                this.setState({ value: date });
                            }
                            if (typeof onChange == 'function') {
                                onChange(date, dateString);
                            }
                        }
                    }
                />
            );
        }
        //是否是表单项
        if (form) {
            return this.renderFormItem({
                form: form,
                pDefaultValue: defaultValue === '' ? null : moment(defaultValue, format),
                pRequired: required,
                component: monthPickerComponent
            });
        }

        return monthPickerComponent;
    };

    //渲染多选下拉选择器输入控件类型
    renderMultiSelect = () => {
        let { form, id, value, disabled, onChange } = this.props;
        let { widthType, widthStr, dataTag, required, defaultValue, baseUrl } = this.state.featureParams;

        let multiselectComponent = null;
        let otherParams = {}; //其他参数

        if (!form && id !== undefined && id != '') {
            otherParams.id = id;
        }
        if (!form && value) {
            otherParams.value = this.state.value === '' ? [] : this.state.value;
        }
        if (!form && defaultValue !== undefined && defaultValue != '') {
            otherParams.defaultValue = defaultValue;
        }

        if (baseUrl !== null && baseUrl !== undefined && baseUrl !== '') {
            otherParams.baseUrl = baseUrl;
        }

        if (widthType != WidthType.DEFAULT) {
            multiselectComponent = (
                <NHMultiSelect
                    {...otherParams}
                    disabled={disabled}
                    sign={dataTag}
                    style={{ width: widthStr }}
                    onChange={
                        (v) => {
                            if (!('value' in this.props)) {
                                this.setState({ value: v });
                            }
                            if (typeof onChange == 'function') {
                                onChange(v);
                            }
                        }
                    }
                />
            );
        } else {
            multiselectComponent = (
                <NHMultiSelect
                    {...otherParams}
                    disabled={disabled}
                    sign={dataTag}
                    onChange={
                        (v) => {
                            if (!('value' in this.props)) {
                                this.setState({ value: v });
                            }
                            if (typeof onChange == 'function') {
                                onChange(v);
                            }
                        }
                    }
                />
            );
        }

        //是否是表单项
        if (form) {
            return this.renderFormItem({
                form: form,
                pDefaultValue: defaultValue,
                pRequired: required,
                component: multiselectComponent
            });
        }

        return multiselectComponent;
    }

    //渲染级联下拉选择器输入控件类型
    renderNHCascader = () => {
        let { form, id, value, disabled, onChange } = this.props;
        let { widthType, widthStr, dataTag, required, defaultValue, baseUrl, changeOnSelect } = this.state.featureParams;

        let nhcasecaderComponent = null;
        let otherParams = {}; //其他参数

        if (!form && id !== undefined && id != '') {
            otherParams.id = id;
        }
        if (!form && value) {
            otherParams.value = this.state.value === '' ? [] : this.state.value;
        }
        if (!form && defaultValue !== undefined && defaultValue != '') {
            otherParams.defaultValue = defaultValue;
        }

        if (baseUrl !== null && baseUrl !== undefined && baseUrl !== '') {
            otherParams.baseUrl = baseUrl;
        }

        // if (changeOnSelect !== null && changeOnSelect !== undefined && changeOnSelect !== '') {
        //   otherParams.changeOnSelect = changeOnSelect;
        // }
        if (widthType != WidthType.DEFAULT) {
            nhcasecaderComponent = (
                <NHCascader
                    {...otherParams}
                    disabled={disabled}
                    sign={dataTag}
                    style={{ width: widthStr }}
                    changeOnSelect={false}
                    onChange={
                        (v, o) => {
                            if (!('value' in this.props)) {
                                this.setState({ value: v });
                            }
                            if (typeof onChange == 'function') {
                                onChange(v, o);
                            }
                        }
                    }
                />
            );
        } else {
            nhcasecaderComponent = (
                <NHCascader
                    {...otherParams}
                    disabled={disabled}
                    sign={dataTag}
                    changeOnSelect={false}
                    onChange={
                        (v, o) => {
                            if (!('value' in this.props)) {
                                this.setState({ value: v });
                            }
                            if (typeof onChange == 'function') {
                                onChange(v, o);
                            }
                        }
                    }
                />
            );
        }

        //是否是表单项
        if (form) {
            return this.renderFormItem({
                form: form,
                pDefaultValue: defaultValue,
                pRequired: required,
                component: nhcasecaderComponent
            });
        }

        return nhcasecaderComponent;
    }

    //渲染数字输入框控件类型
    renderInputNumber = () => {
        let { form, id, value, disabled, onChange } = this.props;
        let { widthType, widthStr, size, step, max = '', min = '', required, defaultValue = '' } = this.state.featureParams;
        let inputnumberComponent = null;
        let otherParams = {}; //动态参数

        if (!form && id !== undefined && id != '') {
            otherParams.id = id;
        }
        if (value) {
            otherParams.value = this.state.value;
        }
        if (defaultValue !== undefined && defaultValue != '') {
            otherParams.defaultValue = defaultValue;
        }
        if (size !== undefined && size != '') {
            otherParams.size = size;
        }
        if (step !== undefined && step != '') {
            otherParams.step = step;
        }
        if (max !== undefined && max != '') {
            otherParams.max = max;
        }
        if (min !== undefined && min != '') {
            otherParams.min = min;
        }
        console.log("otherParams", otherParams);
        try {
            if (max < min) {
                message.info('最大值不得小于最小值!');
            } else if (widthType != WidthType.DEFAULT) {
                inputnumberComponent = (
                    <InputNumber
                        {...otherParams}
                        disabled={disabled}
                        style={{ width: widthStr }}
                        onChange={
                            (value) => {

                                if (!('value' in this.props)) {
                                    this.setState({ value: value });
                                }

                                if (typeof onChange == 'function') {
                                    onChange(value, value);
                                }
                            }
                        }
                    />
                );
            } else {
                inputnumberComponent = (
                    <InputNumber
                        {...otherParams}
                        disabled={disabled}
                        onChange={
                            (value) => {

                                if (!('value' in this.props)) {
                                    this.setState({ value: value });
                                }

                                if (typeof onChange == 'function') {
                                    onChange(value, value);
                                }
                            }
                        }
                    />
                );
            }
        } catch (e) {
            console.log(e);
            message.error('组装控件配置参数出错！');
        }

        //是否是表单项
        if (form) {
            return this.renderFormItem({
                form: form, hasFeedback: true, size: size, step: step, max: max, min: min, pDefaultValue: defaultValue,
                pRequired: required, component: inputnumberComponent
            });
        }

        return inputnumberComponent;
    }

    renderOldUpload = () => {
        let { form, id, onChange, disabled, value } = this.props;
        let { size, fileType, required, mode, numberLimit, describe } = this.state.featureParams;
        let params = {};
        if (!form && id !== undefined && id !== '') {
            params.id = id;
        }
        params.describe = describe;
        params.maxFileSize = size;

        let uploadComponent = null;
        if (fileType === 'image') {
            uploadComponent = (
                <NHImage {...params} numberOfSize={size} saveType={mode} disabled={disabled}
                    numberOfLimit={numberLimit}
                    imageList={value}
                    onChange={(imageList) => {
                        if (typeof onChange === 'function') {
                            onChange(imageList);
                        }
                    }}
                />
            );
        } else {
            uploadComponent = (
                <NHOldUpload {...params} uploadType={mode} redisData={30} disabled={disabled}
                    fileCountLimit={numberLimit}
                    multiple={numberLimit ? true : false}
                    onChange={(file, fileList) => {
                        if (typeof onChange === 'function') {
                            onChange(file, fileList);
                        }
                    }}
                />
            );
        }

        //是否是表单项
        if (form) {
            return this.renderFormItem({
                form: form,
                pDefaultValue: '',
                pRequired: required,
                component: uploadComponent
            });
        }

        return uploadComponent;
    }

    render() {

        let feature = this.state.feature;
        let featureParams = this.state.featureParams;
        let fileType = featureParams.fileType;
        //初步验证参数
        if (feature === undefined || feature === null || feature == '') {
            return null;
        }

        switch (featureParams.type) {
            case InputType.INPUT:
                return this.renderInput(); //单行输入控件类型
                break;
            case InputType.TEXTAREA:
                return this.renderTextArea(); //多行输入控件类型
                break;
            case InputType.RADIO:
                return this.renderRadio(); //单选框控件类型
                break;
            case InputType.SELECT:
                return this.renderSelect(); //下拉选择器输入控件类型
                break;
            case InputType.SWITCH:
                return this.renderSwitch(); //开关输入控件类型
                break;
            case InputType.UPLOAD:
                // return fileType === 'image' ? this.renderImageUpload() : this.renderFileUpload(); //文件上传控件类型
                return this.renderUpload();
                break;
            case InputType.DATEPICKER:
                return this.renderDatePicker(); // 日期控件
                break;
            case InputType.RANGEPICKER:
                return this.renderRangePicker();// 日期范围控件
                break;
            case InputType.TIMEPICKER:
                return this.renderTimePicker();// 时间控件
            case InputType.MULTISELECT:
                return this.renderMultiSelect(); // 多选下拉框
            case InputType.NHCASCADER:
                return this.renderNHCascader(); // 级联下拉框
            case InputType.DATETIMEPICKER:
                return this.renderDateTimePicker();//日期时间下拉框
            case InputType.INPUTNUMBER:
                return this.renderInputNumber();   //数字输入框
                break;
            case InputType.MONTHPICKER:
                return this.renderMonthPicker(); //月份选择器
            case InputType.OLDUPLOAD:
                return this.renderOldUpload(); //旧版附件上传
        }

        return null;
    }
}

NHInputRender.defaultProps = {
    formLayout: "horizontal", //默认水平布局
    formItemLayout: { //表单项布局
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
            md: { span: 7 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 12 },
            md: { span: 10 },
        }
    },
    colon: true,
    hideLabel: false,
    required: false,
    readOnly: false,
}

NHInputRender.propTypes = {
    id: PropTypes.string, //渲染成表单项时必填，的字段标识
    label: PropTypes.string, //渲染成表单项时必填，的字段名称
    colon: PropTypes.bool, //是否显示label后面的冒号
    hideLabel: PropTypes.bool, //是否隐藏label
    checked: PropTypes.bool, //渲染成表单项时可选，开关、多选控件类型选中属性
    defaultValue: PropTypes.node, //渲染成表单项时可选，默认值
    value: PropTypes.any, //渲染成受控组件时，可选初始值
    required: PropTypes.bool, //渲染成表单项时可选，是否必填
    readOnly: PropTypes.bool, //渲染成表单项时可选，是否只读
    disabled: PropTypes.bool, //渲染成表单项时可选，是否禁用
    form: PropTypes.object, //渲染成表单项时必填，表单对象
    feature: PropTypes.string.isRequired, //必填，输入控件参数字符串
    formLayout: PropTypes.string, //渲染成表单项时可选，表单布局
    formItemLayout: PropTypes.object, //渲染成表单项时可选，表单项布局
    onChange: PropTypes.func,//输入控件值onChange方法
    //当使用NHUpload时需要的参数
    serviceMark: PropTypes.string,//服务标志，说明是属于哪个服务，必填
    moduleName: PropTypes.string,//模块名称，说明是属于哪个服务的哪个模块，必填
    fjzbid: PropTypes.string,//附件主表id，非必填
    ms: PropTypes.string,//描述，对上传的附件的一个描述信息，非必填
    fjzbm: PropTypes.string,//附件主表名，存储该附件对应的主表的名称，主要是为了以后进行追溯，非必填
    parentValue: PropTypes.string, // 下拉框级联时上一级选中的值
}

export default NHInputRender;
