import React from 'react';
import {message} from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import createHistory from 'history/createHashHistory';
import {getLoginUser} from "@utils/NHCore";
import './index.css';
import NHFetch from "../../../../utils/NHFetch";
import {mkbz} from './menuObj';
import NHCascader from "@components/common/NHCascader";

const history = createHistory();


const menu = {
    tzgg: [],//通知通告
    zzbd: [],//自助报到
    dhlj: [],//导航链接
};


export default class _siderMenu extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        menuType: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            menuList: [],//菜单列表
            unShowBlzt: ['GD009'],
            BlztMc: {
                GD002: ['已购买', '未购买'],
                GD005: ['已审核', '已申请', '未申请', '未办理'],
                GD005_2: ['待审核', '已办理', '通过', '驳回', '未办理', '不通过'],
                GD005_10: ['待审核', '已办理', '通过', '驳回', '未办理', '不通过'],
                GD005_15: ['待审核', '已办理', '通过', '驳回', '未办理', '不通过'],
                GD007: ['已审核', '已申请', '未申请', '申请被删除']
            },
            //bddSchool:'lydx',
            // shzt:false
        }
    }

    componentDidMount() {
        const {menuType, flmid} = this.props;
        const tzgg = menu.tzgg;
        const zzbd = menu.zzbd;
        const dhlj = menu.dhlj;
        if (menuType === '通知公告') {
            if (tzgg && tzgg.length == 0) {
                this.loadXxbfMenuList(tzgg);
            } else {
                this.setState({menuList: tzgg}, () => {
                    if (this.props.setMenuList) {
                        this.props.setMenuList(tzgg);
                    }
                });
            }
        }

        if (menuType === '自助报到') {
            if (zzbd && zzbd.length == 0) {
                this.loadMenuList(zzbd);
            } else {
                this.setState({menuList: zzbd}, () => {
                    if (this.props.setMenuList) {
                        this.props.setMenuList(zzbd);
                    }
                    this.getBddCssz()
                });
            }
        }

        if (menuType === '导航链接') {
            if (dhlj && dhlj.length == 0 && flmid) {
                this.loadDhljList(dhlj, flmid);
            } else {
                this.setState({menuList: dhlj});
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        const {menuType, mkbz} = this.props;
        if (menuType === '导航链接' && !this.state.flmid && nextProps.flmid != '') {
            const dhlj = menu.dhlj;
            this.loadDhljList(dhlj, nextProps.flmid);
        }
    }

    getLstdXcShzt = () => {
        NHFetch('/openapi/yxwz/getLstdXcShzt', 'GET')
            .then(res => {
                if (res) {
                    if (res.data !== '0' && res.data !== null) {
                        this.setState({shzt: true})
                    }
                }
            })
    }
    //获取报到单学校模板参数设置
    getBddCssz = () => {
        const { menuList } = this.state
        if(menuList.some((item)=>item.HJDM === 'GD009')) {
            // 有报到单环节再请求对应参数
            NHFetch('/zzbdCommon/getCssz', 'GET', {csbz: 'YXXT_BDD_SCHOOL'}).then(res => {
                if (res) {
                    if (res.data) {
                        this.setState({bddSchool: res.data});
                    } else {
                        this.setState({bddSchool: 'lydx'});
                    }
                } else {
                    this.setState({bddSchool: 'lydx'});
                }
            })
        }
    }

    loadDhljList = (menuList, flmid) => {
        let userId = getLoginUser() ? getLoginUser().userId : '';
        let xsfs = '';
        if (userId) {
            xsfs = 1;
        }
        let params = {
            flmid: flmid,
            xsfs: xsfs,
        }

        NHFetch('/dhlm/getDhljList', 'GET', params).then(resData => {
            if (resData && resData.data) {
                if (menuList.length > 0) {
                    menuList = [];
                }
                menuList.push(...resData.data);
                this.setState({menuList: resData.data});
            }
        });
    }

    reloadMenuList = () => {
        const zzbd = menu.zzbd;
        this.loadMenuList(zzbd);
    }

    loadMenuList = (menuList) => {
        //获取自助报到环节信息
        NHFetch('/zzbd/getZzbdInfoList', 'GET').then(resData => {
            if (resData && resData.data) {
                if (menuList.length > 0) {
                    menuList.splice(0, menuList.length);
                }
                menuList.push(...resData.data);
                this.setState({menuList: resData.data}, () => {
                    if (this.props.setMenuList) {
                        this.props.setMenuList(resData.data);
                    }
                    //this.getLstdXcShzt();
                    this.getBddCssz()
                });
            }
        });

    }

    loadXxbfMenuList = (menuList) => {
        /*NHFetch('/xxfb/loadXxbfMenuList','GET').then(resData => {
            if (resData && resData.data) {
                if(menuList.length>0){
                    menuList = [];
                }
                menuList.push(...resData.data);
                this.setState({menuList:resData.data},()=>{
                    if(this.props.setMenuList){
                        this.props.setMenuList(resData.data);
                    }
                });
            }
        });*/

        let params = {}
        let xsid = getLoginUser()?getLoginUser().userId:'';
        if(xsid){
            params={
                xsfs: 2, //显示方式
                lmlx: '1' //栏目类型
            }
        }
        NHFetch("api/zhxg-yxwz/dhlm/getDhlmList","GET",{ xsfs: 2,lmlx: '1'})
            .then(resData => {
                if(resData && resData.data){
                    if(menuList.length>0){
                        menuList = [];
                    }
                    if(!xsid){
                        menuList = resData.data.filter(e => e.xsfs !== '2');
                    } else {
                        menuList.push(...resData.data);
                    }
                    this.setState({menuList:menuList},()=>{
                        if(this.props.setMenuList){
                            this.props.setMenuList(menuList);
                        }
                    });
                }
            });
    }

    toLink_GD = (hjdm, pkid) => {
        if (pkid && this.isLogin()) {
            //报到单环节 > 动态地址
            let {bddSchool} = this.state;
            let hjPath = hjdm === 'GD009' ? '/' + bddSchool + 'Index' : mkbz[hjdm];
            history.push({
                pathname: hjPath,
                search: 'pkid=' + pkid,
            });
        }
    }

    toLink_ZDY = (hjdm, pkid, lx) => {
        if (pkid && this.isLogin()) {
            history.push({
                    pathname: '/customLink',
                    search: 'pkid=' + pkid + '&mkbz=' + hjdm + "&lx=" + lx,
                }
            );
        }
    }

    isLogin = () => {
        let userId = getLoginUser() ? getLoginUser().userId : '';
        if (!userId) {
            message.warning("未获取登录信息。");
            history.push({
                pathname: '/index',
            });
            return false;
        }
        return true;
    }

    render() {
        const {className, title, menuType, active, url} = this.props;
        let menuList = this.state.menuList;
        let unShowBlzt = this.state.unShowBlzt;
        let blztMc = this.state.BlztMc;
        /*if (menuType === '通知公告') {
            menuList = [
                { title: '入学须知' },
                { title: '交通路线' },
                { title: '校园风景' },
                { title: '视频简介' },
                { title: '军训服装' }
            ];
        }

        if (menuType === '自助报到') {
            menuList = [
                { title: '个人信息填写', url: '/update_info' },
                { title: '物品购买', url: '/buy' },
                { title: '军训服装尺码', url: '/size' },
                { title: '报到情况填写', url: '/report' },
                { title: '绿色通道申请', url: '/channel' },
                { title: '自选宿舍', url: '/dorm' },
                { title: '专业调整', url: '/major' },
                { title: '网上缴费', url: '/pay' },
                { title: '入学报到单打印', url: '/print' }
            ];
        }*/

        return (
            <div className={className} styleName="sider_menu">
                <div styleName="sider_menu_title">{title}</div>
                {menuList && title === '自助报到' &&
                    menuList.length > 0 &&
                    menuList.map((elem, index) => {
                        let hjlx = elem.HJLX;
                        let showBlzt = true;
                        let i = unShowBlzt.indexOf(elem.HJDM);
                        if (i > 0 || i == 0) {
                            showBlzt = false;
                        }
                        let mcList = blztMc[elem.HJDM];
                        let ztmc = '';
                        if (mcList) {
                            if (elem.HJDM === 'GD005' ) {
                                ztmc = elem.shzt && elem.shzt !== '0' ? (elem.shzt === '9' ? mcList[3] : mcList[0]) : (elem.BLZT && elem.BLZT == 1 ? mcList[1] : mcList[2]); 
                            } else if (elem.HJDM === 'GD005_2' || elem.HJDM === 'GD005_10' || elem.HJDM === 'GD005_15') {
                                ztmc = elem.shzt != null ? (elem.shzt == '0' ? mcList[0] : elem.shzt == '2' ? mcList[1] : elem.shzt == '1' ? mcList[2] : 
                                elem.shzt == '9' ? mcList[3] : mcList[5]) : mcList[4];
                            } else if (elem.HJDM === 'GD007') {
                                ztmc = elem.shzt ? (elem.shzt !== '0' ? mcList[0] : mcList[1]) : mcList[2];
                            } else {
                                ztmc = elem.BLZT && elem.BLZT == 1 ? mcList[0] : mcList[1];
                            }
                        } else {
                            ztmc = elem.BLZT && elem.BLZT == 1 ? '已办理' : '未办理';
                        }
                        if ('GD005_14' === elem.HJDM) {
                            if (elem.BLZT === '1') {
                                ztmc = '已办理'
                            }
                            else if (elem.shzt) {
                               switch (elem.shzt) {
                                   case '-1': ztmc = '不通过'
                                       break;
                                   case '0': ztmc = '审核中'
                                       break;
                                   case '1': ztmc = '审核中'
                                       break;
                                   case '2': ztmc = '已办理'
                                       break;
                                   case '9': ztmc = '驳回'
                                       break;
                               }
                            } else {
                                ztmc = '未办理'
                            }
                        }

                        return (
                            hjlx == 1 ?
                                <div
                                    key={index}
                                    styleName={classNames('sider_menu_item', {
                                        active: elem.HJDM === this.props.mkbz
                                    })}
                                    onClick={() => {
                                        this.toLink_GD(elem.HJDM, elem.PKID);
                                    }}
                                >

                                    {elem.HJMC.length <= 10 ? elem.HJMC : `${elem.HJMC.substring(0, 10)}...`}
                                    {showBlzt && elem.HJDM == 'GD006_2' ? '' : <span
                                        className={elem.BLZT && elem.BLZT == 1 ? "" : "text-info-red"}>({ztmc})</span>}
                                </div> :
                                <div
                                    key={index}
                                    styleName={classNames('sider_menu_item', {
                                        active: elem.HJDM === this.props.mkbz
                                    })}
                                    onClick={() => {
                                        this.toLink_ZDY(elem.HJDM, elem.PKID, elem.LX)
                                    }}
                                >
                                    {elem.HJMC.length <= 10 ? elem.HJMC : `${elem.HJMC.substring(0, 10)}...`}
                                    {showBlzt && <span
                                        className={elem.BLZT && elem.BLZT == 1 ? "" : "text-info-red"}>({ztmc})</span>}
                                </div>
                        );
                    })}

                {menuList && title === '消息发布' &&
                    menuList.length > 0 &&
                    menuList.map((elem, index) => {
                        return (
                            <div
                                key={index}
                                styleName={classNames('sider_menu_item', {
                                    active: elem.flbz === this.props.flbz
                                })}
                                onClick={() => {
                                    if (elem) {
                                        if (elem.isUnique === '1') {
                                            history.push({
                                                    pathname: '/notice/detail',
                                                    search: 'pkid=' + elem.pkid,
                                                }
                                            );
                                        } else {
                                            history.push({
                                                    pathname: '/notice',
                                                    search: 'flbz=' + elem.flbz,
                                                }
                                            );
                                        }
                                    }
                                }}
                            >
                                {elem.lmmc.length <= 10 ? elem.lmmc : `${elem.lmmc.substring(0, 10)}...`}
                            </div>
                        )
                    })}

                {menuList && title === '导航链接' &&
                    menuList.length > 0 &&
                    menuList.map((elem, index) => {
                        return (
                            <div
                                key={index}
                                styleName={classNames('sider_menu_item', {
                                    active: elem.pkid === this.props.pkid
                                })}
                                onClick={() => {
                                    if (elem.lmlj) {
                                        history.push({
                                                pathname: '/link',
                                                search: 'pkid=' + elem.pkid,
                                            }
                                        );
                                        window.open(elem.lmlj);
                                    }
                                }}
                            >
                                {elem.lmmc.length <= 10 ? elem.lmmc : `${elem.lmmc.substring(0, 10)}...`}
                            </div>
                        )
                    })}
            </div>
        );
    }
}
//属性检查
_siderMenu.propTypes = {
    setMenuList: PropTypes.func, //选择完成后的回调
}