import React from 'react';
import {Link} from 'react-router-dom';
import {Flex} from '@components';
import {Form, Input, Checkbox, Row, Col, Upload, Button,message} from 'antd';
import TitleTop from '@pages/Assembly/titleTop';
import NHFetch from "@utils/NHFetch";
import {createUuid, getLoginUser} from '@utils/NHCore';
import NextButton from '@pages/SelfHelp/common/nextIndex'
const {TextArea} = Input;
const basicUrl = "/zzbd";

const shztList = {
    '-1':'审核不通过',
    '0':'未审核',
    '1':'审核通过',
    '2':'已办理',
    '9':'驳回'
}
export default class View extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dkyhmc:'',
            nextUrl:this.props.nextUrl,
            search:this.props.search,
            jhrgx:[]
        };
    }

    componentDidMount() {
        this.getDmkMc("YXWZ_DKYH");
        NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList?&sign=DMK_XSXX_JTCYGX', 'GET')
        .then(res => {
            if (res && res.data) {
                let jtcyList = res.data;
                console.log(jtcyList);
                this.setState({jhrgx : jtcyList});
            }
        });
    }

    //获取下拉列表value对应的名称
    getDmkMc = (dmbz) => {
        const {data} = this.props;
        let dkyhmc = this.state.dkyhmc;
        NHFetch(basicUrl + "/getDmkMc", "GET", {dmbz, dmbz})
            .then(res => {
                if (res) {
                    if (res.data) {
                        if(dmbz==="YXWZ_DKYH"){
                            res.data.map(initItem => {
                                if(initItem.DM === data.dkyh){
                                    dkyhmc=initItem.MC;
                                }
                            });
                            this.setState({dkyhmc: dkyhmc});
                        }
                    }
                }
            });
    }

    getQueryString =(name)=> {
        return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[, ''])[1].replace(/\+/g, '%20')) || null
    }

    edit=()=>{

        let xsid = getLoginUser().userId;
        NHFetch('/zzbd/xzsfzz/getXzsfzzByXsid', 'GET',{xsid:xsid}).then(resData => {
            if (resData && resData.data) {
                if(resData.data.SHZT != '' && resData.data.SHZT!=undefined){
                    if(resData.data.SHZT !== '0' && resData.data.SHZT !== '9'){
                        message.warning('该申请已经审核，不能修改')
                        return ;
                    }
                    this.props.loadInfo('edit');
                    this.props.reloadMenu();
                }else{
                    this.props.loadInfo('edit');
                    this.props.reloadMenu();
                }
            }else{
                message.warning("修改异常");
            }
        })
    }

    // 点击文件链接或预览图标时的回调
    handlePreview = (fjmc, fjid) => {
        let isImage = true;
        if (
            !this.props.setJudge(fjmc,this.props.judgeImgList)
        ) {
            isImage = false;
        }

        if (isImage) {
            this.props.showPreview(`anonymity/docrepo/download/file?attachmentId=${fjid}`);
        } else {
            window.open(`anonymity/docrepo/download/file?attachmentId=${fjid}`);
        }
    };


    render() {
        const {data} = this.props;
        const {dkyhmc,jhrgx} = this.state;
        let fjList = [];
        if(JSON.stringify(data) != "{}"){
            let fj =  data['WSSQFJ'];
            console.log(fj);
            if(fj){
                fjList = JSON.parse(fj);
            }
        }
        console.log(fjList);
        return (
            <div>
                <TitleTop title="申请信息" extra={false}/>
                <div className="p-sw">
                    <div className="form_item" style={{width: '100%'}}>
                    <Row gutter={24}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>是否选择住宿&nbsp;: </span></Col>
                            <Col span={20}><span>{data['SFZS'] == '1'? '是':'否' }</span></Col>
                        </Row>
                        <br/>
                        {data['SFZS'] == '0'&&<Row gutter={24}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>潜江详细地址&nbsp;: </span></Col>
                            <Col span={20}><span>{data['WSDZ']}</span></Col>
                        </Row>}
                        <br/>
                        {data['SFZS'] == '0'&&<Row gutter={24}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>监护人&nbsp;: </span></Col>
                            <Col span={20}><span>{data['JHR']}</span></Col>
                        </Row>}
                        <br/>
                        {data['SFZS'] == '0'&&<Row gutter={24}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>与监护人关系&nbsp;: </span></Col>
                            <Col span={20}>
                                {
                                    jhrgx && jhrgx.map((item,index)=>{
                                        if(item.VALUE == data['JHRGX']){
                                            return <span>{item.LABEL}</span>
                                        }
                                    }) 
                                }
                            </Col>
                        </Row>}
                        <br/>
                        {data['SFZS'] == '0'&&<Row gutter={24}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>监护人电话&nbsp;: </span></Col>
                            <Col span={20}><span>{data['JHRDH']}</span></Col>
                        </Row>}
                        <br/>
                    </div>
                </div>
                {data['SFZS'] == '0'&&<TitleTop title="申请材料" extra={false}/>}
                {data['SFZS'] == '0'&&<div className="p-sw">
                    <div className="form_item" style={{width: '100%' }}>
                        <Row gutter={24}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>附件&nbsp;: </span></Col>
                            <Col span={20}>
                                {fjList.length>0 && fjList.map((elem, index) => {
                                    console.log(fjList,elem);
                                    return (
                                        <div style={{marginBottom: '20px'}}>
                                            <a onClick={() => {
                                                this.handlePreview(elem.fjmc, elem.fjid);
                                            }}>{elem.fjmc}</a>
                                        </div>
                                    );
                                })}
                            </Col>
                        </Row>
                    </div>
                </div>}
                <TitleTop title="申请审核" extra={false}/>
                <div className="p-sw">
                    <div className="form_item" style={{width: '100%', marginBottom: '24px'}}>
                        <Row gutter={24}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>审核意见&nbsp;: </span></Col>
                            <Col span={20}><span>{data['SHYJ']}</span></Col>
                        </Row>
                    </div>
                    <br/>
                    <div className="form_item" style={{width: '100%', marginBottom: '24px'}}>
                        <Row gutter={24}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>审核状态&nbsp;: </span></Col>
                            <Col span={20}><span style={{color: '#0066FF'}}>{data['SHZTMC']}</span></Col>
                        </Row>
                    </div>
                    <br/>
                    {(data['SHZT']=='0'|| data['SHZT']==undefined || data['SHZT']=='') &&<div className="form_item" style={{width: '100%', marginBottom: '24px'}}>
                        <Row gutter={24} >
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>提示&nbsp;: </span></Col>
                            <Col span={20}><span style={{color:'red'}}>{data['SFZS'] == '0'?"已提交走读申请，请等待审核!":"请等待分配宿舍后再继续后续操作！"}</span></Col>
                        </Row>
                    </div>}
                </div>
                {(data['SHZT']=="0" || data['SHZT']=="9" ||data['SHZT']==null) && <div className="text-center mt-50">
                    <Button type="primary" onClick={()=>{this.edit(data['SHZT'])}}>
                        修改
                    </Button>
                    <NextButton nextUrl={this.state.nextUrl}  search={this.state.search}/>
                </div>}
            </div>
        );
    }
}
