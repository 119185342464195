import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Form, Input, Checkbox, Row, Col, Button, Modal, Upload, Icon, message, Spin, InputNumber, Radio, DatePicker, Tooltip } from 'antd'
import TitleTop from '@pages/Assembly/titleTop'
import { createUuid, getLoginUser } from '@utils/NHCore'
import NHFetch from '@utils/NHFetch'
import View from './view'
const Dragger = Upload.Dragger
import createHistory from 'history/createHashHistory'
import NHSelect from '@components/common/NHSelect'
import NextButton from '@pages/SelfHelp/common/nextIndex'
import NHFormItem from '@components/common/NHFormItem'
import { NHConfirm } from '@components/common/NHModal'
import moment from 'moment'
const history = createHistory()
//确认对话框
const confirm = Modal.confirm
const { TextArea } = Input
const FormItem = Form.Item
const CheckboxGroup = Checkbox.Group
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 }
}
const formItemLayoutJx = {
  labelCol: { span: 7 },
  wrapperCol: { span: 15 }
}

@Form.create()
export default class MainType extends Component {
  constructor(props) {
    super(props)
    this.state = {
      uploading: false,
      previewVisible: false,
      checkGroup: [], //绿色通道办理原因
      HjsqdxGroup: [], //缓交申请对象
      judgeImgList: ['.png', '.PNG', '.jpg', '.JPG', '.bpm', '.BPM', '.jpeg', '.JPEG'], //可以导入的图片格式
      judgeFileList: ['.pdf', '.PDF', '.doc', '.DOC', '.docx', '.DOCX', '.zip', '.ZIP', '.txt', '.TXT', '.xls', '.XLS', '.xlsx', '.XLSX', '.rar', '.RAR'], //可以导入的非图片格式
      x_hjjzrq: '',
      jfhjzt: false, //缴费环节状态
      BlztList: [], //办理状态
      saveLoading: false,
      snwList: [], //省内外
      x_lqsj: '',
      x_tysj: '',
      x_fxsj: '',
      fileList: []
    }
  }

  componentDidMount() {
    this.getHjsjCsbz()
    // this.getJfhjData();
    if (this.props.operable) {
      this.loadLstdBlyy()
      this.loadLstdHjsqdx()
      this.updateZzbdBlzt()
    }
    this.getSfsqLstd()
    this.loadLstdSnw()
    this.initFiles(this.props)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.data !== nextProps.data) {
      this.initFiles(nextProps)
    }
  }

  getHjsjCsbz = () => {
    NHFetch('/zzbdCommon/getCssz', 'GET', { csbz: 'YXXT_LSTD_HJJZRQ' }).then(res => {
      if (res) {
        if (res.data && res.code === 200) {
          this.setState({ hjjzrqxz: res.data })
        }
      }
    })
  }

  // 获取绿色通道是否办理选项
  getSfsqLstd = () => {
    NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList', 'get', 'sign=dmk_YXXT_LSTD_BLZT').then(res => {
      if (res) {
        if (res.data) {
          this.setState({
            BlztList: res.data
          })
        }
      }
    })
  }

  //迎新现场已办理时迎新网站自动办理
  updateZzbdBlzt = () => {
    let zzbdhjid = this.getQueryString('pkid')
    NHFetch('/zzbd/updateZzbdBlzt', 'GET', { zzbdhjid: zzbdhjid, hjdm: 'GD005_2' }).then(res => {
      if (res.data) {
        this.props.reloadMenu()
      }
    })
  }

  getQueryString = name => {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
  }

  loadLstdBlyy = () => {
    NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList', 'get', 'sign=dmk_YXXT_LSTD_BLYY').then(res => {
      if (res) {
        let col = []
        res.data.map(item => {
          col.push(
            <Col span={12}>
              <Checkbox value={item.VALUE}>{item.LABEL}</Checkbox>
            </Col>
          )
        })
        let html = <Row>{col}</Row>
        this.setState({ checkGroup: html })
      }
    })
  }

  loadLstdHjsqdx = () => {
    NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList', 'get', 'sign=dmk_YXXT_LSTD_HJSQDXLX').then(res => {
      if (res) {
        let col = []
        res.data.map(item => {
          if (item.VALUE == '7') {
            col.push(
              <Col span={12}>
                <Tooltip placement="bottom" title="主要包括扶贫部门监测的边缘户；享受国家定期抚恤补助的优抚对象子女；家庭经济困难残疾人子女；因家庭遭受重大自然灾害（疫情）造成重大损失的困难家庭子女；因家庭成员遭受重大疾病或意外伤害的困难家庭子女；因家庭发生重大变故等影响其子女入学就读的困难家庭子女；其他需要资助的低收入家庭经济困难学生。">
                  <Checkbox value={item.VALUE}>{item.LABEL}</Checkbox>
                </Tooltip>
              </Col>
            )
          } else {
            col.push(
              <Col span={12}>
                <Checkbox value={item.VALUE}>{item.LABEL}</Checkbox>
              </Col>
            )
          }
        })
        let html = <Row>{col}</Row>
        this.setState({ HjsqdxGroup: html })
      }
    })
  }

  // 转化base64
  getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  // 点击文件链接或预览图标时的回调
  handlePreview = file => {
    const isImage = /^image\/.*$/i.test(file.type)
    if (isImage) {
      if (file) {
        this.getBase64(file, imageUrl => {
          this.setState({
            previewImage: imageUrl,
            previewVisible: true
          })
        })
      } else {
        this.setState({
          previewImage: file.url || file.thumbUrl,
          previewVisible: true
        })
      }
    } else if (file && file['response'] && file['response']['data']) {
      //其他情况
      let isImage = true
      let fjmc = file['response']['data']['fjmc']
      let ssbh = file['response']['data']['ssbh']
      if (!this.setJudge(fjmc, this.state.judgeImgList)) {
        isImage = false
      }

      if (isImage) {
        this.showPreview(`anonymity/docrepo/download/file?attachmentId=${ssbh}`)
      } else {
        window.open(`anonymity/docrepo/download/file?attachmentId=${ssbh}`)
      }
    }
  }

  showPreview = url => {
    this.setState({
      previewImage: url,
      previewVisible: true
    })
  }

  // 关闭图片弹窗
  handleCancel = () => this.setState({ previewVisible: false })

  // 获取缴费环节状态
  getJfhjData = () => {
    let xsid = getLoginUser().userId ? getLoginUser().userId : ''
    let param = {
      xsid: xsid,
      hjdm: 'GD008_1'
    }
    NHFetch('/zzbdCommon/getBlztByHjdm', 'GET', param).then(res => {
      if (res) {
        if (res.data == '1') {
          this.setState({
            jfhjzt: true
          })
        } else {
          this.setState({
            jfhjzt: false
          })
        }
      }
    })
  }

  //提交
  handleOk = e => {
    const { sqValue, dkfsValue, setChannelState, data } = this.props
    if (!sqValue) {
      message.warning('请选择是否办理')
    } else if (sqValue === '1' && !dkfsValue) {
      message.warning('请选择绿色通道类型')
    } else {
      confirm({
        title: '提示',
        content: '是否确定提交，提交后不可修改！',
        okText: '确定',
        cancelText: '取消',
        okType: 'danger',
        iconType: 'info-circle',
        onOk: () => {
          this.handleSubmit(e)
        }
      })
    }
  }

  // 确认提交
  handleSubmit = e => {
    const { sqValue, dkfsValue, setChannelState, data } = this.props

    let xsid = getLoginUser().userId ? getLoginUser().userId : ''
    if (!xsid) {
      message.error('登录已过期，请重新登录！')
      return
    }
    let modalFlag = ''
    this.setState({ saveLoading: true }, () => setChannelState({ loading: this.state.saveLoading }))
    let zzbdhjid = this.getQueryString('pkid')
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        this.setState({ saveLoading: false }, () => setChannelState({ loading: this.state.saveLoading }))
        return false
      }

      let fjlist = []
      if (sqValue == 1) {
        const fj = values['fj']
        let lenList = []
        if (fj) {
          if (fj['fileList'] && fj['fileList'].length > 0) {
            fj['fileList'].map((item, index) => {
              if (index < 5) {
                lenList.push(item)
              }
            })
          }
        }
        let isEmpty = false
        if (dkfsValue == '1' || dkfsValue == '5' || dkfsValue == 'whrj_1' || dkfsValue == 'whrj_4' || dkfsValue == 'stye_1' || dkfsValue == 'stye_3' || dkfsValue == 'jxsf_3' || dkfsValue == 'qzy_4' || dkfsValue == 'jxyy_zxdk' || dkfsValue == 'jxyy_tyrx' || dkfsValue == 'jxyy_hjsq' || dkfsValue == 'fjsw_1') {
          fj['fileList'] = lenList
          console.log(values, lenList)
          if (fj) {
            if (fj['fileList'] && fj['fileList'].length > 0) {
              isEmpty = false
            } else {
              isEmpty = true
            }
          } else {
            isEmpty = true
          }
          if (isEmpty) {
            this.props.form.setFields({
              fj: {
                value: undefined,
                errors: [new Error('请上传材料证明')]
              }
            })
            this.setState({ saveLoading: false }, () => setChannelState({ loading: this.state.saveLoading }))
            return false
          }
        }
        if (fj != undefined) {
          let size = fj['fileList'].length
          let file = fj['fileList'].filter(file => {
            if (file.status === 'error') {
              return false
            }
            return true
          })

          if (file.length != size) {
            this.props.form.setFields({
              fj: {
                value: fj,
                errors: [new Error('材料上传出错，请重新上传.')]
              }
            })
            this.setState({ saveLoading: false }, () => setChannelState({ loading: this.state.saveLoading }))
            return false
          }

          let fileList = fj['fileList']

          for (let i = 0; i < fileList.length; i++) {
            let fjid = fileList[i].response.data.ssbh
            let fjmc = fileList[i].name
            fjlist.push({
              fjid: fjid,
              fjmc: fjmc
            })
          }
        }
      }

      let sqsmFlag = false
      let msg = ''
      let blyy = values['blyy']
      let sqsm = values['sqsm']
      let dkyh = values['dkyh']
      let hzjym = values['hzjym']
      let dkhtbh = values['dkhtbh']
      let dklx = dkfsValue
      let pkid = data.pkid
      let x_djlqzy = values['x_djlqzy']
      let dkyhmc = values['dkyhmc']
      let x_jzdh = values['x_jzdh']
      let sfsndk = values['sfsndk']
      let x_hjjzrq = this.state.x_hjjzrq == '' || this.state.x_hjjzrq == null ? values['x_hjjzrq'] : this.state.x_hjjzrq
      let x_lqsj = this.state.x_lqsj == '' || this.state.x_lqsj == null ? values['x_lqsj'] && values['x_lqsj']._i : this.state.x_lqsj
      let x_tysj = this.state.x_tysj == '' || this.state.x_tysj == null ? values['x_tysj'] && values['x_tysj']._i : this.state.x_tysj
      let x_fxsj = this.state.x_fxsj == '' || this.state.x_fxsj == null ? values['x_fxsj'] && values['x_fxsj']._i : this.state.x_fxsj
      let sfbllstd = sqValue
      let x_hjsqdxlx = values['x_hjsqdxlx']
      if (x_hjsqdxlx) {
        x_hjsqdxlx = x_hjsqdxlx.toString()
      }
      if (sqsmFlag) {
        message.error(msg)
        return
      }
      let params = {}
      let blje = values['blje']
      if (sqValue == 1) {
        if (fjlist.length > 0) {
          params = {
            sfbllstd: sfbllstd,
            fj: fjlist,
            blje: blje,
            blyy: blyy,
            sqsm: sqsm,
            hzjym: hzjym,
            dkyh: dkyh,
            xsid: xsid,
            zzbdhjid: zzbdhjid,
            pkid: pkid,
            dkhtbh: dkhtbh,
            dklx: dklx,
            x_djlqzy: x_djlqzy
          }
        } else {
          params = {
            sfbllstd: sfbllstd,
            blje: blje,
            blyy: blyy,
            sqsm: sqsm,
            hzjym: hzjym,
            dkyh: dkyh,
            xsid: xsid,
            zzbdhjid: zzbdhjid,
            pkid: pkid,
            dkhtbh: dkhtbh,
            dklx: dklx,
            x_djlqzy: x_djlqzy
          }
        }
      } else {
        params = {
          sfbllstd: sfbllstd,
          xsid: xsid,
          zzbdhjid: zzbdhjid,
          pkid: pkid
        }
      }
      if (dkfsValue == 'whrj_4') {
        params['x_hjjzrq'] = x_hjjzrq
        params['x_hjsqdxlx'] = x_hjsqdxlx
      }

      if (dkfsValue == 'qzy_4') {
        params['x_hjjzrq'] = x_hjjzrq
      }
      if (dkfsValue == 'jxyy_tyrx') {
        params['x_lqsj'] = x_lqsj
        params['x_tysj'] = x_tysj
        params['x_fxsj'] = x_fxsj
      }
      if (dkfsValue == 'jxyy_zxdk') {
        params['dkyhmc'] = dkyhmc
        params['sfsndk'] = sfsndk
      }
      if (dkfsValue == 'jxyy_hjsq') {
        params['x_jzdh'] = x_jzdh
      }

      console.log('params', params)
      if (!pkid) {
        NHFetch('/lstdFb/insert', 'POST', params)
          .then(res => {
            if (res) {
              message.success('绿色通道申请保存成功。')
              this.props.loadLstdInfo()
              this.props.reloadMenu()
            }
            this.setState({ saveLoading: false }, () => setChannelState({ edit: false, loading: this.state.saveLoading }))
          })
          .catch(res => {
            this.setState({ saveLoading: false }, () => setChannelState({ loading: this.state.saveLoading }))
            return res
          })
      } else {
        NHFetch('/lstdFb/update', 'POST', params)
          .then(res => {
            if (res && res.data && res.data > 0) {
              message.success('绿色通道申请保存成功。')
              this.props.loadLstdInfo()
              this.props.reloadMenu()
            } else {
              if (!res.message.startsWith('businessLogicWarm[')) {
                message.warning('绿色通道申请保存失败，请重试。')
              }
              // message.warning("绿色通道申请保存失败，请重试。");
            }
            this.setState({ saveLoading: false }, () => setChannelState({ edit: false, loading: this.state.saveLoading }))
          })
          .catch(res => {
            this.setState({ saveLoading: false }, () => setChannelState({ edit: false, loading: this.state.saveLoading }))
            return res
          })
      }
    })
  }

  getQueryString = name => {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
  }

  // 初始化附件
  initFiles = props => {

    const { data: lstdData } = props
    let fileList = []
    if (lstdData && lstdData['fjmap']) {
      lstdData['fjmap'].map((elem, index) => {
        //格式化对于附件取值
        let file = {
          uid: elem.fjid,
          name: elem.fjmc,
          response: {
            data: {
              ssbh: elem.fjid,
              fjmc: elem.fjmc
            }
          },
          status: 'done'
        }
        fileList.push(file)
      })
    }
    this.setState({ fileList }, () => {
      this.props.form.setFieldsValue({ fj: { fileList } })
    })
  }

  handleChange = ({ fileList }) => {
    let count = 0
    fileList.map(elem => {
      if (elem && elem['status'] && elem['status'] === 'uploading') {
        this.setState({ uploading: true })
        return
      } else if (elem && elem['status']) {
        count++
      }
    })
    if (count > 0) {
      this.setState({ uploading: false })
    }
  }

  //判断文件类型是否正常  name：文件名称  map：类型范围数组  true正常
  setJudge = (name, map) => {
    let flag = false
    map.map(item => {
      if (new RegExp(`${item}$`).test(name)) {
        flag = true
      }
    })
    return flag
  }

  beforeUpload = file => {
    const { fileList } = this.state
    if (fileList.length >= 5) {
      message.error('最多只能上传5个文件')
      console.log()
      return false
    }
    if (!(this.setJudge(file.name, this.state.judgeImgList) || this.setJudge(file.name, this.state.judgeFileList))) {
      message.error('不能上传该格式文件')
      return false
    } else {
      return true
    }
  }

  remove = ssbh => {
    let url = `api/docrepo/attachment/sourceId/delete?souerceId=${ssbh}`
    NHFetch(url, 'POST').then(res => {
      if (res.data && res.data > 0) {
        message.success('已删除材料.')
      }
    })
  }

  //选择缓交截止日期回调
  timeData = (moment, timeString) => {
    this.setState({
      x_hjjzrq: timeString
    })
  }
  timeLqsjData = (moment, timeString) => {
    this.setState({
      x_lqsj: timeString
    })
  }
  timeTysjData = (moment, timeString) => {
    this.setState({
      x_tysj: timeString
    })
  }
  timeFxsjData = (moment, timeString) => {
    this.setState({
      x_fxsj: timeString
    })
  }

  disabledStartDate = startValue => {
    const endValue = this.state.hjjzrqxz
    if (!startValue || !endValue) {
      return false
    }
    return startValue.valueOf() > moment(endValue, 'YYYY-MM-DD').add(1, 'd').valueOf()
  }

  loadLstdSnw = () => {
    NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList', 'get', 'sign=dmk_XTGL_SFM').then(res => {
      if (res && res.code == 200) {
        let snwList = res.data
        this.setState({
          snwList: snwList
        })
      }
    })
  }

  snwOnChange = e => {
    this.setState({
      snwValue: e.target.value
    })
  }

  render() {
    const { form } = this.props
    const { getFieldDecorator } = this.props.form
    const { nextUrl, search, sqValue, data, BlztList, fjsmxx, fjsmzt, edit, dkfsValue } = this.props
    const { previewImage, previewVisible, snwList, saveLoading } = this.state
    const props = {
      name: 'file',
      action: 'zuul/docrepo/upload',
      maxCount: 5,
      data: { uuid: createUuid(), type: '1' },

      onRemove: file => {
        this.setState(
          ({ fileList }) => {
            const index = fileList.indexOf(file)
            const newFileList = fileList.slice()
            newFileList.splice(index, 1)
            this.props.form.setFieldsValue({ fj: { fileList: newFileList } })
            return {
              fileList: newFileList
            }
            console.log('fileList', fileList)
          },
          () => {
            if (file.response && file.response.data && file.response.data.ssbh) {
              this.remove(file.response.data.ssbh)
            }
          }
        )
      },
      beforeUpload: file => {
        if (!this.beforeUpload(file)) {
          return false
        }
        if (this.state.fileList.length < 5) {
          this.setState(({ fileList }) => ({
            fileList: [...fileList, file]
          }))
        }
        return true
      },
      onChange: file => {
        this.handleChange(file)
      },
      onPreview: this.handlePreview,
      fileList: this.state.fileList
    }

    return (
      <React.Fragment>
        {edit ? (
          <Form>
            <div>
              {sqValue == '1' && (
                <div style={{ marginLeft: '30px' }}>
                  {(dkfsValue == 'whrj_1' || dkfsValue == 'jxsf_3' || dkfsValue == 'stye_3' || dkfsValue == 'stye_1' || dkfsValue == 'qzy_4' || dkfsValue == 'jxyy_zxdk' || dkfsValue == 'jxyy_hjsq') && (
                    <NHFormItem id={'blyy'} required={true} initialValue={data['blyydm']} form={form} formItemLayout={{ labelCol: { span: 4 }, wrapperCol: { span: 16 } }} label="办理原因">
                      <CheckboxGroup style={{ width: '100%' }}>{this.state.checkGroup}</CheckboxGroup>
                    </NHFormItem>
                  )}
                </div>
              )}

              {sqValue == '1' && (
                <div style={{ marginLeft: '30px' }}>
                  {dkfsValue == 'whrj_4' && (
                    <NHFormItem id={'x_hjsqdxlx'} required={true} initialValue={data['x_hjsqdxlx']} form={form} formItemLayout={{ labelCol: { span: 4 }, wrapperCol: { span: 16 } }} label="缓交申请对象">
                      <CheckboxGroup style={{ width: '100%' }}>{this.state.HjsqdxGroup}</CheckboxGroup>
                    </NHFormItem>
                  )}
                </div>
              )}

              {sqValue == '1' && (
                <div className="form_item" style={{ marginLeft: '7%' }}>
                  {/*<span style={{color: '#ff0000',fontSize:"12.7px",paddingLeft:'25%'}} >湖南省未设立高校助学贷款，只接受生源地助学贷款</span>*/}
                  {(dkfsValue == '1' || dkfsValue == 'whrj_1' || dkfsValue == 'stye_3' || dkfsValue == 'stye_1' || dkfsValue == 'jxyy_zxdk') && (
                    <NHFormItem id={'blje'} required={true} initialValue={data['blje']} form={form} formItemLayout={formItemLayout} label="贷款金额">
                      <InputNumber formatter={value => `${value} 元`} style={{ width: '100%' }} min={0} max={9999999} precision="0" placeholder="请填写金额（元）" />
                    </NHFormItem>
                  )}
                  {(dkfsValue == '4' || dkfsValue == 'whrj_4' || dkfsValue == 'qzy_4' || dkfsValue == 'jxyy_hjsq' || dkfsValue == 'fjsw_1') && (
                    <NHFormItem id={'blje'} required={true} initialValue={data['blje']} form={form} formItemLayout={formItemLayout} label="缓交金额">
                      <InputNumber formatter={value => `${value} 元`} style={{ width: '100%' }} min={0} max={9999999} precision="0" placeholder="缓交金额" />
                    </NHFormItem>
                  )}
                  {dkfsValue == '5' ||
                    (dkfsValue == 'jxyy_tyrx' && (
                      <NHFormItem id={'blje'} required={true} initialValue={data['blje']} form={form} formItemLayout={formItemLayout} label="减免金额">
                        <InputNumber formatter={value => `${value} 元`} style={{ width: '100%' }} min={0} max={9999999} precision="0" placeholder="减免金额" />
                      </NHFormItem>
                    ))}
                  {(dkfsValue == 'whrj_4' || dkfsValue == 'qzy_4') && (
                    <NHFormItem id={'x_hjjzrq'} required={true} form={form} formItemLayout={formItemLayout} label="缓交截止日期" initialValue={data.x_hjjzrq ? moment(data.x_hjjzrq, 'YYYY/MM/DD') : null}>
                      <DatePicker onChange={this.timeData} placeholder={'选择日期...'} format={'YYYY-MM-DD'} disabledDate={this.disabledStartDate} />
                    </NHFormItem>
                  )}

                  {dkfsValue == 'jxyy_tyrx' && (
                    <NHFormItem id={'x_lqsj'} required={true} form={form} formItemLayout={formItemLayout} label="录取时间" initialValue={data.x_lqsj ? moment(data.x_lqsj, 'YYYY/MM/DD') : null}>
                      <DatePicker onChange={this.timeLqsjData} placeholder={'选择日期...'} format={'YYYY-MM-DD'} />
                    </NHFormItem>
                  )}
                  {dkfsValue == 'jxyy_tyrx' && (
                    <NHFormItem id={'x_tysj'} required={true} form={form} formItemLayout={formItemLayout} label="退役时间" initialValue={data.x_tysj ? moment(data.x_tysj, 'YYYY/MM/DD') : null}>
                      <DatePicker onChange={this.timeTysjData} placeholder={'选择日期...'} format={'YYYY-MM-DD'} />
                    </NHFormItem>
                  )}
                  {dkfsValue == 'jxyy_tyrx' && (
                    <NHFormItem id={'x_fxsj'} form={form} formItemLayout={formItemLayout} label="复学时间" initialValue={data.x_fxsj ? moment(data.x_fxsj, 'YYYY/MM/DD') : null}>
                      <DatePicker onChange={this.timeFxsjData} placeholder={'选择日期...'} format={'YYYY-MM-DD'} />
                    </NHFormItem>
                  )}

                  {dkfsValue == 'jxyy_zxdk' && (
                    <NHFormItem required={true} id={'sfsndk'} initialValue={data['sfsndk']} form={form} formItemLayout={formItemLayoutJx} label="是否江西省内贷款">
                      <Radio.Group onChange={this.snwOnChange} value={this.state.snwValue} style={{ display: 'flex', margin: '10px' }}>
                        {snwList.map((item, index) => {
                          return <Radio value={item.VALUE}>{item.LABEL}</Radio>
                        })}
                      </Radio.Group>
                    </NHFormItem>
                  )}
                  {dkfsValue == 'jxyy_zxdk' && (
                    <NHFormItem id={'dkyhmc'} required={true} initialValue={data['dkyhmc']} form={form} formItemLayout={formItemLayout} label="贷款银行名称">
                      {this.state.snwValue === '1' ? <NHSelect sign={'dmk_yxwz_dkyh'} onChange={v => { }} /> : <Input placeholder="请填写贷款银行名称" />}
                    </NHFormItem>
                  )}
                  {dkfsValue == 'jxyy_hjsq' && (
                    <NHFormItem id={'x_jzdh'} required={false} initialValue={data['x_jzdh']} form={form} formItemLayout={formItemLayout} label="家长电话">
                      <Input placeholder="请填写家长电话" />
                    </NHFormItem>
                  )}

                  {(dkfsValue == '1' || dkfsValue == 'whrj_1' || dkfsValue == 'stye_1') && (
                    <NHFormItem id={'dkyh'} required={true} initialValue={data['dkyh']} form={form} formItemLayout={formItemLayout} label="贷款银行">
                      <NHSelect sign={'dmk_yxwz_dkyh'} onChange={v => { }} />
                    </NHFormItem>
                  )}
                  {(dkfsValue == '1' || dkfsValue == 'whrj_1' || dkfsValue == 'stye_1' || dkfsValue == 'jxyy_zxdk') && (
                    <NHFormItem required={dkfsValue == 'jxyy_zxdk' ? false : true} id={'hzjym'} initialValue={data['hzjym']} form={form} formItemLayout={formItemLayout} label="回执校验码">
                      <Input maxLength={6} placeholder="6位数回执校验码" />
                    </NHFormItem>
                  )}
                  {(dkfsValue == '1' || dkfsValue == 'stye_1') && (
                    <NHFormItem required={true} id={'dkhtbh'} initialValue={data['dkhtbh']} form={form} formItemLayout={formItemLayout} label="贷款合同编号">
                      <Input placeholder="贷款合同编号" />
                    </NHFormItem>
                  )}
                  {dkfsValue == '5' && (
                    <NHFormItem required={true} id={'x_djlqzy'} initialValue={data['x_djlqzy']} form={form} formItemLayout={formItemLayout} label="登记录取专业">
                      <Input placeholder="登记录取专业" />
                    </NHFormItem>
                  )}
                  {(dkfsValue == '1'|| dkfsValue == '4' || dkfsValue == 'whrj_1' || dkfsValue == 'jxsf_3' || dkfsValue == 'stye_3' || dkfsValue == 'stye_1' || dkfsValue == 'jxyy_zxdk' || dkfsValue == 'jxyy_hjsq' || dkfsValue == 'fjsw_1') && (
                    <NHFormItem id={'sqsm'} required={dkfsValue == '4' || dkfsValue == 'fjsw_1' ? true : false} initialValue={data['sqsm']} form={form} formItemLayout={formItemLayout} label="申请理由">
                      <TextArea maxLength={50} placeholder="请填写申请理由" />
                    </NHFormItem>
                  )}
                </div>
              )}
            </div>
            {sqValue == '1' && dkfsValue != '' && dkfsValue != undefined && <TitleTop title="申请材料" extra={false} />}
            {sqValue == '1' && dkfsValue != '' && dkfsValue != undefined && (
              <div className="p-sw">
                <div className="form_item" style={{ marginLeft: '7%' }}>
                  <FormItem labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} label={<span>请上传材料证明&nbsp;</span>}>
                    {getFieldDecorator('fj', {
                      rules: [
                        {
                          required: dkfsValue == '1' || dkfsValue == '5' || dkfsValue == 'whrj_1' || dkfsValue == 'whrj_4' || dkfsValue == 'stye_1' || dkfsValue == 'stye_3' || dkfsValue == 'jxsf_3' || dkfsValue == 'qzy_4' || dkfsValue == 'jxyy_zxdk' || dkfsValue == 'jxyy_tyrx' || dkfsValue == 'jxyy_hjsq' || dkfsValue == 'fjsw_1' ? true : false,
                          message: '请上传材料证明'
                        }
                      ]
                    })(
                      <Dragger {...props}>
                        <p className="ant-upload-drag-icon">
                          <Icon type="inbox" />
                        </p>
                        <Spin spinning={this.state.uploading} />
                        <p className="ant-upload-text font-14">点击此处或拖拽文件到此</p>
                      </Dragger>
                      )}
                  </FormItem>
                  <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                  </Modal>
                  {fjsmzt ? (
                    <div style={{ color: '#F00', wordBreak: 'break-all', whiteSpace: 'normal', lineHeight: '15px', width: '95%' }} justify="left" align="middle">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{fjsmxx}
                    </div>
                  ) : null}
                </div>
              </div>
            )}
            <div className="text-center mt-50">
              <Button type="primary" htmlType="submit" onClick={this.handleOk} loading={saveLoading}>
                保存
              </Button>
              <NextButton nextUrl={nextUrl} search={search} />
            </div>
          </Form>
        ) : (
            <View reloadMenu={this.props.reloadMenu} nextUrl={nextUrl} loadLstdInfo={() => this.props.loadLstdInfo('edit')} search={search} showPreview={this.showPreview} setJudge={this.setJudge} data={data || {}} judgeImgList={this.state.judgeImgList} jfhjzt={this.state.jfhjzt} BlztList={BlztList} />
          )}
      </React.Fragment>
    )
  }
}
