import React, { Component } from 'react'
import Layout from "@pages/SelfHelp/Bdd/pages/common";
import Main from '@pages/SelfHelp/Bdd/pages/scjtzyxy/print'
import { getLoginUser, createUuid } from "@utils/NHCore";
import NHFetch from "@utils/NHFetch";
import './index.css';
import PropTypes from 'prop-types'

const schoolCode = `scjtzyxy`;
/**
 * @author qianzhiwei
 * @date 2020/09/27
 * @description 报到单
 */
export class OtherIndex extends Component {
    constructor(props) {
        super(props)

        this.state = {
            xsxx: [],//学生信息
            schoolName: '',//学校名称
            year: '',//当前年度
            account: '',//默认账号
            bddbz: '',
            nr: [
                {
                    key: 1,
                    row_id: 1,
                    nr: '交验录取通知书'
                },
                {
                    key: 2,
                    row_id: 2,
                    nr: '领取一卡通'
                },
                {
                    key: 3,
                    row_id: 3,
                    nr: '自愿购买保险'
                },
                {
                    key: 4,
                    row_id: 4,
                    nr: '自带床上用品检验'
                },
                {
                    key: 5,
                    row_id: 5,
                    nr: '领取宿舍钥匙'
                },
                {
                    key: 6,
                    row_id: 6,
                    nr: '参加体检'
                },
            ],
            extraData: {}    //额外参数
        }
    }

    componentDidMount() {
        let userId = getLoginUser() ? getLoginUser().userId : undefined;
 
        if (userId) {
            this.getStudentPhotoBase64(userId, (data => {
                if (!data) return;
                this.setState({ 
                    xsid: userId,
                    imageUrl: `data:image/jpeg;base64,${data}`
                })
            })) 
        } 
        this.getPrintInfo();
        this.getCurrentYear();
        this.getCurrentSchool();
        this.getAccount();
        this.getBddBz();
    }

     // 获取学生头像
     getStudentPhotoBase64 =  (userId, callback) => {
        NHFetch('api/zhxg-yxwz/openapi/student/getStudentPhotoBase64', 'get', { xsid: userId, type: 10 })
        .then(res => {
            if (res && res.code == 200) {
                callback(res.data);
            }
        }); 
    }

    //获取当前学校
    getCurrentSchool = () => {
        let params = {};
        return NHFetch("/getCurrentXxmc", "GET", params)
            .then(resData => {
                if (resData.data != null) {
                    this.setState({ schoolName: resData.data });
                }
            })
    }

    //获取当前年度
    getCurrentYear = () => {
        let params = {};
        return NHFetch("/zdybl/getCurrentYear", "GET", params)
            .then(resData => {
                if (resData.data != null) {
                    this.setState({ year: resData.data });
                }
            })
    }

    //获取当前年度
    getAccount = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (xsid == '') {

        }
        return NHFetch("/zdybl/getAccount", "GET", { xsid: xsid })
            .then(resData => {
                if (resData.data != null) {
                    this.setState({ account: resData.data });
                }
            })
    }

    //获取打印信息
    getPrintInfo = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (xsid == '') {

        }
        NHFetch("/zdybl/getInfoForPrint", 'GET', { xsid: xsid })
            .then(res => {
                if (res && res.data) {
                    this.setState({ xsxx: res.data })
                }
            });
    }

    //获取报到单备注
    getBddBz = () => {
        NHFetch('/zzbdCommon/getCssz', 'GET', { csbz: 'YXXT_BDD_BZ' }).then(res => {
            if (res && res.data) {
                this.setState({ bddbz: res.data });
            }
        })
    }

    render() {
        let { xsxx, schoolName, year, account, bddbz, nr,imageUrl } = this.state;
        let headimg = (<img styleName="jbxx_icon" className="_bg" src={imageUrl}
            onError={e => { e.target.src = stuDefault }}
        />);
        let initFormDate = { xsxx: xsxx, schoolName: schoolName, year: year, account: account, nr, bddbz: bddbz, headimg: headimg };
        return (
            <div>
                <Layout schoolCode={schoolCode} initFormDate={initFormDate}>
                    {/* <Main xsxx={xsxx} schoolName={schoolName} year={year} account={account} bddbz={bddbz} headimg={headimg} nr={nr}></Main> */}
                    <Main initFormDate={initFormDate} ></Main>
                </Layout>
            </div>
        )
    }

    static propTypes = {

    }
}

export default OtherIndex
