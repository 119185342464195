import React from 'react';
import {Link} from 'react-router-dom';
import {Flex} from '@components';
import {Form, Input, Checkbox, Row, Col, Upload, Button,message} from 'antd';
import TitleTop from '@pages/Assembly/titleTop';
import NHFetch from "@utils/NHFetch";
import NextButton from '@pages/SelfHelp/common/nextIndex'
const {TextArea} = Input;
const basicUrl = "/zzbd";

const shztList = {
    '-1':'审核不通过',
    '0':'未审核',
    '1':'审核通过',
    '2':'已办理',
    '9':'驳回'
}
export default class View extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dkyhmc:'',
            nextUrl:this.props.nextUrl,
            search:this.props.search,
        };
    }

    componentDidMount() {
        this.getDmkMc("YXWZ_DKYH");
    }

    //获取下拉列表value对应的名称
    getDmkMc = (dmbz) => {
        const {data} = this.props;
        let dkyhmc = this.state.dkyhmc;
        NHFetch(basicUrl + "/getDmkMc", "GET", {dmbz, dmbz})
            .then(res => {
                if (res) {
                    if (res.data) {
                        if(dmbz==="YXWZ_DKYH"){
                            res.data.map(initItem => {
                                if(initItem.DM === data.dkyh){
                                    dkyhmc=initItem.MC;
                                }
                            });
                            this.setState({dkyhmc: dkyhmc});
                        }
                    }
                }
            });
    }

    getQueryString =(name)=> {
        return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[, ''])[1].replace(/\+/g, '%20')) || null
    }

    cancel=(lstdid,shzt)=>{

        if(shzt!=='0'){
            message.warning('该申请已经审核，不能取消')
            return ;
        }

        let pkid = this.getQueryString("pkid");

        let params = {
            zzbdhjid:pkid,
            pkid:lstdid
        }

        NHFetch("/lstd/deleteByParams", "POST", params)
            .then(res => {
                if (res && res.data && res.data>0) {
                    message.success("取消成功");
                    this.props.loadLstdInfo();
                    this.props.reloadMenu();
                }else{
                    message.warning("取消异常");
                }
            });
    }

    // 点击文件链接或预览图标时的回调
    handlePreview = (fjmc, fjid) => {
        let isImage = true;
        if (
            !this.props.setJudge(fjmc,this.props.judgeImgList)
        ) {
            isImage = false;
        }

        if (isImage) {
            this.props.showPreview(`anonymity/docrepo/download/file?attachmentId=${fjid}`);
        } else {
            window.open(`anonymity/docrepo/download/file?attachmentId=${fjid}`);
        }
    };


    render() {
        const {data} = this.props;
        const {dkyhmc} = this.state;
        return (
            <div>
                <TitleTop title="申请信息" extra={false}/>
                <div className="p-sw">

                    <div className="form_item" style={{width: '100%'}}>
                        <Row gutter={24}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>申请理由&nbsp;: </span></Col>
                            <Col span={20}><span>{data['sqsm']}</span></Col>
                        </Row>
                        <br/>
                        <Row gutter={24}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>贷款金额&nbsp;: </span></Col>
                            <Col span={20}><span>{data['blje']}元</span></Col>
                        </Row>
                        <br/>
                        <Row gutter={24}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>贷款银行&nbsp;: </span></Col>
                            <Col span={20}><span>{dkyhmc}</span></Col>
                        </Row>
                        <br/>
                        <Row gutter={24}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>6位数回执校验码&nbsp;: </span></Col>
                            <Col span={20}><span>{data['hzjym']}</span></Col>
                        </Row>
                        <br/>
                        <Row gutter={24}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>贷款合同编号&nbsp;: </span></Col>
                            <Col span={20}><span>{data['dkhtbh']}</span></Col>
                        </Row>
                    </div>
                </div>
                <TitleTop title="申请材料" extra={false}/>
                <div className="p-sw">
                    <div className="form_item" style={{width: '100%' }}>
                        <Row gutter={24}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>材料证明&nbsp;: </span></Col>
                            <Col span={20}>
                                {data['fj'].map((elem, index) => {
                                    return (
                                        <div style={{marginBottom: '20px'}}>
                                            <a onClick={() => {
                                                this.handlePreview(elem['fjmc'], elem['fjid']);
                                            }}>{elem['fjmc']}</a>
                                        </div>
                                    )
                                })
                                }
                            </Col>
                        </Row>
                    </div>
                </div>
                <TitleTop title="申请审核" extra={false}/>
                <div className="p-sw">
                    <div className="form_item" style={{width: '100%', marginBottom: '24px'}}>
                        <Row gutter={24}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>审核意见&nbsp;: </span></Col>
                            <Col span={20}><span>{data['shyj']}</span></Col>
                        </Row>
                    </div>
                    <br/>
                    <div className="form_item" style={{width: '100%', marginBottom: '24px'}}>
                        <Row gutter={24}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>审核状态&nbsp;: </span></Col>
                            <Col span={20}><span style={{color: '#0066FF'}}>{shztList[data['shzt']]}</span></Col>
                        </Row>
                    </div>
                </div>
                <div className="text-center mt-50">
                    <Button type="primary" onClick={()=>{this.cancel(data['pkid'],data['shzt'])}}>
                        取消
                    </Button>
                    <NextButton nextUrl={this.state.nextUrl}  search={this.state.search}/>
                </div>
            </div>
        );
    }
}
