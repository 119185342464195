import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'antd';
import createHashHistory from 'history/createHashHistory';
const hashHistory = createHashHistory();

export default class _Breadcrumb extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        list: PropTypes.array
    };

    render() {
        const { className, list, noLine } = this.props;

        return (
            <div
                className={classNames('Breadcrumb', className, {
                    Breadcrumb_noLine: noLine
                })}
            >
                <div className="Breadcrumb_l">当前位置：</div>
                <Breadcrumb separator=">">
                    {list &&
                        list[0] &&
                        list.map((elem, index) => {
                            if (elem.url) {
                                let param = '';
                                let value = elem.value;
                                if(value){
                                    for(let key of Object.keys(value)){
                                        param +='&'+key+'='+value[key];
                                    }
                                }
                                return (
                                    <Breadcrumb.Item key={index}>
                                        <a onClick={()=>{
                                            hashHistory.push({
                                                pathname:elem.url,
                                                search:param,
                                            });
                                        }}>{elem.name}</a>
                                    </Breadcrumb.Item>
                                );
                            } else {
                                return (
                                    <Breadcrumb.Item key={index}>
                                        {elem.name}
                                    </Breadcrumb.Item>
                                );
                            }
                        })}
                </Breadcrumb>
            </div>
        );
    }
}
