import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Flex } from '@components';
import {
    Form, Input, Checkbox, Row, Col, Button, Modal,
    Upload, Icon, message, Spin, InputNumber, Radio, DatePicker, Tooltip
} from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import { createUuid, getLoginUser } from '@utils/NHCore';
import NHFetch from "@utils/NHFetch";
import View from "@pages/SelfHelp/channelBjwl/view";
const Dragger = Upload.Dragger;
import createHistory from 'history/createHashHistory';
import CommonTip from "@pages/SelfHelp/common";
import NHSelect from "@components/common/NHSelect";
import NextButton from '@pages/SelfHelp/common/nextIndex'
import { mkbz } from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";
import NHFormItem from "@components/common/NHFormItem";
import { NHConfirm } from "@components/common/NHModal";
import moment from 'moment';
const history = createHistory();
//确认对话框
const confirm = Modal.confirm;
const { TextArea } = Input;
const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 }
};

@Form.create()
export default class Channel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mkbz: 'GD005_10',
            hjmc: '',
            uploading: false,
            loading: false,
            previewVisible: false,
            fileList: [],
            checkGroup: [],//绿色通道办理原因
            HjsqdxGroup: [],//缓交申请对象
            data: {},//绿色通道申请信息
            nextUrl: '',
            search: '',
            operable: true,//是否显示环节
            nonOperationalData: {},//不可操作的数据
            sqlyFlag: false, //申请理由flag
            jymFlag: false,  //6位数回执校验码flag
            judgeImgList: ['.png', '.PNG', '.jpg', '.JPG', '.bpm', '.BPM', '.jpeg', '.JPEG'],   //可以导入的图片格式
            judgeFileList: ['.pdf', '.PDF', '.doc', '.DOC', '.zip', '.ZIP', '.txt', '.TXT', '.xls', '.XLS', '.rar', '.RAR'],  //可以导入的非图片格式
            dkfsList: [],
            dkfsValue: "",//申请类型
            sqValue: "",//是否申请绿色通道
            lcidData: {},
            edit: false,
            x_hjjzrq: "",
            XsxxlcidData: [],
            jfhjzt: false,//缴费环节状态
            BlztList: [],//办理状态
            saveLoading: false
        };
    }

    componentDidMount() {
        this.getHjsjCsbz();
        this.getMojorInfo();
        this.loadLstdInfo();
        this.getJfhjData();
        this.queryQztjAndYlhj((operable) => {
            if (operable) {
                this.loadLstdBlyy();
                this.loadLstdHjsqdx();
                this.updateZzbdBlzt();
            }
        });
        this.getSfsqLstd();
        this.loadLstddkfs();
        this.getLcidInfo();
    }

    getHjsjCsbz = () => {
        NHFetch('/zzbdCommon/getCssz', "GET", { csbz: 'YXXT_LSTD_HJJZRQ' })
            .then(res => {
                if (res) {
                    if (res.data && res.code === 200) {
                        this.setState({ hjjzrqxz: res.data });
                    }
                }
            })
    }

    // 获取绿色通道是否办理选项
    getSfsqLstd = () => {
        NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList', 'get', 'sign=dmk_YXXT_LSTD_BLZT').then(res => {
            if (res) {
                if (res.data) {
                    this.setState({
                        BlztList: res.data
                    })
                }
            }
        })
    }

    // 获取lcid接口
    getMojorInfo = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        NHFetch("/zdybl/getXsxxAndLcidByXsid", 'GET', { xsid: xsid })
            .then(res => {
                if (res.code == "200") {
                    this.setState({
                        XsxxlcidData: res.data
                    })
                } else {
                    message.error("获取流程信息接口失败");
                }
            })
    }


    loadLstddkfs = () => {
        NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList', 'get', 'sign=dmk_YXXT_DKLX').then(res => {
            if (res && res.code == 200) {
                let dkfsList = res.data;
                this.setState({
                    dkfsList: dkfsList
                })
            }
        });
    }

    //迎新现场已办理时迎新网站自动办理
    updateZzbdBlzt = () => {
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch('/zzbd/updateZzbdBlzt', 'GET', { zzbdhjid: zzbdhjid, hjdm: 'GD005_10' }).then(res => {
            if (res.data) {
                this.reloadMenu();
            }
        })
    }

    //查询前置条件和依赖环节
    queryQztjAndYlhj = (callback) => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({
                nonOperationalData: { nologin: true },
                operable: false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj'];
                    this.setState({ nonOperationalData: res.data });
                    if (kfsj && kfsj['YXSJ'] != '1') {
                        this.setState({ operable: false });
                    }

                    if (ylhj && ylhj.length > 0) {
                        this.setState({ operable: false });
                    }

                    if (qzhj && qzhj.length > 0) {
                        this.setState({ operable: false });
                    }
                    if (nohj) {
                        this.setState({ operable: false });
                    }
                    if (callback) {
                        callback(this.state.operable);
                    }

                }
            });
    }

    getQueryString = (name) => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }

    setMenuList = (menuList) => {
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC;
                this.setState({ hjmc: hjmc })
                nextCount = index + 1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if (menu) {
            let hjdm = menu.HJDM;
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID,
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: '',
            })
        }
    }

    loadLstdBlyy = () => {
        NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList', 'get', 'sign=dmk_YXXT_LSTD_BLYY').then(res => {
            if (res) {
                let col = [];
                res.data.map(item => {
                    col.push(
                        <Col span={6}>
                            <Checkbox value={item.VALUE}>
                                {item.LABEL}
                            </Checkbox>
                        </Col>
                    );
                });
                let html = <Row>{col}</Row>
                this.setState({ checkGroup: html });
            }
        });
    }

    loadLstdHjsqdx = () => {
        NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList', 'get', 'sign=dmk_YXXT_LSTD_HJSQDXLX').then(res => {
            if (res) {
                let col = [];
                res.data.map(item => {
                    if (item.VALUE == '7') {
                        col.push(
                            <Col span={12}>
                                <Tooltip placement="bottom" title="主要包括扶贫部门监测的边缘户；享受国家定期抚恤补助的优抚对象子女；家庭经济困难残疾人子女；因家庭遭受重大自然灾害（疫情）造成重大损失的困难家庭子女；因家庭成员遭受重大疾病或意外伤害的困难家庭子女；因家庭发生重大变故等影响其子女入学就读的困难家庭子女；其他需要资助的低收入家庭经济困难学生。">
                                    <Checkbox value={item.VALUE}>
                                        {item.LABEL}
                                    </Checkbox>
                                </Tooltip>
                            </Col>
                        );
                    } else {
                        col.push(
                            <Col span={12}>
                                <Checkbox value={item.VALUE}>
                                    {item.LABEL}
                                </Checkbox>
                            </Col>
                        );
                    }
                });
                let html = <Row>{col}</Row>
                this.setState({ HjsqdxGroup: html });
            }
        });
    }

    //读取学生的绿色通道信息
    loadLstdInfo = (flag) => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        NHFetch('/lstdFb/getLstdInfoByXsid', 'GET', { xsid: xsid }).then(resData => {
            if (resData && resData.data) {
                if (flag == "edit") {
                    this.setState({
                        edit: true
                    })
                }
                if (resData.data.sfbllstd == '0') {
                    this.setState({
                        edit: false
                    })
                }
                let params = {
                    xsid: xsid,
                    hjdm: 'GD005_10'
                }
                NHFetch('/zzbdCommon/getBlztByHjdm', 'GET', params).then(res => {
                    if (res) {
                        if (res.data == '1') {
                            this.setState({
                                lstdblzt: true
                            })
                        } else {
                            this.setState({
                                lstdblzt: false
                            }, () => {
                                const { lstdblzt } = this.state;
                                if (!lstdblzt && resData.data.sfbllstd == '0') {
                                    this.reloadMenu();
                                    message.success('环节办理成功！');
                                }
                            })
                        }
                    }
                })
                // this.setState({ data: resData.data });
                this.setState({
                    data: resData.data,
                    dkfsValue: resData.data.dklx,
                    sqValue: resData.data.sfbllstd
                })
                let fileList = [];
                if (resData.data && resData.data['fjmap']) {
                    resData.data['fjmap'].map((elem, index) => {
                        //格式化对于附件取值
                        let file = {
                            uid: elem.fjid,
                            name: elem.fjmc,
                            response: {
                                data: {
                                    ssbh: elem.fjid,
                                    fjmc: elem.fjmc,
                                }
                            },
                            status: 'done'
                        }
                        fileList.push(file);
                    })
                }
                console.log(fileList);
                this.setState({ fileList: fileList });
                this.props.form.setFieldsValue({ fj: { fileList: this.state.fileList } });
            } else {
                this.setState({ data: {}, edit: true });
                this.setState({ fileList: [] });
                this.props.form.resetFields();
            }
        });
    }

    // 转化base64
    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    // 点击文件链接或预览图标时的回调
    handlePreview = file => {
        const isImage = /^image\/.*$/i.test(file.type);
        if (isImage) {
            if (file) {
                this.getBase64(file, imageUrl => {
                    this.setState({
                        previewImage: imageUrl,
                        previewVisible: true
                    });
                });
            } else {
                this.setState({
                    previewImage: file.url || file.thumbUrl,
                    previewVisible: true
                });
            }
        } else if (file && file['response'] && file['response']['data']) {//其他情况
            let isImage = true;
            let fjmc = file['response']['data']['fjmc'];
            let ssbh = file['response']['data']['ssbh'];
            if (
                !this.setJudge(fjmc, this.state.judgeImgList)
            ) {
                isImage = false;
            }

            if (isImage) {
                this.showPreview(`anonymity/docrepo/download/file?attachmentId=${ssbh}`);
            } else {
                window.open(`anonymity/docrepo/download/file?attachmentId=${ssbh}`);
            }
        }
    };

    showPreview = (url) => {
        this.setState({
            previewImage: url,
            previewVisible: true
        });
    }

    // 获取流程信息接口
    getLcidInfo = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        NHFetch("/zdybl/getXsxxAndLcidByXsid", 'GET', { xsid: xsid })
            .then(res => {
                if (res.code == "200") {
                    this.setState({
                        lcidData: res.data
                    })
                } else {
                    message.error("获取专业信息接口失败");
                }
            })
    }

    // 环节办理接口
    hjblBtnClick = () => {
        const { lcidData } = this.state
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        let zzbdhjid = this.getQueryString('pkid');
        let formData = { xsid: xsid, yhid: xsid, zzbdhjid: zzbdhjid, lchjid: lcidData.LCID }
        NHFetch('/zdybl/saveCustomLinkData', "POST", formData)
            .then(res => {
                if (res) {
                    if (res.data == '1') {
                        this.setState({
                            isHjLinked: true
                        }, () => {
                            this.reloadMenu();
                        })
                    } else {
                        this.setState({
                            isHjLinked: false
                        })
                    }
                }
            })
    }

    // 关闭图片弹窗
    handleCancel = () => this.setState({ previewVisible: false });

    // 获取缴费环节状态
    getJfhjData = () => {
        let xsid = getLoginUser().userId ? getLoginUser().userId : "";
        let param = {
            xsid: xsid,
            hjdm: 'GD008_1'
        }
        NHFetch('/zzbdCommon/getBlztByHjdm', 'GET', param).then(res => {
            if (res) {
                if (res.data == '1') {
                    this.setState({
                        jfhjzt: true
                    })
                } else {
                    this.setState({
                        jfhjzt: false
                    })
                }
            }
        })
    }

    //提交
    handleOk = e => {
        confirm({
            title: '提示',
            content: '是否确定提交，提交后不可修改！',
            okText: '确定',
            cancelText: '取消',
            okType: 'danger',
            iconType: 'info-circle',
            onOk: () => {
                this.handleSubmit(e);
            },
            onCancel() {

            },
        });
    }

    // 确认提交
    handleSubmit = e => {
        let xsid = getLoginUser().userId ? getLoginUser().userId : "";
        if (!xsid) {
            message.error("登录已过期，请重新登录！");
            return;
        }
        if (this.state.jfhjzt) {
            message.error("缴费环节已办理，不可再申请绿色通道！");
            return;
        }
        let modalFlag = "";
        this.setState({ loading: true, saveLoading: true });
        let zzbdhjid = this.getQueryString('pkid');
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                this.setState({ loading: false, saveLoading: false });
                return false;
            }

            let fjlist = [];
            if (values['sfbllstd'] == 1) {
                const fj = values['fj'];
                let lenList = [];
                if (fj) {
                    if (fj['fileList'] && fj['fileList'].length > 0) {
                        fj['fileList'].map((item, index) => {
                            if (index < 5) {
                                lenList.push(item);
                            }
                        })
                    }
                }
                let isEmpty = false;
                if (values['dklx'] == '5' || values['dklx'] == 'whrj_1' || values['dklx'] == 'whrj_4') {
                    fj['fileList'] = lenList;
                    console.log(values, lenList);
                    if (fj) {
                        if (fj['fileList'] && fj['fileList'].length > 0) {
                            isEmpty = false;
                        } else {
                            isEmpty = true;
                        }
                    } else {
                        isEmpty = true;
                    }
                    if (isEmpty) {
                        this.props.form.setFields({
                            fj: {
                                value: undefined,
                                errors: [new Error('请上传材料证明')],
                            }
                        });
                        this.setState({ loading: false, saveLoading: false });
                        return false;
                    }
                }
                if (fj != undefined) {
                    let size = fj['fileList'].length;
                    let file = fj['fileList'].filter((file) => {
                        if (file.status === 'error') {
                            return false;
                        }
                        return true;
                    });

                    if (file.length != size) {
                        this.props.form.setFields({
                            fj: {
                                value: fj,
                                errors: [new Error('材料上传出错，请重新上传.')],
                            }
                        });
                        this.setState({ loading: false, saveLoading: false });
                        return false;
                    }

                    let fileList = fj['fileList'];

                    for (let i = 0; i < fileList.length; i++) {
                        let fjid = fileList[i].response.data.ssbh;
                        let fjmc = fileList[i].name;
                        fjlist.push({
                            fjid: fjid,
                            fjmc: fjmc
                        });
                    }
                }
            }

            let sqsmFlag = false;
            let msg = '';
            let blyy = values['blyy'];
            let sqsm = values['sqsm'];
            let dkyh = values['dkyh'];
            let hzjym = values['hzjym'];
            let dkhtbh = values['dkhtbh'];
            let dklx = values['dklx'];
            let pkid = values['pkid'];
            let x_djlqzy = values['x_djlqzy']
            let x_hjjzrq = this.state.x_hjjzrq == "" || this.state.x_hjjzrq == null ? values['x_hjjzrq'] : this.state.x_hjjzrq;
            let sfbllstd = values['sfbllstd']
            let x_hjsqdxlx = values['x_hjsqdxlx']
            if (x_hjsqdxlx) {
                x_hjsqdxlx = x_hjsqdxlx.toString();
            }
            if (sqsmFlag) {
                message.error(msg);
                return;
            }
            let params = {};
            let blje = values['blje'];
            if (values['sfbllstd'] == 1) {
                if (fjlist.length > 0) {
                    params = {
                        sfbllstd: sfbllstd,
                        fj: fjlist,
                        blje: blje,
                        blyy: blyy,
                        sqsm: sqsm,
                        hzjym: hzjym,
                        dkyh: dkyh,
                        xsid: xsid,
                        zzbdhjid: zzbdhjid,
                        pkid: pkid,
                        dkhtbh: dkhtbh,
                        dklx: dklx,
                        x_djlqzy: x_djlqzy
                    }
                } else {
                    params = {
                        sfbllstd: sfbllstd,
                        blje: blje,
                        blyy: blyy,
                        sqsm: sqsm,
                        hzjym: hzjym,
                        dkyh: dkyh,
                        xsid: xsid,
                        zzbdhjid: zzbdhjid,
                        pkid: pkid,
                        dkhtbh: dkhtbh,
                        dklx: dklx,
                        x_djlqzy: x_djlqzy
                    }
                }
            } else {
                params = {
                    sfbllstd: sfbllstd,
                    xsid: xsid,
                    zzbdhjid: zzbdhjid,
                    pkid: pkid,
                    // shzt:'1'
                }
            }
            if (values['dklx'] == 'bjwl_4') {
                params['x_hjjzrq'] = x_hjjzrq;
                params['x_hjsqdxlx'] = x_hjsqdxlx;
            }
            if (!pkid) {
                NHFetch('/lstdBjwl/insert', "POST", params)
                    .then(res => {
                        if (res) {
                            message.success("绿色通道申请保存成功。");
                            this.loadLstdInfo();
                            this.reloadMenu();
                        }
                        this.setState({ loading: false, edit: false, saveLoading: false })
                    }).catch((res) => {
                        this.setState({ loading: false, saveLoading: false })
                        return res;
                    });
            } else {
                NHFetch('/lstdBjwl/update', "POST", params)
                    .then(res => {
                        if (res && res.data && res.data > 0) {
                            message.success("绿色通道申请保存成功。");
                            this.loadLstdInfo();
                            this.reloadMenu();
                        } else {
                            message.warning("绿色通道申请保存失败，请重试。");
                        }
                        this.setState({ loading: false, edit: false, saveLoading: false })
                    }).catch((res) => {
                        this.setState({ loading: false, saveLoading: false })
                        return res;
                    });
            }

        });
    };

    getQueryString = (name) => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }



    handleChange = ({ fileList }) => {
        let count = 0;
        fileList.map((elem) => {
            if (elem && elem['status'] && elem['status'] === 'uploading') {
                this.setState({ uploading: true });
                return;
            } else if (elem && elem['status']) {
                count++;
            }
        })
        if (count > 0) {
            this.setState({ uploading: false });
        }
    }

    //判断文件类型是否正常  name：文件名称  map：类型范围数组  true正常
    setJudge = (name, map) => {
        let flag = false;
        map.map(item => {
            if (name.indexOf(item) !== -1) {
                flag = true;
            }
        })
        return flag;
    }

    beforeUpload = (file) => {
        const { fileList } = this.state
        if (fileList.length >= 5) {
            message.error('最多只能上传5个文件');
            return false;
        }
        if (
            !(this.setJudge(file.name, this.state.judgeImgList) ||
                this.setJudge(file.name, this.state.judgeFileList))
        ) {
            message.error('不能上传该格式文件');
            return false;
        } else {
            return true;
        }
    }

    remove = (ssbh) => {
        let url = `api/docrepo/attachment/sourceId/delete?souerceId=${ssbh}`;
        NHFetch(url, "POST").then(res => {
            if (res.data && res.data > 0) {
                message.success('已删除材料.');
            }
        });
    }

    //重新加载菜单
    reloadMenu = () => {
        this.refs.siderMenu.reloadMenuList()
    }

    // 回调
    dkfsOnChange = e => {
        this.setState({
            dkfsValue: e.target.value,
        });
    };

    sfsqOnChange = e => {
        this.setState({
            sqValue: e.target.value,
        });
    };

    //选择缓交截止日期回调
    timeData = (moment, timeString) => {
        this.setState({
            x_hjjzrq: timeString
        })
    }

    // //限制时间
    // disabledDate = (current) =>{
    //     console.log("this is current:",current)
    //     return current && current < moment().endOf('day');
    // }

    disabledStartDate = startValue => {
        const endValue = this.state.hjjzrqxz;
        if (!startValue || !endValue) {
            return false;
        }
        return startValue.valueOf() > moment(endValue, 'YYYY-MM-DD').add(1, 'd').valueOf();
    };

    render() {
        const { form } = this.props;
        const { getFieldDecorator } = this.props.form;
        const { previewImage, previewVisible, jymFlag, sqlyFlag, dkfsList, dkfsValue, edit, data, sqValue, BlztList } = this.state;
        const props = {
            name: 'file',
            action: 'zuul/docrepo/upload',
            maxCount: 5,
            data: { uuid: createUuid(), type: '1' },

            onRemove: file => {
                this.setState(({ fileList }) => {
                    const index = fileList.indexOf(file);
                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    this.props.form.setFieldsValue({ fj: { fileList: newFileList } });
                    return {
                        fileList: newFileList
                    };
                    console.log('fileList', fileList);
                }, () => {
                    if (file.response && file.response.data && file.response.data.ssbh) {
                        this.remove(file.response.data.ssbh);
                    }
                });
            },
            beforeUpload: file => {
                if (!this.beforeUpload(file)) {
                    return false;
                }
                if (this.state.fileList.length < 5) {
                    this.setState(({ fileList }) => ({
                        fileList: [...fileList, file]
                    }));
                }
                return true;
            },
            onChange: file => { this.handleChange(file) },
            onPreview: this.handlePreview,
            fileList: this.state.fileList,
        };

        return (
            <div style={{ position: 'relative' }}>{this.state.operable ?
                <div>
                    <Flex className="w1180 mt-20" align="start">
                        <SiderMenu
                            ref={"siderMenu"}
                            setMenuList={this.setMenuList}
                            title="自助报到"
                            mkbz={this.state.mkbz}
                            /*     active={4}*/
                            menuType='自助报到' />
                        <Flex.Item className="page_con_r" styleName="">
                            <Breadcrumb
                                noLine
                                list={[
                                    { name: '首页', url: '/index' },
                                    { name: '自助报到', url: '/register' },
                                    { name: this.state.hjmc }
                                ]}
                            />
                            <div className="box">
                                <Hint />
                                {edit ? <Form onSubmit={this.handleOk}>
                                    <TitleTop title="申请信息" extra={false} />
                                    <div className="p-sw">
                                        <div style={{ display: 'none' }}>
                                            <NHFormItem id={'pkid'} initialValue={data['pkid']} form={form}>
                                                <Input />
                                            </NHFormItem>
                                        </div>

                                        <div className="form_item" style={{ marginLeft: '7%' }}>
                                            <NHFormItem
                                                required={true}
                                                id={'sfbllstd'}
                                                initialValue={data['sfbllstd']}
                                                form={form}
                                                formItemLayout={formItemLayout}
                                                label="绿色通道申请"
                                            >
                                                <Radio.Group onChange={this.sfsqOnChange}
                                                    value={this.state.sqValue}
                                                    style={{ display: 'flex', margin: '10px' }}
                                                >
                                                    {
                                                        BlztList && BlztList.map((item, index) => {
                                                            return <Radio value={item.VALUE}>{item.LABEL}</Radio>
                                                        })
                                                    }
                                                    {/* <Radio value={'0'}>否</Radio> */}
                                                </Radio.Group>
                                            </NHFormItem>
                                        </div>

                                        {sqValue == '1' &&
                                            <div className="form_item" style={{ marginLeft: '7%' }}>
                                                <NHFormItem
                                                    required={true}
                                                    id={'dklx'}
                                                    initialValue={data['dklx']}
                                                    form={form}
                                                    formItemLayout={formItemLayout}
                                                    label="申请类型"
                                                >
                                                    <Radio.Group onChange={this.dkfsOnChange}
                                                        value={this.state.dkfsValue}
                                                        style={{ display: 'flex', flexWrap: 'wrap', margin: '10px' }}
                                                    >
                                                        {
                                                            dkfsList.map((item, index) => {
                                                                return <Radio value={item.VALUE}>{item.LABEL}</Radio>
                                                            })
                                                        }
                                                    </Radio.Group>
                                                </NHFormItem>
                                            </div>}

                                        {/* {sqValue=='1' && <div style = {{marginLeft:"30px"}}>
                                        {dkfsValue=='whrj_1' && <NHFormItem id={'blyy'} required={true} initialValue={data['blyydm']} form={form} formItemLayout={{labelCol:{span:4},wrapperCol:{span:16}}} label="办理原因">
                                            <CheckboxGroup style={{ width: '100%'}}>
                                                {this.state.checkGroup}
                                            </CheckboxGroup>
                                        </NHFormItem>}
                                    </div>}

                                    {sqValue=='1' && <div style = {{marginLeft:"30px"}}>
                                        {dkfsValue=='whrj_4' && <NHFormItem id={'x_hjsqdxlx'} required={true} initialValue={data['x_hjsqdxlx']} form={form} formItemLayout={{labelCol:{span:4},wrapperCol:{span:16}}} label="缓交申请对象">
                                            <CheckboxGroup style={{ width: '100%'}}>
                                                {this.state.HjsqdxGroup}
                                            </CheckboxGroup>
                                        </NHFormItem>}
                                    </div>} */}

                                        {sqValue == '1' && <div className="form_item" style={{ marginLeft: '7%' }}>

                                            {(dkfsValue == '1' || dkfsValue == 'bjwl_1') &&
                                                <NHFormItem
                                                    id={'dkyh'}
                                                    required={true}
                                                    initialValue={data['dkyh']}
                                                    form={form}
                                                    formItemLayout={formItemLayout} label="贷款来源"
                                                >
                                                    <NHSelect sign={"dmk_yxwz_dkyh"} onChange={(v) => { }} />
                                                </NHFormItem>}
                                            {(dkfsValue == '1' || dkfsValue == 'bjwl_1') &&
                                                <NHFormItem
                                                    id={'blje'}
                                                    required={true}
                                                    initialValue={data['blje']}
                                                    form={form}
                                                    formItemLayout={formItemLayout}
                                                    label="申请金额"
                                                >
                                                    <InputNumber
                                                        style={{ width: '100%' }}
                                                        min={0} max={9999999}
                                                        precision="0"
                                                        placeholder="申请金额必同受理证明金额一致，申请上限为12000元"
                                                    />
                                                </NHFormItem>}
                                            {(dkfsValue == '1' || dkfsValue == 'bjwl_1' || dkfsValue == 'bjwl_6') &&
                                                <NHFormItem
                                                    id={'sqsm'}
                                                    required={(dkfsValue == '4' || dkfsValue == 'bjwl_6') ? true : false}
                                                    initialValue={data['sqsm']}
                                                    form={form}
                                                    formItemLayout={formItemLayout}
                                                    label="申请理由"
                                                >
                                                    <TextArea maxLength={50} placeholder="请填写申请理由" />
                                                </NHFormItem>}
                                            {(dkfsValue == '1' || dkfsValue == 'bjwl_1') &&
                                                <NHFormItem
                                                    required={true}
                                                    id={'hzjym'}
                                                    initialValue={data['hzjym']}
                                                    form={form}
                                                    formItemLayout={formItemLayout}
                                                    label="回执校验码"
                                                >
                                                    <Input maxLength={6} placeholder="6位数回执校验码" />
                                                </NHFormItem>}

                                            {(dkfsValue == '4' || dkfsValue == 'bjwl_4') &&
                                                <NHFormItem
                                                    id={'x_hjjzrq'}
                                                    required={true}
                                                    form={form}
                                                    formItemLayout={formItemLayout}
                                                    label="缓交时间"
                                                    initialValue={data.x_hjjzrq ? moment(data.x_hjjzrq, 'YYYY/MM/DD') : null}
                                                >
                                                    <DatePicker onChange={this.timeData}
                                                        placeholder={"选择日期..."}
                                                        format={"YYYY-MM-DD"}
                                                        disabledDate={this.disabledStartDate}
                                                    />
                                                </NHFormItem>}

                                        </div>}
                                    </div>
                                    {sqValue == '1' && dkfsValue != '' && dkfsValue != undefined &&
                                        <TitleTop title="申请材料" extra={false} />}
                                    {sqValue == '1' && dkfsValue != '' && dkfsValue != undefined &&
                                        <div className="p-sw">
                                            <div className="form_item" style={{ marginLeft: '7%' }}>
                                                <FormItem
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    label={dkfsValue == 'bjwl_1' ? '必须上传①受理证明、②电子合同' : '请上传材料证明'}
                                                >
                                                    {getFieldDecorator('fj', {
                                                        rules: [
                                                            {
                                                                required: (dkfsValue == 'bjwl_1' || dkfsValue == 'bjwl_4'
                                                                    || dkfsValue == 'bjwl_6') ? true : false,
                                                                message: '请上传材料证明'
                                                            }
                                                        ]
                                                    })(
                                                        <Dragger {...props}>
                                                            <p className="ant-upload-drag-icon">
                                                                <Icon type="inbox" />
                                                            </p>
                                                            <Spin spinning={this.state.uploading} />
                                                            <p className="ant-upload-text font-14">
                                                                点击此处或拖拽文件到此
                                                            </p>
                                                        </Dragger>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </div>}
                                    <div className="text-center mt-50">
                                        <Button type="primary" htmlType="submit" loading={this.state.saveLoading}>
                                            保存
                                        </Button>
                                        <NextButton nextUrl={this.state.nextUrl} search={this.state.search} />
                                    </div>
                                </Form>
                                    :
                                    <View
                                        reloadMenu={this.reloadMenu}
                                        nextUrl={this.state.nextUrl}
                                        loadLstdInfo={() => this.loadLstdInfo("edit")}
                                        search={this.state.search}
                                        showPreview={this.showPreview}
                                        setJudge={this.setJudge}
                                        data={this.state.data}
                                        judgeImgList={this.state.judgeImgList}
                                        jfhjzt={this.state.jfhjzt}
                                        BlztList={BlztList}
                                    />
                                }
                            </div>
                        </Flex.Item>
                    </Flex>
                    <Modal
                        visible={previewVisible}
                        footer={null}
                        onCancel={this.handleCancel}
                    >
                        <img
                            alt="example"
                            style={{ width: '100%' }}
                            src={previewImage}
                        />
                    </Modal>
                    <Spin
                        style={{ position: 'absolute', top: '300px', left: '60%' }}
                        spinning={this.state.loading}
                    />
                </div>
                :
                <CommonTip hjmc={"绿色通道申请"}
                    key={"GD005_10"}
                    mkbz='GD005_10'
                    nonOperationalData={this.state.nonOperationalData}
                />}
            </div>

        );
    }
}
