import React from 'react';
import {Link} from 'react-router-dom';
import {Flex} from '@components';
import {Form, Input, Checkbox, Row, Col, Upload, Button,message} from 'antd';
import TitleTop from '@pages/Assembly/titleTop';
import NHFetch from "@utils/NHFetch";
import NextButton from '@pages/SelfHelp/common/nextIndex'
const {TextArea} = Input;
//请求默认路径
const pathUrl = "api/zhxg-yxxt";

const shztList = {
    '0':'未审核',
    '1':'审核通过',
    '2':'审核不通过',
    '3':'驳回'
}
export default class View extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nextUrl:this.props.nextUrl,
            search:this.props.search,
        };
    }

    componentDidMount() {
    }


    getQueryString =(name)=> {
        return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[, ''])[1].replace(/\+/g, '%20')) || null
    }

    cancel=(lstdid,shzt)=>{

        if(shzt=='1'||shzt=='2'){
            message.warning('该申请已经审核，不能取消')
            return ;
        }

        let pkid = this.getQueryString("pkid");

        let params = {
            zzbdhjid:pkid,
            pkid:lstdid,
            shzt:shzt
        }

        NHFetch("/lstdfd/deleteByParams", "POST", params)
            .then(res => {
                if (res && res.data && res.data>0) {
                    message.success("取消成功");
                    this.props.loadLstdInfo();
                    this.props.reloadMenu();
                }else{
                    message.warning("取消异常");
                }
            });
    }



    render() {
        const {data} = this.props;
        const {dkyhmc,dkfsValue} = this.state;
        return (
            <div>
                <TitleTop title="申请信息" extra={false}/>
                <div className="p-sw">
                    <div className="form_item" style={{width: '100%'}}>
                        <Row gutter={24}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>缓缴学费&nbsp;: </span></Col>
                            <Col span={20}><span>{data['xfhj']}元</span></Col>
                        </Row>
                        <br/>
                        <Row gutter={24}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>缓缴住宿费&nbsp;: </span></Col>
                            <Col span={20}><span>{data['zsfhj']}元</span></Col>
                        </Row>
                        <br/>
                        <Row gutter={24}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>缓缴总金额&nbsp;: </span></Col>
                            <Col span={20}><span>{data['hjje']}元</span></Col>
                        </Row>
                        <br/>
                        <Row gutter={24}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>缓缴期限&nbsp;: </span></Col>
                            <Col span={20}><span>{data['hjqx']}月</span></Col>
                        </Row>
                        <br/>
                        <Row gutter={24}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>家庭经济困难主要情况说明&nbsp;: </span></Col>
                            <Col span={20}><span>{data['sqsm']}</span></Col>
                        </Row>
                    </div>
                </div>
                <TitleTop title="申请审核" extra={false}/>
                <div className="p-sw">
                <div className="form_item" style={{width: '100%', marginBottom: '24px'}}>
                        <Row gutter={24}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>审核时间&nbsp;: </span></Col>
                            <Col span={20}><span>{data['shsj']?data['shsj']:'未审核'}</span></Col>
                        </Row>
                    </div>
                    <br/>
                    <div className="form_item" style={{width: '100%', marginBottom: '24px'}}>
                        <Row gutter={24}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>审核意见&nbsp;: </span></Col>
                            <Col span={20}><span>{data['shyj']?data['shyj']:'未审核'}</span></Col>
                        </Row>
                    </div>
                    <br/>
                    <div className="form_item" style={{width: '100%', marginBottom: '24px'}}>
                        <Row gutter={24}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>审核状态&nbsp;: </span></Col>
                            <Col span={20}><span style={{color: '#0066FF'}}>{shztList[data['shzt']]}</span></Col>
                        </Row>
                    </div>
                </div>
                <div className="text-center mt-50">
                    <Button type="primary" onClick={()=>{this.cancel(data['pkid'],data['shzt'])}}>
                        取消
                    </Button>
                    <NextButton nextUrl={this.state.nextUrl}  search={this.state.search}/>
                </div>
            </div>
        );
    }
}
