import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Flex } from '@components';
import { Button, Icon } from 'antd';
import './index.css';

export default class _siderMenu extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        extra: PropTypes.bool,
        onEdit: PropTypes.func,
        onSave: PropTypes.func,
        onClose: PropTypes.func,
        onApplyClick: PropTypes.func,
    };

    static defaultProps = {
        extra: true,
        onEdit: Function.prototype,
        onSave: Function.prototype,
        onClose: Function.prototype,
        onApplyClick: Function.prototype,
    };

    state = {
        edit: false
    };

    render() {
        const {
            className,
            children,
            apply,
            title,
            extra,
            onEdit,
            onSave,
            onClose,
            onApplyClick,
            zytzsqFlag   //专业调整申请状态
        } = this.props;
        const { edit } = this.state;

        return (
            <Flex className={className} styleName="title_top" justify="between">
                <div styleName="title_top_name">{title}</div>
                <Flex.Item styleName="line" />
                {extra && (
                    <div>
                        <Button
                            className="ml-10"
                            type="primary"
                            ghost
                            onClick={() => {
                                // edit ? onSave(title) : onEdit(title);
                                // this.setState({
                                //     edit: !edit
                                // });
                                edit ? onSave(validate =>{
                                    if(validate) {
                                        this.setState({
                                            edit: !edit
                                        });
                                    }
                                }) : onEdit(title);
                                if(edit===false){
                                    this.setState({
                                        edit: true
                                    });
                                }

                            }}
                        >
                            {edit ? '保存' : '编辑'}
                        </Button>
                        {edit && (
                            <Button
                            className="ml-10"
                            type='ghost'
                            onClick={() => {
                                this.setState({
                                    edit: false
                                });
                                onClose(title);
                            }}
                        >
                            {'取消'}
                        </Button>
                        )}
                    </div>
                )}
                {apply && !zytzsqFlag && (
                    <Button
                        className="ml-10"
                        type="primary"
                        ghost
                        onClick={() => {
                            onApplyClick();
                        }}
                    >
                        申请
                    </Button>
                )}
                {children}
            </Flex>
        );
    }
}
