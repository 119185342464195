import React, { Component } from 'react'
import  './index.css';
import PropTypes from 'prop-types'
import { getLoginUser, createUuid } from "@utils/NHCore";
import NHFetch from "@utils/NHFetch";

const schoolCode = `zszyjsxy`;
const printInfoUrl = `/zdybl/getInfoForPrint`
/**
 * @author qianzhiwei
 * @date 2020/09/27
 * @description 打印单
 */
export class ZszyjsxyPrint extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            xsxx:[],
            extraData:{}    //额外参数
        }
    }

    componentDidMount() {
        this.getPrintInfo();
    }

    //获取打印信息
    getPrintInfo = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (xsid == '') {

        }
        NHFetch(printInfoUrl, 'GET', { xsid: xsid })
            .then(res => {
                if (res && res.data) {
                    this.setState({ xsxx: res.data })
                }
            });
    }

    render() {
        return (
            <div>
                    中山职业技术学院打印单
            </div>
        )
    }

    static propTypes = {

    }
}

export default ZszyjsxyPrint
