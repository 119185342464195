import React from 'react'
import { Link } from 'react-router-dom'
import { Flex } from '@components'
import { Form, Input, Checkbox, Row, Col, Upload, Button, message } from 'antd'
import TitleTop from '@pages/Assembly/titleTop'
import NHFetch from '@utils/NHFetch'
import NextButton from '@pages/SelfHelp/common/nextIndex'
const basicUrl = '/zzbd'
import { createUuid, getLoginUser } from '@utils/NHCore'

export default class View extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      nextUrl: this.props.nextUrl,
      search: this.props.search
    }
  }

  getQueryString = name => {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
  }

  cancel = lstdid => {
    let xsid = getLoginUser() ? getLoginUser().userId : ''

    let pkid = this.getQueryString('pkid')
    let params = {
      zzbdhjid: pkid,
      pkid: lstdid,
      xsid
    }

    NHFetch('/hbnz/lstd/deleteByParams', 'POST', params).then(res => {
      if (res && res.data && res.data > 0) {
        message.success('取消成功')
        this.props.loadLstdInfo()
        this.props.reloadMenu()
      } else {
        message.warning('取消异常')
      }
    })
  }

  // 点击文件链接或预览图标时的回调
  handlePreview = (fjmc, fjid) => {
    debugger
    let isImage = true
    if (!this.props.setJudge(fjmc, this.props.judgeImgList)) {
      isImage = false
    }

    if (isImage) {
      this.props.showPreview(`anonymity/docrepo/download/file?attachmentId=${fjid}`)
    } else {
      window.open(`anonymity/docrepo/download/file?attachmentId=${fjid}`)
    }
  }

  render() {
    const { data, yjfyInfo } = this.props
    return (
      <div>
        <TitleTop title="申请信息" extra={false} />
        <div className="p-sw">
          <div className="form_item" style={{ width: '100%' }}>
            <Row gutter={24}>
              <Col span={6} style={{ textAlign: 'right', paddingRight: 0 }}>
                <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>类型选择&nbsp;: </span>
              </Col>
              <Col span={18}>
                <span>{data['dklxmc']}</span>
              </Col>
            </Row>

            {data['dklxdm'] === 'hb_sydzxdk' && (
              <>
                <Row gutter={24}>
                  <Col span={6} style={{ textAlign: 'right', paddingRight: 0 }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>家庭总人口&nbsp;: </span>
                  </Col>
                  <Col span={18}>
                    <span>{data['jtzrk']}</span>
                  </Col>
                </Row>
                <br />
                <Row gutter={24}>
                  <Col span={6} style={{ textAlign: 'right', paddingRight: 0 }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>家庭人均月收入&nbsp;: </span>
                  </Col>
                  <Col span={18}>
                    <span>{data['jtrjysr']}元</span>
                  </Col>
                </Row>
                <br />
                <Row gutter={24}>
                  <Col span={6} style={{ textAlign: 'right', paddingRight: 0 }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>家庭情况&nbsp;: </span>
                  </Col>
                  <Col span={18}>
                    <span>{data['jtqkmc']}</span>
                  </Col>
                </Row>
                <br />
                <Row gutter={24}>
                  <Col span={6} style={{ textAlign: 'right', paddingRight: 0 }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>生源地助学贷款办理情况&nbsp;: </span>
                  </Col>
                  <Col span={18}>
                    <span>{data['sydzxdkblqkmc']}</span>
                  </Col>
                </Row>
                <br />
                <Row gutter={24}>
                  <Col span={6} style={{ textAlign: 'right', paddingRight: 0 }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>助学贷款回执校验码&nbsp;: </span>
                  </Col>
                  <Col span={18}>
                    <span>{data['hzjym']}</span>
                  </Col>
                </Row>
                <br />
                <Row gutter={24}>
                  <Col span={6} style={{ textAlign: 'right', paddingRight: 0 }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>已受资助情况&nbsp;: </span>
                  </Col>
                  <Col span={18}>
                    <span>{data['yszzqk'] || '未填写'}</span>
                  </Col>
                </Row>
                <br />
                <Row gutter={24}>
                  <Col span={6} style={{ textAlign: 'right', paddingRight: 0 }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>应缴学费&nbsp;: </span>
                  </Col>
                  <Col span={18}>
                    <span>{yjfyInfo['yjxf']}元</span>
                  </Col>
                </Row>
                <br />
                <Row gutter={24}>
                  <Col span={6} style={{ textAlign: 'right', paddingRight: 0 }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>应缴住宿费&nbsp;: </span>
                  </Col>
                  <Col span={18}>
                    <span>{yjfyInfo['yjzsf']}元</span>
                  </Col>
                </Row>
                <br />
                <Row gutter={24}>
                  <Col span={6} style={{ textAlign: 'right', paddingRight: 0 }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>先交金额&nbsp;: </span>
                  </Col>
                  <Col span={18}>
                    <span>{data['xjje']}元</span>
                  </Col>
                </Row>
                <br />
                <Row gutter={24}>
                  <Col span={6} style={{ textAlign: 'right', paddingRight: 0 }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>缓交金额&nbsp;: </span>
                  </Col>
                  <Col span={18}>
                    <span>{data['hjje']}元</span>
                  </Col>
                </Row>
                <br />
                <Row gutter={24}>
                  <Col span={6} style={{ textAlign: 'right', paddingRight: 0 }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>缓交方式&nbsp;: </span>
                  </Col>
                  <Col span={18}>
                    <span>{data['hjfsmc']}</span>
                  </Col>
                </Row>

                {data['hjfsdm'] === '9' && (
                  <>
                    <Row gutter={24}>
                      <Col span={6} style={{ textAlign: 'right', paddingRight: 0 }}>
                        <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>其他方式&nbsp;: </span>
                      </Col>
                      <Col span={18}>
                        <span>{data['qthjfs']}</span>
                      </Col>
                    </Row>
                  </>
                )}
                <br />
              </>
            )}
            <br />
          </div>
        </div>

        {false && (
          <>
            <TitleTop title="申请材料" extra={false} />
            <div className="p-sw">
              <div className="form_item" style={{ width: '100%' }}>
                <Row gutter={24}>
                  <Col span={6} style={{ textAlign: 'right', paddingRight: 0 }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>材料证明&nbsp;: </span>
                  </Col>
                  <Col span={18}>
                    {data['fj'].map((elem, index) => {
                      return (
                        <div style={{ marginBottom: '20px' }}>
                          <a
                            onClick={() => {
                              this.handlePreview(elem['fjmc'], elem['fjid'])
                            }}
                          >
                            {elem['fjmc']}
                          </a>
                        </div>
                      )
                    })}
                  </Col>
                </Row>
              </div>
            </div>
          </>
        )}

        <div className="text-center mt-50">
          <Button
            type="primary"
            onClick={() => {
              this.cancel(data['pkid'])
            }}
          >
            取消
          </Button>
          <NextButton nextUrl={this.state.nextUrl} search={this.state.search} />
        </div>
      </div>
    )
  }
}
