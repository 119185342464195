import React from 'react';
import { Flex } from '@components';
import { Form, Input, Button, Select, InputNumber } from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import { message } from "antd/lib/index";
import NHFetch from "../../../../utils/NHFetch";
import NHSelect from "@components/common/NHSelect";
import createHistory from 'history/createHashHistory';
import NextButton from '@pages/SelfHelp/common/nextIndex'
import { createUuid, getLoginUser } from '@utils/NHCore';
import CommonTip from "../common";
import { mkbz } from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";
const history = createHistory();
const FormItem = Form.Item;
const Option = Select.Option;
const basicUrl = "/zzbd";
const commonUrl = '/zzbdCommon';

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
};

@Form.create()
export default class SizeJxgczyjsxy extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mkbz: 'GD003_3',
            hjmc: '',
            editable: true,
            initPOvalue: {
                twcm: '',
                xm: '',
                fzcm: '',
                sg: '',
                tz: '',
            },
            twmc: {
            },
            fzmc: {
            },
            xmmc: {
            },
            nextUrl: '',//左侧菜单加载完后传进来
            search: '',
            operable: true,//是否显示环节
            nonOperationalData: {},//不可操作的数据
        }
    }

    //渲染前获取信息
    componentDidMount() {
        this.queryQztjAndYlhj();
        this.getFzpo();
    }

    componentWillMount() {
        this.getDmkMc("YXWZ_JXXM");
        this.getDmkMc("YXWZ_FZCM");
        this.getDmkMc("YXWZ_TWCM");
    }

    //查询前置条件和依赖环节
    queryQztjAndYlhj = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({
                nonOperationalData: { nologin: true },
                operable: false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj'];
                    this.setState({ nonOperationalData: res.data });
                    if (kfsj && kfsj['YXSJ'] != '1') {
                        this.setState({ operable: false });
                    }

                    if (ylhj && ylhj.length > 0) {
                        this.setState({ operable: false });
                    }

                    if (qzhj && qzhj.length > 0) {
                        this.setState({ operable: false });
                    }
                    if (nohj) {
                        this.setState({ operable: false });
                    }
                }
            });
    }

    getFzpo() {
        const xsid = getLoginUser().userId; //todo
        let twcmmc = '';
        let fzcmmc = '';
        let xmcmmc = '';
        NHFetch(basicUrl + "/getFzpoByXsidAndNd", "GET", { xsid: xsid })
            .then(res => {
                if (res) {
                    if (res.data) {

                        this.setState({ initPOvalue: res.data, editable: false })

                        this.state.twmc && this.state.twmc.map(initItem => {
                            if (initItem.DM === res.data.twcm) {
                                twcmmc = initItem.MC;
                            }
                        });
                        this.state.fzmc && this.state.fzmc.map(initItem => {
                            if (initItem.DM === res.data.fzcm) {
                                fzcmmc = initItem.MC;
                            }
                        });
                        this.state.xmmc && this.state.xmmc.map(initItem => {
                            if (initItem.DM === res.data.xm) {
                                xmcmmc = initItem.MC;
                            }
                        });
                        this.setState({ twcmmc: twcmmc, fzcmmc: fzcmmc, xmcmmc: xmcmmc });
                    }
                }
            });
    }

    getQueryString = (name) => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }

    //获取下拉列表value对应的名称
    getDmkMc = (dmbz) => {
        NHFetch(basicUrl + "/getDmkMc", "GET", { dmbz: dmbz })
            .then(res => {
                if (res) {
                    if (res.data) {
                        if (dmbz === "YXWZ_JXXM") {
                            this.setState({ xmmc: res.data });
                        } else if (dmbz === "YXWZ_FZCM") {
                            this.setState({ fzmc: res.data });
                        } else if (dmbz === "YXWZ_TWCM") {
                            this.setState({ twmc: res.data });
                        }

                    }
                }
            });
    }

    setMenuList = (menuList) => {
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC;
                this.setState({ hjmc: hjmc })
                nextCount = index + 1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if (menu) {
            let hjdm = menu.HJDM;
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID,
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: '',
            })
        }
    }

    //保存和修改
    handleSubmit = e => {


        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return;
            }

            let sUrl = '/saveJxfzData';
            if (this.state.update) {
                sUrl = '/updateJxfzData';
            }
            //修改 可编辑状态，并可以update数据
            if (!this.state.editable) {
                this.setState({ update: true, editable: true });
                return
            }
            const xsid = getLoginUser().userId;
            const zzbdhjid = this.getUrlParams("pkid");
            let params = { xsid: xsid, zzbdhjid: zzbdhjid, formData: values };
            //请求保存或修改数据
            NHFetch(basicUrl + sUrl, 'POST', params)
                .then(res => {
                    if (res) {
                        if (res.data == '1') {
                            message.success('信息保存成功！');
                            this.reloadMenu();
                            this.getFzpo();
                        } else {
                            message.error('信息保存失败，请重试！');
                        }
                    }
                })

        });
    };

    getUrlParams = (param) => {
        return decodeURIComponent((new RegExp('[?|&]' + param + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    };

    //重新加载菜单
    reloadMenu = () => {
        this.refs.siderMenu.reloadMenuList()
    }

    render() {

        const { getFieldDecorator } = this.props.form;
        const { data, editable, initPOvalue, twcmmc, fzcmmc, xmcmmc } = this.state;

        return (
            <div>
                {this.state.operable ?
                    <Flex className="w1180 mt-20" align="start">
                        <SiderMenu
                            ref={"siderMenu"}
                            setMenuList={this.setMenuList}
                            title="自助报到"
                            mkbz={this.state.mkbz}
                            menuType='自助报到'
                        />
                        <Flex.Item className="page_con_r">
                            <Breadcrumb
                                noLine
                                list={[
                                    { name: '首页', url: '/index' },
                                    { name: '自助报到', url: '/register' },
                                    { name: this.state.hjmc }
                                ]}
                            />
                            <div className="box">
                                <TitleTop title={this.state.hjmc} extra={false} />
                                <Form onSubmit={this.handleSubmit}>
                                    <div className="p-sw">
                                        <Flex align="start" wrap="wrap">
                                            <div className="form_item">
                                                <FormItem
                                                    {...formItemLayout}
                                                    label="身高"
                                                >
                                                    {getFieldDecorator('sg', { initialValue: initPOvalue.sg })(
                                                        editable ?
                                                            <InputNumber placeholder="请填写身高"
                                                                min={0}
                                                                max={300}
                                                                disabled={!editable} />

                                                            :
                                                            <span>{initPOvalue.sg} </span>
                                                    )}&nbsp;&nbsp;<span>  CM </span>
                                                </FormItem>
                                            </div>
                                            <div className="form_item">
                                                <FormItem
                                                    {...formItemLayout}
                                                    label="体重"
                                                >
                                                    {getFieldDecorator('tz', { initialValue: initPOvalue.tz })(
                                                        editable ? <InputNumber placeholder="请填写体重"
                                                            min={0}
                                                            max={500} disabled={!editable} /> :
                                                            <span>{initPOvalue.tz}</span>
                                                    )}&nbsp;&nbsp;<span>  KG </span>
                                                </FormItem>
                                            </div>
                                            <div className="form_item">
                                                <FormItem
                                                    {...formItemLayout}
                                                    label="身高及服装尺码"
                                                >
                                                    {getFieldDecorator('fzcm', {
                                                        initialValue: initPOvalue.fzcm,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message:
                                                                    '请选择身高及服装尺码'
                                                            }
                                                        ]
                                                    })(editable ?

                                                        <NHSelect sign={"dmk_yxwz_fzcm"} />

                                                        : <span>{fzcmmc}</span>
                                                    )}
                                                </FormItem>
                                            </div>
                                            <div className="form_item">
                                                <FormItem
                                                    {...formItemLayout}
                                                    label="头围及帽子尺码"
                                                >
                                                    {getFieldDecorator('twcm', {
                                                        initialValue: initPOvalue.twcm,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message:
                                                                    '请选择头围及帽子尺码'
                                                            }
                                                        ]
                                                    })(editable ?

                                                        <NHSelect sign={"dmk_yxwz_twcm"} />
                                                        : <span> {twcmmc} </span>
                                                    )}
                                                </FormItem>
                                            </div>
                                            <div className="form_item">
                                                <FormItem
                                                    {...formItemLayout}
                                                    label="脚长及鞋子尺码"
                                                >
                                                    {getFieldDecorator('xm', {
                                                        initialValue: initPOvalue.xm,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message:
                                                                    '请选择脚长及鞋子尺码'
                                                            }
                                                        ]
                                                    })(editable ?

                                                        <NHSelect sign={"dmk_yxwz_jxxm"} />
                                                        : <span> {xmcmmc} </span>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Flex>
                                        <Hint />
                                        <div className="text-center mt-50">
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                            >
                                                {editable ? '保存' : '修改'}
                                            </Button>
                                            <NextButton nextUrl={this.state.nextUrl} search={this.state.search} />
                                        </div>
                                    </div>
                                </Form>

                            </div>
                        </Flex.Item>
                    </Flex>
                    : <CommonTip hjmc={"军训服装尺码"} key={'GD003_3'} mkbz='GD003_3' nonOperationalData={this.state.nonOperationalData} />}
            </div>
        )
    }
}