import React, { Component } from 'react'
import { Form, Input, Button, Modal, Upload, Icon, message, Spin, InputNumber, Checkbox, Row, Col } from 'antd'
import TitleTop from '@pages/Assembly/titleTop'
import { createUuid, getLoginUser } from '@utils/NHCore'
import NHFetch from '@utils/NHFetch'
import View from './view'
import NextButton from '@pages/SelfHelp/common/nextIndex'
import NHFormItem from '@components/common/NHFormItem'
import NHSelect from '@components/common/NHSelect'

const Dragger = Upload.Dragger
//确认对话框
const confirm = Modal.confirm
const { TextArea } = Input
const FormItem = Form.Item
const CheckboxGroup = Checkbox.Group
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 }
}

@Form.create()
export default class Gxst_xyddk extends Component {
  constructor(props) {
    super(props)
    this.state = {
      uploading: false,
      previewVisible: false,
      judgeImgList: ['.png', '.PNG', '.jpg', '.JPG', '.bpm', '.BPM', '.jpeg', '.JPEG'], //可以导入的图片格式
      judgeFileList: ['.pdf', '.PDF', '.doc', '.DOC', '.docx', '.DOCX', '.zip', '.ZIP', '.txt', '.TXT', '.xls', '.XLS', '.xlsx', '.XLSX', '.rar', '.RAR'], //可以导入的非图片格式
      BlztList: [], //办理状态
      saveLoading: false,
      fileList: [],
      checkGroup: [], //绿色通道办理原因
      dkyhdm: '', //贷款银行代码
      dkyhTip: '', //贷款银行提示
      validateStatus: {
        dkyh: '',
        hzjym: '',
        dkhtbh: ''
      }
    }
  }

  componentDidMount() {
    if (this.props.operable) {
      this.loadLstdBlyy()
      this.updateZzbdBlzt()
    }
    this.getSfsqLstd()
    this.initFiles(this.props)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.data !== nextProps.data) {
      this.initFiles(nextProps)
    }
  }

  loadLstdBlyy = () => {
    NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList', 'get', 'sign=dmk_YXXT_LSTD_BLYY').then(res => {
      if (res) {
        let col = []
        res.data.map(item => {
          col.push(
            <Col span={12}>
              <Checkbox value={item.VALUE}>{item.LABEL}</Checkbox>
            </Col>
          )
        })
        let html = <Row>{col}</Row>
        this.setState({ checkGroup: html })
      }
    })
  }

  // 获取绿色通道是否办理选项
  getSfsqLstd = () => {
    NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList', 'get', 'sign=dmk_YXXT_LSTD_BLZT').then(res => {
      if (res) {
        if (res.data) {
          this.setState({
            BlztList: res.data
          })
        }
      }
    })
  }

  //迎新现场已办理时迎新网站自动办理
  updateZzbdBlzt = () => {
    let zzbdhjid = this.getQueryString('pkid')
    NHFetch('/zzbd/updateZzbdBlzt', 'GET', { zzbdhjid: zzbdhjid, hjdm: 'GD005_2' }).then(res => {
      if (res.data) {
        this.props.reloadMenu()
      }
    })
  }

  getQueryString = name => {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
  }

  // 转化base64
  getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  // 点击文件链接或预览图标时的回调
  handlePreview = file => {
    const isImage = /^image\/.*$/i.test(file.type)
    if (isImage) {
      if (file) {
        this.getBase64(file, imageUrl => {
          this.setState({
            previewImage: imageUrl,
            previewVisible: true
          })
        })
      } else {
        this.setState({
          previewImage: file.url || file.thumbUrl,
          previewVisible: true
        })
      }
    } else if (file && file['response'] && file['response']['data']) {
      //其他情况
      let isImage = true
      let fjmc = file['response']['data']['fjmc']
      let ssbh = file['response']['data']['ssbh']
      if (!this.setJudge(fjmc, this.state.judgeImgList)) {
        isImage = false
      }

      if (isImage) {
        this.showPreview(`anonymity/docrepo/download/file?attachmentId=${ssbh}`)
      } else {
        window.open(`anonymity/docrepo/download/file?attachmentId=${ssbh}`)
      }
    }
  }

  showPreview = url => {
    this.setState({
      previewImage: url,
      previewVisible: true
    })
  }

  // 关闭图片弹窗
  handleCancel = () => this.setState({ previewVisible: false })

  //提交
  handleOk = e => {
    confirm({
      title: '提示',
      content: '是否确定提交，提交后不可修改！',
      okText: '确定',
      cancelText: '取消',
      okType: 'danger',
      iconType: 'info-circle',
      onOk: () => {
        this.handleSubmit(e)
      },
      onCancel() {}
    })
  }

  // 确认提交
  handleSubmit = e => {
    const { sqValue, dkfsValue, setChannelState, data } = this.props
    const { saveLoading } = this.state

    let xsid = getLoginUser().userId ? getLoginUser().userId : ''
    if (!xsid) {
      message.error('登录已过期，请重新登录！')
      return
    }

    this.setState({ saveLoading: true }, () => setChannelState({ loading: saveLoading }))
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        if(err.dkyh) {
          this.setState({validateStatus:{...this.state.validateStatus, dkyh: 'error'}, dkyhTip: '请选择贷款银行'})
        }
        this.setState({ saveLoading: false }, () => setChannelState({ loading: saveLoading }))
        return false
      }

      // 处理附件数据
      let fjlist = []
      if (sqValue == 1) {
        const fj = values['fj']
        let lenList = []
        if (fj) {
          if (fj['fileList'] && fj['fileList'].length > 0) {
            fj['fileList'].map((item, index) => {
              if (index < 5) {
                lenList.push(item)
              }
            })
          }
        }
        let isEmpty = false

        fj['fileList'] = lenList
        if (fj) {
          if (fj['fileList'] && fj['fileList'].length > 0) {
            isEmpty = false
          } else {
            isEmpty = true
          }
        } else {
          isEmpty = true
        }
        if (isEmpty) {
          this.props.form.setFields({
            fj: {
              value: undefined,
              errors: [new Error('请上传材料证明')]
            }
          })
          this.setState({ saveLoading: false }, () => setChannelState({ loading: saveLoading }))
          return false
        }

        if (fj != undefined) {
          let size = fj['fileList'].length
          let file = fj['fileList'].filter(file => {
            if (file.status === 'error') {
              return false
            }
            return true
          })

          if (file.length != size) {
            this.props.form.setFields({
              fj: {
                value: fj,
                errors: [new Error('材料上传出错，请重新上传.')]
              }
            })
            this.setState({ saveLoading: false }, () => setChannelState({ loading: saveLoading }))
            return false
          }

          let fileList = fj['fileList']

          for (let i = 0; i < fileList.length; i++) {
            let fjid = fileList[i].response.data.ssbh
            let fjmc = fileList[i].name
            fjlist.push({
              fjid: fjid,
              fjmc: fjmc
            })
          }
        }
      }

      // 根据需要添加或删除pamrams中的字段
      // 整理其他表单数据
      let zzbdhjid = this.getQueryString('pkid')
      let dklx = dkfsValue
      let pkid = data.pkid
      let sfbllstd = sqValue
      let blje = values['blje']
      let sqsm = values['sqsm']
      let blyy = values['blyy']
      let dkyh = values['dkyh']
      let hzjym = values['hzjym']
      let dkhtbh = values['dkhtbh']
      let params = {}

      if (sqValue == 1) {
        params = {
          sfbllstd,
          blje,
          sqsm,
          xsid,
          zzbdhjid,
          pkid,
          dklx,
          blyy,
          dkyh,
          hzjym,
          dkhtbh
        }
        if (fjlist.length > 0) {
          params.fj = fjlist
        }
      } else {
        params = {
          sfbllstd: sfbllstd,
          xsid: xsid,
          zzbdhjid: zzbdhjid,
          pkid: pkid
        }
      }

      // 提交数据
      if (!pkid) {
        NHFetch('/lstdFb/insert', 'POST', params)
          .then(res => {
            if (res) {
              message.success('绿色通道申请保存成功。')
              this.props.loadLstdInfo()
              this.props.reloadMenu()
            }
            this.setState({ saveLoading: false }, () => setChannelState({ edit: false, loading: saveLoading }))
          })
          .catch(res => {
            this.setState({ saveLoading: false }, () => setChannelState({ loading: saveLoading }))
            return res
          })
      } else {
        NHFetch('/lstdFb/update', 'POST', params)
          .then(res => {
            if (res && res.data && res.data > 0) {
              message.success('绿色通道申请保存成功。')
              this.props.loadLstdInfo()
              this.props.reloadMenu()
            } else {
              if (!res.message.startsWith('businessLogicWarm[')) {
                message.warning('绿色通道申请保存失败，请重试。')
              }
            }
            this.setState({ saveLoading: false }, () => setChannelState({ edit: false, loading: saveLoading }))
          })
          .catch(res => {
            this.setState({ saveLoading: false }, () => setChannelState({ edit: false, loading: saveLoading }))
            return res
          })
      }
    })
  }

  getQueryString = name => {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
  }

  // 初始化附件
  initFiles = props => {
    const { data: lstdData } = props
    let fileList = []
    if (lstdData && lstdData['fjmap']) {
      lstdData['fjmap'].map((elem, index) => {
        //格式化对于附件取值
        let file = {
          uid: elem.fjid,
          name: elem.fjmc,
          response: {
            data: {
              ssbh: elem.fjid,
              fjmc: elem.fjmc
            }
          },
          status: 'done'
        }
        fileList.push(file)
      })
    }
    this.setState({ fileList }, () => {
      this.props.form.setFieldsValue({ fj: { fileList } })
    })
  }

  handleChange = ({ fileList }) => {
    let count = 0
    fileList.map(elem => {
      if (elem && elem['status'] && elem['status'] === 'uploading') {
        this.setState({ uploading: true })
        return
      } else if (elem && elem['status']) {
        count++
      }
    })
    if (count > 0) {
      this.setState({ uploading: false })
    }
  }

  //判断文件类型是否正常  name：文件名称  map：类型范围数组  true正常
  setJudge = (name, map) => {
    let flag = false
    map.map(item => {
      if (new RegExp(`${item}$`).test(name)) {
        flag = true
      }
    })
    return flag
  }

  beforeUpload = file => {
    const { fileList, judgeImgList, judgeFileList } = this.state
    if (fileList.length >= 5) {
      message.error('最多只能上传5个文件')
      console.log()
      return false
    }
    if (!(this.setJudge(file.name, judgeImgList) || this.setJudge(file.name, judgeFileList))) {
      message.error('不能上传该格式文件')
      return false
    } else {
      return true
    }
  }

  remove = ssbh => {
    let url = `api/docrepo/attachment/sourceId/delete?souerceId=${ssbh}`
    NHFetch(url, 'POST').then(res => {
      if (res.data && res.data > 0) {
        message.success('已删除材料.')
      }
    })
  }

  onDkyhChange = dkyhdm => {
    const { setFields } = this.props.form
    setFields({ hzjym: { value: '', error: null }, dkhtbh: { value: '', error: null } })
    let dkyhTip
    const validateStatus = {
      dkyh: '',
      hzjym: '',
      dkhtbh: ''
    }

    if (dkyhdm === '01') {
      dkyhTip = <span style={{ fontSize: 12, color: '#f5222d' }}>回执校验码必须填写，贷款合同编号选填</span>
    } else if (!dkyhdm) {
      dkyhTip = '贷款银行不能为空！'
      validateStatus.dkyh = 'error'
    } else {
      dkyhTip = '回执校验码和贷款合同编号至少填写一项'
      validateStatus.hzjym = 'error'
      validateStatus.dkhtbh = 'error'
    }
    this.setState({ dkyhdm, dkyhTip, validateStatus })
  }

  // 校验贷款合同编号和回执验证码
  validateDkhtbhAndHzjym = (rule, value, callback) => {
    const { getFieldsValue } = this.props.form
    const { dkyhdm } = this.state

    if (dkyhdm === '01') {
      callback()
    } else {
      const fieldsValue = getFieldsValue()
      if (!fieldsValue.hzjym && !fieldsValue.dkhtbh) {
        callback('回执校验码和贷款合同编号至少填写一项')
      } else {
        callback()
      }
    }
  }

  render() {
    const { form } = this.props
    const { getFieldDecorator } = this.props.form
    const { nextUrl, search, sqValue, data, BlztList, fjsmxx, fjsmzt, edit, loadLstdInfo, reloadMenu } = this.props
    const { previewImage, previewVisible, saveLoading, judgeImgList, fileList, uploading, dkyhdm, dkyhTip, validateStatus } = this.state
    const props = {
      name: 'file',
      action: 'zuul/docrepo/upload',
      maxCount: 5,
      data: { uuid: createUuid(), type: '1' },

      onRemove: file => {
        this.setState(
          ({ fileList }) => {
            const index = fileList.indexOf(file)
            const newFileList = fileList.slice()
            newFileList.splice(index, 1)
            this.props.form.setFieldsValue({ fj: { fileList: newFileList } })
            return {
              fileList: newFileList
            }
          },
          () => {
            if (file.response && file.response.data && file.response.data.ssbh) {
              this.remove(file.response.data.ssbh)
            }
          }
        )
      },
      beforeUpload: file => {
        if (!this.beforeUpload(file)) {
          return false
        }
        if (fileList.length < 5) {
          this.setState(({ fileList }) => ({
            fileList: [...fileList, file]
          }))
        }
        return true
      },
      onChange: file => {
        this.handleChange(file)
      },
      onPreview: this.handlePreview,
      fileList
    }

    const viewAttr = {
      loadLstdInfo: () => loadLstdInfo('edit'),
      setJudge: this.setJudge,
      showPreview: this.showPreview,
      reloadMenu: reloadMenu,
      judgeImgList,
      data: data || {},
      BlztList,
      nextUrl,
      search
    }

    return (
      <React.Fragment>
        {edit ? (
          <Form>
            {/* 如下根据需要添加或删除表单项 */}

            <div>
              {sqValue == '1' && (
                <div className="form_item" style={{ marginLeft: '7%' }}>
                  <NHFormItem id={'blyy'} required={true} initialValue={data['blyydm']} form={form} formItemLayout={{ labelCol: { span: 4 }, wrapperCol: { span: 16 } }} label="办理原因">
                    <CheckboxGroup style={{ width: '100%' }}>{this.state.checkGroup}</CheckboxGroup>
                  </NHFormItem>

                  <NHFormItem id={'blje'} required={true} initialValue={data['blje']} form={form} formItemLayout={formItemLayout} label="贷款金额">
                    <InputNumber formatter={value => `${value} 元`} style={{ width: '100%' }} min={0} max={9999999} precision="0" placeholder="贷款金额" />
                  </NHFormItem>

                  <Form.Item label="贷款银行" validateStatus={validateStatus.dkyh} help={<span style={{ fontSize: 12, color: '#f5222d' }}>{dkyhTip}</span>} {...formItemLayout}>
                    {getFieldDecorator('dkyh', {
                      initialValue: data['dkyh'],
                      rules: [
                        {
                          required: true
                        }
                      ]
                    })(<NHSelect sign={'dmk_yxwz_dkyh'} onChange={this.onDkyhChange} />)}
                  </Form.Item>

                  <Form.Item label="回执校验码" {...formItemLayout}>
                    {getFieldDecorator('hzjym', {
                      initialValue: data['hzjym'],
                      rules: [
                        {
                          len: 6,
                          message: '长度有误，请输入6位数回执校验码'
                        },
                        {
                          required: dkyhdm === '01',
                          message: '回执校验码不能为空！'
                        },
                        {
                          validator: this.validateDkhtbhAndHzjym
                        }
                      ]
                    })(<Input maxLength={6} placeholder="6位数回执校验码" />)}
                  </Form.Item>

                  <Form.Item label="贷款合同编号" {...formItemLayout}>
                    {getFieldDecorator('dkhtbh', {
                      initialValue: data['dkhtbh'],
                      rules: [
                        {
                          validator: this.validateDkhtbhAndHzjym
                        }
                      ]
                    })(<Input placeholder="贷款合同编号" />)}
                  </Form.Item>

                  <NHFormItem id={'sqsm'} initialValue={data['sqsm']} form={form} formItemLayout={formItemLayout} label="申请理由">
                    <TextArea maxLength={50} placeholder="请填写申请理由" />
                  </NHFormItem>
                </div>
              )}
            </div>

            {sqValue == '1' && <TitleTop title="申请材料" extra={false} />}
            {sqValue == '1' && (
              <div className="p-sw">
                <div className="form_item" style={{ marginLeft: '7%' }}>
                  <FormItem labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} label={<span>请上传材料证明&nbsp;</span>}>
                    {getFieldDecorator('fj', {
                      rules: [
                        {
                          required: true,
                          message: '请上传材料证明'
                        }
                      ]
                    })(
                      <Dragger {...props}>
                        <p className="ant-upload-drag-icon">
                          <Icon type="inbox" />
                        </p>
                        <Spin spinning={uploading} />
                        <p className="ant-upload-text font-14">点击此处或拖拽文件到此</p>
                      </Dragger>
                    )}
                  </FormItem>
                  <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                  </Modal>
                  {fjsmzt ? (
                    <div style={{ color: '#F00', wordBreak: 'break-all', whiteSpace: 'normal', lineHeight: '15px', width: '95%' }} justify="left" align="middle">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{fjsmxx}
                    </div>
                  ) : null}
                </div>
              </div>
            )}
            <div className="text-center mt-50">
              <Button type="primary" htmlType="submit" onClick={this.handleOk} loading={saveLoading}>
                保存
              </Button>
              <NextButton nextUrl={nextUrl} search={search} />
            </div>
          </Form>
        ) : (
          <View {...viewAttr} />
        )}
      </React.Fragment>
    )
  }
}
