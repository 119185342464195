import React from 'react';
import { Link } from 'react-router-dom';
import { Flex } from '@components';
import { Button, Checkbox, Col, Modal, Row, message, Alert, Spin, Radio, Result } from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
// import SelectBox from './selectBox';
// import SelectModal from './selectModal';
import './index.css';
import NHFetch from "@utils/NHFetch";
import getSize from '@utils/getSize';
import createHistory from 'history/createHashHistory';
import { getLoginUser, getLoginUserInfo } from "@utils/NHCore";
import CommonTip from "../common";
import NextButton from '@pages/SelfHelp/common/nextIndex'
import { mkbz } from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";
import { NHConfirm } from "@components/common/NHModal";

const history = createHistory();
const confirm = Modal.confirm;
const CheckboxGroup = Checkbox.Group;
const RadioGroup = Radio.Group;

/**
 * @author weishihuai
 * @date 2018/7/6
 * @time 14:41
 * @Description: 迎新网站-自助报到-宿舍分配
 */
export default class Dorm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mkbz: 'GD006_5',
            hjmc: '',
            xzId: '',
            _xzId: '',
            cwsList: [],                    //床位数
            sfbzList: [],                   //收费标准
            xqList: [],                     //校区信息
            currentXqid: "",                //当前校区ID
            fjxxList: [],                   //房间信息
            currentClickCwPkid: "",         //当前床位信息ID
            xsssruYwxx: {},                 //学生宿舍入住业务信息
            isHjLinked: false,              //环节是否办理状态
            param: {},                      //过滤条件
            scrollHeight: 200,              //默认滚动高度
            loginUserInfo: {},              //当前登录学生信息
            nextUrl: '',                    //左侧菜单加载完后传进来
            search: '',
            operable: true,                 //是否显示环节
            nonOperationalData: {},         //不可操作的数据
            autoBlLoading: false,           //自动办理按钮载入状态
            cancelLoading: false,           //取消办理按钮载入状态
            zdrzLoading: false,             //自动入住载入状态
            setRoom: [],//过滤房间信息
            sfbz: "",                   //收费标准选中值
            stuInfo: {},
            stuXxcj: {},
            stuSsxx: {},
            cwhFlag: true
        };
    }

    componentWillMount() {
        let that = this;
        this.setState({ scrollHeight: getSize().windowH - 206 });
        window.onresize = function () {
            that.setState({
                scrollHeight: getSize().windowH - 206,
            })
        };
    }

    componentDidMount() { 
        this.queryQztjAndYlhj((operable) => { 
            if(operable){
                this.getSsinfo();
                this.getCssz();
            } 
        }); 
    } 

    getCssz = () => {
        let params = { csbz: "YXXT_SSXX_SFXSCWH" };
        NHFetch('/zzbdCommon/getCssz', 'GET', params)
            .then(res => {
                if (res && res.data) {
                    this.setState({
                        cwhFlag: res.data == 'true' ? true : false,
                    })
                }
            });
    }

    getSsinfo = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (xsid) {
            NHFetch('/newStudent/queryStuInfo', 'GET', { 'xsid': xsid })
                .then(res => {
                    if (res && res.data) {
                        let stuInfo = res.data.stuInfo.userBaseInfo;
                        let stuXxcj = res.data.stuXxcj;
                        let stuSsxx = res.data.stuSsxx;
                        this.setState({
                            stuInfo: stuInfo,
                            stuXxcj: stuXxcj,
                            stuSsxx: stuSsxx,
                        }); 
                        if(stuSsxx == null){//如果没有宿舍信息则自动分配
                            this.handleAutoDistributeListener();
                        }
                    }
                });
        } else {
            message.error("登录超时,请重新登录！");
        }
    }

   
    //查询前置条件和依赖环节
    queryQztjAndYlhj = (callback) => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({
                nonOperationalData: { nologin: true },
                operable: false,
            });
            return;
        }
        let zzbdhjid = this.getUrlParams('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj'];
                    this.setState({ nonOperationalData: res.data });
                    if (kfsj && kfsj['YXSJ'] != '1') {
                        this.setState({ operable: false });
                    }

                    if (ylhj && ylhj.length > 0) {
                        this.setState({ operable: false });
                    }

                    if (qzhj && qzhj.length > 0) {
                        this.setState({ operable: false });
                    }
                    if (nohj) {
                        this.setState({ operable: false });
                    }
                    if (callback) {
                        callback(this.state.operable);
                    }
                }
            });
    };

    setMenuList = (menuList) => {
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC;
                this.setState({ hjmc: hjmc })
                nextCount = index + 1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if (menu) {
            let hjdm = menu.HJDM;
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID,
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: '',
            })
        }
    }

    //获取url中传递的参数
    getUrlParams = (param) => {
        return decodeURIComponent((new RegExp('[?|&]' + param + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    };

 
    //重新加载菜单
    reloadMenu = () => {
        this.refs.siderMenu.reloadMenuList()
    }
 

    //自动办理事件
    handleAutoDistributeListener = () => {
        this.setState({
            autoBlLoading: true
        });
        let xsid = getLoginUser() ? getLoginUser().userId : ''; 
        let zzbdhjid = this.getUrlParams("pkid"); 
        if (!xsid) {
            message.warning("登录过期请重新登录！");
            return;
        }  
        //自动分配宿舍
        let params = { 
            xsid: xsid,  
            zzbdhjid: zzbdhjid
        }; 
        NHFetch("/zxssFe/yxwzAutoDistributeSscwxx", 'POST', params).then(res => {
            if (res) {
                console.log("自动分配宿舍:",res,res.data)
                if (res.data === 1) { 
                    message.info("自动分配宿舍成功！");
                    this.setState({
                        autoBlLoading: false
                    });
                    this.getSsinfo();
                    this.reloadMenu();
                }
            } else {
                message.error("自动分配宿舍失败,请稍后重试！");
                this.setState({
                    autoBlLoading: false
                });
            }
        }).catch(() => {
            message.error("自动分配宿舍失败,请稍后重试！");
            this.setState({
                autoBlLoading: false
            });
        });
    };


    render() {
        const { stuInfo, stuXxcj, stuSsxx, cwhFlag } = this.state;
        return (
            <div>{this.state.operable ?
                <Flex className="w1180 mt-20" align="start">
                    <SiderMenu
                        ref={"siderMenu"}
                        setMenuList={this.setMenuList}
                        title="自助报到"
                        mkbz={this.state.mkbz}
                        /*   active={5}*/
                        menuType="自助报到"
                    />
                    <Flex.Item className="page_con_r" styleName="">
                        <Breadcrumb
                            noLine
                            list={[
                                { name: '首页', url: '/index' },
                                { name: '自助报到', url: '/register' },
                                { name: this.state.hjmc }
                            ]}
                        />
                        <div className="box">
                            <Hint />
                            <TitleTop
                                className="mt-20"
                                title={this.state.hjmc}
                                extra={false}
                            >
                            </TitleTop>
                            <div style={{ marginTop: "10px" }}>
                                <Row>
                                    <Col
                                        style={{ textAlign: "center", padding: "10px" }}
                                        span={8}
                                    >
                                        姓名：{stuInfo ? stuInfo.XM : ""}
                                    </Col>

                                    <Col
                                        style={{ textAlign: "center", padding: "10px" }}
                                        span={8}
                                    >
                                        校区名称：{stuSsxx ? stuSsxx.xqmc : ""}
                                    </Col>
                                    <Col
                                        style={{ textAlign: "center", padding: "10px" }}
                                        span={8}
                                    >
                                        楼栋：{stuSsxx ? stuSsxx.ldmc : ""}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col
                                        style={{ textAlign: "center", padding: "10px" }}
                                        span={8}
                                    >
                                        楼层：{stuSsxx ? stuSsxx.lcmc : ""}
                                    </Col>
                                    <Col
                                        style={{ textAlign: "center", padding: "10px" }}
                                        span={8}
                                    >
                                        房间号：{stuSsxx ? stuSsxx.fj : ""}
                                    </Col>
                                    {cwhFlag ?
                                        <Col
                                            style={{ textAlign: "center", padding: "10px" }}
                                            span={8}
                                        >
                                            床位号：{stuSsxx ? stuSsxx.cwh : ""}
                                        </Col>
                                        : null}
                                </Row>
                            </div>  
                            <div className="text-center mt-50">
                                <NextButton nextUrl={this.state.nextUrl} search={this.state.search} />
                            </div>
                            <style jsx="true" global="true">
                                {`
                                .ant-result-title{
                                    font-size:20px!important;
                                }
                            `}
                            </style>
                        </div>
                    </Flex.Item>
                </Flex> : <CommonTip hjmc={"宿舍信息查看"} key={'GD006_5'} mkbz='GD006_5' nonOperationalData={this.state.nonOperationalData} />}
            </div>
        );
    }
}
