import React from 'react';
import {Link} from 'react-router-dom';
import {Flex} from '@components';
import {Form, Input, Checkbox, Row, Col, Upload, Button,message} from 'antd';
import {createUuid, getLoginUser} from '@utils/NHCore';
import TitleTop from '@pages/Assembly/titleTop';
import NHFetch from "@utils/NHFetch";
import NextButton from '@pages/SelfHelp/common/nextIndex'
const {TextArea} = Input;
const basicUrl = "/zzbd";

const shztList = {
    '-1':'审核不通过',
    '0':'未审核',
    '1':'审核通过',
    '2':'已办理',
    '9':'驳回'
}
export default class View extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dkyhmc:'',
            nextUrl:this.props.nextUrl,
            search:this.props.search,
            hjsqdxlx:[],//缓交申请对象
            dkyhList:[],//贷款银行
            sfsndkList:[],//是否省内贷款
            sfsndkmc:'',
        };
    }

    componentDidMount() {
        this.getDmkMc("YXWZ_DKYH");
        this.getDmkMc("XTGL_SFM");
        this.loadLstdHjsqdx();
    }

    loadLstdHjsqdx=()=>{
        NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList','get','sign=dmk_YXXT_LSTD_HJSQDXLX').then(res=>{
            if(res){
                if(res.data){
                    this.setState({
                        hjsqdxlx:res.data
                    })
                }
            }
        })
    }

    //获取下拉列表value对应的名称
    getDmkMc = (dmbz) => {
        NHFetch(basicUrl + "/getDmkMc", "GET", {dmbz, dmbz})
            .then(res => {
                if (res) {
                    if (res.data) {
                        if(dmbz==="YXWZ_DKYH"){
                         /*    res.data.map(initItem => {
                                if(initItem.DM === data.dkyh){
                                    dkyhmc=initItem.MC;
                                }
                            });
                            this.setState({dkyhmc: dkyhmc}); */
                            this.setState({
                                dkyhList:res.data
                            })
                        }
                        if(dmbz==="XTGL_SFM"){
                               this.setState({
                                   sfsndkList:res.data
                               })
                           }
                    }
                }
            });
    }

    getQueryString =(name)=> {
        return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[, ''])[1].replace(/\+/g, '%20')) || null
    }

    edit=(lstdid,shzt)=>{
        let xsid = getLoginUser()?getLoginUser().userId:'';
        NHFetch('/lstdFb/getLstdInfoByXsid', 'GET',{ xsid: xsid }).then(resData => {
            if (resData && resData.data) {
                if(resData.data.shzt != '' && resData.data.shzt!=undefined && resData.data.shzt == null){
                    if(resData.data.shzt !== '0' && resData.data.shzt !== '9'){
                        message.warning('该申请已经审核，不能修改')
                        return ;
                    }
                    this.props.loadLstdInfo('edit');
                    this.props.reloadMenu();
                }else{
                    this.props.loadLstdInfo('edit');
                    this.props.reloadMenu();
                }
                
            }else{
                message.warning("修改异常");
            }
        })
    }

    // 点击文件链接或预览图标时的回调
    handlePreview = (fjmc, fjid) => {
        let isImage = true;
        if (
            !this.props.setJudge(fjmc,this.props.judgeImgList)
        ) {
            isImage = false;
        }

        if (isImage) {
            window.open(`anonymity/docrepo/download/file?attachmentId=${fjid}`);
            this.props.showPreview(`anonymity/docrepo/download/file?attachmentId=${fjid}`);
        } else {
            window.open(`anonymity/docrepo/download/file?attachmentId=${fjid}`);
        }
    };


    render() {
        const {data,BlztList} = this.props;
        const {hjsqdxlx,dkyhList,sfsndkList} = this.state;
        let x_hjsqdxlx = [];
        if(data['x_hjsqdxlx']){
            let x_hjsqdxlxdm = data['x_hjsqdxlx'].split(',');
            hjsqdxlx.map((item,index)=>{
                x_hjsqdxlxdm.map((elem)=>{
                    if(item.VALUE==elem){
                        x_hjsqdxlx.push(item.LABEL);
                    }
                })
            })
        }
        let dkyhmc = ''
        if(data['dkyh']){
            if(dkyhList){
                dkyhList.map((item,index)=>{
                    if(item.DM === data.dkyh){
                        dkyhmc=item.MC;
                    }
                })
            }
        }

        let sfsndkmc = '';
        if(data['sfsndk']){
            if(sfsndkList){
                sfsndkList.map((item,index)=>{
                    if(item.DM === data.sfsndk){
                        sfsndkmc=item.MC;
                    }
                })
            }
        }
        
        let lstdBlzt = "";
        if(data['sfbllstd']){
            if(BlztList){
                BlztList.map((item,index)=>{
                    if(data['sfbllstd'] == item.VALUE){
                        lstdBlzt = item.LABEL;
                    }
                })
            }
        }
        return (
            <div>
                <TitleTop title="申请信息" extra={false}/>
                <div className="p-sw">
                    <div className="form_item" style={{width: '100%'}}>
                    <Row gutter={24}  style={{margin:"10px 0px"}} style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>绿色通道申请&nbsp;: </span></Col>
                            <Col span={20}><span>{lstdBlzt}</span></Col>
                        </Row>
                        {data['sfbllstd']=='1' && <Row gutter={24}  style={{margin:"10px 0px"}} style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>申请类型&nbsp;: </span></Col>
                            <Col span={20}><span>{data['dklxmc']?data['dklxmc']:""}</span></Col>
                        </Row>}

                        {data['sfbllstd']=='1' && data['dklx'] != '5' && data['dklx'] != 'whrj_4' && data['dklx'] != 'qzy_4' && data['dklx'] != 'jxyy_tyrx'&& <Row gutter={24}  style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>申请理由&nbsp;: </span></Col>
                            <Col span={20}><span>{data['sqsm']?data['sqsm']:""}</span></Col>
                        </Row>}
                        {data['sfbllstd']=='1' &&data['dklx'] != '4' && data['dklx'] != '5' && data['dklx'] != 'whrj_4' && data['dklx'] != 'qzy_4' && data['dklx'] != 'jxsf_3'  && <Row gutter={24}  style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>贷款金额&nbsp;: </span></Col>
                            <Col span={20}><span>{data['blje']?data['blje']:""}元</span></Col>
                        </Row>}
                        {data['sfbllstd']=='1' && (data['dklx'] == 'whrj_4' || data['dklx'] == '4' || data['dklx'] == 'qzy_4') && <Row gutter={24}  style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>缓交金额&nbsp;: </span></Col>
                            <Col span={20}><span>{data['blje']?data['blje']:""}元</span></Col>
                        </Row>}
                        {data['sfbllstd']=='1' && data['dklx'] == '5'  && <Row gutter={24}  style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>减免金额&nbsp;: </span></Col>
                            <Col span={20}><span>{data['blje']?data['blje']:""}元</span></Col>
                        </Row>}
                        {data['sfbllstd']=='1' &&(data['dklx'] == '1' || data['dklx'] == 'whrj_1'||data['dklx'] == 'stye_1') && <Row gutter={24}  style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>贷款银行&nbsp;: </span></Col>
                            <Col span={20}><span>{dkyhmc}</span></Col>
                        </Row>}
                        {data['sfbllstd']=='1' &&(data['dklx'] == 'jxyy_zxdk') && <Row gutter={24}  style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>是否江西省内贷款&nbsp;: </span></Col>
                            <Col span={20}><span>{sfsndkmc}</span></Col>
                        </Row>}
                        {data['sfbllstd']=='1' &&(data['dklx'] == 'jxyy_zxdk') && <Row gutter={24}  style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>贷款银行名称&nbsp;: </span></Col>
                            <Col span={20}><span>{data['dkyhmc']?data['dkyhmc']:""}</span></Col>
                        </Row>}
                        {data['sfbllstd']=='1' && (data['dklx'] == '1' || data['dklx'] == 'whrj_1'||data['dklx'] == 'stye_1' || data['dklx'] == 'jxyy_zxdk') && <Row gutter={24}  style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>6位数回执校验码&nbsp;: </span></Col>
                            <Col span={20}><span>{data['hzjym']?data['hzjym']:""}</span></Col>
                        </Row>}
                        {data['sfbllstd']=='1' && (data['dklx'] == '1' ||data['dklx'] == 'stye_1') && <Row gutter={24}  style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>贷款合同编号&nbsp;: </span></Col>
                            <Col span={20}><span>{data['dkhtbh']?data['dkhtbh']:""}</span></Col>
                        </Row>}
                        {data['sfbllstd']=='1' && data['dklx'] == '5' && <Row gutter={24}  style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>登记录取专业&nbsp;: </span></Col>
                            <Col span={20}><span>{data['x_djlqzy']?data['x_djlqzy']:""}</span></Col>
                        </Row>}
                        {data['sfbllstd']=='1' && (data['dklx'] == 'whrj_4' || data['dklx'] == 'qzy_4') && <Row gutter={24}  style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>缓交截止日期&nbsp;: </span></Col>
                            <Col span={20}><span>{data['x_hjjzrq']?data['x_hjjzrq']:""}</span></Col>
                        </Row>}
                        {data['sfbllstd']=='1' && (data['dklx'] == 'jxyy_tyrx') && <Row gutter={24}  style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>录取时间&nbsp;: </span></Col>
                            <Col span={20}><span>{data['x_lqsj']?data['x_lqsj']:""}</span></Col>
                        </Row>}
                        {data['sfbllstd']=='1' && (data['dklx'] == 'jxyy_tyrx') && <Row gutter={24}  style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>退伍时间&nbsp;: </span></Col>
                            <Col span={20}><span>{data['x_tysj']?data['x_tysj']:""}</span></Col>
                        </Row>}
                        {data['sfbllstd']=='1' && (data['dklx'] == 'jxyy_tyrx') && <Row gutter={24}  style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>复学时间&nbsp;: </span></Col>
                            <Col span={20}><span>{data['x_fxsj']?data['x_fxsj']:""}</span></Col>
                        </Row>}
                        {data['sfbllstd']=='1' && (data['dklx'] == 'jxyy_hjsq') && <Row gutter={24}  style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>家长电话&nbsp;: </span></Col>
                            <Col span={20}><span>{data['x_jzdh']?data['x_jzdh']:""}</span></Col>
                        </Row>}
                        {/* {data['sfbllstd']=='1' && data['dklx'] == 'whrj_4' && <Row gutter={24}  style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>缓交申请对象&nbsp;: </span></Col>
                            <Col span={20}><span>{data['x_hjsqdxlx']?data['x_hjsqdxlx']:""}</span></Col>
                        </Row>} */}
                        {data['sfbllstd']=='1' && data['dklx'] == 'whrj_4' &&
                        <Row gutter={24}  style={{margin:"30px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>缓交申请对象&nbsp;: </span></Col>
                            <Col span={20}>
                                {
                                x_hjsqdxlx && x_hjsqdxlx.map((elem, index) => {
                                    return (
                                        <span style={{
                                            borderColor: '#1890ff',
                                            borderRadius: '5px',
                                            padding: '2px 16px',
                                            border: '1px solid rgb(24, 144, 255)',
                                            margin: '5px',
                                            display: 'inline-block'
                                        }}>
                                       {elem}
                                   </span>
                                    );
                                })
                                }
                            </Col>
                        </Row>}
                        {data['sfbllstd']=='1' && (data['dklx'] == 'whrj_1' ||data['dklx'] == 'jxsf_3'||data['dklx'] == 'stye_1'|| data['dklx'] == 'stye_3' || data['dklx'] == 'qzy_4' || data['dklx'] =='jxyy_zxdk' || data['dklx'] =='jxyy_hjsq') && 
                        <Row gutter={24}  style={{margin:"30px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>办理原因&nbsp;: </span></Col>
                            <Col span={20}>
                                {data['blyy'].map((elem, index) => {
                                    return (
                                        <span style={{
                                            borderColor: '#1890ff',
                                            borderRadius: '5px',
                                            padding: '2px 16px',
                                            border: '1px solid rgb(24, 144, 255)',
                                            margin: '5px',
                                            display: 'inline-block'
                                        }}>
                                       {elem}
                                   </span>
                                    );
                                })}
                            </Col>
                        </Row>}
                    </div>
                </div>

                {data['sfbllstd']=='1' && <TitleTop title="申请材料" extra={false}/>}
                {data['sfbllstd']=='1'  && <div className="p-sw">
                    <div className="form_item" style={{width: '100%' }}>
                        <Row gutter={24}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>材料证明&nbsp;: </span></Col>
                            <Col span={20}>
                                {data['fjmap'] && data['fjmap'].map((elem, index) => {
                                    return (
                                        <div style={{marginBottom: '20px'}}>
                                            <a onClick={() => {
                                                this.handlePreview(elem['fjmc'], elem['fjid']);
                                            }}>{elem['fjmc']}</a>
                                        </div>
                                    )
                                })
                                }
                            </Col>
                        </Row>
                    </div>
                </div>}
                {data['sfbllstd']=='1' && <TitleTop title="申请审核" extra={false}/>}
                {data['sfbllstd']=='1' && <div className="p-sw">
                    <div className="form_item" style={{width: '100%', marginBottom: '24px'}}>
                        <Row gutter={24}  style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>审核意见&nbsp;: </span></Col>
                            <Col span={20}><span>{data['shyj']}</span></Col>
                        </Row>
                    </div>
                    <br/>
                    <div className="form_item" style={{width: '100%', marginBottom: '24px'}}>
                        <Row gutter={24}  style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>审核状态&nbsp;: </span></Col>
                            <Col span={20}><span style={{color: '#0066FF'}}>{shztList[data['shzt']]}</span></Col>
                        </Row>
                    </div>
                </div>}
                <div className="text-center mt-50">
                    {data['sfbllstd']=='1' &&  data['shzt'] == "9" && <Button type="primary" onClick={()=>{this.edit(data['pkid'],data['shzt'])}}>修改</Button>}
                    <NextButton nextUrl={this.state.nextUrl}  search={this.state.search}/>
                </div>
            </div>
        );
    }
}
