import React, { Component } from 'react'
import  './index.css';
import Layout from "@pages/SelfHelp/Bdd/pages/common";
import Main from '@pages/SelfHelp/Bdd/pages/zszyjsxy/print'
import PropTypes from 'prop-types'

const schoolCode = `zszyjsxy`;
/**
 * @author qianzhiwei
 * @date 2020/09/27
 * @description 报到单
 */
export class ZszyjsxyIndex extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            extraData:{}    //额外参数
        }
    }

    render() {
        return (
            <div>
                <Layout schoolCode={schoolCode}>
                    <Main></Main>
                </Layout>
            </div>
        )
    }

    static propTypes = {

    }
}

export default ZszyjsxyIndex
