import React, { Component } from 'react'
import Print from '@pages/SelfHelp/print/printTemplate'
import PropTypes from 'prop-types'

export class LydxPrint extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             
        }
    }

    render() {
        return (
            <Print/>
        )
    }

    static propTypes = {

    }
}

export default LydxPrint
