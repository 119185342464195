import React from 'react';
import { Flex } from '@components';
import {
    Form, Input, Row, Col, Button, Modal,
    Upload, Icon, message, Spin, InputNumber, Radio
} from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import { createUuid, getLoginUser } from '@utils/NHCore';
import NHFetch from "@utils/NHFetch";
import { get, post } from '@utils/request';
import { getQueryString } from "@utils/globalUtil";
import View from "@pages/SelfHelp/anLv/view";
const Dragger = Upload.Dragger;
import CommonTip from "@pages/SelfHelp/common";
import NHSelect from "@components/common/NHSelect";
import NextButton from '@pages/SelfHelp/common/nextIndex'
import { mkbz } from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";
import NHFormItem from "@components/common/NHFormItem";
const { TextArea } = Input;
const FormItem = Form.Item;
const baseUrl = '/api/zhxg-yxwz'

@Form.create()
export default class AnLv extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mkbz: 'GD005_8',
            hjmc: '',
            uploading: false,
            loading: false,
            previewVisible: false,
            fileList: [],
            checkGroup: [],//绿色通道办理原因
            data: {},//绿色通道申请信息
            nextUrl: '',
            search: '',
            operable: true,//是否显示环节
            nonOperationalData: {},//不可操作的数据
            fdm: 1, //默认办理方式
            judgeImgList: ['.png', '.PNG', '.jpg', '.JPG', '.bpm', '.BPM', '.jpeg', '.JPEG'],   //可以导入的图片格式
            judgeFileList: ['.pdf', '.PDF', '.doc', '.DOC', '.zip', '.ZIP', '.txt', '.TXT', '.xls', '.XLS', '.rar', '.RAR'],  //可以导入的非图片格式
            blYyList: [],//总办理原因
            blYyValue: '',//选中的办理原因
            submitFlag: false,//是否提交
        };
    }

    componentDidMount() {
        this.queryQztjAndYlhj((operable) => {
            if (operable) {
                this.loadLstdBlyy('AHGS_YXXT_LSTD_BLYY');//获取办理原因
                this.loadLstdInfo();//查询申请后的信息
            }
        });

    }

    //查询前置条件和依赖环节
    queryQztjAndYlhj = (callback) => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({
                nonOperationalData: { nologin: true },
                operable: false,
            });
            return;
        }
        let zzbdhjid = getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj'];
                    this.setState({ nonOperationalData: res.data });
                    if (kfsj && kfsj['YXSJ'] != '1' || ylhj && ylhj.length > 0 || qzhj && qzhj.length > 0) {
                        this.setState({ operable: false });
                    }
                    if (nohj) {
                        this.setState({ operable: false });
                    }
                    if (callback) {
                        callback(this.state.operable);
                    }

                }
            });
    }
    // 获取办理原因
    loadLstdBlyy = (dmbz) => {
        NHFetch("/openapi/yxwz/getDmk", "GET", { dmbz, dmbz })
            .then(res => {
                if (!res || res.code !== 200) return message.error('系统繁忙，请联系管理员!')
                this.setState({ blYyList: res.data })
            });
    }

    // 选择办理方式
    onChange = (event) => {
        this.props.form.setFieldsValue({ blyy: null });//重置办理原因选中值
        this.setState({ fdm: event.target.value, blYyValue: '' })
    }
    // 选择办理原因
    onChange1 = (event) => {
        this.setState({ blYyValue: event.target.value })
    }

    setMenuList = (menuList) => {
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC;
                this.setState({ hjmc: hjmc })
                nextCount = index + 1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if (menu) {
            let hjdm = menu.HJDM;
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID,
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: '',
            })
        }
    }

    //查询学生的绿色通道申请信息
    loadLstdInfo = () => {
        get(baseUrl +'/lstdAhgs/getLstdInfoByXsid').then(res => {
            if (res && res.code === 200) {
                if (!res.data) {
                    this.setState({
                        data: {},
                        fileList: [],
                        submitFlag: false,
                        fdm: 1,
                        blYyValue: '',
                    });
                    this.props.form.resetFields();
                    return;
                }
                let fileList = [];
                res.data['fj'] && res.data['fj'].forEach(elem => {
                    //格式化对于附件取值
                    let file = {
                        uid: elem.fjid,
                        name: elem.fjmc,
                        response: {
                            data: {
                                ssbh: elem.fjid,
                                fjmc: elem.fjmc,
                            }
                        },
                        status: 'done'
                    }
                    fileList.push(file);
                })
                if (res.data.shzt != '9') this.setState({ submitFlag: true })
                this.setState({
                    data: res.data,
                    fdm: res.data.fdm,
                    fileList,
                    blYyValue: res.data.dklx,
                })
                this.props.form.setFieldsValue({ fj: { fileList } });
            } else {
                this.setState({ data: {}, fileList: [] });
                this.props.form.resetFields();
            }
        });
    }

    // 转化base64
    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    // 点击文件链接或预览图标时的回调
    handlePreview = file => {
        const isImage = /^image\/.*$/i.test(file.type);
        if (isImage) {
            if (file) {
                this.getBase64(file, imageUrl => {
                    this.setState({
                        previewImage: imageUrl,
                        previewVisible: true
                    });
                });
            } else {
                this.setState({
                    previewImage: file.url || file.thumbUrl,
                    previewVisible: true
                });
            }
        } else if (file && file['response'] && file['response']['data']) {//其他情况
            let isImage = true;
            let fjmc = file['response']['data']['fjmc'];
            let ssbh = file['response']['data']['ssbh'];
            if (
                !this.setJudge(fjmc, this.state.judgeImgList)
            ) {
                isImage = false;
            }

            if (isImage) {
                this.showPreview(`anonymity/docrepo/download/file?attachmentId=${ssbh}`);
            } else {
                window.open(`anonymity/docrepo/download/file?attachmentId=${ssbh}`);
            }
        }
    };

    showPreview = (url) => {
        this.setState({
            previewImage: url,
            previewVisible: true
        });
    }

    // 关闭图片弹窗
    handleCancel = () => this.setState({ previewVisible: false });

    // 提交
    handleSubmit = e => {
        this.setState({ loading: true });
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                this.setState({ loading: false });
                return false;
            }
            const fj = values['fj'];
            let fjlist = [];
            if (fj) {
                let size = fj['fileList'].length;
                let file = fj['fileList'].filter((file) => {
                    if (file.status === 'error') {
                        return false;
                    }
                    return true;
                });
                if (file.length !== size) {
                    this.props.form.setFields({
                        fj: {
                            value: fj,
                            errors: [new Error('材料上传出错，请重新上传!')],
                        }
                    });
                    this.setState({ loading: false });
                    return false;
                }

                let fileList = fj['fileList'];
                fileList.forEach(element => {
                    let fjid = element.response.data.ssbh;
                    let fjmc = element.name;
                    fjlist.push({
                        fjid: fjid,
                        fjmc: fjmc
                    });
                });
            }

            if (fjlist.length === 0) fjlist = undefined;
            console.log('附件:', fjlist)
            const { blyy, blje, sqsm, dkyh, hzjym, pkid } = values;
            const xsid = getLoginUser().userId;
            const zzbdhjid = getQueryString('pkid');
            let params = {
                fj: fjlist,//附件
                dklx: blyy,//办理原因
                blje,//贷款金额
                sqsm,//申请理由
                hzjym,//回执检验码
                dkyh,//贷款银行
                xsid,//学生id
                zzbdhjid,//环节id
                pkid,//流程id
                sjly: 2,//数据来源：1.迎新现场办理\2.学生申请
                shzt: 0,//审核状态
            }
            console.log('params:', params)
            if (!pkid) {
                post(baseUrl +'/lstdAhgs/insert', params)
                    .then(res => {
                        this.setState({ loading: false })
                        if (!res || res.code !== 200 || res.data != 1) return message.error("提交失败!")
                        message.success("绿色通道申请提交成功。");
                        this.loadLstdInfo();
                        this.reloadMenu();
                    }).catch((res) => {
                        this.setState({ loading: false })
                        return res;
                    });
            } else {
                post(baseUrl +'/lstdAhgs/update', params)
                    .then(res => {
                        this.setState({ loading: false })
                        if (!res || res.code !== 200 || res.data != 1) return message.warning("绿色通道申请修改失败，请重试!")
                        message.success("绿色通道申请修改成功。");
                        this.loadLstdInfo();
                        this.reloadMenu();
                    }).catch((res) => {
                        this.setState({ loading: false })
                        return res;
                    });
            }
        });
    };

    handleChange = ({ fileList }) => {
        let count = 0;
        fileList.map((elem) => {
            if (elem && elem['status'] && elem['status'] === 'uploading') {
                this.setState({ uploading: true });
                return;
            } else if (elem && elem['status']) {
                count++;
            }
        })
        if (count > 0) {
            this.setState({ uploading: false });
        }
    }

    /* 附件上传与处理部分---start */
    //判断文件类型是否正常  name：文件名称  map：类型范围数组  true正常
    setJudge = (name, map) => {
        let flag = false;
        map.map(item => {
            if (name.indexOf(item) !== -1) {
                flag = true;
            }
        })
        return flag;
    }

    beforeUpload = (file) => {
        if (
            !(this.setJudge(file.name, this.state.judgeImgList) ||
                this.setJudge(file.name, this.state.judgeFileList))
        ) {
            message.error('不能上传该格式文件');
            return false;
        } else {
            return true;
        }
    }
    remove = (ssbh) => {
        let url = `api/docrepo/attachment/sourceId/delete?souerceId=${ssbh}`;
        NHFetch(url, "POST").then(res => {
            if (res.data && res.data > 0) {
                message.success('已删除材料.');
            }
        });
    }
    /* end */

    //重新加载菜单
    reloadMenu = () => {
        this.refs.siderMenu.reloadMenuList()
    }

    render() {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        const { previewImage, previewVisible, submitFlag, fdm, data,
            blYyList, blYyValue } = this.state;
        const props = {
            name: 'file',
            action: 'anonymity/docrepo/upload',
            data: { uuid: createUuid(), type: '1' },

            onRemove: file => {
                this.setState(({ fileList }) => {
                    const index = fileList.indexOf(file);
                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    this.props.form.setFieldsValue({ fj: { fileList: newFileList } });
                    return {
                        fileList: newFileList
                    };
                }, () => {
                    if (file.response && file.response.data && file.response.data.ssbh) {
                        this.remove(file.response.data.ssbh);
                    }
                });
            },
            beforeUpload: file => {
                if (!this.beforeUpload(file)) {
                    return false;
                }
                this.setState(({ fileList }) => ({
                    fileList: [...fileList, file]
                }));
                return true;
            },
            onChange: file => { this.handleChange(file) },
            onPreview: this.handlePreview,
            fileList: this.state.fileList,
        };
        // 设置展示免缴学费
        const showList = blYyList.filter(item => item.FDM == fdm)
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 16 }
        };
        return (
            <div style={{ position: 'relative' }}>
                {this.state.operable ?
                    <div>
                        <Flex className="w1180 mt-20" align="start">
                            <SiderMenu
                                ref={"siderMenu"}
                                setMenuList={this.setMenuList}
                                title="自助报到"
                                mkbz={this.state.mkbz}
                                menuType='自助报到' />
                            <Flex.Item className="page_con_r" styleName="">
                                <Breadcrumb
                                    noLine
                                    list={[
                                        { name: '首页', url: '/index' },
                                        { name: '自助报到', url: '/register' },
                                        { name: this.state.hjmc }
                                    ]}
                                />
                                <div className="box">
                                    <Hint />
                                    {!submitFlag ?
                                        <Form onSubmit={this.handleSubmit}>
                                            <TitleTop title="申请信息" extra={false} />
                                            <div className="p-sw">
                                                <div style={{ display: 'none' }}>
                                                    <NHFormItem id={'pkid'} initialValue={data['pkid']} form={form}>
                                                        <Input />
                                                    </NHFormItem>
                                                </div>
                                                <div style={{ marginLeft: "30px" }}>
                                                    <NHFormItem id={'blfs'} required={true} initialValue={Number(fdm)} form={form}
                                                        formItemLayout={{ labelCol: { span: 4 }, wrapperCol: { span: 16 } }} label="办理方式">
                                                        <Radio.Group name="radiogroup" onChange={this.onChange}>
                                                            <Radio value={1}>免缴学费</Radio>
                                                            <Radio value={2}>缓缴学费</Radio>
                                                        </Radio.Group>
                                                    </NHFormItem>
                                                    <NHFormItem id={'blyy'} required={true} initialValue={data['dklx'] ? data['dklx'] : null} form={form}
                                                        formItemLayout={{ labelCol: { span: 4 }, wrapperCol: { span: 16 } }} label="办理原因">
                                                        <Radio.Group name="radiogroup" onChange={this.onChange1}>
                                                            {
                                                                showList.map(item => {
                                                                    return <Radio value={item.DM} key={item.PXH}>{item.MC}</Radio>
                                                                })
                                                            }
                                                        </Radio.Group>
                                                    </NHFormItem>
                                                </div>
                                                <div className="form_item" style={{ marginLeft: '7%' }}>
                                                    {fdm == 2 &&
                                                        <NHFormItem id={'sqsm'} required={true} initialValue={data['sqsm']} form={form}
                                                            formItemLayout={formItemLayout} label="申请理由">
                                                            <TextArea maxLength={50} placeholder="请填写申请理由" />
                                                        </NHFormItem>}
                                                    {(blYyValue && blYyValue === 'syddk') && <div>
                                                        <NHFormItem id={'blje'} initialValue={data['blje']} form={form} formItemLayout={formItemLayout} label="贷款金额">
                                                            <InputNumber style={{ width: '100%' }} min={0} max={9999999} precision={0} placeholder="请填写贷款金额（元）" />
                                                        </NHFormItem>
                                                        <NHFormItem id={'dkyh'} initialValue={data['dkyh']} form={form} formItemLayout={formItemLayout} label="贷款银行">
                                                            <NHSelect sign={"dmk_yxwz_dkyh"} onChange={(v) => { }} />
                                                        </NHFormItem>
                                                        <NHFormItem id={'hzjym'} initialValue={data['hzjym']} form={form} formItemLayout={formItemLayout} label="回执校验码">
                                                            <Input placeholder="请输入回执校验码" />
                                                        </NHFormItem>
                                                    </div>}
                                                </div>
                                            </div>
                                            <TitleTop title="申请材料" extra={false} />
                                            <div className="p-sw">
                                                <div className="form_item" style={{ marginLeft: '7%' }}>
                                                    <FormItem
                                                        labelCol={{ span: 8 }}
                                                        wrapperCol={{ span: 16 }}
                                                        label="上传受理证明"
                                                    >
                                                        {getFieldDecorator('fj')(
                                                            <Dragger {...props}>
                                                                <p className="ant-upload-drag-icon">
                                                                    <Icon type="inbox" />
                                                                </p>
                                                                <Spin spinning={this.state.uploading} />
                                                                <p className="ant-upload-text font-14">
                                                                    点击此处或拖拽文件到此
                                                                </p>
                                                            </Dragger>
                                                        )}
                                                    </FormItem>
                                                </div>
                                            </div>
                                            {data['shzt'] === '9' ?
                                                <div>
                                                    <TitleTop title="审核信息" extra={false} />
                                                    <div className="p-sw">
                                                        <div className="form_item" style={{ width: '100%', marginBottom: '24px' }}>
                                                            <Row gutter={24}>
                                                                <Col span={4} style={{ textAlign: 'right', paddingRight: 0 }}><span
                                                                    style={{ color: 'rgba(0, 0, 0, 0.85)' }}>审核意见&nbsp;: </span></Col>
                                                                <Col span={20}><span>{data['shyj']}</span></Col>
                                                            </Row>
                                                        </div>
                                                        <br />
                                                        <div className="form_item" style={{ width: '100%', marginBottom: '24px' }}>
                                                            <Row gutter={24}>
                                                                <Col span={4} style={{ textAlign: 'right', paddingRight: 0 }}><span
                                                                    style={{ color: 'rgba(0, 0, 0, 0.85)' }}>审核状态&nbsp;: </span></Col>
                                                                <Col span={20}><span style={{ color: '#0066FF' }}>驳回</span></Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </div> : ""}
                                            <div className="text-center mt-50">
                                                <Button type="primary" htmlType="submit">
                                                    保存
                                                </Button>
                                                <NextButton nextUrl={this.state.nextUrl} search={this.state.search} />
                                            </div>
                                        </Form>
                                        :
                                        <View reloadMenu={this.reloadMenu} nextUrl={this.state.nextUrl} loadLstdInfo={this.loadLstdInfo}
                                            search={this.state.search} showPreview={this.showPreview} setJudge={this.setJudge} data={data} judgeImgList={this.state.judgeImgList} />
                                    }
                                </div>
                            </Flex.Item>
                        </Flex>
                        <Modal
                            visible={previewVisible}
                            footer={null}
                            onCancel={this.handleCancel}
                        >
                            <img
                                alt="example"
                                style={{ width: '100%' }}
                                src={previewImage}
                            />
                        </Modal>
                        <Spin style={{ position: 'absolute', top: '300px', left: '60%' }} spinning={this.state.loading} />
                    </div>
                    :
                    <CommonTip hjmc={"绿色通道申请"} key={"GD005_8"} mkbz='GD005_8' nonOperationalData={this.state.nonOperationalData} />
                }
            </div>

        );
    }
}
