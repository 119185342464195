import React from 'react';
import { Result } from 'antd';
import QRCode from 'qrcode.react';
import NHFetch from "@utils/NHFetch";


export default class QrCodeIndex extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            paySuccess: false,
            timeOutTip: false
        }
    }
    componentDidMount(){

        const {hjdm} = this.props;

         const checkOrderStatus = () => {
            const { paySuccess } = this.state;
            NHFetch('/zjkjxy/wxChatPay/queryOrderInformation', 'GET', {hjdm: hjdm, accountName: 'zjkj_kdtczh'}).then(res => {
                if (res && res.code == 200) {
                    if (res.data == 1) {
                        console.log("支付成功")
                        this.setState({
                            paySuccess: true
                        })
                    } else if (res.data == 0) {
                        console.log("继续轮询")
                        if (paySuccess) { //支付成功
                            clearInterval(intervalId);
                            clearTimeout(timeOutId);
                        }
                    }
                }
            })
        }

        // 每隔5秒执行一次myFunction函数，并在60秒后停止
        let intervalId = setInterval(checkOrderStatus, 5000);

        // 在60秒后清除间隔
        let timeOutId = setTimeout(() => {
            console.log('支付超时，关闭二维码');
            clearInterval(intervalId);
            this.setState({
                timeOutTip: true
            })

            setTimeout(() => this.props.cancel(), 3000);
        }, 125000);
    }


    render() {
        const { qrcode } = this.props;
        const { paySuccess, timeOutTip } = this.state;
        return (
            <div style={{display:'flex',justifyContent:'center'}}>
                <div className="result-example">
                    <QRCode className="spe" value={qrcode} renderAs='canvas'
                            style={{
                                height: '160px',
                                width: '160px',
                                padding:'1.0rem'
                            }}
                    />
                    { (!paySuccess && !timeOutTip) &&
                        <p style={{fontSize:'18px'}}>请使用微信扫码缴费，两分钟后超时</p>
                    }
                    { paySuccess &&
                        <p style={{color: 'green', fontSize:'18px'}}>支付成功，请刷新页面...</p>
                    }
                    { timeOutTip &&
                        <p style={{color: 'red', fontSize:'18px'}}>支付超时，即将关闭二维码...</p>
                    }
                </div>

                <style jsx="true" global="true">
                    {`
                          .result-example .spe {
                            width: 160px;
                            height: 160px;
                          }
                         .am-result .am-result-pic{
                            width: 160px;
                            height: 160px;
                         }
                        
                    `}
                </style>
            </div>
        );
    }
}
