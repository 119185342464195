import React, { Component } from 'react'
import { Flex } from '@components';
import { Table } from 'antd';
import TitleTop from '@pages/Assembly/titleTop';
import {getLoginUser,createUuid} from "@utils/NHCore";
import NHFetch from "@utils/NHFetch";
import BarCode from 'react-barcode';
import './index.css';
import PropTypes from 'prop-types'

export class OtherPrint extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    render() {
        let { xsxx, schoolName, year, nr, account, bddbz,headimg } = this.props.initFormDate;
        // if(initFormDate){
        //     xsxx = xsxx ? xsxx : initFormDate.xsxx;
        //     headimg = headimg ? headimg : initFormDate.headimg;
        //     schoolName = schoolName || schoolName == "" ? schoolName : initFormDate.schoolName;
        //     year = year ? year : initFormDate.year;
        //     nr = nr ? nr : initFormDate.nr;
        //     account = account ? account : initFormDate.account;
        //     bddbz = bddbz ? bddbz : initFormDate.bddbz;
        // }
        const sfxmList = xsxx.sfxmList
        if (sfxmList) {
            for (let i = 0; i < sfxmList.length; i++) {
                sfxmList[i].row_id = i + 1;
            }
        }
        const payColumns = [
            {
                title: '序号',
                dataIndex: 'row_id'
            },
            {
                title: '收费项目',
                dataIndex: 'sfxmmc'
            },
            {
                title: '应收金额',
                dataIndex: 'ysje'
            },
            {
                title: '已交金额',
                dataIndex: 'ssje',
                render: (text, record) => {
                    return <span className="text-primary">{text}</span>;
                }
            }
        ];
        const Columns = [
            {
                title: '序号',
                dataIndex: 'row_id'
            },
            {
                title: '主要内容',
                dataIndex: 'nr'
            }
        ];
        return (
            <div>
                <div className="text-center">
                    <div styleName="title">
                        {schoolName}{year}新生入学报到单
                                        </div>
                </div>

                <TitleTop title="基本信息" extra={false} />
                <Flex className="p-sw-big"> 
                    {headimg}
                    <Flex.Item className="ml-20">
                        {[
                            {
                                label: '姓名',
                                name: xsxx.xm
                            },
                            {
                                label: '性别',
                                name: xsxx.xb
                            },
                            {
                                label: '考生号',
                                name: xsxx.ksh
                            },
                            {
                                label: '民族',
                                name: xsxx.mz
                            },
                            {
                                label: '政治面貌',
                                name: xsxx.zzmm
                            },
                            {
                                label: '籍贯',
                                name: xsxx.jg
                            },
                            {
                                label: '联系电话',
                                name: xsxx.sjhm
                            },
                            {
                                label: '身份证号',
                                name: xsxx.sfzjh
                            },
                            {
                                label: '考生类型',
                                name: xsxx.xslb
                            },
                            {
                                label: '学院',
                                name: xsxx.bmmc
                            },
                            {
                                label: '专业',
                                name: xsxx.zymc
                            },
                            {
                                label: '班级',
                                name: xsxx.bjmc
                            }
                        ].map((elem, index) => {
                            return (
                                <div key={index} styleName="jbxx_item">
                                    <span styleName="item-label">{elem.label}：</span>
                                    <div
                                        className="t-clamp-1"
                                        styleName="item-name"
                                        style={{ display: 'inline' }}
                                    >
                                        {elem.name}
                                    </div>
                                </div>
                            );
                        })}
                    </Flex.Item>
                </Flex>
                <TitleTop title="宿舍信息" extra={false} />
                <div className="p-sw-big">
                    <div styleName="ssxx_list">
                        <div styleName="ssxx_item">
                            <div styleName="ssxx_label">
                                校区：
                                                </div>
                            <div styleName="ssxx_name">
                                {xsxx.xqmc}
                            </div>
                        </div>
                        <div styleName="ssxx_item">
                            <div styleName="ssxx_label">
                                楼栋：
                                                </div>
                            <div styleName="ssxx_name">
                                {xsxx.ldmc}
                            </div>
                        </div>
                    </div>
                    <div styleName="ssxx_list">
                        <div styleName="ssxx_item">
                            <div styleName="ssxx_label">
                                房间：
                                                </div>
                            <div styleName="ssxx_name">
                                {xsxx.fjmc}
                            </div>
                        </div>
                        <div styleName="ssxx_item">
                            <div styleName="ssxx_label">
                                床位：
                                                </div>
                            <div styleName="ssxx_name">
                                {xsxx.cwh}
                            </div>
                        </div>
                    </div>
                </div>
                <TitleTop title="缴费信息" extra={false} />
                <div className="p-sw-big">
                    <Table
                        bordered
                        size="middle"
                        pagination={false}
                        dataSource={xsxx.sfxmList}
                        columns={payColumns}
                    />
                </div>
                <TitleTop title="二级学院报到" extra={false} />
                <div className="p-sw-big">
                    <Table
                        bordered
                        size="middle"
                        pagination={false}
                        dataSource={nr}
                        columns={Columns}
                    />
                </div>
                <Flex className="p-sw-big mt-30">
                    <Flex.Item>
                        <div className="text-title">
                            备注：
                                            </div>
                        <div styleName="bz-item">{bddbz}</div>
                    </Flex.Item>
                    <BarCode styleName="bz-img"
                        value={account}
                        width={2}
                        height={60}
                        fontSize={18}
                    >
                    </BarCode>

                </Flex>
            </div>
        )
    }

    static propTypes = {

    }
}

export default OtherPrint
