import React from 'react';
import { Row, Col, Button, message } from 'antd';
import TitleTop from '@pages/Assembly/titleTop';
import NHFetch from "@utils/NHFetch";
import { getQueryString } from "@utils/globalUtil";
import NextButton from '@pages/SelfHelp/common/nextIndex'


const shztList = {
    '-1': '审核不通过',
    '0': '未审核',
    '1': '审核通过',
    '2': '已办理',
    '9': '驳回'
}
export default class View extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nextUrl: this.props.nextUrl,
            search: this.props.search,
        };
    }

    componentDidMount() { }
    // 取消申请
    cancel = (lstdid, shzt) => {
        if (shzt !== '0') return message.warning('该申请已经审核，不能取消')
        const pkid = getQueryString("pkid");
        let params = {
            zzbdhjid: pkid,
            pkid: lstdid
        }

        NHFetch("/lstd/deleteByParams", "POST", params)
            .then(res => {
                if (res && res.data && res.data > 0) {
                    message.success("取消成功");
                    this.props.loadLstdInfo();
                    this.props.reloadMenu();
                } else {
                    message.warning("取消异常");
                }
            });
    }

    // 点击文件链接或预览图标时的回调
    handlePreview = (fjmc, fjid) => {
        let isImage = true;
        if (
            !this.props.setJudge(fjmc, this.props.judgeImgList)
        ) {
            isImage = false;
        }

        if (isImage) {
            this.props.showPreview(`anonymity/docrepo/download/file?attachmentId=${fjid}`);
        } else {
            window.open(`anonymity/docrepo/download/file?attachmentId=${fjid}`);
        }
    };


    render() {
        const { data } = this.props;
        return (
            <div>
                <TitleTop title="申请信息" extra={false} />
                <div className="p-sw">
                    <div className="form_item" style={{ width: '100%', marginBottom: '20px' }}>
                        <Row gutter={24}>
                            <Col span={4} style={{ textAlign: 'right', marginTop: "3px", paddingRight: 0 }}><span
                                style={{ color: 'rgba(0, 0, 0, 0.85)' }}>办理原因&nbsp;: </span></Col>
                            <Col span={20}><span>{data['dklxmc']}</span></Col>
                        </Row>
                    </div>
                    <div className="form_item" style={{ width: '100%' }}>
                        {data['fdm'] && data['fdm'] == '2' &&
                            <div>
                                <Row gutter={24}>
                                    <Col span={4} style={{ textAlign: 'right', paddingRight: 0 }}><span
                                        style={{ color: 'rgba(0, 0, 0, 0.85)' }}>申请理由&nbsp;: </span></Col>
                                    <Col span={20}><span>{data['sqsm']}</span></Col>
                                </Row>
                                <br />
                            </div>
                        }
                        {data['dklx'] && data['dklx'] === 'syddk' && <div>
                            <Row gutter={24}>
                                <Col span={4} style={{ textAlign: 'right', paddingRight: 0 }}><span
                                    style={{ color: 'rgba(0, 0, 0, 0.85)' }}>贷款金额&nbsp;: </span></Col>
                                <Col span={20}><span>{data['blje'] ? data['blje'] : 0}元</span></Col>
                            </Row>
                            <br />
                            <Row gutter={24}>
                                <Col span={4} style={{ textAlign: 'right', paddingRight: 0 }}><span
                                    style={{ color: 'rgba(0, 0, 0, 0.85)' }}>贷款银行&nbsp;: </span></Col>
                                <Col span={20}><span>{data['dkyhmc'] ? data['dkyhmc'] : '暂无'}</span></Col>
                            </Row>
                            <br />
                            <Row gutter={24}>
                                <Col span={4} style={{ textAlign: 'right', paddingRight: 0 }}><span
                                    style={{ color: 'rgba(0, 0, 0, 0.85)' }}>回执校验码&nbsp;: </span></Col>
                                <Col span={20}><span>{data['hzjym'] ? data['hzjym'] : '暂无'}</span></Col>
                            </Row>
                            <br />
                        </div>}
                    </div>
                </div>
                <TitleTop title="申请材料" extra={false} />
                <div className="p-sw">
                    <div className="form_item" style={{ width: '100%' }}>
                        <Row gutter={24}>
                            <Col span={4} style={{ textAlign: 'right', paddingRight: 0 }}><span
                                style={{ color: 'rgba(0, 0, 0, 0.85)' }}>材料证明&nbsp;: </span></Col>
                            <Col span={20}>
                                {data['fj'] ? data['fj'].map((elem, index) => {
                                    return (
                                        <div style={{ marginBottom: '20px' }} key={index}>
                                            <a onClick={() => {
                                                this.handlePreview(elem['fjmc'], elem['fjid']);
                                            }}>{elem['fjmc']}</a>
                                        </div>
                                    )
                                })
                                    : <span>暂无</span>
                                }
                            </Col>
                        </Row>
                    </div>
                </div>
                <TitleTop title="申请审核" extra={false} />
                <div className="p-sw">
                    <div className="form_item" style={{ width: '100%', marginBottom: '24px' }}>
                        <Row gutter={24}>
                            <Col span={4} style={{ textAlign: 'right', paddingRight: 0 }}><span
                                style={{ color: 'rgba(0, 0, 0, 0.85)' }}>审核意见&nbsp;: </span></Col>
                            <Col span={20}><span>{data['shyj']}</span></Col>
                        </Row>
                    </div>
                    <br />
                    <div className="form_item" style={{ width: '100%', marginBottom: '24px' }}>
                        <Row gutter={24}>
                            <Col span={4} style={{ textAlign: 'right', paddingRight: 0 }}><span
                                style={{ color: 'rgba(0, 0, 0, 0.85)' }}>审核状态&nbsp;: </span></Col>
                            <Col span={20}><span style={{ color: '#0066FF' }}>{shztList[data['shzt']]}</span></Col>
                        </Row>
                    </div>
                </div>
                <div className="text-center mt-50">
                    <Button type="primary" onClick={() => { this.cancel(data['pkid'], data['shzt']) }}>
                        取消
                    </Button>
                    <NextButton nextUrl={this.state.nextUrl} search={this.state.search} />
                </div>
            </div >
        );
    }
}
