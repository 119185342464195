import { asyncImport as sync } from '@utils/Router/AsyncImport';
import LydxIndex from '@pages/SelfHelp/Bdd/pages/lydx/index';
import LydxrPrint from '@pages/SelfHelp/Bdd/pages/lydx/print';
import ZszyjsxyIndex from '@pages/SelfHelp/Bdd/pages/zszyjsxy/index';
import ZszyjsxyPrint from '@pages/SelfHelp/Bdd/pages/zszyjsxy/print';
import ScjtzyxyIndex from '@pages/SelfHelp/Bdd/pages/scjtzyxy/index';
import ScjtzyxyPrint from '@pages/SelfHelp/Bdd/pages/scjtzyxy/print';
import GznyzyxyIndex from '@pages/SelfHelp/Bdd/pages/gznyzyxy/index'
import GznyzyxyPrint from '@pages/SelfHelp/Bdd/pages/gznyzyxy/print';
import OtherIndex from '@pages/SelfHelp/Bdd/pages/other/index';
import OtherPrint from '@pages/SelfHelp/Bdd/pages/other/print';

/**
 * 
 * @author qianzhiwei
 * @date 2020/09/25
 * 
 * @description 报到单学校模版配置
 * 
 * A:完全新报到单
 * 1、维护学校代码库 ：YXXT_BDD_SCHOOL【学校报到单模版】
 * 2、新增一个学校的报到单页面：在 @pages/SelfHelp/Bdd/pages 下新建学校代码库维护的代码名文件夹，包含 index 和 print 子文件夹，具体参考zszyjsxy
 * 3、配置动态路由参数 bdd_school { dm: 学校代码库常量值, mc: 学校代码库常量名, indexComponent: 报到单组件地址, printComponent: 打印单组件地址 }
 * 4、主页面编写在 print 文件夹下，index 报到单页面引用 print 下的页面即可。具体参考zszyjsxy
 * 
 * B:修改基线报到单
 * 1、复制 other 文件夹内容去新学校代码文件夹，然后子文件夹名不用修改
 * 2、修改样式文字之类的在 print 文件夹下的组件修改
 * 3、修改数据在 index 文件夹下的组件修改
 * 
 */

//报到单参数配置
export const bdd_school = [
    {
        dm: 'lydx', mc: '联奕大学',
        // indexComponent: sync(() => import( /* webpackChunkName: 'lydx' */ '@pages/SelfHelp/Bdd/pages/lydx/index')),     //报到单
        // printComponent: sync(() => import( /* webpackChunkName: 'lydx' */ '@pages/SelfHelp/Bdd/pages/lydx/print'))      //打印单
        indexComponent: LydxIndex,
        printComponent: LydxrPrint
    },
    {
        dm: 'zszyjsxy', mc: '中山职业技术学院',
        // indexComponent: sync(() => import( /* webpackChunkName: 'zszyjsxy' */ '@pages/SelfHelp/Bdd/pages/zszyjsxy/index')),
        // printComponent: sync(() => import( /* webpackChunkName: 'zszyjsxy' */ '@pages/SelfHelp/Bdd/pages/zszyjsxy/print'))
        indexComponent: ZszyjsxyIndex,
        printComponent: ZszyjsxyPrint
    },
    {
        dm: 'scjtzyxy', mc: '四川交通职业技术学院',
        // indexComponent: sync(() => import( /* webpackChunkName: 'lydx' */ '@pages/SelfHelp/Bdd/pages/scjtzyxy/index')),     //报到单
        // printComponent: sync(() => import( /* webpackChunkName: 'lydx' */ '@pages/SelfHelp/Bdd/pages/scjtzyxy/print'))      //打印单
        indexComponent: ScjtzyxyIndex,
        printComponent: ScjtzyxyPrint
    },
    {
        dm: 'gznyzyxy', mc: '贵州农业职业学院',
        indexComponent: GznyzyxyIndex,
        printComponent: GznyzyxyPrint
    },
    {
        dm: 'other', mc: '其它基线大学',
        // indexComponent: sync(() => import( /* webpackChunkName: 'lydx' */ '@pages/SelfHelp/Bdd/pages/other/index')),     //报到单
        // printComponent: sync(() => import( /* webpackChunkName: 'lydx' */ '@pages/SelfHelp/Bdd/pages/other/print'))      //打印单
        indexComponent: OtherIndex,
        printComponent: OtherPrint
    },
];

export const types = [{ value: 'index', name: '报到单' }, { value: 'print', name: '打印单' }];

export const base_indexComponent = sync(() => import( /* webpackChunkName: 'lydx' */ '@pages/SelfHelp/Bdd/pages/lydx/index'));

export const base_printComponent = sync(() => import( /* webpackChunkName: 'lydx' */ '@pages/SelfHelp/Bdd/pages/lydx/print'));
