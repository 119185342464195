import React, { Component } from 'react'
import './index.css';
import { Button, Progress, Table } from 'antd';
import { getQztjAndYlhjInfo } from '@pages/SelfHelp/common/commonAction'
import { Flex } from '@components';
import NHFetch from "@utils/NHFetch";
import { getLoginUser, createUuid } from "@utils/NHCore";
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import { mkbz } from "@pages/Assembly/siderMenu/menuObj";
import NextButton from '@pages/SelfHelp/common/nextIndex'
import Hint from "@pages/SelfHelp/common/hint";
import Print from "@utils/Print";
import {getBddComponent,basePrintComponent} from '@utils/Router'
import PropTypes from 'prop-types'

/**
 * @author qianzhiwei
 * @date 2020/09/27
 * @description 打印基础页
 */
export class Index extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            mkbz: 'GD009',
            hjmc: '',
            loading: false,
            nextUrl: '',//左侧菜单加载完后传进来
            search: '',
            operable: true,//是否显示环节
            nonOperationalData: {},//不可操作的数据
            PrintComponent : basePrintComponent,
            schoolCode: this.props.schoolCode == '' ? 'lydx' : this.props.schoolCode,
            //printInfoUrl: this.props.printInfoUrl == '' || this.props.printInfoUrl == undefined ? `/zdybl/getInfoForPrint` : this.props.printInfoUrl,
        }
    }
    
    componentDidMount() {
        
        this.queryQztjAndYlhj();
        const { schoolCode } = this.state;
        let printComponent = getBddComponent(schoolCode,'print');
        this.setState({ PrintComponent: printComponent});
    }

    //查询前置条件和依赖环节
    queryQztjAndYlhj = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({
                nonOperationalData: { nologin: true },
                operable: false,
            });
            return;
        }

        getQztjAndYlhjInfo(xsid, () => {
            let kfsj = res.data['kfsj'];
            let ylhj = res.data['ylhj'];
            let qzhj = res.data['qzhj']; 
            let nohj = res.data['nohj']; 
            this.setState({ nonOperationalData: res.data });
            if (kfsj['YXSJ'] != '1') {
                this.setState({ operable: false });
            }

            if (ylhj && ylhj.length > 0) {
                this.setState({ operable: false });
            }

            if (qzhj && qzhj.length > 0) {
                this.setState({ operable: false });
            }
            if (nohj) {
                this.setState({ operable: false });
            }
        });
    }

    setMenuList = (menuList) => {
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC;
                this.setState({ hjmc: hjmc })
                nextCount = index + 1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if (menu) {
            let hjdm = menu.HJDM;
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID,
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: '',
            })
        }
    }

    //打印
    onPrint = () => {
        this.refs.print.onPrint();
    }

    render() {
        const { loading, operable, mkbz, hjmc, PrintComponent } = this.state;
        const { extraData, children } = this.props;
        return (
            <div>
                {this.state.operable ?
                    <Flex className="w1180 mt-20" align="start">
                        <SiderMenu
                            ref={"siderMenu"}
                            setMenuList={this.setMenuList}
                            title="自助报到"
                            unShowBlzt={"0"}
                            /*      active={8}*/
                            mkbz={mkbz}
                            menuType="自助报到"
                        />
                        <Flex.Item className="page_con_r">
                            <Breadcrumb
                                noLine
                                list={[
                                    { name: '首页', url: '/index' },
                                    { name: '自助报到', url: '/register' },
                                    { name: hjmc }
                                ]}
                            />
                            <div className="box">
                                <Hint/>
                                <Print ref="print" lazyRender={true} insertHead={true} winStyle='toolbar=yes,menubar=yes' isIframe={false}>
                                    {/* <PrintTemplate initFormDate={xsxx} year={year} schoolName={schoolName} account={account} bddbz={bddbz}/> */}
                                    {/* <PrintComponent initFormDate={xsxx} extraData={extraData} /> */}
                                    <PrintComponent initFormDate={this.props.initFormDate}/>
                                </Print>
                                <div>
                                    {children}
                                </div>
                                <div className="p-sw-big text-center mt-30">
                                    <Button
                                        type="primary"
                                        loading={loading}
                                        onClick={this.onPrint}
                                    >
                                        打印
                                    </Button>
                                    <NextButton nextUrl={this.state.nextUrl}  search={this.state.search}/>
                                </div>        
                            </div>
                        </Flex.Item>
                    </Flex>
                    : <CommonTip hjmc={"入学报到单打印"} key={'GD009'} mkbz='GD009' nonOperationalData={this.state.nonOperationalData} />}
            </div>
        )
    }

    static propTypes = {

    }
}

export default Index
