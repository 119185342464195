import React from 'react';
import {Link} from 'react-router-dom';
import {Flex} from '@components';
import Stepper from 'react-stepper-primitive';
import {Button, Table, Icon, InputNumber,Input} from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import NHFetch from "../../../../utils/NHFetch";
import {message} from "antd/lib/index";
import './index.css';
import createHistory from 'history/createHashHistory';
import {createUuid, getLoginUser} from '@utils/NHCore';
import CommonTip from "../common";
import NextButton from '@pages/SelfHelp/common/nextIndex'
import {mkbz} from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";
const history = createHistory();
const basicUrl = "/zzbd";
const commonUrl ='/zzbdCommon';
export default class Buy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mkbz:'GD002',
            hjmc:'',
            data: [],
            selectedRowKeys: [],
            loading: false,
            totalMoney: "",  //购买总金额
            count: "", //总购买物件数
            ediFolag: true,
            selectedList: [],
            WPSLMap: new Map(), //物品数量map   key：物品pkid  value:物品数量
            WPDJMap: new Map(),  //物品单价map   key: 物品pkid  value:物品单价
            transform: true,
            update: false,
            initStuWpData: [],
            nextUrl:'',//左侧菜单加载完后传进来
            search:'',
            operable:true,//是否显示环节
            nonOperationalData:{},//不可操作的数据
            isHjLinked:false
        };
    }

    componentDidMount() {
        this.checkIsZzbdHjLinked();
        this.getWPDatalist();
        this.queryQztjAndYlhj();

    }

    //查询前置条件和依赖环节
    queryQztjAndYlhj=()=>{
        let xsid = getLoginUser()?getLoginUser().userId:'';
        if(!xsid){
            this.setState({
                nonOperationalData:{nologin:true},
                operable:false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', {xsid:xsid,zzbdhjid:zzbdhjid})
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj'];
                    this.setState({nonOperationalData:res.data});
                    if(kfsj && kfsj['YXSJ']!='1'){
                        this.setState({operable:false});
                    }

                    if(ylhj && ylhj.length>0){
                        this.setState({operable:false});
                    }

                    if(qzhj && qzhj.length>0){
                        this.setState({operable:false});
                    }
                    
                    if (nohj) {
                        this.setState({ operable: false });
                    }
                }
            });
    }

    //环节pkid
    getQueryString =(name)=> {
        return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[, ''])[1].replace(/\+/g, '%20')) || null
    }

    setMenuList=(menuList)=>{
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem,index)=>{
            if(elem.HJDM==bz){
                let hjmc = elem.HJMC;
                this.setState({hjmc:hjmc})
                nextCount = index+1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if(menu){
            let hjdm = menu.HJDM;
            if(hjdm.indexOf('GD')==0){
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl:gdUrl,
                    search:'pkid='+menu.PKID,
                })
            }else if(hjdm.indexOf('ZDY')==0){
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl:zdyUrl,
                    search:'pkid='+menu.PKID+'&mkbz='+hjdm+'&lx='+menu.LX,
                })
            }
        }else{
            this.setState({
                nextUrl:'',
                search:'',
            })
        }
    }

//初始开始算必须购买物品的总数和总价
    setInitNumMap(m) {
        const {WPSLMap, WPDJMap, initStuWpData} = this.state;
        m.map(item => {
            if (item.operation != '0') {
                WPSLMap.set(item.key, item.operation);
            }
            WPDJMap.set(item.key, item.dj);
        });

        this.changeMonMoneyAndNub();
    }

    //初始获取table列表
    getWPlist = () => {
        NHFetch(basicUrl + "/getWPList", "GET")
            .then(res => {
                if (res) {
                    if (res.data) {
                        const {initStuWpData} = this.state;
                        let operationI = 0;
                        const basicdata = res.data.map(item => {
                            operationI = 0
                            if (item.SFBXXG === '1') {
                                operationI = 1;
                            }

                            if (initStuWpData.length > 0) {
                                initStuWpData.map(initItem => {
                                    if (initItem.SHWPID === item.PKID) {
                                        operationI = initItem.GMSL;
                                    }
                                });
                            }
                            return {
                                key: item.PKID, name: item.WPMC, gk: item.WPGG,
                                zd: item.ZLYQ, dj: item.WPDJ, buy: item.SFBXXG,
                                zdgmsl: item.ZDGML, operation: operationI
                            };
                        });
                        this.setInitNumMap(basicdata);
                        this.setState({data: basicdata});
                    }
                }
            });
    }
    //检查该环节是否已经办理
    checkIsZzbdHjLinked = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        let zzbdhjid = this.getQueryString('pkid');

        NHFetch("/zzbdCommon/checkIsZzbdHjLinked", 'GET', {xsid: xsid, zzbdhjid: zzbdhjid})
            .then(res => {
                if (res) {
                    if (res.data) {
                        this.setState({
                            transform: !res.data
                        });
                    } else {
                        this.setState({
                            transform: true
                        })
                    }
                } else {
                    this.setState({
                        transform: true
                    })
                }
            });
    };

    //学生个人table列表数据
    getWPDatalist = () => {
        const xsid = getLoginUser().userId; //todo
        NHFetch(basicUrl + "/getShwpgmListById", "GET", {xsid:xsid})
            .then(res => {
                if (res) {
                    if (res.data) {
                        this.setState({initStuWpData: res.data});
                        if (this.state.initStuWpData.length > 0) {
                            this.setState({transform: false});
                        }
                    }
                }
                this.getWPlist();//物品table列表数据
            });
    }


    //数字框发生变化检测
    onChange = (value, record) => {
        // 限制数量 >= 0 
        if (value < 0) {
            value = 0;
        }
        const {WPSLMap} = this.state;
        if(value>record.zdgmsl){
            message.warning(record.name+'已经到达最大购买数！');
            value=record.zdgmsl;
        }
        if(record.buy==='1' && value == 0){
            value = 1;
        }
        if (value == 1) {
            record.operation = 1;
        }
        WPSLMap.set(record.key, value);

        record.operation = value;
        this.changeMonMoneyAndNub();
        if (value == 0) {
            // this.setState({editFlag:true});
            WPSLMap.delete(record.key);
            record.editable = false;
            record.operation = 0;
        }


    }


    //更改总金额和数量
    changeMonMoneyAndNub() {
        const {WPSLMap, WPDJMap} = this.state;
        let totalMoneys = 0;
        let nums = 0;
        WPSLMap.forEach(function (itemValue, itemKey, map) {
            totalMoneys += WPDJMap.get(itemKey) * itemValue;
            nums += itemValue;
        });
        this.setState({
            totalMoney: totalMoneys.toFixed(2),
            count: nums
        });
    }

    //保存
    onSave = () => {
        const {WPSLMap, update,transform} = this.state;


        if (WPSLMap.size === 0) {
            message.error('你还没选择物品，请点击列表中的购买按钮！');
            return;
        } else {
            let sUrl = '/saveShwpgmData';
            let succeMsg='信息保存成功！';
            let errMsg='信息保存失败，请重试！';
            // if (update) {
                // sUrl = '/updateShwpgmData';          //2018/08/16需求变更

            // }
            if (!transform) {
                sUrl = '/deleteShwpgmData';
                 succeMsg='取消成功！';
                 errMsg='取消失败，请重试！';
                // 点击取消时把物品清零
                WPSLMap.clear();
            }

            // //修改 可编辑状态，并可以update数据
            // if (!this.state.transform) {            //2018/08/16需求变更
            //     this.setState({update: true, transform: true});
            //     return
            // }
            const xsid = getLoginUser().userId;
            const zzbdhjid = this.getUrlParams("pkid");
            let params = {xsid: xsid, zzbdhjid: zzbdhjid, formData: WPSLMap};
            //请求保存或修改数据
            NHFetch(basicUrl + sUrl, 'POST', params)
                .then(res => {
                    if (res) {
                        if (res.data == '1') {
                            message.success(succeMsg);
                            this.setState({transform: !transform});
                            this.getWPDatalist();
                            this.reloadMenu();
                        } else {
                            message.error(errMsg);
                        }
                    }
                })
           }
        this.setState({loading: true});
        setTimeout(() => {
            this.setState({
                loading: false
            });
        }, 1000);
    };

    //获取url中传递的参数
    getUrlParams = (param) => {
        return decodeURIComponent((new RegExp('[?|&]' + param + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    };


    // onSave = () => {
    //     this.setState({loading: true});
    //     setTimeout(() => {
    //         this.setState({
    //             selectedRowKeys: [],
    //             loading: false
    //         });
    //     }, 1000);
    // };
    onSelectChange = selectedRowKeys => {
        this.setState({selectedRowKeys});
    };
    getCheckboxProps = record => {
        return {
            disabled: record.key === 'last'
        };
    };
    edit = key => {
        const newData = [...this.state.data];
        const target = newData.filter(item => key === item.key)[0];
        if (target) {
            target.editable = true;
            this.setState({data: newData});
        }
    };
    save = key => {
        const newData = [...this.state.data];
        const target = newData.filter(item => key === item.key)[0];
        if (target) {
            delete target.editable;
            this.setState({data: newData});
            this.cacheData = newData.map(item => ({...item}));
        }
    };

    //重新加载菜单
    reloadMenu=()=>{
        this.refs.siderMenu.reloadMenuList()
    }

    render() {
        const {loading, selectedRowKeys, data, selectedList, WPSLMap, WPDJMap, count, totalMoney, ediFolag, transform} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            getCheckboxProps: this.getCheckboxProps
        };
        const hasSelected = selectedRowKeys.length > 0;
        const columns = [
            {
                title: '物品名称',
                dataIndex: 'name'
            },
            {
                title: '物品规格',
                dataIndex: 'gk',
                render: (text, record) => {
                    const obj = {
                        children: text,
                        props: {}
                    };
                    if (record.key === 'last') {
                        obj.props.colSpan = 5;
                    }
                    return obj;
                }
            },
            {
                title: '质量要求',
                dataIndex: 'zd',
                render: (text, record) => {
                    const obj = {
                        children: text,
                        props: {}
                    };
                    if (record.key === 'last') {
                        obj.props.colSpan = 0;
                    }
                    return obj;
                }
            },
            {
                title: '单价（元）',
                dataIndex: 'dj',
                render: (text, record) => {
                    if (record.key === 'last') {
                        return {
                            props: {
                                colSpan: 0
                            }
                        };
                    }
                    return <span className="text-primary">{text}</span>;
                }
            },
            {
                title: '是否必须购买',
                dataIndex: 'buy',
                render: (text, record) => {
                    if (record.key === 'last') {
                        return {
                            props: {
                                colSpan: 0
                            }
                        };
                    }
                    return text == '1' ?
                        '是'
                        : '否';
                    return <span className="text-primary">{text}</span>;

                    // return text == '1' ?
                    //    '是'
                    //     : '否';
                }
            },
            {
                title: (transform ? '操作':'购买数量'),
                dataIndex: 'operation',
                width: 120,
                render: (text, record) => {
                    if (record.key === 'last') {
                        return {
                            props: {
                                colSpan: 0
                            }
                        };
                    }
                    const {editable, buy, key, dj, zdgmsl, operation} = record;
                    WPDJMap.set(key, dj);
                    /* if (buy === '1' || operation > 0) {
                        record.editable = true;
                    }
                    if (editable) {
                        return (
                            transform ?
                            <Stepper
                                min={buy}
                                max={zdgmsl}
                                render={({
                                             getFormProps,
                                             getInputProps,
                                             getIncrementProps,
                                             getDecrementProps
                                         }) => (
                                    <form {...getFormProps()}>
                                        <div styleName="btn_icon">
                                            <Icon
                                                {...getDecrementProps()}
                                                type="minus-circle-o"
                                                onClick={() => {
                                                    this.onChange(operation-1, record);
                                                }}
                                            />
                                        </div>
                                        <span>{operation}</span>
                                        <div styleName="btn_icon">
                                            <Icon
                                                {...getIncrementProps()}
                                                type="plus-circle"
                                                onClick={() => {
                                                    this.onChange(operation+1, record);
                                                }}
                                            />
                                        </div>
                                    </form>
                                )}
                            /> :  <InputNumber
                            defaultValue={operation}
                            disabled={!transform}
                        />
                        );
                    } */
                    /* return (
                        transform ?
                            <Button
                                type="primary"
                                onClick={() => {
                                    this.edit(record.key);
                                    this.onChange(1, record);
                                }}
                            >
                                数量:{record.buy}
                            </Button>
                             : <InputNumber
                                defaultValue={operation}
                                disabled={!transform}
                            />
                    ); */
                    return (
                        transform ?
                        <Stepper
                            min={buy}
                            max={zdgmsl}
                            render={({
                                         getFormProps,
                                         getInputProps,
                                         getIncrementProps,
                                         getDecrementProps
                                     }) => (
                                <form {...getFormProps()}>
                                    <div styleName="btn_icon">
                                        <Icon
                                            {...getDecrementProps()}
                                            type="minus-circle-o"
                                            onClick={() => {
                                                this.onChange(operation-1, record);
                                            }}
                                        />
                                    </div>
                                    <span>{operation}</span>
                                    <div styleName="btn_icon">
                                        <Icon
                                            {...getIncrementProps()}
                                            type="plus-circle"
                                            onClick={() => {
                                                this.onChange(operation+1, record);
                                            }}
                                        />
                                    </div>
                                </form>
                            )}
                        /> :  <InputNumber
                        defaultValue={operation}
                        disabled={!transform}
                    />
                    );

                }
            }
        ];
        if (data[0]) {
            if (data[data.length - 1].key === 'last') {
                // data.filter((i) => i !== data.length - 1)
                data.splice(data.length - 1, 1);
            }
            data.push({
                key: 'last',
                name: '合计',
                gk: (
                    <span>
                        共 <span className="text-red">{count || 0}</span> 件商品，共计{' '}
                        <span className="text-primary">{totalMoney || 0}</span> 元
                    </span>
                )
            });
        }

        return (
            <div styleName="page_buy">
                {this.state.operable?
                <Flex className="w1180 mt-20" align="start">
                    <SiderMenu
                        ref={"siderMenu"}
                        setMenuList={this.setMenuList}
                        title="自助报到"
                        mkbz={this.state.mkbz}
                        /*       active={1}*/
                        menuType="自助报到"
                    />
                    <Flex.Item className="page_con_r">
                        <Breadcrumb
                            noLine
                            list={[
                                {name: '首页', url: '/index'},
                                {name: '自助报到', url: '/register'},
                                {name: this.state.hjmc}
                            ]}
                        />
                        <div className="box">
                            <Hint/>
                            <TitleTop title={this.state.hjmc} extra={false}/>
                            <div className="p-sw">
                                <Table
                                    bordered
                                    size="middle"
                                    pagination={false}
                                    // rowSelection={rowSelection}
                                    columns={columns}
                                    dataSource={data}
                                />
                                <div className="text-center mt-50">
                                    <Button
                                        type="primary"
                                        onClick={this.onSave}
                                        // disabled={!ediFolag}
                                        // disabled={!transform}
                                        loading={loading}
                                    >
                                        {/*保存购买*/}
                                        {transform ? '保存' : '取消'}
                                    </Button>
                                    <NextButton nextUrl={this.state.nextUrl}  search={this.state.search}/>
                                </div>

                            </div>
                        </div>
                    </Flex.Item>
                </Flex>:<CommonTip hjmc = {"物品购买"} key={"GD002"} mkbz='GD002' nonOperationalData={this.state.nonOperationalData}/>}
            </div>
        );
    }
}
