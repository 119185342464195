import React from 'react'
import {Button, Icon, message, Spin, Upload,Modal} from 'antd'
import {createUuid, getLoginUser} from '@utils/NHCore';
import NHFetch from "@utils/NHFetch";
const downloadPrefix = 'anonymity/docrepo/download/file';
// import {createUuid,NHFetch,getLoginUser} from 'xgui-for-react'

/**
 * 文件上传路径：
 * file模式：anonymity/docrepo/upload
 * redis模式：anonymity/zhxg-lxxt/proData/uploadRedis
 * 文件下载路径：
 * file模式：anonymity/docrepo/download/file?attachmentId={uuid}
 * redis模式：anonymity/zhxg-lxxt/proData/downloadRedis
 */

// function getBase64(file) {
//     return new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.readAsDataURL(file);
//       reader.onload = () => resolve(reader.result);
//       reader.onerror = error => reject(error);
//     });
//   }
export default class NHUpload extends React.Component {
    //设置可以传递参数的默认值
    static defaultProps = {
        multiple: false,//是否支持上传多个文件，默认false
        // fileList:[],//设置默认上传的内容，格式:[{uuid:'附件文档库的标志',name:'文件名称'}],只有uploadType为file(文档库)模式的时候才能使用此功能
        uploadType: 'file',//文件上传方式，file:文档库，redis:Redis缓存，如果是需要永久上传请使用文档库，如果只是暂存，建议使用redis缓存
        redisData: 30,//缓存时间,单位：分钟，设置缓存时间，意思是超过这个时间就会删除这个缓存信息
        fileCountLimit: undefined,//上传文件个数限制
        fileTypeLimit: undefined,//上传文件格式限制
        disabled: false,//是否禁用
        onChange: undefined,//值改变事件,参数：resultStr,resultList
        maxFileSize: 20, //默认上传最大大小限制20MB
        serviceMark: 'ly-zhxg-xtgl',//服务标志，说明是属于哪个服务
        moduleName: '系统管理',//模块名称，说明是属于哪个服务的哪个模块
        value: '',//附件主表id
        describe: '',//描述，对上传的附件的一个描述信息
        fileMainName: '',//附件主表名，存储该附件对应的主表的名称，主要是为了以后进行追溯
        isEdit: true,//是否可以编辑，默认为true，查看模式必须传value
        listType: 'picture',//上传列表的内建样式，支持两种基本样式 text, picture
        compressThreshold: 5,  //压缩的阈值,图片大小超过5M,则需要进行压缩
        isPictureCompress: false, //是否开启图片压缩
        pictureQuality: 0.92, //指定压缩的图片质量,取值范围为0~1,quality值越小,图像越模糊,默认图片质量为0.92
        defaultEffective:false,//指定上传的附件是否默认在附件主表有效
        templateList:[],         // 附件模板
        image:false
    };

    constructor(props) {
        super(props)
        // let fileList = props.value || props.defaultValue || props.fileList;
        let value = props.value || props.defaultValue;
        this.state = {
            fileList: [],//上传的文件
            value: (value !== undefined && value !== null && value.trim() !== '') ? value : createUuid(),//传了附件主表id，查询附件明细
            // value:'',
            // previewVisible: false,
            // previewImage: '',
            fileName: '',
            spinLoading: false, //加载中标识
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.uploadType === 'file') {
            const {serviceMark, moduleName} = this.props;
            if (serviceMark && moduleName) {
                if ('value' in nextProps) {
                    const value = nextProps.value;
                    if (JSON.stringify(value) === '[]' || typeof (value) === "undefined" ||
                        (typeof (value) === 'string' && value.trim() === '') || value === null) {
                    } else {
                        if (value !== this.state.value) {
                            this.setState({value});
                            this.setState({fileList: []});
                            let t = new Date().getTime();
                            NHFetch('api/zhxg-yxwz/dynamic/' + value + '/getDetailed', 'GET', t)
                                .then(res => {
                                    if (res && res.data && res.data.length > 0) {
                                        this.handleFileList(res.data)
                                    }
                                })
                        }
                    }
                }
            } else {
                message.error("服务标志和模块名称不能为空");
            }
        }
    }

    //在组件挂载之前调用一次。如果在这个函数里面调用setState，本次的render函数可以看到更新后的state，并且只渲染一次。
    UNSAFE_componentWillMount() {
        this.setState({fileList: []})
        if (this.props.uploadType === 'file') {
            const {serviceMark, moduleName} = this.props;
            if (serviceMark && moduleName) {
                const value = this.state.value;
                let t = new Date().getTime();
                NHFetch('api/zhxg-yxwz/dynamic/' + value + '/getDetailed', 'GET', t)
                    .then(res => {
                        if (res && res.data && res.data.length > 0) {
                            this.handleFileList(res.data)
                        }
                    })
            } else {
                message.error("服务标志和模块名称不能为空");
            }
        }
    }

    //在组件挂载之后调用一次。
    componentDidMount() {

    }

    //处理fileList
    handleFileList = (propsfileList) => {
        let initFielList = propsfileList;
        if (typeof propsfileList === 'string') {
            initFielList = [];
            propsfileList.split(",").map((item) => {
                initFielList.push({
                    uuid: item.split(";")[0],
                    name: item.split(";")[1]
                });
                return item;
            });
        }
        let fileList = this.state.fileList;
        let uuidList = [];
        if (fileList.length > 0) {
            fileList.map(item => {
                uuidList.push(item.uuid)
            })
        }
        let img = ['png', 'jpeg', 'jpg', 'gif', 'bmp'];
        initFielList.map((item, index) => {
            if (uuidList.indexOf(item.uuid) === -1) {
                if (img.indexOf(item.name.split(".")[1]) === -1) {
                    fileList.push({
                        uuid: item.uuid,
                        uid: item.uuid,
                        name: item.name,
                        status: 'done',
                        reponse: 'Server Error 500', // custom error message to show
                        // url: 'anonymity/docrepo/download/file?attachmentId='+item.uuid,
                    });
                    return item;
                }
                fileList.push({
                    uuid: item.uuid,
                    uid: item.uuid,
                    name: item.name,
                    status: 'done',
                    reponse: 'Server Error 500', // custom error message to show
                    url: 'anonymity/docrepo/download/file?attachmentId=' + item.uuid,
                });
                return item;
            }
        });
        this.setState({
            fileList: fileList
        });
    }

    //判断文件是否满足要求文件格式的要求
    checkTypeLimit = (filename, fileTypeLimit) => {
        for (let i = 0; i < fileTypeLimit.length; i++) {
            if (filename.toUpperCase().indexOf(fileTypeLimit[i].toUpperCase()) > -1) {
                return true;
            }
        }
        return false;
    }

    //判断文件是否是可执行文件
    checkIsRun = (filename, executable) => {
        for (let i = 0; i < executable.length; i++) {
            if (filename.toUpperCase().indexOf(executable[i].toUpperCase()) > -1) {
                return true;
            }
        }
        return false;
    }

    //文件上传之前执行的方法
    beforeUpload = (file, fileList) => {
        let suffixMap = {
            'word': ['.doc', '.docx'],
            'exce': ['.xls', '.xlsx'],
            'img': ['.png', '.jpeg', '.jpg', '.gif', '.bmp']
        }
        let executable = ['.exe', '.sys', '.com', '.bat', '.jsp', '.asp', '.php', '.aspx', '.asa', '.asax', '.asmx', '.ashx', '.ascx', '.pif', '.acf',
            '.arj', '.swg', '.swf', '.svx', '.dos'];
        //文件格式的限制
        if (this.checkIsRun(file.name, executable)) {
            message.info("不允许上传可执行的文件！");
            return false;
        } else {
            if (this.props.fileTypeLimit && this.props.fileTypeLimit.length > 0) {
                let fileTypeLimit = this.props.fileTypeLimit;
                for (let i = fileTypeLimit.length - 1; i >= 0; i--) {
                    let list = suffixMap[fileTypeLimit[i]];
                    if (list) {
                        fileTypeLimit.splice(i, 1);
                        for (let j = 0; j < list.length; j++) {
                            fileTypeLimit.push(list[j]);
                        }
                    }
                }
                if (!this.checkTypeLimit(file.name, fileTypeLimit)) {
                    message.info("只能上传后缀为[" + fileTypeLimit.join(",") + "]格式的文件！");
                    return false;
                }
            }
        }
        if (this.props.image && !this.checkIsImage(file.name)) {
            message.info("只能上传图片类型");
            return false;
        }
        //文件上传数量限制
        let fileCountLimit = this.props.multiple === false ? 1 : this.props.fileCountLimit;
        if (fileCountLimit && fileCountLimit <= this.state.fileList.length) {
            message.info("最多只能上传" + fileCountLimit + "个文件！");
            return false;
        }

        //限制上传文件大小(默认上传大小限制20MB)
        const {maxFileSize = 20} = this.props;
        let fileSize = file.size / 1024 / 1024;
        const isOverSize = fileSize > maxFileSize;

        if (isOverSize) {
            let msg = '抱歉，上传文件大小最大不能超过' + maxFileSize + 'M！';
            message.error(msg);
            return !isOverSize;
        }
        this.setState({fileName: file.name})
        return true;
    };

    //在上传之前转换文件
    transformFile = (file) => {
        /**
         * 针对图片进行压缩,如果图片大小超过压缩阈值,则执行压缩,否则不压缩
         */
        //判断是否是图片类型
        if (this.checkIsImage(file.name)) {
            const {compressThreshold = 5, isPictureCompress = false, pictureQuality = 0.92} = this.props;
            let fileSize = file.size / 1024 / 1024;
            // console.log('before compress, the file size is : ', fileSize + "M");
            //当开启图片压缩且图片大小大于等于压缩阈值,进行压缩
            if ((fileSize >= compressThreshold) && isPictureCompress) {
                //判断浏览器内核是否支持base64图片压缩
                if (typeof (FileReader) === 'undefined') {
                    return file;
                } else {
                    try {
                        this.setState({
                            spinLoading: true
                        });
                        return new Promise(resolve => {
                            //声明FileReader文件读取对象
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = () => {
                                // 生成canvas画布
                                const canvas = document.createElement('canvas');
                                // 生成img
                                const img = document.createElement('img');
                                img.src = reader.result;
                                img.onload = () => {
                                    const ctx = canvas.getContext('2d');
                                    //原始图片宽度、高度
                                    let originImageWidth = img.width, originImageHeight = img.height;
                                    //默认最大尺度的尺寸限制在（1920 * 1080）
                                    let maxWidth = 1920, maxHeight = 1080, ratio = maxWidth / maxHeight;
                                    //目标尺寸
                                    let targetWidth = originImageWidth, targetHeight = originImageHeight;
                                    //当图片的宽度或者高度大于指定的最大宽度或者最大高度时,进行缩放图片
                                    if (originImageWidth > maxWidth || originImageHeight > maxHeight) {
                                        //超过最大宽高比例
                                        if ((originImageWidth / originImageHeight) > ratio) {
                                            //宽度取最大宽度值maxWidth,缩放高度
                                            targetWidth = maxWidth;
                                            targetHeight = Math.round(maxWidth * (originImageHeight / originImageWidth));
                                        } else {
                                            //高度取最大高度值maxHeight,缩放宽度
                                            targetHeight = maxHeight;
                                            targetWidth = Math.round(maxHeight * (originImageWidth / originImageHeight));
                                        }
                                    }
                                    // canvas对图片进行缩放
                                    canvas.width = targetWidth;
                                    canvas.height = targetHeight;
                                    // 清除画布
                                    ctx.clearRect(0, 0, targetWidth, targetHeight);
                                    // 绘制图片
                                    ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
                                    // quality值越小,图像越模糊,默认图片质量为0.92
                                    const imageDataURL = canvas.toDataURL(file.type || 'image/jpeg', pictureQuality);
                                    // 去掉URL的头,并转换为byte
                                    const imageBytes = window.atob(imageDataURL.split(',')[1]);
                                    // 处理异常,将ascii码小于0的转换为大于0
                                    const arrayBuffer = new ArrayBuffer(imageBytes.length);
                                    const uint8Array = new Uint8Array(arrayBuffer);
                                    for (let i = 0; i < imageBytes.length; i++) {
                                        uint8Array[i] = imageBytes.charCodeAt(i);
                                    }
                                    let mimeType = imageDataURL.split(',')[0].match(/:(.*?);/)[1];
                                    let newFile = new File([uint8Array], file.name, {type: mimeType || 'image/jpeg'});
                                    // console.log('after compress, the file size is : ', (newFile.size / 1024 / 1024) + "M");
                                    resolve(newFile);
                                };
                            };
                            reader.onerror = () => {
                                this.setState({
                                    spinLoading: false
                                });
                                return file;
                            }
                        }).then(res => {
                            this.setState({
                                spinLoading: false
                            });
                            return res;
                        }).catch(() => {
                            this.setState({
                                spinLoading: false
                            });
                            return file;
                        });
                    } catch (e) {
                        this.setState({
                            spinLoading: false
                        });
                        //压缩出错,直接返回原file对象
                        return file;
                    }
                }
            } else {
                //不需要压缩，直接返回原file对象
                return file;
            }
        } else {
            //非图片文件,不进行压缩,直接返回原file对象
            return file;
        }
    };

    //检查是否为图片类型
    checkIsImage = (fileName) => {
        let flag;
        let imageTypeList = ['.jpg', '.png', '.jpeg', '.gif', '.GIF', '.JPG', '.PNG', '.JPEG'];
        for (let item of imageTypeList) {
            flag = fileName.indexOf(item) !== -1;
            if (flag) {
                return true;
            }
        }
        return flag;
    };

    //新增附件主表和明细
    handleMasterTable = (e, fileList) => {
        let value = this.state.value;
        const {serviceMark, moduleName, describe, fileMainName,defaultEffective} = this.props;
        let param = {
            fjzbid: value,
            fwbz: serviceMark,
            mkmc: moduleName,
            ms: describe,
            fjzbm: fileMainName,

            fjid: e.file.response.data.ssbh,
            fjmc: e.file.name,
            cjsid: getLoginUser().userId,
        }
        if (defaultEffective) {
            param.ztm = '1'
        }
        NHFetch("api/zhxg-yxwz/dynamic/insert", 'POST', param)
            .then(res => {
                if (res && res.code === 200) {
                    this.getResult(fileList);
                } else {
                    fileList.map((item, key) => {
                        if (e.file.uid = item.uid) {
                            fileList.splice(key, 1);
                        }
                    })
                    this.setState({fileList})
                    message.error("附件上传失败！");
                }
            }).catch(() => {
            fileList.map((item, key) => {
                if (e.file.uid = item.uid) {
                    fileList.splice(key, 1);
                }
            })
            this.setState({fileList})
        })
    }
    //文件上传改变事件
    handleChange = (e) => {
        let fileList = e.fileList;
        if (e.file.status === 'done') {//上传链接调用成功
            let response = e.file.response;
            if (this.props.uploadType === 'file') {//上传到文档库中
                if (response.meta && response.meta.statusCode === 200) {//导出成功
                    fileList = fileList.map((file) => {
                        if (file.uid === e.file.uid) {
                            file.uuid = response.data.ssbh;
                        }
                        return file;
                    });
                    this.handleMasterTable(e, fileList);
                } else {
                    message.error("文件上传失败，请重新上传！");
                    fileList = this.filterCurrentFile(e.fileList, e.file);
                }
            } else { //上传到redis中缓存
                if (response.code === 200) {
                    fileList = fileList.map((file) => {
                        if (file.uid === e.file.uid) {
                            file.uuid = e.file.response.data;
                        }
                        return file;
                    });
                    this.getResult(fileList);
                } else {
                    message.error("文件上传失败，请重新上传！");
                    fileList = this.filterCurrentFile(e.fileList, e.file);
                }
            }
        } else if (e.file.status === 'uploading') {//上传中，此时不需要做处理

        } else if (e.file.status === 'error') {//上传错误
            message.error("文件由于未知原因失败，请重新上传！");
            fileList = this.filterCurrentFile(e.fileList, e.file);
        } else if (e.file.status === 'removed') {//删除
            NHFetch("api/zhxg-yxwz/dynamic/updateDel", 'POST', e.file.uid)
                .then(res => {
                    if (res && res.code === 200) {
                        this.getResult(fileList);
                    } else {
                        message.error("附件上传失败！");
                        fileList = this.filterCurrentFile(e.fileList, e.file);
                    }
                })
        }

        //beforeUpload返回false的时候，status状态值是undefined
        if (e.file.status) {
            if (e.file.response && e.file.response.meta && e.file.response.meta.statusCode === 200) {
                let img = ['png', 'jpeg', 'jpg', 'gif', 'bmp'];
                if (img.indexOf(e.file.name.split(".")[1]) === -1) {
                    fileList.push({
                        uuid: e.file.response.data.ssbh,
                        uid: e.file.response.data.ssbh,
                        name: e.file.name,
                        status: e.file.status,
                        reponse: 'Server Error 500', // custom error message to show
                        // url: 'anonymity/docrepo/download/file?attachmentId='+e.file.response.data.ssbh,
                    });
                } else {
                    fileList.push({
                        uuid: e.file.response.data.ssbh,
                        uid: e.file.response.data.ssbh,
                        name: e.file.name,
                        status: e.file.status,
                        reponse: 'Server Error 500', // custom error message to show
                        url: 'anonymity/docrepo/download/file?attachmentId=' + e.file.response.data.ssbh,
                    });
                }
            }
            this.setState({fileList})
        }
    }

    filterCurrentFile = (list, currFile) => {
        let fileList = [];
        list.map((file) => {
            if (file.uid !== currFile.uid) {
                fileList.push(file);
            }
            return file;
        });
        return fileList;
    }

    //发送请求的时候的数据
    requestData = () => {
        if (this.props.uploadType === 'file') {
            return {uuid: createUuid(), type: '1'};
        } else {
            return {'redisData': this.props.redisData};
        }
    }

    getResult = (fileList) => {
        if (this.props.uploadType === 'file') {
            let list = [];
            let listMap = [];
            fileList.map((item, index) => {
                if (item.name) {
                    list.push(item.name);
                }
                if (item.size || item.size === 0) {
                    fileList.splice(index, 1)
                }
                if (item.uuid) {
                    let param = {};
                    param.uuid = item.uuid;
                    param.name = item.name;
                    listMap.push(param);
                }
            })
            this.setState({fileList})
            let value = this.state.value;
            if (this.props.onChange) {
                if (fileList.length > 0) {
                    this.props.onChange(value, list, listMap);
                } else {
                    this.props.onChange(undefined);
                }
            }
        } else {
            let resultStr = '';
            let resultList = [];
            fileList.map((file) => {
                resultList.push({
                    uuid: file.uuid,
                    name: file.name
                });
                resultStr += ',' + file.uuid + ';' + file.name;
                return file;
            });
            if (resultStr) {
                resultStr = resultStr.substring(1);
            }
            if (this.props.onChange) {
                this.props.onChange(resultStr, resultList);
            }
        }
    }

    handleDownload = async file => {
        window.open('anonymity/docrepo/download/file?attachmentId=' + file.uid)
    }
    handlePreview = async file => {
        if (!this.props.image) return;
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        let name = file.name.split(".")[1];
        if(name==='png' || name==='jpeg' || name==='jpg' || name==='gif' || name==='bmp'){
            this.setState({
                previewImage: file.url || file.preview,
                previewVisible: true,
            });
        }else{
            this.setState({
                previewImage: file.url || file.preview,
                previewVisible: false,
            });
        }
    };

    handleCancel = () => this.setState({ previewVisible: false });

    render() {
        let action = this.props.uploadType === 'file' ? ' anonymity/docrepo/upload' : window.baseUrl + '/proData/uploadRedis?fileName=' + this.state.fileName;
        // const {fileList} = this.state;
        const { previewVisible, previewImage, fileList } = this.state;
        const showUploadList = {
            showDownloadIcon: this.props.uploadType === 'file' ? true : false,
        }
        const { templateList=[], accept='*/*',image,fileCountLimit=1 } = this.props;
        let uploadButton = image?(
            <div>
                <Icon type='plus'/>
            </div>
        ):(<Button disabled={this.props.disabled}>
            <Icon type="upload"/> 点击上传
        </Button>);
        if (fileList.length >= fileCountLimit) {
            uploadButton = null;
        }
        return (
            <div>
                {
                    this.props.isEdit === true ? <div><Upload
                        name={'file'}
                        accept={accept}
                        action={action}
                        beforeUpload={this.beforeUpload}
                        transformFile={this.transformFile}
                        listType={this.props.listType}
                        onChange={this.handleChange}
                        disabled={this.props.disabled}
                        data={this.requestData()}
                        fileList={fileList}
                        onPreview={this.handlePreview}
                        onDownload={this.handleDownload}
                        showUploadList={showUploadList}
                    >
                        {uploadButton}
                    </Upload>
                    {templateList.map(item=>{
                        return <a key={item.uuid} href={`${downloadPrefix}?attachmentId=${item.uuid}`}>{item.name}</a>
                    })}
                        {this.state.spinLoading && <Spin/>}
                    </div> : <Upload
                        name={'file'}
                        accept={accept}
                        action={action}
                        beforeUpload={this.beforeUpload}
                        transformFile={this.transformFile}
                        listType={this.props.listType}
                        disabled={true}
                        data={this.requestData()}
                        fileList={fileList}
                        onPreview={this.handlePreview}
                        onDownload={this.handleDownload}
                        showUploadList={showUploadList}
                    />
                }
                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </div>
        )
    }
}

