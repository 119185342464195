import React from 'react'
import { Upload, message, Button, Icon } from 'antd'
import { createUuid } from '@utils/NHCore';
// import {createUuid} from 'xgui-for-react'


/**
 * 文件上传路径：
 * file模式：anonymity/docrepo/upload
 * redis模式：anonymity/zhxg-lxxt/proData/uploadRedis
 * 文件下载路径：
 * file模式：/anonymity/docrepo/download/file?attachmentId={uuid}
 * redis模式：anonymity/zhxg-lxxt/proData/downloadRedis
 */
export default class NHOldUpload extends React.Component {
  //设置可以传递参数的默认值
  static defaultProps = {
    multiple: false,//是否支持上传多个文件，默认false
    fileList: [],//设置默认上传的内容，格式:[{uuid:'附件文档库的标志',name:'文件名称'}],只有uploadType为file(文档库)模式的时候才能使用此功能
    uploadType: 'file',//文件上传方式，file:文档库，redis:Redis缓存，如果是需要永久上传请使用文档库，如果只是暂存，建议使用redis缓存
    redisData: 30,//缓存时间,单位：分钟，设置缓存时间，意思是超过这个时间就会删除这个缓存信息
    fileCountLimit: undefined,//上传文件个数限制
    fileTypeLimit: undefined,//上传文件格式限制
    disabled: false,//是否禁用
    onChange: undefined,//值改变事件,参数：resultStr,resultList
    maxFileSize: 20, //默认上传最大大小限制20MB
  };
  constructor(props) {
    super(props)
    let fileList = props.value || props.defaultValue || props.fileList;
    this.state = {
      fileList,//上传的文件
    }
  }

  componentWillReceiveProps(nextProps) {

    if ('fileList' in nextProps || 'value' in nextProps) {
      const fileList = nextProps.value || nextProps.fileList;
      if (JSON.stringify(fileList) === '[]' || typeof (fileList) === "undefined" ||
        (typeof (fileList) === 'string' && fileList.trim() === '')) {
        this.setState({ fileList: [] })
      }
    }

  }

  //在组件挂载之前调用一次。如果在这个函数里面调用setState，本次的render函数可以看到更新后的state，并且只渲染一次。
  UNSAFE_componentWillMount() {

    if (this.props.fileList || this.props.value || this.props.defaultValue) {
      let propsfileList = this.props.value || this.props.fileList || this.props.defaultValue
      let initFielList = propsfileList;
      if (typeof propsfileList === 'string') {
        initFielList = [];
        propsfileList.split(",").map((item) => {
          initFielList.push({
            uuid: item.split(";")[0],
            name: item.split(";")[1]
          });
          return item;
        });

      }
      let fileList = [];
      initFielList.map((item, index) => {
        fileList.push({
          uuid: item.uuid,
          uid: item.uuid,
          name: item.name,
          status: 'done',
          reponse: 'Server Error 500', // custom error message to show
          // url: 'http://www.baidu.com/xxx.png',
        });
        return item;
      });
      this.setState({
        fileList: fileList
      });





    }
  }
  //在组件挂载之后调用一次。
  componentDidMount() {

  }



  //判断文件是否满足要求文件格式的要求
  checkTypeLimit = (filename, fileTypeLimit) => {
    for (let i = 0; i < fileTypeLimit.length; i++) {
      if (filename.toUpperCase().indexOf(fileTypeLimit[i].toUpperCase()) > -1) {
        return true;
      }
    }
    return false;
  }

  //文件上传之前执行的方法
  beforeUpload = (file, fileList) => {
    let suffixMap = {
      'word': ['.doc', '.docx'],
      'exce': ['.xls', '.xlsx'],
      'img': ['.png', '.jpeg', '.jpg', '.gif', '.bmp']
    }
    //文件格式的限制
    if (this.props.fileTypeLimit && this.props.fileTypeLimit.length > 0) {
      let fileTypeLimit = this.props.fileTypeLimit;
      for (let i = fileTypeLimit.length - 1; i >= 0; i--) {
        let list = suffixMap[fileTypeLimit[i]];
        if (list) {
          fileTypeLimit.splice(i, 1);
          for (let j = 0; j < list.length; j++) {
            fileTypeLimit.push(list[j]);
          }
        }
      }
      if (!this.checkTypeLimit(file.name, fileTypeLimit)) {
        message.info("只能上传后缀为[" + fileTypeLimit.join(",") + "]格式的文件！");
        return false;
      }
    }
    //文件上传数量限制
    let fileCountLimit = this.props.multiple === false ? 1 : this.props.fileCountLimit;
    if (fileCountLimit && fileCountLimit <= this.state.fileList.length) {
      message.info("最多只能上传" + fileCountLimit + "个文件！");
      return false;
    }

    //限制上传文件大小(默认上传大小限制20MB)
    const { maxFileSize = 20 } = this.props;
    let fileSize = file.size / 1024 / 1024;
    const isOverSize = fileSize > maxFileSize;

    if (isOverSize) {
      let msg = '抱歉，上传文件大小最大不能超过' + maxFileSize + 'M！';
      message.error(msg);
      return !isOverSize;
    }

    return true;
  }

  //文件上传改变事件
  handleChange = (e) => {
    let fileList = e.fileList;
    if (e.file.status === 'done') {//上传链接调用成功
      let response = e.file.response;
      if (this.props.uploadType === 'file') {//上传到文档库中
        if (response.meta && response.meta.statusCode === 200) {//导出成功
          fileList = fileList.map((file) => {
            if (file.uid === e.file.uid) {
              file.uuid = response.data.ssbh;
            }
            return file;
          });
          this.getResult(fileList);
        } else {
          message.error("文件上传失败，请重新上传！");
          fileList = this.filterCurrentFile(e.fileList, e.file);
        }
      } else { //上传到redis中缓存
        if (response.code === 200) {
          fileList = fileList.map((file) => {
            if (file.uid === e.file.uid) {
              file.uuid = e.file.response.data;
            }
            return file;
          });
          this.getResult(fileList);
        } else {
          message.error("文件上传失败，请重新上传！");
          fileList = this.filterCurrentFile(e.fileList, e.file);
        }
      }
    } else if (e.file.status === 'uploading') {//上传中，此时不需要做处理

    } else if (e.file.status === 'error') {//上传错误
      message.error("文件由于未知原因失败，请重新上传！");
      fileList = this.filterCurrentFile(e.fileList, e.file);
    } else if (e.file.status === 'removed') {//删除
      this.getResult(fileList);
    }
    //beforeUpload返回false的时候，status状态值是undefined
    if (e.file.status) {
      this.setState({
        fileList: fileList
      });
    }
  }

  filterCurrentFile = (list, currFile) => {
    let fileList = [];
    list.map((file) => {
      if (file.uid !== currFile.uid) {
        fileList.push(file);
      }
      return file;
    });
    return fileList;
  }

  //发送请求的时候的数据
  requestData = () => {
    if (this.props.uploadType === 'file') {
      return { uuid: createUuid(), type: '1' };
    } else {
      return { 'redisData': this.props.redisData };
    }
  }

  getResult = (fileList) => {
    let resultStr = '';
    let resultList = [];
    fileList.map((file) => {
      resultList.push({
        uuid: file.uuid,
        name: file.name
      });
      resultStr += ',' + file.uuid + ';' + file.name;
      return file;
    });
    if (resultStr) {
      resultStr = resultStr.substring(1);
    }

    if (this.props.onChange) {
      this.props.onChange(resultStr, resultList);
    }
  }


  render() {
    let action = this.props.uploadType === 'file' ? 'anonymity/docrepo/upload' : window.baseUrl + '/proData/uploadRedis';
    return (
      <Upload
        name={'file'}
        // accept={'application/vnd.openxmlformats-officedocument.presentationml.presentation'}  用来限制可以上传的文件，不是这种类型的会看不到
        action={action}
        beforeUpload={this.beforeUpload}
        listType={'text'}
        onChange={this.handleChange}
        disabled={this.props.disabled}
        data={this.requestData()}
        fileList={this.state.fileList}
      >
        <Button disabled={this.props.disabled}>
          <Icon type="upload" /> 点击上传
                </Button>
        <br/>
        <span style={{fontSize: 'small', color: '#999'}}>{this.props.describe}</span>
      </Upload>
    )
  }
}
