import React, {Component} from 'react'
import {
    Button,
    Checkbox,
    Col,
    Descriptions,
    Form,
    Icon,
    InputNumber,
    message,
    Modal,
    Row,
    Spin,
    Tooltip,
    Upload
} from 'antd'
import {createUuid, getLoginUser} from '@utils/NHCore'
import NHFetch from '@utils/NHFetch'
import NextButton from '@pages/SelfHelp/common/nextIndex'
import NHFormItem from '@components/common/NHFormItem'
import moment from 'moment'
import css from '../index.css'
import TitleTop from "@pages/Assembly/titleTop";

const Dragger = Upload.Dragger

//确认对话框
const confirm = Modal.confirm
const FormItem = Form.Item

@Form.create()
export default class MainType extends Component {
    constructor(props) {
        super(props)
        this.state = {
            uploading: false,
            previewVisible: false,
            checkGroup: [], //绿色通道办理原因
            HjsqdxGroup: [], //缓交申请对象
            judgeImgList: ['.png', '.PNG', '.jpg', '.JPG', '.bpm', '.BPM', '.jpeg', '.JPEG'], //可以导入的图片格式
            judgeFileList: ['.pdf', '.PDF', '.doc', '.DOC', '.docx', '.DOCX', '.zip', '.ZIP', '.txt', '.TXT', '.xls', '.XLS', '.xlsx', '.XLSX', '.rar', '.RAR'], //可以导入的非图片格式
            x_hjjzrq: '',
            jfhjzt: false, //缴费环节状态
            saveLoading: false,
            fileList: []
        }
    }

    componentDidMount() {
        this.initFiles(this.props)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.data !== nextProps.data) {
            this.initFiles(nextProps)
        }
    }


    getQueryString = name => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }

    // 转化base64
    getBase64 = (img, callback) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => callback(reader.result))
        reader.readAsDataURL(img)
    }

    // 点击文件链接或预览图标时的回调
    handlePreview = file => {
        const isImage = /^image\/.*$/i.test(file.type)
        if (isImage) {
            if (file) {
                this.getBase64(file, imageUrl => {
                    this.setState({
                        previewImage: imageUrl,
                        previewVisible: true
                    })
                })
            } else {
                this.setState({
                    previewImage: file.url || file.thumbUrl,
                    previewVisible: true
                })
            }
        } else if (file && file['response'] && file['response']['data']) {
            //其他情况
            let isImage = true
            let fjmc = file['response']['data']['fjmc']
            let ssbh = file['response']['data']['ssbh']
            if (!this.setJudge(fjmc, this.state.judgeImgList)) {
                isImage = false
            }

            if (isImage) {
                this.showPreview(`anonymity/docrepo/download/file?attachmentId=${ssbh}`)
            } else {
                window.open(`anonymity/docrepo/download/file?attachmentId=${ssbh}`)
            }
        }
    }

    showPreview = url => {
        this.setState({
            previewImage: url,
            previewVisible: true
        })
    }

    // 关闭图片弹窗
    handleCancel = () => this.setState({previewVisible: false})

    // 获取缴费环节状态
    getJfhjData = () => {
        let xsid = getLoginUser().userId ? getLoginUser().userId : ''
        let param = {
            xsid: xsid,
            hjdm: 'GD008_1'
        }
        NHFetch('/zzbdCommon/getBlztByHjdm', 'GET', param).then(res => {
            if (res) {
                if (res.data == '1') {
                    this.setState({
                        jfhjzt: true
                    })
                } else {
                    this.setState({
                        jfhjzt: false
                    })
                }
            }
        })
    }

    //提交
    handleOk = e => {
        const {sqValue, dkfsValue, setChannelState, data} = this.props
        if (!sqValue) {
            message.warning('请选择是否办理')
        } else if (sqValue === '1' && !dkfsValue) {
            message.warning('请选择困难类型')
        } else {
            confirm({
                title: '提示',
                content: '是否确定提交，提交后不可修改！',
                okText: '确定',
                cancelText: '取消',
                okType: 'danger',
                iconType: 'info-circle',
                onOk: () => {
                    this.handleSubmit(e)
                }
            })
        }
    }

    // 确认提交
    handleSubmit = e => {
        const {sqValue, dkfsValue, setChannelState, data} = this.props

        let xsid = getLoginUser().userId ? getLoginUser().userId : ''
        if (!xsid) {
            message.error('登录已过期，请重新登录！')
            return
        }
        this.setState({saveLoading: true}, () => setChannelState({loading: this.state.saveLoading}))
        let zzbdhjid = this.getQueryString('pkid')

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                this.setState({saveLoading: false}, () => setChannelState({loading: this.state.saveLoading}))
                return false
            }

            let fjlist = []
            if (sqValue === '1') {
                const fj = values['fj']
                if (fj['fileList'] && fj['fileList'].length === 0) {
                    this.props.form.setFields({
                        fj: {
                            value: undefined,
                            errors: [new Error('请上传贷款合同或贷款回执单')]
                        }
                    })
                    this.setLoading(false)
                    return false
                }

                let file = fj['fileList'].filter(file => {
                    return file.status !== 'error';
                })

                if (file.length !== fj['fileList'].length) {
                    this.props.form.setFields({
                        fj: {
                            value: fj,
                            errors: [new Error('附件材料上传出错，请重新上传')]
                        }
                    })
                    this.setLoading(false)
                    return false
                }

                let fileList = fj['fileList']

                for (let i = 0; i < fileList.length; i++) {
                    let fjid = fileList[i].response.data.ssbh
                    let fjmc = fileList[i].name
                    fjlist.push({
                        fjid: fjid,
                        fjmc: fjmc
                    })
                }
            }

            let yjfy = values['yjfy']
            let hjfy = values['hjfy']
            let zjfy = values['zjfy']
            let dklx = dkfsValue
            let pkid = data.pkid
            let sfbllstd = sqValue
            let params;
            if (sqValue === '1') {
                params = {
                    sfbllstd, yjfy, hjfy, zjfy, xsid, zzbdhjid, pkid, dklx
                    , sjly: 2//数据来源：1.迎新现场办理\2.学生申请
                }
                if (fjlist.length > 0) params.fj = fjlist;
            } else {
                params = {sfbllstd, xsid, zzbdhjid, pkid}
            }

            if (!pkid) {
                NHFetch('/jxkjsfdx/lstdFb/insert', 'POST', params)
                    .then(res => {
                        if (res) {
                            message.success('绿色通道申请保存成功。')
                            this.props.loadLstdInfo()
                            this.props.reloadMenu()
                        }
                        this.setState({saveLoading: false}, () => setChannelState({
                            edit: false,
                            loading: this.state.saveLoading
                        }))
                    })
                    .catch(res => {
                        this.setLoading(false)
                        return res
                    })
            } else {
                NHFetch('/jxkjsfdx/lstdFb/update', 'POST', params)
                    .then(res => {
                        if (res && res.data && res.data > 0) {
                            message.success('绿色通道申请保存成功。')
                            this.props.loadLstdInfo()
                            this.props.reloadMenu()
                        } else {
                            if (!res.message.startsWith('businessLogicWarm[')) {
                                message.warning('绿色通道申请保存失败，请重试。')
                            }
                            // message.warning("绿色通道申请保存失败，请重试。");
                        }
                        this.setState({saveLoading: false}, () => setChannelState({
                            edit: false,
                            loading: this.state.saveLoading
                        }))
                    })
                    .catch(res => {
                        this.setState({saveLoading: false}, () => setChannelState({
                            edit: false,
                            loading: this.state.saveLoading
                        }))
                        return res
                    })
            }
        })
    }

    setLoading = status => {
        const {setChannelState} = this.props
        this.setState({saveLoading: status}, () => setChannelState({loading: this.state.saveLoading}))
    }


    getQueryString = name => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }

    // 初始化附件
    initFiles = props => {

        const {data: lstdData} = props
        let fileList = []
        if (lstdData && lstdData['fjmap']) {
            lstdData['fjmap'].map((elem, index) => {
                //格式化对于附件取值
                let file = {
                    uid: elem.fjid,
                    name: elem.fjmc,
                    response: {
                        data: {
                            ssbh: elem.fjid,
                            fjmc: elem.fjmc
                        }
                    },
                    status: 'done'
                }
                fileList.push(file)
            })
        }
        this.setState({fileList}, () => {
            this.props.form.setFieldsValue({fj: {fileList}})
        })
    }

    handleChange = ({fileList}) => {
        let count = 0
        fileList.map(elem => {
            if (elem && elem['status'] && elem['status'] === 'uploading') {
                this.setState({uploading: true})
                return
            } else if (elem && elem['status']) {
                count++
            }
        })
        if (count > 0) {
            this.setState({uploading: false})
        }
    }

    //判断文件类型是否正常  name：文件名称  map：类型范围数组  true正常
    setJudge = (name, map) => {
        let flag = false
        map.map(item => {
            if (new RegExp(`${item}$`).test(name)) {
                flag = true
            }
        })
        return flag
    }

    beforeUpload = file => {
        const {fileList} = this.state
        if (fileList.length >= 5) {
            message.error('最多只能上传5个文件')
            return false
        }
        if (!(this.setJudge(file.name, this.state.judgeImgList) || this.setJudge(file.name, this.state.judgeFileList))) {
            message.error('不能上传该格式文件')
            return false
        } else {
            return true
        }
    }

    remove = ssbh => {
        let url = `api/docrepo/attachment/sourceId/delete?souerceId=${ssbh}`
        NHFetch(url, 'POST').then(res => {
            if (res.data && res.data > 0) {
                message.success('已删除材料.')
            }
        })
    }



    handleShztmc = shzt => {
        switch (shzt) {
            case '0':
                return '学院审核'
            case '1':
                return '资助中心审核'
            case '2':
                return '审核通过'
            case '-1':
                return '不通过'
            case '9':
                return '驳回'
        }
    }


    render() {
        const {form} = this.props
        const {getFieldDecorator, getFieldValue, setFieldsValue} = this.props.form
        const {nextUrl, search, data, fjsmxx, fjsmzt, edit, sqValue} = this.props
        const {previewImage, previewVisible, saveLoading} = this.state
        const props = {
            name: 'file',
            action: 'zuul/docrepo/upload',
            maxCount: 5,
            data: {uuid: createUuid(), type: '1'},
            disabled: !edit,
            onRemove: file => {
                this.setState(
                    ({fileList}) => {
                        const index = fileList.indexOf(file)
                        const newFileList = fileList.slice()
                        newFileList.splice(index, 1)
                        this.props.form.setFieldsValue({fj: {fileList: newFileList}})
                        return {
                            fileList: newFileList
                        }
                    },
                    () => {
                        if (file.response && file.response.data && file.response.data.ssbh) {
                            this.remove(file.response.data.ssbh)
                        }
                    }
                )
            },
            beforeUpload: file => {
                if (!this.beforeUpload(file)) {
                    return false
                }
                if (this.state.fileList.length < 5) {
                    this.setState(({fileList}) => ({
                        fileList: [...fileList, file]
                    }))
                }
                return true
            },
            onChange: file => {
                this.handleChange(file)
            },
            onPreview: this.handlePreview,
            fileList: this.state.fileList
        }


        return (
            <React.Fragment>

                <Form>
                    { sqValue === '1' && <div>
                    <div className={css.form_item}>
                        <span style={{color: 'red'}}>* </span>
                        <span style={{fontSize: '14px'}}>本人本次应缴纳费用（学费、住宿费）：</span>
                        <NHFormItem id={'yjfy'} required={true}
                                    initialValue={data['yjfy']}
                                    form={form}>
                            <InputNumber formatter={value => `${value}元`}
                                         parser={value => value.replace('元', '')}
                                         style={{width: '150px'}}
                                         min={0}
                                         max={9999999}
                                         disabled={!edit}
                                         step={0.01}
                                         precision={2}
                                         onChange={value => {
                                             let zjfy = getFieldValue('zjfy')
                                             if (zjfy && zjfy > 0) {
                                                 setFieldsValue({hjfy: value - zjfy})
                                             }
                                         }}/>
                        </NHFormItem>
                    </div>

                    <div className={css.form_item}>
                        <span style={{color: 'red'}}>* </span>
                        <span style={{fontSize: '14px'}}>现只能暂交：</span>
                        <NHFormItem id={'zjfy'}
                                    required={true}
                                    initialValue={data['zjfy']}
                                    form={form}>
                            <InputNumber formatter={value => `${value}元`}
                                         parser={value => value.replace('元', '')}
                                         style={{width: '150px'}}
                                         min={0}
                                         disabled={!edit}
                                         max={getFieldValue('yjfy')}
                                         step={0.01}
                                         precision={2}
                                         onChange={value => {
                                             let yjfy = getFieldValue('yjfy')
                                             if (yjfy && yjfy > 0) {
                                                 setFieldsValue({hjfy: yjfy - value})
                                             }
                                         }}/>
                        </NHFormItem>
                    </div>

                    <div className={css.form_item}>
                        <span style={{color: 'red'}}>* </span>
                        <span style={{fontSize: '14px'}}>因家庭经济困难，缓交：</span>
                        <NHFormItem id={'hjfy'} required={true}
                                    initialValue={data['hjfy']}
                                    form={form}>
                            <InputNumber formatter={value => `${value}元`}
                                         parser={value => value.replace('元', '')}
                                         style={{width: '150px'}}
                                         min={0}
                                         disabled={true}
                                         max={9999999}
                                         step={0.01}
                                         precision={2}/>
                        </NHFormItem>
                    </div>

                    <div className={css.form_item}>
                        <span style={{color: 'red'}}>* </span>
                        <span>请上传困难佐证材料（含贷款合同或回执）：</span>
                        <FormItem form={form}>
                            {
                                getFieldDecorator('fj', {
                                    rules: [{
                                        required: true,
                                        message: '请上传贷款合同或贷款回执单'
                                    }]
                                })
                                (
                                    <Dragger style={{width: '100%'}} {...props}>
                                        <p className="ant-upload-drag-icon">
                                            <Icon type="inbox"/>
                                        </p>
                                        <Spin spinning={this.state.uploading}/>
                                        <p className="ant-upload-text font-14">点击此处或拖拽文件到此</p>
                                    </Dragger>
                                )
                            }
                        </FormItem>
                        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                            <img alt="example" style={{width: '100%'}} src={previewImage}/>
                        </Modal>
                    </div>
                    {
                        fjsmzt &&
                        <div style={{
                            color: '#F00',
                            wordBreak: 'break-all',
                            whiteSpace: 'normal',
                            lineHeight: '15px',
                            width: '100%'
                        }} align="middle">
                            {fjsmxx}
                        </div>
                    }

                    {
                        Object.keys(data).length > 0 && data.sfbllstd === '1' &&
                        <div>
                            <TitleTop title="审核状态" extra={false} />
                            <div className="form_item" style={{marginLeft: '50px', marginTop: '50px'}}>
                                <Descriptions title="学院审核" column={1}>
                                    <Descriptions.Item label="审核时间">{data['yjshsj']}</Descriptions.Item>
                                    <Descriptions.Item label="审核意见">{data['yjshyj']}</Descriptions.Item>
                                </Descriptions>
                                <Descriptions title="资助中心审核" column={1} style={{ marginTop: '50px'}}>
                                    <Descriptions.Item label="审核时间">{data['ejshsj']}</Descriptions.Item>
                                    <Descriptions.Item label="审核意见">{data['ejshyj']}</Descriptions.Item>
                                </Descriptions>

                                <div style={{ marginTop: '50px'}}>
                                    <h1><span style={{fontWeight: '600',marginRight: '14px'}}>审核状态:</span>
                                        {
                                            this.handleShztmc(data['shzt'])
                                        }
                                    </h1>

                                </div>

                            </div>
                        </div>
                    }
                    </div>}

                    <div className="text-center mt-50">
                        {edit &&
                            <Button type="primary" htmlType="submit" onClick={this.handleOk} loading={saveLoading}>
                                保存
                            </Button>
                        }
                        <NextButton nextUrl={nextUrl} search={search}/>
                    </div>
                </Form>

            </React.Fragment>
        )
    }
}
