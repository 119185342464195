import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Flex } from '@components';
import { Button, message, Table, Descriptions, Divider } from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import NextButton from '@pages/SelfHelp/common/nextIndex'
import styles from './index.css';
import { getLoginUser, createUuid } from "@utils/NHCore";
import createHistory from 'history/createHashHistory';
import NHFetch from "../../../utils/NHFetch";
import { mkbz } from "@pages/Assembly/siderMenu/menuObj";
import BarCode from 'react-barcode';
import classNames from 'classnames'

const history = createHistory();
const DesItem = Descriptions.Item;

function handleGroup(arr) {
    let obj = {};
    let groups = [];
    arr.forEach(item => {

        if (!obj[item.MC]) { //根据MC分组
            groups.push({
                groupName: item.MC,
                items: [item]
            });
            obj[item.MC] = item;
        } else {

            groups.find(v => {//选择符合条件的第一个元素
                if (v.groupName === item.MC) {
                    v.items.push(item);
                }
            });


        }
    });
    return groups;
}

export default class informationSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: {}, //个人信息
            classInfo: {}, //班级信息
            jfXqInfo: [], //缴费详情信息
            lxxxInfo: {},
            jfHzInfo: {}, //缴费汇总信息
            xcbdInfo: [], //现场报到信息
            WdXxflList: [],
        };
    }

    componentDidMount() {
        let userId = getLoginUser() ? getLoginUser().userId : undefined;
        if (userId) {
            this.getStudentPhotoBase64(userId, (data => {
                if (!data) return;
                this.setState({
                    xsid: userId,
                    imageUrl: `data:image/jpeg;base64,${data}`
                })
            }))

            this.getWdXxflList();
            this.getUserInfo(userId);
            this.getClassInfo(userId);//D32B39EC72084D82E055000000000001
            this.getJfInfo(userId);//AF7D9429405241CB87200CB0A2A1C3A1
            this.getXcbdInfo(userId);//89c7c40c-dd4c-4460-a5e5-5166a54a0fac
            this.getLxxxInfo(userId)
        }else{
            message.error('未获取登录信息')
            history.push({
                pathname: '/index',
            });
        
            return false;
        }
    }

    // 获取学生头像
    getStudentPhotoBase64 = (userId, callback) => {
        NHFetch('api/zhxg-yxwz/openapi/student/getStudentPhotoBase64', 'get', { xsid: userId, type: 10 })
            .then(res => {
                if (res && res.code == 200) {
                    callback(res.data);
                }
            });
    }


    // 获取个人信息
    getUserInfo = (xsid) => {
        NHFetch("/newStudent/getStudentInfoAndZsxx", "GET", {
            xsid,
        }).then((res) => {
            if (res && res.code !== 200) return message.warning("个人信息获取失败");
            this.setState({
                userInfo: res.data,
            });
        });
    };

    // 获取班级信息
    getClassInfo = (xsid) => {
        this.setState({ loading: true });
        NHFetch("/newStudent/getStudentBjxx", "GET", {
            xsid,
        }).then((res) => {
            if (res && res.code !== 200) return message.warning("班级信息获取失败");
            this.setState({
                classInfo: res.data,
            });
        });
    };

    // 联系信息
    getLxxxInfo = (xsid) => {
        this.setState({ loading: true});
        NHFetch("/newStudent/getStudentLxxx", "GET", {
        xsid,
        }).then((res) => {
        if (res.code !== 200) return Toast.fail("联系信息获取失败");
        this.setState({
            lxxxInfo: res.data,
            });
        })
    }

    // 获取缴费信息
    getJfInfo = (xsid) => {
        this.setState({ loading: true });
        NHFetch("/newStudent/getStudentJfxx", "GET", {
            xsid,
        }).then((res) => {
            if (res && res.code !== 200) return message.warning("缴费信息获取失败"); 
            let jfXqInfo = res.data && res.data.JFXQ ? res.data.JFXQ :[];
            let jfHzInfo = res.data && res.data.JFHZ ? res.data.JFHZ :{};

            jfHzInfo["SFXMMC"] = "合计";
            jfXqInfo.push(jfHzInfo);
            this.setState({
                jfXqInfo: jfXqInfo,
                jfHzInfo: res.data ? res.data.JFHZ :{},
            });
        });
    };

    // 现场报到信息
    getXcbdInfo = (xsid) => {
        this.setState({ loading: true });
        NHFetch("/openapi/yxxt/getXsYxxcLcXx", "GET", {
            xsid,
        }).then((res) => {
            if (res && res.code !== 200) return message.warning("现场报到信息获取失败");
            this.setState({
                xcbdInfo: res.data ? res.data : [],
            });
        });
    };

    //获取我的信息分类模块
    getWdXxflList = () => {
        NHFetch(
        "/proData/selectDataList",
        "get",
        "sign=dmk_YXXT_ZZBD_XS_WD_XXFL"
        ).then((res) => {
        if (res && res.data) {
            this.setState({ WdXxflList: res.data });
        } else {
            Toast.fail("首页模块信息获取失败!");
        }
        });
    };

    renderContent = (key) => {
        const { userInfo, classInfo, jfXqInfo, xcbdInfo, imageUrl, lxxxInfo } = this.state;
        let zsxx = "";
        if(userInfo){
            if (userInfo.XQMC) zsxx = `-${userInfo.XQMC}`; //校区
            if (userInfo.LDMC) zsxx += `-${userInfo.LDMC}`; //楼栋
            if (userInfo.LCMC) zsxx += `-${userInfo.LCMC}`; //楼层
            if (userInfo.FJMC) zsxx += `-${userInfo.FJMC}（房间号）`; //房间
            if (userInfo.CWH) zsxx += `-${userInfo.CWH}（床位号）`; //床位
            zsxx = zsxx ? zsxx.slice(1) : ""; //删除第一个'-'
        }
        //学生信息
        const userInfoColumns = [
            {
                label: '姓名',
                name: userInfo?userInfo.XM:''
            },
            {
                label: '身份证号',
                name: userInfo?userInfo.SFZJH:''
            },
            {
                label: '学号',
                name: userInfo?userInfo.XH:'',
                judgeIsShow: true
            },
            {
                label: '学院',
                name: userInfo?userInfo.BMMC:''
            },
            {
                label: '专业',
                name: userInfo?userInfo.ZYMC:''
            },
            {
                label: '班级',
                name: userInfo?userInfo.BJMC:'',
                judgeIsShow: true
            },
            {
                label: '考生号',
                name: userInfo?userInfo.KSH:'',
                judgeIsShow: true
            },
            {
                label: '民族',
                name: userInfo?userInfo.MZ:''
            }, 
            {
                label: '住宿信息',
                name: zsxx,
                judgeIsShow: true
            }
        ]

        //班级信息
        const classInfoColumns = classInfo ? [
            {
                label: '班主任',
                name:  classInfo.BZR && classInfo.BZR[0]? classInfo.BZR[0].XM:null
            },
            {
                label: '班主任联系电话',
                name: classInfo.BZR && classInfo.BZR[0]? classInfo.BZR[0].LXDH:null
            },
            {
                label: '辅导员',
                name: classInfo.FDY && classInfo.FDY[0]?classInfo.FDY[0].XM:null
            },
            {
                label: '辅导员联系电话',
                name: classInfo.FDY && classInfo.FDY[0]?classInfo.FDY[0].LXDH:null
            },
            {
                label: '班级qq',
                name: classInfo.BJQQ && classInfo.BJQQ[0]?classInfo.BJQQ[0].BJQQ:null
            }
        ]:[];

        //缴费信息
        let payColumns = [
            {
                title: '收费项目',
                dataIndex: 'SFXMMC'
            },
            {
                title: '收费周期',
                dataIndex: 'JFZQ'
            }, 
        ];

        if(jfXqInfo != [] && jfXqInfo.length >0){
           let jfHzInfo= jfXqInfo[jfXqInfo.length-1];
           if(jfHzInfo.YSJE || jfHzInfo.YSJE == 0){
            payColumns.push( {
                title: '应收金额',
                dataIndex: 'YSJE'
            });
           }
           if(jfHzInfo.SSJE  || jfHzInfo.SSJE == 0){
            payColumns.push( {
                title: '已缴金额',
                dataIndex: 'SSJE'
            });
           }
           if(jfHzInfo.JMJE  || jfHzInfo.JMJE == 0){
            payColumns.push( {
                title: '减免金额',
                dataIndex: 'JMJE'
            });
           }
           if(jfHzInfo.HJJE  || jfHzInfo.HJJE == 0){
            payColumns.push( {
                title: '缓交金额',
                dataIndex: 'HJJE'
            });
           }
           if(jfHzInfo.QFJE  || jfHzInfo.QFJE == 0){
            payColumns.push( {
                title: '欠费金额',
                dataIndex: 'QFJE'
            });
           }
        }

        //联系信息
        const lxxxInfoColumns = [];
        let content = "";
        if (lxxxInfo != null) {

            if(lxxxInfo.BJLXR && lxxxInfo.BJLXR.length !== 0) {
                handleGroup(lxxxInfo.BJLXR).map((temp) => {
                    temp.items.map((item) => {
                        if (item) {
                            content += item.JSXM + " " + (item.JSDH ? "(" + item.JSDH +")" : "(无联系方式)");
                            content += "\xa0\xa0\xa0";
                        }
                    })
                    if (content != "") {
                        lxxxInfoColumns.push({label: temp.groupName, name: content})
                    }
                    content = ""
                })
            }

            lxxxInfo.YXLXR && lxxxInfo.YXLXR.length !== 0 ?
                handleGroup(lxxxInfo.YXLXR).map((temp, key) => {
                    temp.items.map((item) => {
                        if (item) {
                            content += item.JSXM + " " + (item.JSDH ? "(" + item.JSDH +")" : "(无联系方式)");
                            content += "\xa0\xa0\xa0";
                        }
                    })
                    if (content != "") {
                        lxxxInfoColumns.push({label: temp.groupName, name: content})
                    }
                    content = ""
                })
            : null;

            lxxxInfo.QH && lxxxInfo.QH.length !== 0 ? 
            lxxxInfoColumns.push({label: lxxxInfo.QH.MC, name: lxxxInfo.QH.QH}) : null

            if (lxxxInfo.SG && lxxxInfo.SG.length !== 0) {
                handleGroup(lxxxInfo.SG).map((temp) => {
                    temp.items.map((item) => {
                        if (item) {
                            content += item.JSXM + " " + (item.JSDH ? "(" + item.JSDH +")" : "(无联系方式)");
                            content += "\xa0\xa0\xa0";
                        }
                    })
                    if (content != "") {
                        lxxxInfoColumns.push({label:  temp.groupName, name: content})
                    }
                    content = ""
                })
            }
        }
        
        const hjColumns = [
            {
                title: '环节名称',
                dataIndex: 'HJMC',
                render(text, record) {
                    return(
                        <div>
                             {record.HJLB == '1'?
                             <div>{record.HJMC}<span style={{ color: 'red' }} >*</span></div> :
                              record.HJMC}
                        </div>
                       
                    );
                }
            }, 
            {
                title: '环节状态',
                dataIndex: 'HJZT',
                render(text, record) {
                    return(
                        <div>
                             {record.HJZT == '1'?
                             "已办理" :
                             "未办理"}
                        </div>
                       
                    );
                }
            }
        ]; 

        let itemList = "";
        switch (key) {
          case "grxx": //个人信息
            itemList = userInfo ? (
                <Flex className="p-sw-big">
                <img styleName="jbxx_icon" className="_bg" src={imageUrl}
                    onError={e => { e.target.src = stuDefault }}
                />
                <Flex.Item className="ml-20">
                    {userInfoColumns.map((elem, index) => {
                        console.log('userInfoColumns',elem)
                        return (
                            // <div key={index} styleName="jbxx_item">
                            //     {elem.judgeIsShow ?
                            //     elem.name ?
                            //         <div>
                            //             <span styleName="item-label">{elem.label}：</span>
                            //             <div
                            //                 className="t-clamp-1"
                            //                 styleName="item-name"
                            //                 style={{ display: 'inline' }}
                            //             >
                            //                 {elem.name}
                            //             </div>
                            //         </div> 
                            //         :""
                            //         :<div>
                            //         <span styleName="item-label">{elem.label}：</span>
                            //         <div
                            //             className="t-clamp-1"
                            //             styleName="item-name"
                            //             style={{ display: 'inline' }}
                            //         >
                            //             {elem.name}
                            //         </div>
                            //     </div> }
                            // </div>
                            elem.name ? (
                                <div key={index} styleName="jbxx_item">
                                    
                                    <div>
                                        <span styleName="item-label">{elem.label}：</span>
                                        <div
                                            className="t-clamp-1"
                                            styleName="item-name"
                                            style={{ display: 'inline' }}
                                        >
                                            {elem.name}
                                        </div>
                                    </div>  
                                </div>
                            ): ''
                            

                        );
                    })}
                </Flex.Item>
                </Flex>) : null
            break;
          case "bjxx": //班级信息
            itemList = classInfo ? (
                classInfo && classInfoColumns.map((elem, index) => {
                return ( 
                    <div styleName="ssxx_item">
                        { elem.name ?
                            <div> 
                                <div styleName="ssxx_label">
                                    {elem.label}：
                                </div>
                                <div styleName="ssxx_name">
                                    {elem.name}
                                </div>
                            </div>
                            : "" 
                            }  
                            
                    </div>
                    // <div styleName="ssxx_list">
                    //     <div styleName="ssxx_item">
                    //         <div styleName="ssxx_label">
                    //             校区：
                    //             </div>
                    //         <div styleName="ssxx_name">
                    //             {userInfo.xqmc}
                    //         </div>
                    //     </div>
                    //     <div styleName="ssxx_item">
                    //         <div styleName="ssxx_label">
                    //             楼栋：
                    //             </div>
                    //         <div styleName="ssxx_name">
                    //             {userInfo.ldmc}
                    //         </div>
                    //     </div>
                    // </div>  
                );
            }) 
            ) : null 
            break;
          case "jfxx": //缴费信息
            itemList = jfXqInfo ? (
                <div className="p-sw-big">
                <span style={{float: "right", marginRight: "20px"}}>单位：元</span>
                <Table
                    bordered
                    size="middle"
                    pagination={false}
                    dataSource={jfXqInfo}
                    columns={payColumns}
                />
                </div>) : null

            break;
          case "xcbd": //现场报到
            itemList = classInfo ? (
                <div className="p-sw-big">
                <Table
                    bordered
                    size="middle"
                    pagination={false}
                    dataSource={xcbdInfo}
                    columns={hjColumns}
                />
                </div>) : null
            
            break;
          case "lxxx": // 联系信息
          itemList = classInfo ? (
            lxxxInfo && lxxxInfoColumns.map((elem, index) => {
                return ( 
                    <div>
                        { elem.name ?
                            <Descriptions bordered style={{ margin: '10px 37px' }} >
                                <DesItem label={elem.label} span={3}>
                                    {elem.name}
                                </DesItem>
                            </Descriptions>
                            : ""
                        }
                    </div>
                );
            })
            ) : null
            break;
          default:
            itemList = "";
            break;
        }
        return itemList;
      };

    render() {
        const { WdXxflList } = this.state;
        return (

            <div>
                    <Flex className="w1180 mt-20" align="start">

                        <Flex.Item className="page_con_r">
                            <Breadcrumb
                                noLine
                                list={[
                                    { name: '首页', url: '/index' },
                                    { name: '信息查询', url: '/informationSearch' },
                                ]}
                            />
                            <div className="box">
                            {
                                WdXxflList.map((item, index) => {

                                    return (
                                        <div>
                                        <TitleTop title={item.LABEL} extra={false} />
                                        {this.renderContent(item.VALUE)}
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </Flex.Item>
                    </Flex>
                }

                <style jsx="true" global="true">
                    {`
                    .ant-descriptions-item-label {
                        width: 200px;
                    }
                    .ant-descriptions-row > td{
                        background-color: #ffffff!important;
                    }
                    `}
                </style>
            </div>

        );
    }
}
