import React, { Component } from 'react'
import { Button } from 'antd'
import '../../../../../assets/css/dormV2/Filter.css'
import { cloneDeep } from 'lodash-es'

// 初始筛选器数据
const initFilterData = [
  {
    categoryName: '楼栋',
    id: 'ld',
    isFlod: false,
    optionFontSize: 'small',
    foldBoxHeight: 'large',
    options: []
  },
  {
    categoryName: '楼层',
    id: 'lc',
    isFlod: true,
    optionFontSize: 'large',
    foldBoxHeight: 'small',
    options: [
      {
        id: '全部',
        describe: '全部',
        selectStatus: true,
        value: ''
      }
    ]
  },
  {
    categoryName: '房间类型',
    id: 'fjlx',
    isFlod: true,
    optionFontSize: 'large',
    foldBoxHeight: 'small',
    options: [
      {
        id: '全部',
        describe: '全部',
        value: '',
        selectStatus: false
      }
    ]
  },
  {
    categoryName: '床位情况',
    id: 'rzqk',
    isFlod: true,
    optionFontSize: 'large',
    foldBoxHeight: 'small',
    options: [
      {
        id: '全部',
        describe: '全部',
        selectStatus: false,
        value: ''
      },
      {
        id: '有剩余',
        describe: '有剩余',
        selectStatus: true,
        value: '0'
      },
      {
        id: '无剩余',
        describe: '无剩余',
        selectStatus: false,
        value: '1'
      }
    ]
  }
]

// 用于重置筛选条件
let filterDataBackup = []

export default class Filter extends Component {
  state = {
    foldStatus: 'folded',
    filterBoxEle: null,
    sectionHeight: 0,
    filterData: initFilterData,
    ldmc: '' //楼栋名称
  }

  componentDidMount() {
    this.getSectionHeight()
    this.saveFilterBoxEle()
  }

  // 保存dom元素
  saveFilterBoxEle = () => {
    this.setState({ filterBoxEle: document.getElementById('filterBox') })
  }

  // 获取section高度
  getSectionHeight = () => {
    this.setState({ sectionHeight: document.getElementById('optionsBox').offsetHeight })
  }

  // 改变折叠状态
  changeFlodStatus = () => {
    const { foldStatus, filterBoxEle, sectionHeight } = this.state
    this.setState({ foldStatus: foldStatus === 'folded' ? 'unfolded' : 'folded' }, () => {
      filterBoxEle.style.height = this.state.foldStatus === 'folded' ? '32px' : 32 + 16 + 60 + sectionHeight + 'px'
    })
  }

  // 更新筛选条件
  updateFilterData = (data, sfxssfbz) => {
    const { filterData } = this.state
    // 处理楼栋名称
    const ldmc = data.ldxxList
      .filter(item => item.LDMRZ && item.LDMRZ === '1')[0]
      .XQ_LDXX.replace(/[|]/g, '-')
      .replace(/[\s*]/g, '')
    this.setState({ ldmc })
    const newFilterData = cloneDeep(filterData)
    // 判断是否显示收费标准
    if (sfxssfbz === '1') {
      const flag = !newFilterData.some(item => item.id === 'sfbz')
      flag &&
        newFilterData.push({
          categoryName: '收费标准(元)',
          id: 'sfbz',
          isFlod: true,
          optionFontSize: 'large',
          foldBoxHeight: 'small',
          options: [
            {
              id: '全部',
              describe: '全部',
              value: '全部',
              selectStatus: false
            }
          ]
        })
    }

    newFilterData.forEach(category => {
      if (category.id === 'fjlx') {
        const options = [
          {
            id: '全部',
            describe: '全部',
            value: '',
            selectStatus: true
          }
        ]
        data.cwsList.forEach(item => {
          options.push({
            id: item,
            describe: `${item}人间`,
            value: item,
            selectStatus: false
          })
        })
        category.options = options
      } else if (category.id === 'sfbz') {
        const options = [
          {
            id: '全部',
            describe: '全部',
            value: '',
            selectStatus: true
          }
        ]
        data.sfbzList.forEach(item => {
          options.push({
            id: item,
            describe: item,
            value: item,
            selectStatus: false
          })
        })
        category.options = options
      } else if (category.id === 'ld') {
        const options = []
        data.ldxxList.forEach((item, index) => {
          const ldmc = item.XQ_LDXX.split('|')
          options.push({
            id: item.LDID,
            value: `${item.XQID} - ${item.LDID}`,
            describe: (
              <React.Fragment>
                {ldmc[0]}
                <br />
                {ldmc[1]}
              </React.Fragment>
            ),
            selectStatus: item.LDMRZ === '1',
            detail: item
          })
        })
        category.options = options
      } else if (category.id === 'lc') {
        const options = [
          {
            id: '全部',
            describe: '全部',
            selectStatus: true,
            value: ''
          }
        ]
        data.lcmcList.forEach((item, index) => {
          options.push({
            id: item,
            value: item,
            describe: item,
            selectStatus: false
          })
        })
        category.options = options
      }
    })

    this.setState({ filterData: newFilterData }, this.getSectionHeight)
    filterDataBackup = newFilterData
    return newFilterData
  }

  // 点击筛选选项
  selectOption = (categoryId, optionId) => {
    const { filterData } = this.state
    const newFilterData = cloneDeep(filterData)
    newFilterData.forEach(item => {
      if (item.id === categoryId) {
        item.options.forEach(option => {
          option.selectStatus = false
          option.id === optionId && (option.selectStatus = true)
        })
      }
    })
    this.setState({ filterData: newFilterData })
  }

  // 整理筛选参数
  getFilterParams = data => {
    const searchParams = {}
    data.forEach(item => {
      const selectedOption = item.options.filter(option => option.selectStatus)[0]

      if (item.id === 'ld') {
        searchParams.ldid = selectedOption.detail.LDID
        searchParams.xqid = selectedOption.detail.XQID
      } else if (item.id === 'lc') {
        searchParams.lcmc = selectedOption.value
      } else if (item.id === 'fjlx') {
        searchParams.cws = selectedOption.value
      } else if (item.id === 'rzqk') {
        searchParams.rzqk = selectedOption.value
      } else if (item.id === 'sfbz') {
        searchParams.sfbz = selectedOption.value
      }
    })
    return searchParams
  }

  // 更新楼栋名称
  updateLdmc = data => {
    const ldmc = data[0].options
      .filter(item => item.selectStatus)[0]
      .detail.XQ_LDXX.replace(/[|]/g, '-')
      .replace(/[\s*]/g, '')
    this.setState({ ldmc })
  }

  // 查询
  search = () => {
    const { onSearch } = this.props
    const { filterData } = this.state
    this.updateLdmc(filterData)
    if (onSearch) {
      const searchParams = this.getFilterParams(filterData)
      onSearch(searchParams)
    }
  }

  // 重置
  reset = () => {
    const { onSearch } = this.props
    this.updateLdmc(filterDataBackup)
    this.setState({ filterData: filterDataBackup })
    if (onSearch) {
      const searchParams = this.getFilterParams(filterDataBackup)
      onSearch(searchParams)
    }
  }

  render() {
    const { foldStatus, filterData, ldmc } = this.state
    return (
      <div className='dormV2-filter' id="filterBox">
        <header>
          <div className={foldStatus === 'folded' ? 'btns btnsfolded' : 'btns btnsunfolded'}>
            <Button type="primary" onClick={this.search}>
              查询
            </Button>
            <Button className="reset" onClick={this.reset}>
              重置
            </Button>
          </div>

          <div className="fold" onClick={this.changeFlodStatus}>
            {foldStatus === 'folded' ? '展开' : '收起'}
            <span className={foldStatus === 'folded' ? 'folded' : 'unfolded'} />
          </div>
        </header>
        <section id="optionsBox">
          <ul>
            {filterData.map(category => {
              return (
                <li key={category.id}>
                  <div className="label">{category.categoryName}：</div>
                  <div className="options">
                    {category.options.map(option => {
                      return (
                        <div
                          key={option.id}
                          className={option.selectStatus ? 'option selected' : 'option'}
                          onClick={() => {
                            this.selectOption(category.id, option.id)
                          }}
                        >
                          {option.describe}
                        </div>
                      )
                    })}
                  </div>
                </li>
              )
            })}
          </ul>
        </section>
        <footer className={foldStatus === 'folded' && 'footer-to-top'}>
          <span>{ldmc}</span>
        </footer>
      </div>
    )
  }
}
