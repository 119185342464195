import React, { Component } from 'react'
import { Icon, message, Modal, Spin } from 'antd'
import '../../../../../assets/css/dormV2/Roommate.css'

export default class Roommate extends Component {
  state = {
    visible: false,
    currentPerson: {}
  }

  showMore = person => {
    return () => {
      if (!person.XSID && !person.XM) {
        message.info('此床位未被选择')
      } else {
        this.setState({ visible: true, currentPerson: person })
      }
    }
  }

  render() {
    const { roommateDetail, back, isViewRoommate, loading, selfId } = this.props
    const { visible, currentPerson } = this.state
    return (
      <div className={'roommate' + (isViewRoommate ? ' translateX0' : ' translateX100')}>
        <div class="top">
          <Icon type="arrow-left" style={{ cursor: 'pointer' }} onClick={back} />
          查看舍友
          <div class="hr" />
        </div>
        <Spin spinning={loading}>
          <div class="content">
            {!loading &&
              roommateDetail.map(item => (
                <div className="person" key={item.XSID}>
                  <img src={'data:image/png;base64,' + item.XSZP} alt="头像" />
                  <div class="info">
                    <div class="row">
                      <div class="name"> {item.XM || '空'}</div>
                      <div class="more" onClick={this.showMore(item)}>
                        更多
                        <Icon type="right" />
                      </div>
                    </div>
                    <div class="bed-number">
                      床位：<span>{item.CWH}</span>
                    </div>
                  </div>
                  {selfId === item.XSID && <div class="self">本人</div>}
                </div>
              ))}
          </div>
        </Spin>

        <Modal
          className="roommate-modal"
          visible={visible}
          width={'max-content'}
          footer={null}
          title={currentPerson.XM}
          onCancel={() => {
            this.setState({ visible: false })
          }}
        >
          <div className="info-card">
            <img src={'data:image/png;base64,' + currentPerson.XSZP} alt="头像" />
            <div className="info-list">
              <div>床位：{currentPerson.CWH}</div>
              <div>电话：{currentPerson.SJHM}</div>
              <div>年级：{currentPerson.SSNJ || '空'}</div>
              <div>学院：{currentPerson.BMMC || '空'}</div>
              {!currentPerson.BJMC && currentPerson.ZYMC && <div>专业：{currentPerson.ZYMC}</div>}
              {currentPerson.BJMC && <div>班级：{currentPerson.BJMC}</div>}
              <div>民族：{currentPerson.MZMC || '空'}</div>
              <div>
                生源地：
                <span>{currentPerson.SSQ || '空'}</span>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}
