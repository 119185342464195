import { Modal, message } from 'antd';
import NHFetch from "@utils/NHFetch";
import { JSEncrypt } from 'jsencrypt'
import createHistory from 'history/createHashHistory';
const history = createHistory();
import { getLoginUser } from "@utils/NHCore";
import moment from 'moment';
//公钥
const pubKey = "MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAPaJRiLeajOhWQhK1HdO/2JVofnwS1APB8R8R85HWtlsAoWMQgVPyJVtqbj3T1cCqenlIkgFv/MuchmvXxJpePkCAwEAAQ==";

var querystring = require('querystring');//将POST请求参数转换请求字符串

export const RECEIVE_USERINFO = 'RECEIVE_USERINFO'



export const receiveUserInfo = (user) => {
    return {
        type: RECEIVE_USERINFO,
        data: user
    }
}

export const userLogin = (params, func) => {
    const { username } = params;
    //公钥加密
    var encrypt = new JSEncrypt();
    encrypt.setPublicKey(pubKey);
    let encryptPassword = encrypt.encrypt(params.password); //password为需要加密的字段
    params.password = encryptPassword;

    NHFetch('api/shiro/cache/' + username + '/delete', 'POST').then(
        () => {
            let url = "yx/yxwz/login";
            if (window.gateway_version === '2') {
                url = "sso/login";
                params.realm = "yxwzAuthc";//认证器id，在网关后台-认证配置
                params.value = params.username;
                params.passWord = params.password;
                //reqMethod = "GET";
            }
            fetch(url, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "X-Requested-With": "XMLHttpRequest",
                },
                body: querystring.stringify(params)
            })
                .then((response) => response.json())
                .then((res) => {
                    if (res.meta && res.meta.statusCode == '200') {
                        sessionStorage.setItem("userLogin", JSON.stringify(res.data));
                        sessionStorage.setItem('access_token', res.data.tokenId);
                        sessionStorage.setItem('zh', username);
                        if (window.gateway_version === '2') {
                            sessionStorage.setItem('access_token', res.data.accessToken);
                            //刷新token,后续用来刷新token
                            sessionStorage.setItem('refresh_token', res.data.refreshToken);
                            //记录用户最后一次操作时间
                            let userLastOperationTime = moment().format('YYYY-MM-DD HH:mm:ss');
                            sessionStorage.setItem('user_last_operation_time', userLastOperationTime);
                        }
                        //拼接单点
                        if (window.casLogoutUrl && window.casLogoutUrl != '') {
                            window.location = window.casLogoutUrl;
                        }
                       
                        //加入默认栏目
                        NHFetch("front/zhxg-unauth/yxwz/getDhlmMrlm", "GET")
                            .then(resData => {
                                if (resData && resData.data) {
                                    console.log('resData.data2', resData.data)

                                    let dhlm = resData.data || [];
                                    // this.setState({ dhlm: dhlm });
                                    if (dhlm.mkbz !== 'index' && dhlm.mkbz !== null) {
                                        if (dhlm.xsfs == 1) {
                                            history.push({
                                                pathname: dhlm.mkbz
                                            })
                                        } else {
                                            let userId = getLoginUser() ? getLoginUser().userId : undefined;
                                            if (userId) {
                                                history.push({
                                                    pathname: dhlm.mkbz
                                                })
                                            } else {
                                                history.push({
                                                    pathname: '/index',
                                                });
                                            }
                                        }
                                        
                                    }
                                    location.reload();
                                } else {
                                    location.reload();
                                }
                            });
                      
                    } else {
                        if (func) {
                            func();
                        }
                        console.log(res)
                        message.error(res.meta.message);
                    }
                    return res;
                })
                .catch((error) => {
                    if (func) {
                        func();
                    }
                    // 网络请求失败返回执行该回调函数，得到错误信息
                    Modal.error({ title: '网络请求异常,请联系管理员', content: error, });
                    return error;
                })
        })
}

export const userLogout = () => {
    const zh = sessionStorage.getItem("zh");
    fetch("logout", {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-Requested-With": "XMLHttpRequest",
        }
    })
        .then((response) => response.json())
        .then((res) => {
            if (res.meta && res.meta.statusCode == '200') {
                sessionStorage.removeItem("userLogin");
                sessionStorage.removeItem("access_token");
                if (window.casLogoutUrl && window.casLogoutUrl != '') {
                    window.location = window.casLogoutUrl;
                }
                // else{
                //     location.reload();
                // }
                location.reload();
                if (zh) {
                    NHFetch('api/shiro/cache/' + zh + '/delete', 'POST');
                }
            } else {
                message.error("退出登陆失败，请稍后再试一次！");
            }
            return res;
        })
        .catch((error) => {
            // 网络请求失败返回执行该回调函数，得到错误信息
            Modal.error({ title: '网络请求异常,请联系管理员', content: error, });
            return error;
        })
}

/**
 * 获取已经登录的用户信息
 * @param func
 */
export const tryLoginUserInfo = (func) => {
    //第一步，清空缓存
    sessionStorage.removeItem("userLogin");
    sessionStorage.removeItem("access_token");
    fetch("tryLoginUserInfo", {
        method: window.gateway_version === '2' ? "GET" : "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-Requested-With": "XMLHttpRequest",
            "gatewayappid": "ly-sm",
        }
    })
        .then((response) => response.json())
        .then((res) => {
            if (res.meta && res.meta.statusCode == '200') {
                if (res.data) {
                    sessionStorage.setItem("userLogin", JSON.stringify(res.data));
                    sessionStorage.setItem('access_token', res.data.tokenId);
                    if (window.gateway_version === '2') {
                        sessionStorage.setItem('access_token', res.data.accessToken);
                        //刷新token,后续用来刷新token
                        sessionStorage.setItem('refresh_token', res.data.refreshToken);
                        //记录用户最后一次操作时间
                        let userLastOperationTime = moment().format('YYYY-MM-DD HH:mm:ss');
                        sessionStorage.setItem('user_last_operation_time', userLastOperationTime);
                    }
                } else {
                    sso(func);
                }
            }
            sso(func);
            return res;
        })
        .catch((error) => {
            if (func) {
                func();
            }
            // 网络请求失败返回执行该回调函数，得到错误信息
            //Modal.error({ title: '网络请求异常,请联系管理员', content: error,});
            return error;
        })
}

//单点登录验证
const sso = (func) => {

    let userLogin = sessionStorage.getItem("userLogin");
    if (userLogin) {
        if (func) {
            func();
        }
    } else {
        //是否启用单点登录
        if (window.casStatus) {
            var urlencode = require('urlencode');
            //跳转至单点登录验证页面
            window.location = window.location.origin + window.location.pathname + 'auth?service=' + urlencode(window.location.href);
        } else {
            if (func) {
                func();
            }
        }
    }
    if (func) {
        func();
    }
}