import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Flex } from '@components';
import {
    Form, Input, Checkbox, Row, Col, Button, Modal,
    Upload, Icon, message, Spin, InputNumber, Radio
} from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import { createUuid, getLoginUser } from '@utils/NHCore';
import NHFetch from "@utils/NHFetch";
import View from "@pages/SelfHelp/channelZtxy/view";
import createHistory from 'history/createHashHistory';
import CommonTip from "@pages/SelfHelp/common";
import NextButton from '@pages/SelfHelp/common/nextIndex'
import { mkbz } from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";
import NHFormItem from "@components/common/NHFormItem";
const history = createHistory();
const { TextArea } = Input;
const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
};

//请求默认路径
const pathUrl = "api/zhxg-yxxt";
@Form.create()
export default class SzjdChannel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mkbz: 'GD005_4',
            hjmc: '',
            loading: false,
            fileList: [],
            data: {},//绿色通道申请信息
            nextUrl: '',
            search: '',
            operable: true,//是否显示环节
            nonOperationalData: {},//不可操作的数据
            sqlyFlag: false, //申请理由flag

        };
    }

    componentDidMount() {
        this.queryQztjAndYlhj((operable) => {
            if (operable) {
                this.loadLstdInfo();
                // this.updateZzbdBlzt();
            }
        });

    }

    //迎新现场已办理时迎新网站自动办理
    // updateZzbdBlzt = () =>{
    //         let zzbdhjid = this.getQueryString('pkid');
    //         NHFetch('/zzbd/updateZzbdBlzt', 'GET',{zzbdhjid:zzbdhjid,hjdm:'GD005_4'}).then(res => {
    //             if(res.data){
    //                 this.reloadMenu();
    //             }
    //         })
    // }

    //查询前置条件和依赖环节
    queryQztjAndYlhj = (callback) => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({
                nonOperationalData: { nologin: true },
                operable: false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj'];
                    this.setState({ nonOperationalData: res.data });
                    if (kfsj && kfsj['YXSJ'] != '1') {
                        this.setState({ operable: false });
                    }

                    if (ylhj && ylhj.length > 0) {
                        this.setState({ operable: false });
                    }

                    if (qzhj && qzhj.length > 0) {
                        this.setState({ operable: false });
                    } 
                    if (nohj) {
                        this.setState({ operable: false });
                    }
                    if (callback) {
                        callback(this.state.operable);
                    }

                }
            });
    }

    getQueryString = (name) => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }

    setMenuList = (menuList) => {
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC;
                this.setState({ hjmc: hjmc })
                nextCount = index + 1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if (menu) {
            let hjdm = menu.HJDM;
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID,
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: '',
            })
        }
    }


    //读取学生的绿色通道信息
    loadLstdInfo = () => {
        let xsid = getLoginUser().userId;
        let params = {
            xsid: xsid
        }
        if (xsid) {
            NHFetch(pathUrl + '/ztxy/swbl/lstd/getLstdData', 'GET', params).then(resData => {
                if (resData && resData.data) {
                    this.setState({ data: resData.data });
                }else{
                    this.setState({ data: {} });
                    this.props.form.resetFields();
                }
            });
        }
    }



    // 提交
    handleSubmit = e => {
        let xsids = getLoginUser().userId;
        this.setState({ loading: true });
        e.preventDefault();
        let zzbdhjid = this.getQueryString('pkid');
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                this.setState({ loading: false });
                return false;
            }
            let sqsm = values['sqsm'];
            let pkid = values['pkid'];
            let hjje = values['hjje'];
            let xfhj = values['xfhj'];
            let zsfhj = values['zsfhj'];
            let hjqx = values['hjqx'];
            let params = {
                hjje: hjje,
                xfhj: xfhj,
                zsfhj: zsfhj,
                hjqx: hjqx,
                sqsm: sqsm,
                xsid: xsids,
                zzbdhjid: zzbdhjid,
                pkid: pkid,
            }
            if (!pkid) {
                NHFetch('/lstdfd/insert', "POST", params)
                    .then(res => {
                        if (res && res.code == 200) {
                            message.success("绿色通道申请保存成功。");
                            this.loadLstdInfo();
                            this.reloadMenu();
                        }
                        this.setState({ loading: false })
                    }).catch((res) => {
                        this.setState({ loading: false })
                        return res;
                    });
            }
        });
    };




    //重新加载菜单
    reloadMenu = () => {
        this.refs.siderMenu.reloadMenuList()
    }


    render() {
        const { form } = this.props;
        let edit = false;
        const data = this.state.data;
        if (JSON.stringify(data) == "{}") {
            edit = true;
    
        }
        return (
            <div style={{ position: 'relative' }}>{this.state.operable ?
                <div>
                    <Flex className="w1180 mt-20" align="start">
                        <SiderMenu
                            ref={"siderMenu"}
                            setMenuList={this.setMenuList}
                            title="自助报到"
                            mkbz={this.state.mkbz}
                            /*     active={4}*/
                            menuType='自助报到' />
                        <Flex.Item className="page_con_r" styleName="">
                            <Breadcrumb
                                noLine
                                list={[
                                    { name: '首页', url: '/index' },
                                    { name: '自助报到', url: '/register' },
                                    { name: this.state.hjmc }
                                ]}
                            />
                            <div className="box">
                                <Hint />
                                {edit ? <Form onSubmit={this.handleSubmit}>
                                    <TitleTop title="申请信息" extra={false} />
                                    <div className="p-sw">
                                        <div style={{ display: 'none' }}>
                                            <NHFormItem id={'pkid'} initialValue={data['pkid']} form={form}>
                                                <Input />
                                            </NHFormItem>
                                        </div>
                                        <div className="form_item" style={{ marginLeft: '7%', width: '80%' }}>
                                            <NHFormItem id={'xfhj'} required={true} initialValue={data['xfhj']} form={form} formItemLayout={formItemLayout} label="缓缴学费">
                                                <InputNumber style={{ width: '50%' }} min={0} max={9999999} precision="0" placeholder="请填写缓缴学费（元）" />
                                            </NHFormItem>
                                            <NHFormItem id={'zsfhj'} required={true} initialValue={data['zsfhj']} form={form} formItemLayout={formItemLayout} label="缓缴住宿费">
                                                <InputNumber style={{ width: '50%' }} min={0} max={9999999} precision="0" placeholder="请填写缓缴住宿费（元）" />
                                            </NHFormItem>
                                            <NHFormItem id={'hjje'} required={true} initialValue={data['hjje']} form={form} formItemLayout={formItemLayout} label="缓缴总金额">
                                                <InputNumber style={{ width: '50%' }} min={0} max={9999999} precision="0" placeholder="请填写缓缴总金额（元）" />
                                            </NHFormItem>
                                            <NHFormItem id={'hjqx'} required={true} initialValue={data['hjqx']} form={form} formItemLayout={formItemLayout} label="缓缴期限">
                                                <InputNumber style={{ width: '50%' }} min={0} max={9999999} precision="0" placeholder="请填写缓缴期限（月）" />
                                            </NHFormItem>
                                            <NHFormItem id={'sqsm'} initialValue={data['sqsm']} form={form} required={true} formItemLayout={formItemLayout} label="家庭经济困难主要情况说明">
                                                <TextArea style={{ width: '50%' }} maxLength={500} placeholder="请填写家庭经济困难主要情况说明" />
                                            </NHFormItem>
                                        </div>
                                    </div>
                                    <div className="text-center mt-50">
                                        <Button type="primary" htmlType="submit" onClick={this.handleSubmit}>
                                            保存
                                        </Button>
                                        <NextButton nextUrl={this.state.nextUrl} search={this.state.search} />
                                    </div>
                                </Form> : <View reloadMenu={this.reloadMenu} nextUrl={this.state.nextUrl} loadLstdInfo={this.loadLstdInfo} search={this.state.search} data={this.state.data} />}
                            </div>
                        </Flex.Item>
                    </Flex>
                    <Spin style={{ position: 'absolute', top: '300px', left: '60%' }} spinning={this.state.loading} /> </div> : <CommonTip hjmc={"绿色通道(昭通学院)"} key={"GD005_4"} mkbz='GD005_4' nonOperationalData={this.state.nonOperationalData} />}
            </div>

        );
    }
}
