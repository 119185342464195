import React from 'react';
import {Link} from 'react-router-dom';
import {Flex} from '@components';
import {Form, Input, Checkbox, Row, Col, Upload, Button,message} from 'antd';
import {createUuid, getLoginUser} from '@utils/NHCore';
import TitleTop from '@pages/Assembly/titleTop';
import NHFetch from "@utils/NHFetch";
import NextButton from '@pages/SelfHelp/common/nextIndex'
const {TextArea} = Input;
const basicUrl = "/zzbd";

const shztList = {
    '-1':'审核不通过',
    '0':'未审核',
    '1':'审核通过',
    '2':'已办理',
    '9':'驳回'
}
export default class View extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dkyhmc:'',
            nextUrl:this.props.nextUrl,
            search:this.props.search,
            dkyhList:[],//贷款银行
            sfhj: this.props.sfhj, // 办理原因是否缓交
            jtknlbList: [], // 家庭困难类别List
        };
    }

    componentDidMount() {
        this.getDmkMc("YXWZ_DKYH");
        this.getDmkMc("WYDX_ZZBD_LSTD_JTKNLB");
    }

    componentWillReceiveProps (nextProps) {
            this.setState({
                sfhj: nextProps.sfhj
            })
    }

    //获取下拉列表value对应的名称
    getDmkMc = (dmbz) => {
        const {data} = this.props;
        let dkyhmc = this.state.dkyhmc;
        NHFetch(basicUrl + "/getDmkMc", "GET", {dmbz, dmbz})
            .then(res => {
                if (res) {
                    if (res.data) {
                        if(dmbz==="YXWZ_DKYH"){
                            this.setState({
                                dkyhList:res.data
                            })
                        }
                        
                        if (dmbz === 'WYDX_ZZBD_LSTD_JTKNLB') {
                            this.setState({
                                jtknlbList:res.data
                            })
                        }
                    }
                }
            });
    }

    getQueryString =(name)=> {
        return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[, ''])[1].replace(/\+/g, '%20')) || null
    }


    render() {
        const {data,BlztList} = this.props;
        const {dkyhList} = this.state;
        const { sfhj, jtknlbList } = this.state;
        let dkyhmc = ''
        if(data['dkyh']){
            if(dkyhList){
                dkyhList.map((item,index)=>{
                    if(item.DM === data.dkyh){
                        dkyhmc=item.MC;
                    }
                })
            }
        }
        let jtknlb = '';
        if(data['qtblyy']){
            if(jtknlbList){
                jtknlbList.map((item,index)=>{
                    if(item.DM === data.qtblyy){
                        jtknlb=item.MC;
                    }
                })
            }
        }
        
        let lstdBlztName = "";
        let isBlLstd = data['sfbllstd'] == '1';
        if(data['sfbllstd']){
            if(BlztList){
                BlztList.map((item,index)=>{
                    if(data['sfbllstd'] == item.VALUE){
                        lstdBlztName = item.LABEL;
                    }
                })
            }
        }
        return (
            <div>
                <TitleTop title="申请信息" extra={false}/>
                <div className="p-sw">
                    <div className="form_item" style={{width: '100%'}}>
                        <Row gutter={24}  style={{margin:"10px 0px"}} style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>绿色通道申请&nbsp;: </span></Col>
                            <Col span={20}><span>{lstdBlztName}</span></Col>
                        </Row>
                        {/* {data['sfbllstd']=='1' && <Row gutter={24}  style={{margin:"10px 0px"}} style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>申请类型&nbsp;: </span></Col>
                            <Col span={20}><span>{data['dklxmc']?data['dklxmc']:""}</span></Col>
                        </Row>} */}

                        {isBlLstd && !sfhj && <Row gutter={24}  style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>贷款银行&nbsp;: </span></Col>
                            <Col span={20}><span>{dkyhmc}</span></Col>
                        </Row>}

                        {isBlLstd && !sfhj && <Row gutter={24}  style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>6位数回执校验码&nbsp;: </span></Col>
                            <Col span={20}><span>{data['hzjym']?data['hzjym']:""}</span></Col>
                        </Row>}

                        {isBlLstd && !sfhj && <Row gutter={24}  style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>贷款合同编号&nbsp;: </span></Col>
                            <Col span={20}><span>{data['dkhtbh']?data['dkhtbh']:""}</span></Col>
                        </Row>}

                        {isBlLstd && sfhj && jtknlbList.length>0 && <Row gutter={24}  style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}} key={jtknlbList[0]}>家庭困难类别&nbsp;: </span></Col>
                            <Col span={20}><span>{jtknlb}</span></Col>
                        </Row>}

                        {isBlLstd && <Row gutter={24}  style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>申请理由&nbsp;: </span></Col>
                            <Col span={20}><span>{data['sqsm']?data['sqsm']:""}</span></Col>
                        </Row>}

                        {isBlLstd && <Row gutter={24}  style={{margin:"30px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>办理原因&nbsp;: </span></Col>
                            <Col span={20}>
                                {data['blyy'] && data['blyy'].map((elem, index) => {
                                    return (
                                        <span style={{
                                            borderColor: '#1890ff',
                                            borderRadius: '5px',
                                            padding: '2px 16px',
                                            border: '1px solid rgb(24, 144, 255)',
                                            margin: '5px',
                                            display: 'inline-block'
                                        }}>
                                       {elem}
                                   </span>
                                    );
                                })}
                            </Col>
                        </Row>}
                    </div>
                </div>
                
                {isBlLstd &&  <TitleTop title="申请审核" extra={false}/>}
                {isBlLstd && <div className="p-sw">
                    <div className="form_item" style={{width: '100%', marginBottom: '24px'}}>
                        <Row gutter={24}  style={{margin:"10px 0px"}}>
                            <Col span={4} style={{textAlign: 'right', paddingRight: 0}}><span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>审核状态&nbsp;: </span></Col>
                            <Col span={20}><span style={{color: '#0066FF'}}>{shztList[data['shzt']]}</span></Col>
                        </Row>
                    </div>
                </div>}
                <div className="text-center mt-50">
                    <Button type="primary" onClick={()=>{this.props.loadLstdInfo()}}>
                        修改
                    </Button>
                    <NextButton nextUrl={this.state.nextUrl}  search={this.state.search}/>
                </div>
            </div>
        );
    }
}
