import React from 'react'
import { Flex } from '@components'
import { Form, Input, Row, Col, Button, Modal, Upload, Icon, message, Spin, InputNumber, Select, Tooltip } from 'antd'
import SiderMenu from '@pages/Assembly/siderMenu'
import Breadcrumb from '@pages/Assembly/Breadcrumb'
import TitleTop from '@pages/Assembly/titleTop'
import { createUuid, getLoginUser } from '@utils/NHCore'
import NHFetch from '@utils/NHFetch'
const Dragger = Upload.Dragger
import CommonTip from '@pages/SelfHelp/common'
import NextButton from '@pages/SelfHelp/common/nextIndex'
import { mkbz } from '@pages/Assembly/siderMenu/menuObj'
import Hint from '@pages/SelfHelp/common/hint'
import NHFormItem from '@components/common/NHFormItem'
import NHSelect from '@components/common/NHSelect'
import View from './view'

const { TextArea } = Input
const FormItem = Form.Item
const formItemLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 }
}
const Option = Select.Option

@Form.create()
export default class Channel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mkbz: 'GD005_13',
      hjmc: '',
      uploading: false,
      loading: false,
      previewVisible: false,
      fileList: [],
      data: {}, //绿色通道申请信息
      nextUrl: '',
      search: '',
      operable: true, //是否显示环节
      nonOperationalData: {}, //不可操作的数据
      judgeImgList: ['.png', '.PNG', '.jpg', '.JPG', '.bpm', '.BPM', '.jpeg', '.JPEG'], //可以导入的图片格式
      judgeFileList: ['.pdf', '.PDF', '.doc', '.DOC', '.zip', '.ZIP', '.txt', '.TXT', '.xls', '.XLS', '.rar', '.RAR'], //可以导入的非图片格式
      yjfyInfo: {}
    }
  }

  componentDidMount() {
    this.queryQztjAndYlhj(operable => {
      if (operable) {
        this.loadLstdInfo()
        this.updateZzbdBlzt()
      }
    })
  }

  //迎新现场已办理时迎新网站自动办理
  updateZzbdBlzt = () => {
    let zzbdhjid = this.getQueryString('pkid')
    NHFetch('/zzbd/updateZzbdBlzt', 'GET', { zzbdhjid: zzbdhjid, hjdm: 'GD005_13' }).then(res => {
      if (res.data) {
        this.reloadMenu()
      }
    })
  }

  //查询前置条件和依赖环节
  queryQztjAndYlhj = callback => {
    let xsid = getLoginUser() ? getLoginUser().userId : ''
    if (!xsid) {
      this.setState({
        nonOperationalData: { nologin: true },
        operable: false
      })
      return
    }
    let zzbdhjid = this.getQueryString('pkid')
    NHFetch('/zzbd/queryQztjAndYlhj', 'GET', { xsid: xsid, zzbdhjid: zzbdhjid }).then(res => {
      if (res && res.data) {
        let kfsj = res.data['kfsj']
        let ylhj = res.data['ylhj']
        let qzhj = res.data['qzhj']
        let nohj = res.data['nohj']
        this.setState({ nonOperationalData: res.data })
        if (kfsj && kfsj['YXSJ'] != '1') {
          this.setState({ operable: false })
        }

        if (ylhj && ylhj.length > 0) {
          this.setState({ operable: false })
        }

        if (qzhj && qzhj.length > 0) {
          this.setState({ operable: false })
        }

        if (nohj) {
          this.setState({ operable: false })
        }

        if (callback) {
          callback(this.state.operable)
        }
      }
    })
  }

  getQueryString = name => {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
  }

  setMenuList = menuList => {
    let nextCount = 0
    let bz = this.state.mkbz
    menuList.map((elem, index) => {
      if (elem.HJDM == bz) {
        let hjmc = elem.HJMC
        this.setState({ hjmc: hjmc })
        nextCount = index + 1
        return
      }
    })
    let menu = menuList[nextCount]
    if (menu) {
      let hjdm = menu.HJDM
      if (hjdm.indexOf('GD') == 0) {
        let gdUrl = mkbz[hjdm]
        this.setState({
          nextUrl: gdUrl,
          search: 'pkid=' + menu.PKID
        })
      } else if (hjdm.indexOf('ZDY') == 0) {
        let zdyUrl = '/customLink'
        this.setState({
          nextUrl: zdyUrl,
          search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX
        })
      }
    } else {
      this.setState({
        nextUrl: '',
        search: ''
      })
    }
  }

  //读取学生的绿色通道信息
  loadLstdInfo = () => {
    let xsid = getLoginUser() ? getLoginUser().userId : ''
    NHFetch('/hbnz/lstd/getLstdInfoByXsid', 'GET').then(resData => {
      this.setState({ yjfyInfo: resData.data?.yjfyInfo || {} })
      if (resData && resData.data && resData.data.lstdInfo) {
        this.setState({ data: resData.data.lstdInfo })
        let fileList = []
        if (resData.data.lstdInfo && resData.data.lstdInfo['fj']) {
          resData.data.lstdInfo['fj'].map((elem, index) => {
            //格式化对于附件取值
            let file = {
              uid: elem.fjid,
              name: elem.fjmc,
              response: {
                data: {
                  ssbh: elem.fjid,
                  fjmc: elem.fjmc
                }
              },
              status: 'done'
            }
            fileList.push(file)
          })
        }
        this.setState({ fileList: fileList })
        this.props.form.setFieldsValue({ fj: { fileList: this.state.fileList } })
      } else {
        this.setState({ data: {} })
        this.setState({ fileList: [] })
        this.props.form.resetFields()
      }
    })
  }

  // 转化base64
  getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  // 点击文件链接或预览图标时的回调
  handlePreview = file => {
    const isImage = /^image\/.*$/i.test(file.type)
    if (isImage) {
      if (file) {
        this.getBase64(file, imageUrl => {
          this.setState({
            previewImage: imageUrl,
            previewVisible: true
          })
        })
      } else {
        this.setState({
          previewImage: file.url || file.thumbUrl,
          previewVisible: true
        })
      }
    } else if (file && file['response'] && file['response']['data']) {
      //其他情况
      let isImage = true
      let fjmc = file['response']['data']['fjmc']
      let ssbh = file['response']['data']['ssbh']
      if (!this.setJudge(fjmc, this.state.judgeImgList)) {
        isImage = false
      }

      if (isImage) {
        this.showPreview(`anonymity/docrepo/download/file?attachmentId=${ssbh}`)
      } else {
        window.open(`anonymity/docrepo/download/file?attachmentId=${ssbh}`)
      }
    }
  }

  showPreview = url => {
    this.setState({
      previewImage: url,
      previewVisible: true
    })
  }

  // 关闭图片弹窗
  handleCancel = () => this.setState({ previewVisible: false })

  // 提交
  handleSubmit = e => {
    this.setState({ loading: true })
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        this.setState({ loading: false })
        return false
      }

      let pkid = values['pkid']
      let xsid = getLoginUser().userId
      let zzbdhjid = this.getQueryString('pkid')
      let fjlist = []

      // 处理附件
      // if (values.dklx !== 'hb_qt') {
      //   const fj = values['fj']
      //   let isEmpty = false
      //   if (fj) {
      //     if (fj['fileList'] && fj['fileList'].length > 0) {
      //       isEmpty = false
      //     } else {
      //       isEmpty = true
      //     }
      //   } else {
      //     isEmpty = true
      //   }

      //   if (isEmpty) {
      //     this.props.form.setFields({
      //       fj: {
      //         value: undefined,
      //         errors: [new Error('请上传材料证明')]
      //       }
      //     })
      //     this.setState({ loading: false })
      //     return false
      //   }

      //   let size = fj['fileList'].length
      //   let file = fj['fileList'].filter(file => {
      //     if (file.status === 'error') {
      //       return false
      //     }
      //     return true
      //   })
      //   if (file.length != size) {
      //     this.props.form.setFields({
      //       fj: {
      //         value: fj,
      //         errors: [new Error('材料上传出错，请重新上传.')]
      //       }
      //     })
      //     this.setState({ loading: false })
      //     return false
      //   }

      //   let fileList = fj['fileList']
      //   for (let i = 0; i < fileList.length; i++) {
      //     let fjid = fileList[i].response.data.ssbh
      //     let fjmc = fileList[i].name
      //     fjlist.push({
      //       fjid: fjid,
      //       fjmc: fjmc
      //     })
      //   }
      // }

      let params = {
        ...values,
        fj: fjlist,
        xsid,
        pkid,
        zzbdhjid
      }

      if (!pkid) {
        NHFetch('/hbnz/lstd/insert', 'POST', params)
          .then(res => {
            if (res) {
              message.success('绿色通道申请保存成功。')
              this.loadLstdInfo()
              this.reloadMenu()
            }
            this.setState({ loading: false })
          })
          .catch(res => {
            this.setState({ loading: false })
            return res
          })
      } else {
        NHFetch('/hbnz/lstd/update', 'POST', params)
          .then(res => {
            if (res && res.data && res.data > 0) {
              message.success('绿色通道申请保存成功。')
              this.loadLstdInfo()
              this.reloadMenu()
            } else {
              message.warning('绿色通道申请保存失败，请重试。')
            }
            this.setState({ loading: false })
          })
          .catch(res => {
            this.setState({ loading: false })
            return res
          })
      }
    })
  }

  handleChange = ({ fileList }) => {
    let count = 0
    fileList.map(elem => {
      if (elem && elem['status'] && elem['status'] === 'uploading') {
        this.setState({ uploading: true })
        return
      } else if (elem && elem['status']) {
        count++
      }
    })
    if (count > 0) {
      this.setState({ uploading: false })
    }
  }

  //判断文件类型是否正常  name：文件名称  map：类型范围数组  true正常
  setJudge = (name, map) => {
    let flag = false
    map.map(item => {
      if (name.indexOf(item) !== -1) {
        flag = true
      }
    })
    return flag
  }

  beforeUpload = file => {
    if (!(this.setJudge(file.name, this.state.judgeImgList) || this.setJudge(file.name, this.state.judgeFileList))) {
      message.error('不能上传该格式文件')
      return false
    } else {
      return true
    }
  }

  remove = ssbh => {
    let url = `api/docrepo/attachment/sourceId/delete?souerceId=${ssbh}`
    NHFetch(url, 'POST').then(res => {
      if (res.data && res.data > 0) {
        message.success('已删除材料.')
      }
    })
  }

  //重新加载菜单
  reloadMenu = () => {
    this.refs.siderMenu.reloadMenuList()
  }

  render() {
    const { form } = this.props
    const { getFieldDecorator, getFieldValue } = this.props.form
    const { previewImage, previewVisible, data, yjfyInfo } = this.state
    const props = {
      name: 'file',
      action: 'zuul/docrepo/upload',
      data: { uuid: createUuid(), type: '1' },

      onRemove: file => {
        this.setState(
          ({ fileList }) => {
            const index = fileList.indexOf(file)
            const newFileList = fileList.slice()
            newFileList.splice(index, 1)
            this.props.form.setFieldsValue({ fj: { fileList: newFileList } })
            return {
              fileList: newFileList
            }
          },
          () => {
            if (file.response && file.response.data && file.response.data.ssbh) {
              this.remove(file.response.data.ssbh)
            }
          }
        )
      },
      beforeUpload: file => {
        if (!this.beforeUpload(file)) {
          return false
        }
        this.setState(({ fileList }) => ({
          fileList: [...fileList, file]
        }))
        return true
      },
      onChange: file => {
        this.handleChange(file)
      },
      onPreview: this.handlePreview,
      fileList: this.state.fileList
    }

    let edit = false
    if (JSON.stringify(data) == '{}' || !data) {
      edit = true
    }

    return (
      <div style={{ position: 'relative' }}>
        {this.state.operable ? (
          <div>
            <Flex className="w1180 mt-20" align="start">
              <SiderMenu ref={'siderMenu'} setMenuList={this.setMenuList} title="自助报到" mkbz={this.state.mkbz} menuType="自助报到" />
              <Flex.Item className="page_con_r" styleName="">
                <Breadcrumb noLine list={[{ name: '首页', url: '/index' }, { name: '自助报到', url: '/register' }, { name: this.state.hjmc }]} />
                <div className="box">
                  <Hint />
                  {edit ? (
                    <Form onSubmit={this.handleSubmit}>
                      <TitleTop title="申请信息" extra={false} />
                      <div className="p-sw">
                        <div style={{ display: 'none' }}>
                          <NHFormItem id={'pkid'} initialValue={data['pkid']} form={form}>
                            <Input />
                          </NHFormItem>
                        </div>
                        <div className="form_item" style={{ marginLeft: '7%' }}>
                          <NHFormItem id={'dklx'} required={true} form={form} formItemLayout={formItemLayout} label="类型选择">
                            <NHSelect sign="DMK_YXXT_DKLX" placeholder="请选择绿色通道类型" />
                          </NHFormItem>
                          {getFieldValue('dklx') === 'hb_sydzxdk' && (
                            <>
                              <NHFormItem id={'jtzrk'} required={true} form={form} formItemLayout={formItemLayout} label="家庭总人口">
                                <InputNumber style={{ width: '100%' }} min={0} precision="0" placeholder="请填写家庭总人口" />
                              </NHFormItem>
                              <NHFormItem id={'jtrjysr'} required={true} form={form} formItemLayout={formItemLayout} label="家庭人均月收入">
                                <InputNumber style={{ width: '100%' }} min={0} precision="0" placeholder="请填写家庭人均月收入(元)" />
                              </NHFormItem>
                              <NHFormItem id={'jtqk'} required={true} form={form} formItemLayout={formItemLayout} label="家庭情况">
                                <NHSelect sign="DMK_XSXX_JTQK" placeholder="请选择家庭情况" />
                              </NHFormItem>
                              <NHFormItem id={'sydzxdkblqk'} initialValue={2} form={form} formItemLayout={formItemLayout} label="生源地助学贷款办理情况">
                                <Select defaultValue={2} placeholder="请选择生源地助学贷款办理情况">
                                  <Option value={1}>办理完成</Option>
                                  <Option value={2}>已经申请，手续齐全，正在办理</Option>
                                </Select>
                              </NHFormItem>
                              <NHFormItem
                                id={'hzjym'}
                                required={false}
                                form={form}
                                formItemLayout={formItemLayout}
                                label="助学贷款回执校验码"
                                pattern={{ pattern: /^\d{6}$/, message: '请输入6位数字' }}
                              >
                                <InputNumber style={{ width: '100%' }} precision="0" placeholder="请填写助学贷款回执校验码" />
                              </NHFormItem>
                              <NHFormItem id={'yszzqk'} form={form} formItemLayout={formItemLayout} label="已受资助情况">
                                <TextArea autoSize={{ minRows: 2 }} style={{ width: '100%' }} placeholder="请填写已受资助情况" />
                              </NHFormItem>

                              <Row gutter={24} style={{ marginBlock: 24 }}>
                                <Col span={9} style={{ textAlign: 'right', paddingRight: 0 }}>
                                  <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>应缴学费&nbsp;: </span>
                                </Col>
                                <Col span={15}>
                                  <span>{yjfyInfo.yjxf}元</span>
                                </Col>
                              </Row>
                              <Row gutter={24} style={{ marginBlock: 24 }}>
                                <Col span={9} style={{ textAlign: 'right', paddingRight: 0 }}>
                                  <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>应缴住宿费&nbsp;: </span>
                                </Col>
                                <Col span={15}>
                                  <span>{yjfyInfo.yjzsf}元</span>
                                </Col>
                              </Row>

                              <NHFormItem
                                id={'xjje'}
                                required={true}
                                form={form}
                                formItemLayout={formItemLayout}
                                label={
                                  <Tooltip title="因家庭困难等原因暂不能交齐学费，故申请先交金额" placement="left" defaultVisible>
                                    先交金额
                                  </Tooltip>
                                }
                              >
                                <InputNumber style={{ width: '100%' }} min={0} max={9999999} precision="0" placeholder="请填写先交金额(元)" />
                              </NHFormItem>

                              <NHFormItem
                                id={'hjje'}
                                required={true}
                                form={form}
                                formItemLayout={formItemLayout}
                                label={
                                  <Tooltip title="因家庭困难等原因暂不能交齐学费，故申请缓交金额" placement="left" defaultVisible>
                                    缓交金额
                                  </Tooltip>
                                }
                              >
                                <InputNumber style={{ width: '100%' }} min={0} max={9999999} precision="0" placeholder="请填写缓交金额(元)" />
                              </NHFormItem>
                              <NHFormItem id={'hjfs'} required={true} form={form} formItemLayout={formItemLayout} label="缓交方式">
                                <NHSelect sign="DMK_HBNZ_YXWZ_LSTD_HJFS" placeholder="请选择缓交方式" />
                              </NHFormItem>
                              {getFieldValue('hjfs') === '9' && (
                                <NHFormItem id={'qthjfs'} required={true} form={form} formItemLayout={formItemLayout} label="其他方式">
                                  <Input style={{ width: '100%' }} placeholder="请填写其他方式" />
                                </NHFormItem>
                              )}
                            </>
                          )}
                        </div>
                      </div>

                      {/* {getFieldValue('dklx') && getFieldValue('dklx') !== 'hb_qt' && (
                        <>
                          <TitleTop title="申请材料" extra={false} />
                          <div className="p-sw">
                            <div className="form_item" style={{ marginLeft: '7%' }}>
                              <FormItem labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} label="申请材料">
                                {getFieldDecorator('fj', {
                                  rules: [
                                    {
                                      required: true,
                                      message: '请上传申请材料'
                                    }
                                  ]
                                })(
                                  <Dragger {...props}>
                                    <p className="ant-upload-drag-icon">
                                      <Icon type="inbox" />
                                    </p>
                                    <Spin spinning={this.state.uploading} />
                                    <p className="ant-upload-text font-14">点击此处或拖拽文件到此</p>
                                  </Dragger>
                                )}
                              </FormItem>
                            </div>
                          </div>
                        </>
                      )} */}

                      <div className="text-center mt-50">
                        <Button type="primary" htmlType="submit">
                          保存
                        </Button>
                        <NextButton nextUrl={this.state.nextUrl} search={this.state.search} />
                      </div>
                    </Form>
                  ) : (
                    <View
                      data={data}
                      setJudge={this.setJudge}
                      reloadMenu={this.reloadMenu}
                      nextUrl={this.state.nextUrl}
                      loadLstdInfo={this.loadLstdInfo}
                      search={this.state.search}
                      showPreview={this.showPreview}
                      judgeImgList={this.state.judgeImgList}
                      yjfyInfo={yjfyInfo}
                    />
                  )}
                </div>
              </Flex.Item>
            </Flex>
            <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
            <Spin style={{ position: 'absolute', top: '300px', left: '60%' }} spinning={this.state.loading} />{' '}
          </div>
        ) : (
          <CommonTip hjmc={'绿色通道申请'} key={'GD005_13'} mkbz="GD005_13" nonOperationalData={this.state.nonOperationalData} />
        )}
      </div>
    )
  }
}
