
import React, { useState, useEffect, useRef, } from 'react';
import { Icon, Tooltip, Form } from 'antd';
import classNames from 'classnames';
import PropTypes from "prop-types";
import CanvasDraw from "react-canvas-draw";

function SignDraw(props) {
    const { label, onChange, formItemLayout, canvasWidth } = props;
    const signCanvas = useRef(null);
    useEffect(() => {
    }, []);

    const onCanvasChange = (instance) => {
        if(instance.lines.length === 0) {
            onChange({ signImg: null });
            return
        }
        const base64 = instance.canvas.drawing.toDataURL('image/png');
        var img = new Image(); //创建图片对象
        img.src = base64;
        img.onload = () => {
            var c = document.createElement("canvas"); //创建处理画布对象
            var ctx = c.getContext("2d");
            c.width = img.width;
            c.height = img.height;
            ctx.drawImage(img, 0, 0); //绘制
            var imgData = ctx.getImageData(0, 0, c.width, c.height).data; //读取图片数据
            var lOffset = c.width,
                rOffset = 0,
                tOffset = c.height,
                bOffset = 0;
            for (var i = 0; i < c.width; i++) {
                for (var j = 0; j < c.height; j++) {
                var pos = (i + c.width * j) * 4;
                if (
                    imgData[pos] == 255 ||
                    imgData[pos + 1] == 255 ||
                    imgData[pos + 2] == 255 ||
                    imgData[pos + 3] == 255
                ) {
                    bOffset = Math.max(j, bOffset); // 找到有色彩的最下端
                    rOffset = Math.max(i, rOffset); // 找到有色彩的最右端
                    tOffset = Math.min(j, tOffset); // 找到有色彩的最上端
                    lOffset = Math.min(i, lOffset); // 找到有色彩的最左端
                }
                }
            }
            lOffset++;
            rOffset++;
            tOffset++;
            bOffset++;
            var x = document.createElement("canvas"); //创建处理后画布对象
            x.width = rOffset - lOffset + 20;
            x.height = bOffset - tOffset + 20;
            var xx = x.getContext("2d");
            xx.drawImage(img, lOffset-10, tOffset-10, x.width, x.height, 0, 0, x.width, x.height); //绘制
            onChange({ signImg: x.toDataURL() });
        };
    };

    const getSignImage = () => {
        let saveImg = signCanvas.current.getSaveData();
        if (saveImg) {
        let imgObj = JSON.parse(saveImg);
        if (imgObj.lines && imgObj.lines.length > 0) {
            let signImg = signCanvas.current.canvas.drawing.toDataURL('image/png');
            return signImg;
        }
        return "";
        }
    }

    const clearSign = () => {
        signCanvas.current.eraseAll();
    }

    const undoSign = () => {
        signCanvas.current.undo();
    }


    return (
        <Form.Item  {...formItemLayout} label={label}
            extra={
            <div>
                <Tooltip title="撤回"><Icon type="undo" onClick={undoSign} style={{ color: '#1890ff' }} /></Tooltip>&nbsp;&nbsp;
                <Tooltip title="清除"><Icon type="delete" onClick={clearSign} style={{ color: 'red' }} /></Tooltip>
            </div>
            }
        >
            <CanvasDraw
                ref={signCanvas}
                backgroundColor="#F5F8FE"
                gridColor="rgba(0, 0, 0, 0)"
                brushColor="#000"
                brushRadius={2}
                lazyRadius={2}
                canvasWidth={canvasWidth}
                canvasHeight={200}
                hideInterface={true}
                hideGrid={false}
                onChange={onCanvasChange}
            />
        </Form.Item>
    )
}

SignDraw.propTypes = {
    label: PropTypes.string,
	onChange: PropTypes.func,
    canvasWidth: PropTypes.number,
};
SignDraw.defaultProps = {
    label: "手写签批",
	onChange: () => {},
    canvasWidth: 400,
};
export default SignDraw;